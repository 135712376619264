import React from 'react';
import { Row, Col } from 'antd';
import MessagesTable from '../elements/messages/MessagesTable'; 

class Messages extends React.Component {

    render(){
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <MessagesTable
                        setAppStateValue={this.props.setAppStateValue}
                        chosen_driver={this.props.chosen_driver}
                        key={"messagesTable" + this.props.chosen_driver}
                    />
                </Col>
            </Row>
        );
    }
};

export default Messages;