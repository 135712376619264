const {
    REACT_APP_BRAND,
    REACT_APP_DRIVING_SCHOOL_SHORT_NAME,
    REACT_APP_GOOGLE_ANALYTICS_CODE,
    REACT_APP_LOGO_FULL_IMAGE,
    REACT_APP_LOGO_L_IMAGE,
    REACT_APP_LOGO_FULL_TEST_IMAGE,
    REACT_APP_LOGO_L_TEST_IMAGE,
    REACT_APP_IS_PFG,
    REACT_APP_PAYMENT_BEFORE_BOOKING,
    REACT_APP_API_URL,
    REACT_APP_IS_PRODUCTION,

    REACT_APP_IPSI_SERVER,
    REACT_APP_IPSI_CONFIG_ID,
    REACT_APP_IPSI_USERNAME,
    REACT_APP_IPSI_SECKEY,
    
    REACT_APP_ALLOW_CHANGE_FIRST_LESSON,
    REACT_APP_SEND_NOTIFICATION_TO_STUDENT_CHECKBOX

} = process.env;

if (window.location.hostname.includes('sandbox')) {
    document.body.classList.add('sandbox');
}

export default {
    brand: REACT_APP_BRAND,
    apiRoot: REACT_APP_API_URL,
    drivingSchoolShortName: REACT_APP_DRIVING_SCHOOL_SHORT_NAME,
    productName: 'Diary',
    isProduction: REACT_APP_IS_PRODUCTION === 'true',
    gaCode: REACT_APP_GOOGLE_ANALYTICS_CODE,
    dayStart: '05',
    dayEnd: '22',
    heardAboutUs: [
        'Family or friends',
        'Google Search',
        'Brochure',
        'Promotional Item',
        'Saw a ' + REACT_APP_DRIVING_SCHOOL_SHORT_NAME + ' car',
        'Facebook',
        'Attended a School event',
        'Gift Voucher',
        'Newspaper',
        'Saw a Driver Trainer',
        'Driver Trainer Referral',
        'Other'
    ],
    products: {
        singleLesson: 4,
        sixLesson: 8,
        tenLesson: 9,
        twentyFiveLesson: 17,
        agedAssessment: 18,
        otLesson: 19,
        otAssessment: 20,
        otAssessmentWithModifications: 21
    },
    bookingTypes: {
        lesson: 1,
        test: 2,
        aged: 3,
        k2d: 4,
        cert4: 5,
        otLesson: 10,
        otAssessment: 11,
        otAssessmentWithModifications: 12,
        cancellationFee: 13
    },
    historyEditDays: 21,
    logoFullImage: REACT_APP_LOGO_FULL_IMAGE,
    logoLImage: REACT_APP_LOGO_L_IMAGE,
    logoFullTestImage: REACT_APP_LOGO_FULL_TEST_IMAGE,
    logoLTestImage: REACT_APP_LOGO_L_TEST_IMAGE,
    isPFG: REACT_APP_IS_PFG === 'true',
    paymentBeforeBooking: REACT_APP_PAYMENT_BEFORE_BOOKING === 'true',

    IPSI_server: REACT_APP_IPSI_SERVER,
    IPSI_configId: REACT_APP_IPSI_CONFIG_ID,
    IPSI_userName: REACT_APP_IPSI_USERNAME,
    IPSI_secKey: REACT_APP_IPSI_SECKEY,

    allowChangeFirstLesson: REACT_APP_ALLOW_CHANGE_FIRST_LESSON === 'true',
    showSendNotificationToStudentCheckbox: REACT_APP_SEND_NOTIFICATION_TO_STUDENT_CHECKBOX === 'true'

}
