import React from 'react';
import {Link} from 'react-router-dom';
import { Alert, Button, Form, Input } from 'antd';
import api from '../../../helpers/api';

import config from '../../../config';
const { logoFullImage } = config;
const images = require.context('../../../images/', true);
const logoFull = images('./' + logoFullImage);

class ResetPasswordForm extends React.Component {

    state = {
        errorMessage: false,
        errorMessageType: "error"
    };
    
    render() {
        let displayAlert = this.state.errorMessage !== false;
        const { getFieldDecorator } = this.props.form;

        var handleResetPassword = async (e) => {
            e.preventDefault();

            return api.post("users/reset-password", {
                body: JSON.stringify({
                    email: document.getElementById('login_username').value
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK'){
                    this.setState({
                        errorMessage: res.result.error,
                        errorMessageType: 'error'
                    });
                }else if (typeof res.result.message !== 'undefined') {
                    this.setState({
                        errorMessage: res.result.message,
                        errorMessageType: 'success'
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    errorMessage: "Error trying to send a Password Reset email. Contact the office if this problem persists.",
                    errorMessageType: 'error'
                });
            });
        };

        return (
            <div className='login-screen'>
                <div className="login-container">
                    <div className="logo-container"><img src={logoFull} className="big-logo" alt="logo" /></div>
                    <Form id="loginForm" onSubmit={handleResetPassword} layout='vertical'>
                        <Form.Item label="Email or Licence Number" >
                          {getFieldDecorator('username', {
                            rules: [{ required: true, message: 'Please enter your Email or Licence Number.' }],
                          })(
                            <Input required="required" autoFocus={true} />
                          )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Reset Password
                            </Button>
                            <Link to="/">
                                <Button type="link">
                                    Cancel
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>

                    <div className={displayAlert ? '' : 'hidden' }>
                        <Alert key="login-failed" message={this.state.errorMessage} type={this.state.errorMessageType} banner />
                    </div>
                </div>
            </div>
        );
    }
};

const ResetPassword = Form.create({name: 'login'})(ResetPasswordForm);

export default ResetPassword;