import React, {Component} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import update from 'immutability-helper';
import ReactGA from 'react-ga';
import history from './helpers/history';
import api from './helpers/api';
import 'antd/dist/antd.css';
import './App.css';
import Spinner from './components/elements/Spinner';
import MainSite from './components/MainSite';
import config from './config';

// No-Login Required Screens:
import Login from './components/elements/users/Login';
import ResetPassword from './components/elements/users/ResetPassword';
import CreatePassword from './components/elements/users/CreatePassword';
import AttendSDC from './components/pages/students/AttendSDC';

// Additional Elements
import BadTestModal from './components/elements/drivers/BadTestModal';

const { isProduction, isPFG } = config;

history.listen(location => {
    if(isProduction) {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }
});

let bad_tests = [];

class App extends Component {

    state = {
        isLoaded: false,
        isAuthenticated: false,
        user: {},
        office_user: false,
        chosen_driver: null,
        chosen_driver_name: null,
        counts: {
            messages: 0,
            urgent: 0,
            reminders: 0
        },
        first_load: true,
        driver_list: [],
        topNavDatePickerOpen: false,
        diaryCurrentDate: null,
        loginMessage: null,
        hide_menu_items: false,
        redirect_to_messages: false,
        bad_test_modal_visible: false
    };

    testResultChecker = () => {
        if (bad_tests.length > 0) {
            // Show Modal asking for a Result Pass/Fail
            this.setState({bad_test_modal_visible: true});
        }
    };

    testResultSave = (test_id, result) => {
        // Save this Test result.
        api.post("tests/update-result", {
            body: JSON.stringify({
                chosen_driver: this.state.chosen_driver,
                lesson: test_id,
                result: (result ? "true" : "false")
            })
        });
        this.setState({bad_test_modal_visible: false}, () => {
            // Remove this Test from Array, recall Check function
            bad_tests.splice(0, 1);
            this.testResultChecker();
        });
    }

    agedAssessmentResultSave = (test_id, result) => {
        // Save this Test result.
        api.post("lessons/update-aged-assessment-result", {
            body: JSON.stringify({
                chosen_driver: this.state.chosen_driver,
                lesson: test_id,
                result: (result ? "true" : "false")
            })
        });
        this.setState({bad_test_modal_visible: false}, () => {
            // Remove this Test from Array, recall Check function
            bad_tests.splice(0, 1);
            this.testResultChecker();
        });
    }

    async componentDidMount() {
        let url = new URL(window.location.href);
        var chosenDriver = url.searchParams.get("change_driver_id");
        var chosenDriverAction = url.searchParams.get("action");

        if(isProduction) {
            ReactGA.pageview(window.location.pathname);
        }
        
        if(localStorage.getItem('studentAccess') !== null){
            // SDC Student attempting access. Get rid of them.
            // Clear their token
            localStorage.clear();
            // Redirect to the attendance page - if using an Access Code, reload to re-use it.
            if (window.location.href.indexOf("/attend/")) {
                window.location.reload();
            } else {
                // Otherwise redirect to the standard Student Login screen.
                window.location.href = '/attend';
            }
        }

        if (localStorage.getItem('authToken')) {
            if(!!chosenDriver) {
                api.post("drivers/select", {
                    body: JSON.stringify({
                        chosen_driver: chosenDriver
                    })
                })
                .then(() => {
                    api.post("drivers/profile", {
                        body: JSON.stringify({
                            chosen_driver: chosenDriver
                        })
                    }).then((result) => {
                        localStorage.setItem('chosen_driver', result.result.driver.id);
                        localStorage.setItem('chosen_driver_name', (result.result.driver.user.first_name + " " + result.result.driver.user.last_name));
                        this.setState({
                            chosen_driver: result.result.driver.id,
                            chosen_driver_name: (result.result.driver.user.first_name + " " + result.result.driver.user.last_name),
                            redirect_to_messages: (!!chosenDriverAction && chosenDriverAction === 'view_messages')
                        });
                    });
                });

            } else if(localStorage.getItem('chosen_driver')) {
                console.log(`Using Driver Trainer \`${localStorage.getItem('chosen_driver_name')}\` (${localStorage.getItem('chosen_driver')})`);
                this.setState({
                    chosen_driver: localStorage.getItem('chosen_driver'),
                    chosen_driver_name: localStorage.getItem('chosen_driver_name')
                });
            } else {
                api.post("users/me", {})
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if(typeof res === 'string'){
                        res = JSON.parse(res);
                    }

                    //Check for an error response (status of "NOK")
                    if(res.status === 'NOK' || typeof res.result.user === 'undefined') {
                        this.logout();
                    } else {
                        this.setState({
                            isAuthenticated: true,
                            office_user: (typeof res.result.user.office_user === 'undefined' ? false : res.result.user.office_user),
                            user: res.result.user,
                            counts: {
                                messages: res.result.user.counts.messages,
                                urgent: res.result.user.counts.urgent,
                                reminders: res.result.user.counts.reminders
                            }
                        });
                    }
                    this.setState({
                        isLoaded: true,
                        first_load: false
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.logout();
                });
            }
        }else{
            this.setState({
                isLoaded: true
            });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if(window.location.pathname === "/logout") {
            this.logout();
        } else if(this.state.chosen_driver !== null && (this.state.chosen_driver !== prevState.chosen_driver || this.state.first_load)) {
            if (this.state.redirect_to_messages) {
                //Redirect, which will reset the session as far as the redirect goes.
                return window.location.replace('/messages');
            } else {
                this.setState({
                    first_load: false
                }, () => {
                    api.post("users/me", {
                        body: JSON.stringify({
                            chosen_driver: parseInt(this.state.chosen_driver)
                        })
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }

                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK' || typeof res.result.user === 'undefined') {
                            this.logout();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                office_user: (typeof res.result.user.office_user === 'undefined' ? false : res.result.user.office_user),
                                user: res.result.user,
                                counts: {
                                    messages: res.result.user.counts.messages,
                                    urgent: res.result.user.counts.urgent,
                                    reminders: res.result.user.counts.reminders
                                }
                            }, ()=> {
                                this.setState({
                                    isLoaded: true
                                });
                                if (typeof(res.result.bad_tests) !== 'undefined') {
                                    bad_tests = res.result.bad_tests;
                                    this.testResultChecker();
                                }
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.logout();
                    });
                });
            }
        }
    }

    logout = () => {
        this.setState({
            isAuthenticated: false,
            office_user: false,
            user: {},
            counts: {
                messages: 0,
                urgent: 0,
                reminders: 0
            },
            chosen_driver: null,
            chosen_driver_name: null,
            first_load: true,
            driver_list: [],
            isLoaded: true
        }, () => {
            localStorage.clear();
            window.history.pushState('logout', '', '/');
        });
    }

    setAppStateValue = async (key, value, replaceObject = false, callback) => {
        //If value is an object, loop through it, assigning children
        if(typeof value === 'object' && !replaceObject){
            for (var itemKey in value) {
                this.setState({
                    [key]: update(this.state[key], {[itemKey]: {$set: value[itemKey]}})
                });
            }
        }
        //Assume we were passed a simple string and assign it.
        else{
            this.setState({
                [key]: value
            });
        }
        if(typeof callback === 'function'){
            callback();
        }
    }

    deselectText = () => {
        window.getSelection().removeAllRanges();
    };

    stripPhoneNumber = (phone_number) => {
        if (phone_number === null) {
            return null;
        }
        return phone_number.replace(/\D/g,'');
    };

    render() {
        let content = !this.state.isAuthenticated ?
            (
                <Router history={history}>
                    <Switch>
                        <Route exact path="/reset" render={(props) => 
                            <ResetPassword />
                        } />
                        <Route path="/reset/token" render={(props) => 
                            <CreatePassword
                                setAppStateValue = {this.setAppStateValue}
                            />
                        } />
                        <Route path="/attend/:hash" render={(props) => 
                            <AttendSDC
                                setAppStateValue = {this.setAppStateValue}
                            />
                        } />
                        <Route path="/attend" render={(props) => 
                            <AttendSDC
                                setAppStateValue = {this.setAppStateValue}
                            />
                        } />
                        <Route render={(props) => 
                            <Login
                                setAppStateValue = {this.setAppStateValue}
                                loginMessage = {this.state.loginMessage}
                            />
                        } />
                    </Switch>
                </Router>
            ) :
            ( <div>
                <Router history={history}>
                    <MainSite
                        {...this.state}
                        setAppStateValue = { this.setAppStateValue }
                        logout = { this.logout }
                        stripPhoneNumber={ this.stripPhoneNumber }
                    />
                </Router>
                {this.state.bad_test_modal_visible && (
                    <BadTestModal
                        test = { bad_tests[0] }
                        testResultSave = { this.testResultSave }
                        agedAssessmentResultSave = { this.agedAssessmentResultSave }
                    />
                )}
            </div> );
        return (
            <div
                className={"App" + (isPFG ? " pfg" : "")}
            >
                {this.state.isLoaded ? content : (<Spinner type="full-page" />)} 
            </div>
        );
    }
};

export default App;
