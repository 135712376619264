import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Badge } from 'antd';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faCalendar, faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faBook, faCog, faCalendarTimes, faChartLine, faClipboardCheck, faUsers, faUserPlus } from '@fortawesome/free-solid-svg-icons';

const LeftNavigation = withRouter(props => {
    const { collapsed, counts } = props;

    return (
        <div className={collapsed ? "sidebar-collapsed" : "sidebar-expanded"}>
            <Menu
                selectedKeys={[window.location.pathname.split("/")[1]]}
                onClick={(e) => {e.domEvent.stopPropagation();}}
            >
                <Menu.Item key="diary" onClick={(e)=>{props.history.push('/' + e.key);}}>
                    <FontAwesomeIcon fixedWidth icon={faCalendar} size="lg" /><span>Calendar</span>
                </Menu.Item>
                <Menu.Item key="students" onClick={(e)=>{props.history.push('/' + e.key);}}>
                    <FontAwesomeIcon fixedWidth icon={faAddressCard} size="lg" /><span>Students</span>
                </Menu.Item>
                <Menu.Item key="library" onClick={(e)=>{props.history.push('/' + e.key);}}>
                    <FontAwesomeIcon fixedWidth icon={faBook} size="lg" /><span>Resources</span>
                </Menu.Item>
                <Menu.Item key="messages" onClick={(e)=>{props.history.push('/' + e.key);}}>
                    <FontAwesomeIcon fixedWidth icon={faEnvelopeOpen} size="lg" /><Badge count={counts.messages} className={counts.urgent > 0 ? "fe-pulse":"" }><span>Messages</span></Badge>
                </Menu.Item>
                <Menu.Item key="reports" onClick={(e)=>{props.history.push('/' + e.key);}}>
                    <FontAwesomeIcon fixedWidth icon={faChartLine} size="lg" /><span>Insights</span>
                </Menu.Item>
                <Menu.Item key="profile" onClick={(e)=>{props.history.push('/' + e.key);}}>
                    <FontAwesomeIcon fixedWidth icon={faCog} size="lg" /><span>Admin</span>
                </Menu.Item>

                {
                    (window.location.pathname.split("/").pop() === '' || window.location.pathname.split("/")[1] === 'diary') &&
                    <div className="bottomLeft">
                        <Menu.Item key="booking_type_header" className="ant-menu-item booking-type-header" onClick={()=>{}} onItemHover={()=>{}}>
                            BOOKING TYPES
                        </Menu.Item>
                        <Menu.Item key="new_student" className="ant-menu-item event-menu-item first_lesson" onClick={()=>{}} onItemHover={()=>{}}>
                            <FontAwesomeIcon fixedWidth icon={faUserPlus} size="lg" /><span>New Student</span>
                        </Menu.Item>
                        <Menu.Item key="repeat_student" className="ant-menu-item event-menu-item booking" onClick={()=>{}} onItemHover={()=>{}}>
                            <FontAwesomeIcon fixedWidth icon={faUsers} size="lg" /><span>Repeat Student</span>
                        </Menu.Item>
                        <Menu.Item key="test" className="ant-menu-item event-menu-item test" onClick={()=>{}} onItemHover={()=>{}}>
                            <FontAwesomeIcon fixedWidth icon={faClipboardCheck} size="lg" /><span>Test</span>
                        </Menu.Item>
                        <Menu.Item key="event" className="ant-menu-item event-menu-item event" onClick={()=>{}} onItemHover={()=>{}}>
                            <FontAwesomeIcon fixedWidth icon={faCalendarTimes} size="lg" /><span>Event</span>
                        </Menu.Item>
                    </div>
                }
            </Menu>
        </div>
    );
});

export default LeftNavigation;