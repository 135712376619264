import React from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Select, Modal } from 'antd';
import api from '../../helpers/api';
import moment from 'moment';
import Spinner from '../elements/Spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAddressCard, faCalendar } from '@fortawesome/free-regular-svg-icons';

const { TextArea } = Input;
const { Option } = Select;

class LessonPlanForm extends React.Component {

    state = {
        student: {},
        lesson_plan: {},
        topics: [],
        practice_topics: [],
        new_topics: [],
        booking: {},
        old_dt: false,
        lesson_plans_loaded: false,
        saving: false
    };

    componentDidMount() {
        this.populateTopics();
        if(!this.props.newPlan){
            this.retrieveLessonPlan();
        }else{
            api.post("students/fetch/" + this.props.match.params.student_id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.student === 'undefined') {
                    this.props.history.push('/lesson-plans/' + this.props.match.params.student_id);
                } else {
                    this.setState({
                        student: res.result.student,
                        lesson_plans_loaded: true
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/lesson-plans/' + this.props.match.params.student_id);
            });
        }
    }

    populateTopics() {
        api.post("lessonPlans/topics", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }
            
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || typeof res.result.topics === 'undefined') {
                Modal.error({
                    title: "Unable to retrieve Lesson Topics",
                    content: (
                        <div>
                            <p>There was an issue retrieving a list of lesson topics.</p>
                        </div>
                    )
                });
            } else {
                this.setState({
                    topics: Object.keys(res.result.topics).map(
                        i => <Option key={res.result.topics[i].id}>{res.result.topics[i].display_number + ". " + res.result.topics[i].topic_name}</Option>
                    )
                });

            }
        })            
        .catch(error => {
            console.log(error);
        });
    }

    retrieveLessonPlan() {
        api.post("lessonPlans/fetch/" + this.props.match.params.booking_id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    student_id: this.props.match.params.student_id,
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result === 'undefined') {
                    Modal.error({
                        title: "Unable to retrieve Lesson Plan",
                        content: (
                            <div>
                                <p>Unable to retrieve existing lesson plan for booking.</p>
                            </div>
                        )
                    });
                } else {
                    this.setState({
                        booking: res.result.lessonPlan.booking,
                        old_dt: (!!res.result.lessonPlan.booking ? res.result.lessonPlan.booking.dt_attendance.driver_trainer_id : null),
                        student: res.result.lessonPlan.student,
                        lesson_plan: res.result.lessonPlan.lesson_plan,
                        new_topics: ((!!res.result.lessonPlan && !!res.result.lessonPlan.lesson_plan && !!res.result.lessonPlan.lesson_plan.new_topics) ? res.result.lessonPlan.lesson_plan.new_topics.map(t => t.topics_topic_id.toString()) : null),
                        practice_topics: ((!!res.result.lessonPlan && !!res.result.lessonPlan.lesson_plan && !!res.result.lessonPlan.lesson_plan.practice_topics) ? res.result.lessonPlan.lesson_plan.practice_topics.map(t => t.topics_topic_id.toString()) : null)
                    },() => {
                        this.setState({
                            lesson_plans_loaded: true
                        });
                    });
                }
            })            
            .catch(error => {
                console.log(error);
            });
    }

    saveLessonPlan = (e) => {
        e.preventDefault();

        return this.props.form.validateFields( async (err, values) => {
            if (!err) {
                this.setState({
                    saving: true
                });

                api.post("lessonPlans/save/" + (!!this.props.match.params.booking_id ? this.props.match.params.booking_id : 'new'), {
                    body: JSON.stringify({
                        chosen_driver: this.props.chosen_driver,
                        student_id: this.props.match.params.student_id,
                        lessonPlan: values
                    })
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if(typeof res === 'string'){
                        res = JSON.parse(res);
                    }
    
                    //Check for an error response (status of "NOK")
                    if(res.status === 'NOK') {
                        Modal.error({
                            title: "Error saving Lesson Plan",
                            content: (
                                <div>
                                    <p>There was an issue saving lesson plan, please try again</p>
                                </div>
                            )
                        });
                    } else {
                        this.setState({
                            saving: false
                        }, () => {
                            Modal.success({
                                title: "Lesson Plan Saved!",
                                content: (
                                    <div>
                                        <p>The Lesson Plan has been successfully saved.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.props.history.goBack();
                                    Modal.destroyAll();
                                },
                                keyboard: false
                            });
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
        });
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 5, offset: 4 },
                xl: {span: 5, offset: 4 }
            },
            wrapperCol: {
                xs: {span: 14},
                xl: {span: 14}
            }
        };
        let newTopicsObject = (!!this.state.new_topics ? {
            initialValue: this.state.new_topics
        } : {});

        let practiceTopicsObject = (!!this.state.practice_topics ? {
            initialValue: this.state.practice_topics
        } : {});

        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    {this.state.lesson_plans_loaded !== true || this.state.saving === true ?
                        (
                            <Spinner type="mega" />
                        ) : (
                        <>
                            <div className="lessonPlanLessonDetails">
                                <div>
                                    <FontAwesomeIcon icon={faAddressCard} />
                                    {!!this.state.student ? 
                                        this.state.student.user.first_name + " " + this.state.student.user.last_name :
                                        ""
                                    }
                                </div>
                                {typeof this.state.booking !== 'undefined' && !!this.state.booking.start_time &&
                                    <div>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        {
                                            (typeof this.state.booking.start_time !== 'undefined' && this.state.booking.start_time !== null ? moment(this.state.booking.start_time).format('D/MM/YYYY') : null)
                                        }
                                    </div>
                                }
                                {!!this.state.old_dt && (this.state.old_dt !== parseInt(this.props.chosen_driver)) && (
                                    <div>
                                        Lesson Done By {this.state.booking.dt_attendance.driver_trainer.display_name}
                                    </div>
                                )}
                            </div>
                            <Form {...formItemLayout} id="lessonPlanForm" onSubmit={this.saveLessonPlan}>
                                <Form.Item label="Location(s)" >
                                    {getFieldDecorator('locations', {
                                        initialValue: 
                                            (!!this.state.lesson_plan && !!this.state.lesson_plan.locations) 
                                            ? this.state.lesson_plan.locations 
                                            : (
                                                !!this.state.booking && !!this.state.booking.booking_start
                                                ? (this.state.booking.booking_start.street_number + " " + this.state.booking.booking_start.street_name + ', ' + this.state.booking.booking_start.suburb.suburb_name)
                                                : ""
                                            )
                                    })(
                                        <TextArea autoSize={{minRows: 4, maxRows:10}} autoFocus={true} />
                                    )}
                                </Form.Item>
                                <Form.Item label="New Topics" >
                                    {getFieldDecorator('new_topics', newTopicsObject)(
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            mode="multiple"
                                            placeholder="Select Topics"
                                            onChange={(value, option) => {
                                                this.value = value.sort((a,b) => {return a - b});
                                            }}
                                        >
                                            {this.state.topics}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Practice Topics">
                                    {getFieldDecorator('practice_topics', practiceTopicsObject)(
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            mode="multiple"
                                            placeholder="Select Topics"
                                            onChange={(value, option) => {
                                                this.value = value.sort((a,b) => {return a - b});
                                            }}
                                        >
                                            {this.state.topics}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Comments/Homework" >
                                    {getFieldDecorator('comments', {
                                        initialValue: (
                                            (!!this.state.lesson_plan && !!this.state.lesson_plan.comments) ? this.state.lesson_plan.comments + (
                                                (!!this.state.lesson_plan.homework ? "\n" + this.state.lesson_plan.homework : "")
                                            ) : "")
                                    })(
                                        <TextArea autoSize={{minRows: 4, maxRows:10}} />
                                    )}
                                </Form.Item>
                                <Button type="primary" htmlType="submit">
                                    <FontAwesomeIcon icon={faSave} />Save Lesson Plan
                                </Button>
                            </Form>
                        </>
                    )}
                </Col>
            </Row>
        );
    }
};

const LessonPlan = Form.create({name: 'lesson_plan_form'})(LessonPlanForm);

export default withRouter(LessonPlan);
