import React from 'react';
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';
import { Alert, Button, Form, Input } from 'antd';
import api from '../../../helpers/api';

import config from '../../../config';
const { logoFullImage } = config;
const images = require.context('../../../images/', true);
const logoFull = images('./' + logoFullImage);

class LoginForm extends React.Component {
    
    state = {
        loading: false,
        errorMessage: false,
        errorMessageType: "error"
    };

    componentDidMount() {
        if(this.props.loginMessage !== null){
            this.setState({
                errorMessage: this.props.loginMessage,
                errorMessageType: 'success'
            });
        }
    }

    render() {
        let displayAlert = this.state.errorMessage !== false;
        const { getFieldDecorator } = this.props.form;

        var handleLogin = async (e) => {
            e.preventDefault();

            this.setState({
                loading: true,
            }, () => {
                return api.post("users/login", {
                    body: JSON.stringify({
                        email: document.getElementById('login_username').value,
                        password: document.getElementById('login_password').value
                    })
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if(typeof res === 'string'){
                        res = JSON.parse(res);
                    }
                    //Check for an error response (status of "NOK")
                    if(res.status === 'NOK'){
                        this.setState({
                            errorMessage: res.result.error,
                            errorMessageType: 'error',
                            loading: false
                        });
                    }else if (typeof res.result.user === 'undefined' || typeof res.result.user.id === 'undefined') {
                        //In case a user id isn't returned (which will mean, by extension, that the jwt token is invalid), don't log the user in
                        this.setState({
                            errorMessage: "There was an issue during login, please try again.",
                            errorMessageType: 'error',
                            loading: false
                        });
                    } else if (res.result.access_token) {
                        localStorage.setItem('authToken', res.result.access_token);
                        this.props.setAppStateValue('user', res.result.user, true);
                        this.props.setAppStateValue('counts', {
                            messages: res.result.user.counts.messages,
                            urgent: res.result.user.counts.urgent,
                            reminders: res.result.user.counts.reminders
                        });
                        if(typeof res.result.user.office_user === 'undefined' || !res.result.user.office_user){
                            this.props.setAppStateValue('chosen_driver', res.result.user.id, true);
                            localStorage.setItem('chosen_driver', res.result.user.id);
                            localStorage.setItem('chosen_driver_name', res.result.user.first_name + ' ' + res.result.user.last_name);
                        }
                        this.props.setAppStateValue('office_user', (typeof res.result.user.office_user === 'undefined' ? false : res.result.user.office_user), false, () => {
                            if(typeof res.result.user.office_user !== 'undefined' && res.result.user.office_user){
                                this.props.history.push('/choose-driver');
                            }
                        });
                        this.props.setAppStateValue('isAuthenticated', true);
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        errorMessage: "Something went wrong while trying to login, please try again.",
                        errorMessageType: 'error',
                        loading: false
                    });
                });
            });
        };

        return (
            <div className='login-screen'>
                <div className="login-container">
                    <div className="logo-container"><img src={logoFull} className="big-logo" alt="logo" /></div>
                    <Form id="loginForm" onSubmit={handleLogin} layout='vertical'>
                        <Form.Item label="Email or Licence Number" >
                          {getFieldDecorator('username', {
                            rules: [{ required: true, message: 'Please enter your Email or Licence Number.' }]
                          })(
                            <Input required="required" autoFocus={true} />
                          )}
                        </Form.Item>
                        <Form.Item label="Password" >
                          {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please enter your password.' }]
                          })(
                            <Input type='password' required="required" />
                          )}
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.loading}
                                disabled={this.state.loading}
                            >
                                Log in
                            </Button>
                            <Link to="/reset">
                                <Button type="link">
                                    Reset Password
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>

                    <div className={displayAlert ? '' : 'hidden' }>
                        <Alert key="login-failed" message={this.state.errorMessage} type={this.state.errorMessageType} banner />
                    </div>
                </div>
            </div>
        );
    }
};

const Login = Form.create({name: 'login'})(LoginForm);

export default withRouter(Login);
