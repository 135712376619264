import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Alert, Select, Switch, Table, Modal, DatePicker, TimePicker, Tooltip, Checkbox } from 'antd';
import api from '../../helpers/api';
import moment from 'moment';
import Spinner from '../elements/Spinner';
import IPSI from '../elements/payments/IPSI';

import FullCalendar from '@fullcalendar/react'
import '@fullcalendar/core/main.css';
import timeGridPlugin from '@fullcalendar/timegrid'
import '@fullcalendar/timegrid/main.css';
import diaryHelper from '../../helpers/diary';
import config from '../../config';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faDollarSign, faHandHoldingUsd, faKey, faMapMarkedAlt, faMinus, faMoneyBillWave, faPhoneAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard, faCalendar, faCheckCircle, faClock, faListAlt, faSave } from '@fortawesome/free-regular-svg-icons';

const { Option } = Select;
const { TextArea } = Input;
const { Column } = Table;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const ButtonGroup = Button.Group;

const { dayStart, dayEnd, bookingTypes, products, historyEditDays, paymentBeforeBooking, allowChangeFirstLesson, showSendNotificationToStudentCheckbox } = config;

let suburbLookupTimeout;
let currentSuburb;

class Lesson extends React.Component {

    state = {
        booking_id: null,
        booking: null,
        start_time: null,
        end_time: null,
        duration: 0,
        future_lessons: [],
        first_lesson: false,
        k2dcode: null,
        test_centres: null,
        lesson_cost: 0,
        amount_owed: 0,
        allow_cancel: true,
        no_cancel_reason: null,
        loading: true,
        changeTimeModalVisible: false,
        changeTimeModalLoading: false,
        calendarLoading: false,
        disabled_hours: [],
        overlap: false,
        processCash: false,
        processPayment: false,
        loadingAddresses: false,
        addressData: [],
        addressDataPickup: null,
        addressDataDropoff: null,
        suburbs: [],
        newAddressModalVisible: false,
        newAddressModalLoading: false,
        payment_info: null,
        payment_info_icons: null,

        agedAssessmentPaymentModalVisible: false,
        otLessonPaymentModalVisible: false,
        otAssessmentPaymentModalVisible: false,
        otAssessmentWithModificationsPaymentModalVisible: false,

        unattached_lesson_plans: null,
        addLessonPlanModalVisible: false,
        processLessonPlanAttach: false,
        products: {}
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (typeof (prevState.booking) !== 'undefined' && prevState.booking !== null && parseInt(nextProps.match.params.id) !== prevState.booking_id){
            return {
                loading: true,
                booking_id: parseInt(nextProps.match.params.id)
            };
        }
        return null;
    };

    componentDidMount() {
        if(this.state.booking_id === null){
            this.setState({booking_id: parseInt(this.props.match.params.id)});
        }
    };
    
    componentDidUpdate(prevProps, prevState){
        if((parseInt(this.props.chosen_driver) !== parseInt(prevProps.chosen_driver)) || (parseInt(this.state.booking_id) !== parseInt(prevState.booking_id))){
            this.fetchLesson();
        }
    }

    retrieveLessonProducts = async () => {        
        return api.post("products/getLessonPackagesForStudent", {
            body: JSON.stringify({
                student_id: this.state.booking.student_attendance.student.id,
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK'){
                this.setState({
                    errorMessage: res.result.error,
                    errorMessageType: 'error'
                });
            } else {
                this.setState({
                    products: res.result.products
                });
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({
                errorMessage: "Error redeeming gift voucher, please try again.",
                errorMessageType: 'error'
            });
        });
    };

    fetchAddresses = async(student_id) => {
        api.post("students/addresses/" + student_id,{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            this.setState({
                loadingAddresses: false
            }, () => {
                if(data.result.addresses.all.length > 0){
                    const addresses = [];
                    data.result.addresses.all.forEach((address) => {
                        addresses.push(<Option key={address.id}>{address.address}</Option>);
                    });
                    this.setState({
                        addressData: addresses
                    });
                    if(this.state.booking.booking_start !== null){
                        this.setState({
                            addressDataPickup: <Option key={this.state.booking.booking_start.id}>{this.state.booking.booking_start.street_number + ' ' + this.state.booking.booking_start.street_name + ', ' + this.state.booking.booking_start.suburb.suburb_name + ' ' + this.state.booking.booking_start.suburb.post_code}</Option>
                        });
                    }
                    if(this.state.booking.booking_end !== null){
                        this.setState({
                            addressDataDropoff: <Option key={this.state.booking.booking_end.id}>{this.state.booking.booking_end.street_number + ' ' + this.state.booking.booking_end.street_name + ', ' + this.state.booking.booking_end.suburb.suburb_name + ' ' + this.state.booking.booking_end.suburb.post_code}</Option>
                        });
                    }
                }
            });
        });
    };

    checkForEnoughPrepaidLessonsToCoverDuration = (value, callback) => {
        api.post("students/fetch/" + value,{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            // Note that this differs from the function in LessonAdd.js - here we only look for the increase in duration.
            // We also only run the callback when duration is valid.
            var durationRequired = ((moment(this.state.end_time).diff(moment(this.state.start_time), 'minutes')/60) - (moment(this.state.booking.end_time).diff(moment(this.state.booking.start_time), 'minutes')/60));
            if(data.result.student.prepaid.remaining < durationRequired) {
                //Disable the Save Button, show a message
                this.setState({
                    noPrepaids: true
                }, () => {
                    Modal.error({
                        title: "Not Enough Lesson Credits",
                        content: (
                            <div>
                                <p>Student does not have enough lesson credits, change duration or visit profile to take payment.</p>
                            </div>
                        )
                    });
                    //Re-enable fields
                    this.setState({
                        changeTimeModalLoading: false
                    });
                    document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = false;
                });
            } else {
                this.setState({
                    noPrepaids: false
                });
                if (typeof callback !== 'undefined') {
                    callback();
                }
            }
        });
    };

    handleAddressChangePickup = (value) => {
        if(value === 'new'){
            this.setState({
                newAddressModalVisible: true
            });
        } else {
            this.saveAddressToBooking(value, 'start_address');
        }
    };

    handleAddressChangeDropoff = (value) => {
        if(value === 'new'){
            this.setState({
                newAddressModalVisible: true
            });
        } else {
            this.saveAddressToBooking(value, 'end_address');
        }
    };

    saveAddressToBooking = (address_id, field) => {
        this.setState({
            newAddressModalLoading: true
        }, () => {
            api.post("bookings/updateAddress", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    booking: this.state.booking_id,
                    field: field,
                    address_id: address_id
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    Modal.error({
                        title: "Error Updating Address",
                        content: (
                            <div>
                                <p>There was an error when trying to Update this Booking.</p>
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                    this.setState({
                        newAddressModalLoading: false
                    });
                } else if(!res.result.success) {
                    Modal.error({
                        title: "Error Updating Booking Address",
                        content: (
                            <div>
                                <p>There was an error when trying to Update this Booking.</p>
                                 {(typeof(res.result.error) !== 'undefined' ? <p>{res.result.error}</p> : '')}
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                    this.setState({
                        newAddressModalLoading: false
                    });
                } else {
                    //Success - Re-Fetch the lesson to get updated Payment info and Times.
                    this.fetchLesson();
                    this.setState({
                        newAddressModalLoading: false,
                        newAddressModalVisible: false
                    });
                }
            })
            .catch(() => {
                Modal.error({
                    title: "Error Updating Booking Address",
                    content: (
                        <div>
                            <p>There was an error when trying to Update this Booking.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
                this.setState({
                    newAddressModalLoading: false
                });
            });
        });
    };

    newAddressModalHandleCancel = (e) => {
        this.setState({
            newAddressModalVisible: false
        }, () => {
            if(this.pickupRef.current.props.value === 'new'){
                this.props.form.resetFields(['pick_up_address']);
            }
            if(this.dropoffRef.current.props.value === 'new'){
                this.props.form.resetFields(['drop_off_address']);
            }
        });
    };

    newAddressModalHandleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['street_number', 'street_name', 'suburb_id'], (err, values) => {
            if (!err) {
                //save to DB and then add to drop downs, select in the one we were using.
                values.chosen_driver = this.props.chosen_driver;
                api.post("address/add", {
                    body: JSON.stringify(values)
                })
                .then(res => {
                    let addressData = this.state.addressData;
                    addressData.push(<Option key={res.result.id}>{res.result.address}</Option>);
                    this.setState({
                        addressData: addressData
                    },() => {
                        if(this.pickupRef.current.props.value === 'new'){
                            this.saveAddressToBooking(res.result.id, 'start_address');
                            this.props.form.setFieldsValue({
                                pick_up_address: [res.result.id.toString()]
                            });
                        }
                        if(this.dropoffRef.current.props.value === 'new'){
                            this.saveAddressToBooking(res.result.id, 'end_address');
                            this.props.form.setFieldsValue({
                                drop_off_address: [res.result.id.toString()]
                            });
                        }
                        // Close this modal.
                        this.setState({
                            newAddressModalVisible: false
                        });
                    });
                });
            }
        });
    };

    disableAutoComplete = () => {
        if (!this.state.autoCompleteDisabled) {
            const elementsToDisable = document.getElementsByClassName("ant-select-search__field");
            for (let i = 0; i < elementsToDisable.length; i++) {
                elementsToDisable[i].setAttribute("autocomplete","any-arbitrary-text");
            }
            this.setState({ autoCompleteDisabled: true });
        }
    };

    handleSuburbSearch = value => {
        this.suburbLookup(value, suburbs => this.setState({ suburbs }));
    };

    suburbLookup = (value, callback) => {
        if (suburbLookupTimeout) {
            clearTimeout(suburbLookupTimeout);
            suburbLookupTimeout = null;
        }
        currentSuburb = value;

        function debouncedLookup() {
            api.post("suburbs/search", {
                body: JSON.stringify({
                    search: value
                })
            })
            .then(d => {
                if (currentSuburb === value) {
                    const data = [];
                    d.result.suburbs.forEach(r => {
                        data.push({
                            value: r.id,
                            text: r.suburb_name
                        });
                    });
                    callback(data);
                }
            });
        }
        suburbLookupTimeout = setTimeout(debouncedLookup, 300);
    }

    fetchLesson = async() => {
        await api.post("lessons/fetch/" + this.state.booking_id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.booking === 'undefined' || res.result.booking === null) {
                    this.props.history.push('/diary');
                } else {
                    // If this is an EVENT - Redirect here.
                    if (res.result.booking.booking_type_id === 7 || res.result.booking.booking_type_id === 8) {
                        return this.props.history.push('/event/' + this.state.booking_id);
                    }
                    
                    // Determine whether to show Cancel Lesson button.
                    // * All future Lessons
                    // * Past Lessons, so long as started within the last 30 minutes.
                    // * Older lessons would need the office to do the Cancel.
                    // * Can't Cancel Lessons for NEW Students (i.e. first booking)
                    let allow_cancel = this.state.allow_cancel;
                    let no_cancel_reason = this.state.no_cancel_reason;
                    if(this.props.office_user && !moment(res.result.booking.start_time).isAfter(moment().subtract(historyEditDays, 'days'))) {
                        //Office Users can cancel lessons up to a certain number of days old (defined in config.js)
                        allow_cancel = false;
                        no_cancel_reason = 'happened more than ' + historyEditDays + ' days ago.';
                    }else if(!this.props.office_user && !moment(res.result.booking.start_time).isAfter(moment().subtract(30, 'minutes'))) {
                        //Drivers can only cancel lessons that started no longer than 30 minutes ago.
                        allow_cancel = false;
                        no_cancel_reason = 'started over 30 minutes ago.';
                    } else if(res.result.first_lesson && !allowChangeFirstLesson) {
                        allow_cancel = false;
                        no_cancel_reason = 'is for a New Student.';
                    }
                    
                    //Fetch Addresses for this Student
                    this.fetchAddresses(res.result.booking.student_attendance.student.id);

                    // Update State
                    this.setState({
                        loading: false,
                        booking: res.result.booking,
                        k2dcode: res.result.k2dcode,
                        unattached_lesson_plans: res.result.unattached_lesson_plans,
                        start_time: res.result.booking.start_time,
                        end_time: res.result.booking.end_time,
                        duration: moment(res.result.booking.end_time).diff(moment(res.result.booking.start_time), 'minutes')/60,
                        future_lessons: res.result.future_lessons,
                        test_centres: res.result.test_centres, 
                        lesson_cost: res.result.lesson_cost,
                        amount_owed: res.result.amount_owed,
                        allow_cancel: allow_cancel,
                        no_cancel_reason: no_cancel_reason,
                        payment_info: res.result.payment_info,
                        payment_info_icons: res.result.payment_info_icons
                    }, () => {
                        this.retrieveLessonProducts();
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/diary');
        });
    };
    
    confirmTentative = () => {
        api.post("lessons/confirm/" + this.state.booking_id, {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if(res.status === 'OK' && res.result.success === true) {
                var bookingData = this.state.booking;
                bookingData.tentative = false;
                this.setState({
                    booking: bookingData
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    calendarRef = React.createRef();
    timePickerRef = React.createRef();
    pickupRef = React.createRef();
    dropoffRef = React.createRef();

    eventFetch = (params = {}, successCallback) => {
        params.chosen_driver = this.props.chosen_driver;
        diaryHelper.eventFetch(params, successCallback);
    };

    calendarLoading = () => {
        this.setState({calendarLoading: !this.state.calendarLoading}, () => {
            if (this.state.calendarLoading) {
                // Show Spinner
                if (document.getElementsByClassName('calendar-spinner').length > 0) {
                    document.getElementsByClassName('calendar-spinner')[0].style['display'] = 'block';
                }
                if (document.getElementsByClassName('fc').length > 0) {
                    document.getElementsByClassName('fc')[0].style['visibility'] = 'hidden';
                }
            } else {
                // Hide Spinner
                if (document.getElementsByClassName('calendar-spinner').length > 0) {
                    document.getElementsByClassName('calendar-spinner')[0].style['display'] = 'none';
                }
                if (document.getElementsByClassName('fc').length > 0) {
                    document.getElementsByClassName('fc')[0].style['visibility'] = 'visible';
                }
            }
        });
    };

    changeTimeModalShowModal = () => {
        if(this.state.allow_cancel) {
            this.setState({
                changeTimeModalVisible: true
            });
        } else {
            Modal.error({
                title: "Booking can not be Adjusted",
                content: (
                    <div>
                        <p>This Booking can not be Adjusted, because it {this.state.no_cancel_reason}</p>
                        <p>Please contact the Office if you require it to be Adjusted.</p>
                    </div>
                )
            });
        }
    };

    changeTimeModalHandleOk = () => {
        this.setState({
            changeTimeModalLoading: true
        }, () => {
            //Do Save Request
            document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = true;

            //Check for Prepaids if required
            var existingDuration = moment(this.state.booking.end_time).diff(moment(this.state.booking.start_time), 'minutes')/60;
            var newDuration = moment(this.state.end_time).diff(moment(this.state.start_time), 'minutes')/60;
            if (paymentBeforeBooking && newDuration > existingDuration) {
                //should only be an issue if increasing the duration.
                this.checkForEnoughPrepaidLessonsToCoverDuration(this.state.booking.student_attendance.student.id, () => {
                    this.updateBookingDateTimeDuration();
                });
            } else {
                this.updateBookingDateTimeDuration();
            }
        });
    };
    
    updateBookingDateTimeDuration = () => {
        let values = {
            chosen_driver: this.props.chosen_driver,
            booking: this.state.booking_id,
            start_time: this.state.start_time,
            end_time: this.state.end_time
        };
        if(showSendNotificationToStudentCheckbox) {
            // As of 2023-10-06, Don't show Notification Checkbox to DT Users
            if (this.props.office_user) {
                if (document.getElementById('send-confirmation-checkbox-datetime') !== null) {
                    values.send_confirmation = document.getElementById('send-confirmation-checkbox-datetime').checked;
                } else {
                    values.send_confirmation = false;
                }
                // NEVER send for Tentative Tests
                if (this.state.booking.booking_type_id === bookingTypes.test && this.state.booking.tentative) {
                    values.send_confirmation = false;
                }
            } else {
                // For DT's, ALWAYS send Notification
                let sendToStudent = true;
                // UNLESS it's a Tentative Test Booking
                if (this.state.booking.booking_type_id === bookingTypes.test && this.state.booking.tentative) {
                    sendToStudent = false;
                }
                values.send_confirmation = sendToStudent;
            }
        }
        api.post("bookings/update", {
            body: JSON.stringify(values)
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }

            //Check for an error response (status of "NOK")
            if(res.status === 'NOK') {
                Modal.error({
                    title: "Error Updating Booking Date/Time",
                    content: (
                        <div>
                            <p>There was an error when trying to Update this Booking.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
                document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = false;
                this.setState({
                    changeTimeModalLoading: false
                });
            } else if(!res.result.success) {
                Modal.error({
                    title: "Error Updating Booking Date/Time",
                    content: (
                        <div>
                            <p>There was an error when trying to Update this Booking.</p>
                             {(typeof(res.result.error) !== 'undefined' ? <p>{res.result.error}</p> : '')}
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
                document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = false;
                this.setState({
                    changeTimeModalLoading: false
                });
            } else {
                //Success - Re-Fetch the lesson to get updated Payment info and Times.
                this.fetchLesson();
                //Show a Success Modal
                Modal.success({
                    title: "Booking Updated!",
                    content: (
                        <div>
                            <p>The Booking has been successfully updated.</p>
                        </div>
                    ),
                    onOk: () => {
                        Modal.destroyAll();
                        this.setState({
                            changeTimeModalLoading: false,
                            changeTimeModalVisible: false
                        });
                    }
                });
            }
        })
        .catch(() => {
            Modal.error({
                title: "Error Updating Booking Date/Time",
                content: (
                    <div>
                        <p>There was an error when trying to Update this Booking.</p>
                        <p>If this problem persists, please contact the Office for assistance.</p>
                    </div>
                )
            });
            document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = false;
            this.setState({
                changeTimeModalLoading: false
            });
        });
    };

    changeTimeModalHandleCancel = () => {
        //Reset the start time, end time and duration to their original states.
        this.setState({
            changeTimeModalVisible: false
        }, () => {
            this.setState({
                start_time: this.state.booking.start_time,
                end_time: this.state.booking.end_time,
                duration: moment(this.state.booking.end_time).diff(moment(this.state.booking.start_time), 'minutes')/60
            });
        });
    };

    handleCancelLesson = (e) => {
        let cancel_reason = document.getElementById('cancel_reason');
        if(cancel_reason.value === '') {
            cancel_reason.parentElement.classList.add('has-feedback', 'has-error');
            cancel_reason.focus();
            return false;
        } else {
            document.querySelector('.cancel-lesson-modal .ant-modal-confirm-btns button').disabled = true;
            cancel_reason.parentElement.classList.remove('has-feedback', 'has-error');
            return new Promise((resolve, reject) => {
                let values = {
                    chosen_driver: this.props.chosen_driver,
                    booking: this.state.booking_id,
                    cancel_reason: cancel_reason.value
                };

                if(showSendNotificationToStudentCheckbox) {
                    // As of 2023-10-06, Don't show Notification Checkbox to DT Users
                    if (this.props.office_user) {
                        if (document.getElementById('send-confirmation-checkbox-cancel') !== null) {
                            values.send_confirmation = document.getElementById('send-confirmation-checkbox-cancel').checked;
                        } else {
                            values.send_confirmation = false;
                        }
                        // NEVER send for Tentative Tests
                        if (this.state.booking.booking_type_id === bookingTypes.test && this.state.booking.tentative) {
                            values.send_confirmation = false;
                        }
                    } else {
                        // For DT's, ALWAYS send Notification
                        let sendToStudent = true;
                        // UNLESS it's a Tentative Test Booking
                        if (this.state.booking.booking_type_id === bookingTypes.test && this.state.booking.tentative) {
                            sendToStudent = false;
                        }
                        values.send_confirmation = sendToStudent;
                    }
                }

                api.post("bookings/cancel", {
                    body: JSON.stringify(values)
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if(typeof res === 'string'){
                        res = JSON.parse(res);
                    }

                    //Check for an error response (status of "NOK")
                    if(res.status === 'NOK' || !res.result.success) {
                        Modal.error({
                            title: "Error Cancelling Booking",
                            content: (
                                <div>
                                    <p>There was an error when trying to Cancel this Booking.</p>
                                    <p>If this problem persists, please contact the Office for assistance.</p>
                                </div>
                            )
                        });
                        document.querySelector('.cancel-lesson-modal .ant-modal-confirm-btns button').disabled = false;
                        reject();
                    } else {
                        Modal.success({
                            title: "Booking Cancelled!",
                            content: (
                                <div>
                                    <p>The Booking has been successfully cancelled.</p>
                                </div>
                            ),
                            onOk: () => {
                                Modal.destroyAll();
                                this.props.history.push('/diary/' + moment(new Date(this.state.start_time)).format('YYYY-MM-DD'));
                            }
                        });
                    }
                })
                .catch(() => {
                    Modal.error({
                        title: "Error Cancelling Booking",
                        content: (
                            <div>
                                <p>There was an error when trying to Cancel this Booking.</p>
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                    document.querySelector('.cancel-lesson-modal .ant-modal-confirm-btns button').disabled = false;
                    reject();
                });
            });
        }
    };

    cancelCashLesson = () => {
        Modal.error({
            title: "Cash Payment Taken",
            content: (
                <div>
                    <p>This Booking can not be Cancelled, because it has been marked as Paid by Cash.</p>
                    <p>In order to Cancel this Booking, please Remove the Cash Payment first.</p>
                    <p>Please contact the Office if you require it to be Cancelled.</p>
                </div>
            )
        });
    }

    cancelLesson = () => {
        if(this.state.allow_cancel) {
            Modal.confirm({
                title: 'Cancel Booking?',
                className: 'cancel-lesson-modal',
                content: (
                    <div>
                        <p>Has the Student been informed of the Cancellation?</p>
                        <p style={{textAlign: 'center'}}>
                            <Button href={"tel:" + this.props.stripPhoneNumber(this.state.booking.student_attendance.student.user.mobile_phone)} type="dashed" target="hidden-phone-iframe">
                                <FontAwesomeIcon icon={faPhoneAlt} />Call Student: {this.state.booking.student_attendance.student.user.mobile_phone}
                            </Button>
                            <iframe title="hidden-phone-iframe" name="hidden-phone-iframe" style={{visibility:'hidden',position:'absolute'}}></iframe>
                        </p>
                        <Form id="cancelLessonForm">
                            <Form.Item label="Reason for Cancelling Booking:" >
                                <TextArea
                                    autoSize
                                    id="cancel_reason"
                                    maxLength={255}
                                />
                                <p
                                    style={{
                                        lineHeight: '15px',
                                        fontSize: '12px'
                                    }}
                                >
                                    <b>Please Note:</b> If you believe that this student should pay a Cancellation Fee, you will need to Contact the Office (you can do this <i>after</i> cancelling the Lesson).
                                </p>
                            </Form.Item>
                        </Form>
                        <p>Cancel this Booking?</p>
                        {/* As of 2023-10-06, Don't show Notification Checkbox to DT Users */}
                        {showSendNotificationToStudentCheckbox && this.props.office_user && (
                            // Not a Tentative Test..
                            this.state.booking.booking_type_id !== bookingTypes.test ||
                            (
                                this.state.booking.booking_type_id === bookingTypes.test && 
                                this.state.booking.tentative !== true
                            )
                        ) && (
                            <Checkbox
                                defaultChecked
                                key="send-confirmation-checkbox-cancel"
                                id="send-confirmation-checkbox-cancel"
                            >
                                Send confirmation to Student?
                            </Checkbox>
                        )}
                    </div>
                ),
                onOk: this.handleCancelLesson,
                okText: "Yes",
                cancelText: "No"
            });
            setTimeout(() => {
                document.getElementById('cancel_reason').focus();
            }, 1);
        } else {
            Modal.error({
                title: "Booking can not be Cancelled",
                content: (
                    <div>
                        <p>This Booking can not be Cancelled, because it {this.state.no_cancel_reason}</p>
                        <p>Please contact the Office if you require it to be Cancelled.</p>
                    </div>
                )
            });
        }
    };

    switchWithCash = () => {
        this.setState({
            processCash: true
        });

        api.post("purchases/switchPrepaidWithCash", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                booking_id: this.state.booking_id,
                student_id: this.state.booking.student_attendance.student.id
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            this.setState({processCash: false});

            if(typeof res === 'string'){
                res = JSON.parse(res);
            }

            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || !res.result.success) {
                Modal.error({
                    title: "Error Paying for Booking with Cash",
                    content: (
                        <div>
                            <p>There was an error when trying to Pay for this Booking.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
            }  else {
                this.fetchLesson();

                //Show a Success Modal
                Modal.success({
                    title: "Cash Payment Accepted!",
                    content: (
                        <div>
                            <p>Booking successfully paid for by Cash.</p>
                        </div>
                    ),
                    onOk: () => {
                        Modal.destroyAll();
                        this.setState({
                            changeTimeModalLoading: false,
                            changeTimeModalVisible: false
                        });
                    }
                });
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({processCash: false});
        });
    };

    acceptCash = () => {
        this.setState({
            processCash: true
        });

        api.post("purchases/payForLessonWithCash", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                booking_id: this.state.booking_id,
                student_id: this.state.booking.student_attendance.student.id
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            this.setState({processCash: false});

            if(typeof res === 'string'){
                res = JSON.parse(res);
            }

            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || !res.result.success) {
                Modal.error({
                    title: "Error Paying for Booking with Cash",
                    content: (
                        <div>
                            <p>There was an error when trying to Pay for this Booking.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
            }  else {
                this.fetchLesson();

                //Show a Success Modal
                Modal.success({
                    title: "Cash Payment Accepted!",
                    content: (
                        <div>
                            <p>Booking successfully paid for by Cash.</p>
                        </div>
                    ),
                    onOk: () => {
                        Modal.destroyAll();
                        this.setState({
                            changeTimeModalLoading: false,
                            changeTimeModalVisible: false
                        });
                    }
                });
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({processCash: false});
        });
    };

    removeCash = () => {
        this.setState({
            processCash: true
        }, () => {
            api.post("purchases/removeCashFromLesson", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    booking_id: this.state.booking_id,
                    student_id: this.state.booking.student_attendance.student.id
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || !res.result.success) {
                    this.setState({
                        processCash: false
                    }, () => {
                        Modal.error({
                            title: "Error Removing Cash From Booking",
                            content: (
                                <div>
                                    <p>There was an error when trying to Remove the Cash Payment from this Booking.</p>
                                    {(typeof(res.result.error) !== 'undefined' ? <p>{res.result.error}</p> : '')}
                                    <p>If this problem persists, please contact the Office for assistance.</p>
                                </div>
                            )
                        });
                    });
                }  else {
                    this.setState({
                        processCash: false
                    }, () => {
                        this.fetchLesson();

                        //Show a Success Modal
                        Modal.success({
                            title: "Cash Payment Removed!",
                            content: (
                                <div>
                                    <p>Booking successfully marked as not paid for by Cash.</p>
                                </div>
                            ),
                            onOk: () => {
                                Modal.destroyAll();
                            }
                        });
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({processCash: false});
            });
        });
    };

    otherPayments = () => {
        this.props.history.push({
            pathname: '/payments/' + this.state.booking.student_attendance.student.id + (this.state.duration > 1 ? '/' + this.state.duration : ''),
            returnPath: window.location.pathname
        });
    };

    updateTestCentre = (value) => {
        //Only update if the new Value is different from the existing.
        if(value.key !== this.state.booking.test_info.test_center){
            api.post("tests/update-centre", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    lesson: this.state.booking_id,
                    centre: value.key
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    console.log(res);
                } else {
                    //Update the state booking data to reflect the change
                    let bookingObject = Object.assign({}, this.state.booking);
                    bookingObject.test_info.test_center = value.key;
                    bookingObject.test_info.address.name = value.label;
                    this.setState({booking: bookingObject});
                }
            })
            .catch(error => {
                console.log(error);
                //@todo: We should show an error/alert to the user.
            });
        }
    }

    updateTestResult = (value) => {
        if(value === ''){
            value = null;
        }

        //Only update if the new Value is different from the existing.
        if(value !== this.state.booking.test_info.student_passed){
            api.post("tests/update-result", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    lesson: this.state.booking_id,
                    result: value
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    console.log(res);
                } else {
                    //Update the state booking data to reflect the change
                    let bookingObject = Object.assign({}, this.state.booking);
                    bookingObject.test_info.student_passed = value;
                    this.setState({booking: bookingObject});
                }
            })
            .catch(error => {
                console.log(error);
                //@todo: We should show an error/alert to the user.
            });
        }
    }

    updateTestOwnCar = (value) => {
        //Only update if the new Value is different from the existing.
        if(value !== this.state.booking.own_car){
            api.post("tests/update-own-car", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    lesson: this.state.booking_id,
                    own_car: value
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    console.log(res);
                } else {
                    //Update the state booking data to reflect the change
                    let bookingObject = Object.assign({}, this.state.booking);
                    bookingObject.own_car = value;
                    this.setState({booking: bookingObject});
                }
            })
            .catch(error => {
                console.log(error);
                //@todo: We should show an error/alert to the user.
            });
        }
    }

    updateAgedAssessmentResult = (value) => {
        if(value === ''){
            value = null;
        }

        //Only update if the new Value is different from the existing.
        if(value !== this.state.booking.aged_assessment_info.student_passed){
            api.post("lessons/update-aged-assessment-result", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    lesson: this.state.booking_id,
                    result: value
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    console.log(res);
                } else {
                    //Update the state booking data to reflect the change
                    let bookingObject = Object.assign({}, this.state.booking);
                    bookingObject.aged_assessment_info.student_passed = value;
                    this.setState({booking: bookingObject});
                }
            })
            .catch(error => {
                console.log(error);
                //@todo: We should show an error/alert to the user.
            });
        }
    }

    changeTimeModalDisabledDates = (current) => {
        return current < moment().startOf('day');
    };

    changeTimeModalHandleDatePickerChange = (chosen_date) => {
        this.setState({
            start_time: chosen_date.set({
                hours: moment(this.state.start_time).hours(), 
                minutes: moment(this.state.start_time).minutes()
            }).format(),
            end_time: chosen_date.add(this.state.duration, 'hours').format()
        }, () => {
            let calendarApi = this.calendarRef.current.getApi();
            // Get the Original Event details
            let originalEvent = calendarApi.getEventById(this.state.booking.id);
            // Change the date of the Calendar
            calendarApi.gotoDate(chosen_date.format('Y-MM-DD'));
            // Render a new Event in place on the new date.
            let newEvent = {
                id: this.state.booking.id,
                title: originalEvent.title,
                classNames: originalEvent.classNames,
                extendedProps: originalEvent.extendedProps,
                start: this.state.start_time,
                end: this.state.end_time //moment(this.state.start_time).add(this.state.duration, 'hours').format()
            };
            // Remove the original Event, to avoid ID clashes.
            originalEvent.remove();
            calendarApi.addEvent(newEvent);
        });
    };

    startTimeChange = (time) => {
        // Close the Time Picker
        this.timePickerRef.current.timePickerRef.setOpen(false);

        if((Math.floor((moment(time).startOf('day').add(dayEnd, 'hours').diff(moment(time), 'minutes')/60)*2)/2) < 1) {
            Modal.error({
                title: 'Error',
                content: (<div><p>You can not start this Booking any later in the day.</p></div>)
            });
        } else {
            if (time.hours() < dayStart) {
                time.set({hours: dayStart});
            }else if (time.hours() >= dayEnd) {
                time.set({hours: (dayEnd - 1)});
            }
            this.setState({
                start_time: time.format(),
                end_time: time.clone().add(this.state.duration, 'hours').format()
            }, () => {
                // get the specific booking calendar event
                let calendarApi = this.calendarRef.current.getApi();
                let eventToUpdate = calendarApi.getEventById(this.state.booking.id);
                //Adjust the display to the new start time, moving the end time to keep duration.
                eventToUpdate.setStart(time.format(), {maintainDuration: true});

                //Check if End Time is now outside bounds of Calendar
                if(
                    (new Date(this.state.end_time).getDate() > new Date(this.state.start_time).getDate())
                ||
                    (new Date(this.state.end_time).getHours() >= dayEnd)
                ) {
                    // End Time is out of bounds - Update End and Duration
                    this.setState({
                        end_time: moment(this.state.start_time).add(Math.floor((moment(this.state.start_time).startOf('day').add(dayEnd, 'hours').diff(moment(this.state.start_time), 'minutes')/60)*2)/2, 'hours').format(),
                        duration: Math.floor((moment(this.state.start_time).startOf('day').add(dayEnd, 'hours').diff(moment(this.state.start_time), 'minutes')/60)*2)/2
                    }, () => {
                        //Update the displayed Event
                        eventToUpdate.setEnd(moment(this.state.end_time).format(), {maintainDuration: false});
                    });
                }
            });
        }
    };

    durationIncrease = async () => {
        // Cap the max-length of a lesson to dayEnd - start_time in hours
        if((this.state.duration + 0.5) > (moment(this.state.start_time).startOf('day').add(dayEnd, 'hours').diff(moment(this.state.start_time), 'minutes')/60)) {
            Modal.error({
                title: 'Error',
                content: (<div><p>You can not extend this Booking any further as it will extend beyond the end of the day.</p></div>)
            });

            return false;
        } else {
            this.setState({
                duration: (this.state.duration + 0.5),
                end_time: moment(this.state.start_time).add((this.state.duration + 0.5), 'hours').format()
            }, () => {
                // get the specific booking calendar event
                let calendarApi = this.calendarRef.current.getApi();
                let eventToUpdate = calendarApi.getEventById(this.state.booking.id);

                //Adjust the display by 1/2 an hour
                eventToUpdate.setEnd(eventToUpdate.end.getTime() + (0.5 * 60 * 60 * 1000));
            });

            return true;
        }
    };

    durationDecrease = async () => {
        if(this.state.duration <= 1 || (this.state.booking.booking_type_id === bookingTypes.test && this.state.duration <= 2)){
            Modal.error({
                title: 'Error',
                content: (
                    <div>
                        <p>You can not reduce this Booking any further.</p>
                        <p>To cancel the Booking, please click the Cancel Booking button.</p>
                    </div>
                )
            });

            return false;
        }else{
            this.setState({
                duration: (this.state.duration - 0.5),
                end_time: moment(this.state.start_time).add((this.state.duration - 0.5), 'hours').format()
            }, () => {
                // get the specific booking calendar event
                let calendarApi = this.calendarRef.current.getApi();
                let eventToUpdate = calendarApi.getEventById(this.state.booking.id);
                
                //Adjust the display by 1/2 an hour
                eventToUpdate.setEnd(eventToUpdate.end.getTime() - (0.5 * 60 * 60 * 1000));
            });

            return true;
        }
    };

    warmupIncrease = async () => {
        //Only change the start time if the length increase is successful
        await this.durationIncrease()
            .then(result => {
                if (result) {
                    this.startTimeChange(moment(this.state.start_time).subtract(30, 'minutes'));
                }
            });
    }

    warmupDecrease = async () => {
        //Only change the start time if the length decrease is successful
        await this.durationDecrease()
            .then(result => {
                if (result) {
                    this.startTimeChange(moment(this.state.start_time).add(30, 'minutes'));
                }
            });
    }

    handleEventRender = (info) => {
        let title = info.el.querySelector('.fc-title');
        if(title !== null){
            title.innerHTML = title.innerHTML + 
                (typeof(info.event.extendedProps.test_location) !== 'undefined' && info.event.extendedProps.test_location !== '' ? "<br />Test Start Time: " + info.event.extendedProps.test_start_time + "<br />Test Center: " + info.event.extendedProps.test_location : "") + 
                (typeof(info.event.extendedProps.start_location) !== 'undefined' && info.event.extendedProps.start_location !== '' ? "<br />" + (info.event.extendedProps.booking_type === 'Event' ? "Event Location: " : "Pick Up: ") + info.event.extendedProps.start_location : '') + 
                (typeof(info.event.extendedProps.end_location) !== 'undefined' && info.event.extendedProps.end_location !== '' ? " - Drop Off: " + info.event.extendedProps.end_location : '');
        }
    };

    checkOverlap = (info) => {
        if(parseInt(info.event.id) === parseInt(this.state.booking.id)) {
            this.setState({overlap: false}, () => {
                //Get All available Events
                let calendarApi = this.calendarRef.current.getApi();
                let events = calendarApi.getEvents();

                // Loop all events, ignore our one - if any others are inside/overlapping our bounds, show error and disable Save button.
                events.forEach((event)=>{
                    // Ignore this event.
                    if(parseInt(this.state.booking.id) === parseInt(event.id)){
                        return;
                    }
                    // All Day Events are okay for clashes
                    if(event.allDay){
                        return;
                    }
                    // Background Events are okay for clashes
                    if(event.rendering === 'inverse-background' || event.rendering === 'background'){
                        return;
                    }

                    // Now we need to check the overlap itself...
                    let eventStart = new Date(event.start.getTime() + (new Date(event.start).getTimezoneOffset() * 60 * 1000));
                    let eventEnd = new Date(event.end.getTime() + (new Date(event.end).getTimezoneOffset() * 60 * 1000));
                    if(
                        (moment(eventStart) >= moment(this.state.start_time) && moment(eventStart) < moment(this.state.end_time)) ||
                        (moment(eventEnd) > moment(this.state.start_time) && moment(eventEnd) <= moment(this.state.end_time)) ||
                        (moment(this.state.start_time) >= moment(eventStart) && moment(this.state.start_time) < moment(eventEnd)) ||
                        (moment(this.state.end_time) > moment(eventStart) && moment(this.state.end_time) <= moment(eventEnd))
                    ){
                        this.setState({overlap: true});
                    }
                });
            });
        }
    };

    changeAgedAssessmentPaymentModalVisibility = () => {
        this.setState({
            agedAssessmentPaymentModalVisible: !this.state.agedAssessmentPaymentModalVisible
        });
    }
    changeOTLessonPaymentModalVisibility = () => {
        this.setState({
            otLessonPaymentModalVisible: !this.state.otLessonPaymentModalVisible
        });
    }
    changeOTAssessmentPaymentModalVisibility = () => {
        this.setState({
            otAssessmentPaymentModalVisible: !this.state.otAssessmentPaymentModalVisible
        });
    }
    changeOTAssessmentWithModificationsPaymentModalVisibility = () => {
        this.setState({
            otAssessmentWithModificationsPaymentModalVisible: !this.state.otAssessmentWithModificationsPaymentModalVisible
        });
    }

    changeAddLessonPlanModalVisibility = () => {
        this.setState({
            addLessonPlanModalVisible: !this.state.addLessonPlanModalVisible
        });
    }

    addLessonPlanColumnFormat = [
        {
            title: 'Locations',
            dataIndex: 'locations',
            width: '22%',
            render: (text, record) =>  {
                return (text);
            }
        },
        {
            title: 'New Topics',
            dataIndex: 'new_topics',
            width: '30%',
            render: (text, record) =>  {
                return (text.map(t => <span key={'span' + record.id + 'new' + t.topic.display_number}>{t.topic.display_number}. {t.topic.topic_name}<br/></span>));
            }
        },
        {
            title: 'Practice Topics',
            dataIndex: 'practice_topics',
            width: '30%',
            render: (text, record) =>  {
                return (text.map(t => <span key={'span' + record.id + 'prac' + t.topic.display_number}>{t.topic.display_number}. {t.topic.topic_name}<br/></span>));
            }
        }
    ];

    selectLessonPlanRow = (record) => {
        this.setState({
            processLessonPlanAttach: true
        }, () => {
            api.post('lessonPlans/attach/' + this.props.match.params.id + "/" + record.id,{
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || !!res.result.message) {
                    this.setState({
                        processLessonPlanAttach: false
                    }, () => {
                        alert(res.result.message);
                    });
                } else {
                    this.props.history.push('/lesson-plans/' + this.state.booking.student_attendance.student.id + '/' + this.props.match.params.id);
                }
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 7},
                xl: {span: 9}
            },
            wrapperCol: {
                xs: {span: 17},
                xl: {span: 15}
            }
        };
        const suburbs = this.state.suburbs.map(d => <Option key={d.value}>{d.text}</Option>);

        let lessonPlanButton;
        if (this.state.booking !== null && typeof this.state.booking.student_attendance !== 'undefined' && this.state.booking.student_attendance.lesson_plan_id !== null) {
            lessonPlanButton = <Link className="ant-btn ant-btn-primary" to={"/lesson-plans/" + this.state.booking.student_attendance.student.id + "/" + this.state.booking.id}><FontAwesomeIcon icon={faListAlt} />View Lesson Plan</Link>;
        } else if (this.state.unattached_lesson_plans !== null){
            lessonPlanButton = <Button type="primary"
                                    onClick={this.changeAddLessonPlanModalVisibility}
                                >
                                    <FontAwesomeIcon icon={faListAlt} />Add Lesson Plan
                                </Button>;
        } else if (this.state.booking !== null && typeof this.state.booking.student_attendance !== 'undefined') {
            lessonPlanButton = <Link className="ant-btn ant-btn-primary" to={"/lesson-plans/" + this.state.booking.student_attendance.student.id + "/" + this.state.booking.id}><FontAwesomeIcon icon={faListAlt} />Create Lesson Plan</Link>;
        }

        if(this.state.disabled_hours.length === 0){
            for (let i = 0; i < 24; i++){
                if(i < dayStart || i >= dayEnd){
                    this.state.disabled_hours.push(i);
                }
            }
        }
        
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h2>
                        Booking Details
                        {this.state.booking !== null && this.state.booking.booking_type_id === bookingTypes.cancellationFee && (
                            <> - Cancellation Fee</>
                        )}
                    </h2>
                    {
                    this.state.loading ? 
                        (
                            <Spinner type="mega" />
                        ) : (
                            <>
                                <Form {...formItemLayout} id="lessonForm">
                                    {typeof this.state.booking.tentative !== 'undefined' && this.state.booking.tentative !== null && this.state.booking.tentative && (
                                        <Alert
                                            type="error"
                                            onClose={this.confirmTentative}
                                            banner
                                            showIcon={false}
                                            closable
                                            closeText="Confirm Booking"
                                            message="This Booking is marked as Tentative"
                                        />
                                    )}

                                    <Form.Item label="Student" >
                                        {getFieldDecorator('student_name', {
                                            initialValue: (typeof this.state.booking.student_attendance !== 'undefined' && this.state.booking.student_attendance !== null ? this.state.booking.student_attendance.student.user.first_name + ' ' + this.state.booking.student_attendance.student.user.last_name : null)
                                        })(
                                            <Input readOnly={true} />
                                        )}
                                        <Link to={"/students/" + this.state.booking.student_attendance.student.id + "/" + this.state.booking.id}>
                                            <FontAwesomeIcon icon={faAddressCard} size="lg" fixedWidth/>
                                        </Link>
                                    </Form.Item>

                                    <h3>Payment Details</h3>
                                    <Form.Item label="Cost of Booking">
                                         {getFieldDecorator('lesson_cost', {
                                             initialValue: "$" + parseFloat(this.state.lesson_cost).toFixed(2)
                                         })(
                                            <Input readOnly={true} />
                                        )}
                                        <Tooltip
                                            trigger="click"
                                            placement="right"
                                            title={(this.state.payment_info !== null && this.state.payment_info[this.state.booking.id] !== null ? "Paid by " + this.state.payment_info[this.state.booking.id] : "Not Paid")}
                                        >
                                            <span className='payment_icon lesson'>{this.state.payment_info_icons[this.state.booking.id]}</span>
                                        </Tooltip>
                                        <span className={"paid-lesson " + (this.state.amount_owed === 0 ? '' : 'hidden')}>
                                            <FontAwesomeIcon icon={faCheckCircle} />Paid
                                        </span>
                                        {this.state.payment_info !== null && (this.state.payment_info[this.state.booking.id] === 'Cash' || this.state.payment_info[this.state.booking.id] === 'Split') && moment(this.state.booking.start_time).isAfter(moment().subtract(1, 'days')) &&
                                             <div className="payment-buttons">
                                                <Button type="primary"
                                                    onClick={this.removeCash}
                                                    disabled={this.state.processCash}
                                                >
                                                    <FontAwesomeIcon icon={faMoneyBillWave} />Remove Cash Payment
                                                </Button>
                                            </div>
                                        }
                                        {(this.state.payment_info !== null && (this.state.payment_info[this.state.booking.id] === 'Credit Card' || this.state.payment_info[this.state.booking.id] === 'Split') && moment(this.state.booking.start_time).isAfter(moment().subtract(1, 'days')) && 
                                            (this.state.booking.booking_type_id === bookingTypes.test || this.state.booking.booking_type_id === bookingTypes.lesson) &&
                                            !paymentBeforeBooking
                                            ) &&
                                            <div className="payment-buttons">
                                                <Button type="primary"
                                                    onClick={this.switchWithCash}
                                                    disabled={this.state.processCash}
                                                >
                                                    <FontAwesomeIcon icon={faHandHoldingUsd} /> Swap with Cash Payment
                                                </Button>
                                            </div>
                                        }
                                    </Form.Item>
                                    <Form.Item label="Amount Owing" className={(this.state.amount_owed > 0 ? '' : 'hidden')}>
                                         {getFieldDecorator('amount_owed', {
                                             initialValue: "$" + parseFloat(this.state.amount_owed).toFixed(2)
                                         })(
                                            <Input readOnly={true} />
                                         )}
                                         <div className="payment-buttons">
                                            <Button type="primary"
                                                onClick={this.acceptCash}
                                                disabled={this.state.processCash}
                                            >
                                                <FontAwesomeIcon icon={faHandHoldingUsd} />Accept Cash Payment
                                            </Button>

                                            {/* Aged Assessment */}
                                            {this.state.booking.booking_type_id === bookingTypes.aged && (
                                                <Button
                                                    onClick={() => this.changeAgedAssessmentPaymentModalVisibility()}
                                                >
                                                    <FontAwesomeIcon icon={faDollarSign} />Pay By Credit Card
                                                </Button>
                                            )}
                                            {/* OT Lesson */}
                                            {this.state.booking.booking_type_id === bookingTypes.otLesson && (
                                                <Button
                                                    onClick={() => this.changeOTLessonPaymentModalVisibility()}
                                                >
                                                    <FontAwesomeIcon icon={faDollarSign} />Pay By Credit Card
                                                </Button>
                                            )}
                                            {/* OT Assessment */}
                                            {this.state.booking.booking_type_id === bookingTypes.otAssessment && (
                                                <Button
                                                    onClick={() => this.changeOTAssessmentPaymentModalVisibility()}
                                                >
                                                    <FontAwesomeIcon icon={faDollarSign} />Pay By Credit Card
                                                </Button>
                                            )}
                                            {/* OT Assessment With Modifications */}
                                            {this.state.booking.booking_type_id === bookingTypes.otAssessmentWithModifications && (
                                                <Button
                                                    onClick={() => this.changeOTAssessmentWithModificationsPaymentModalVisibility()}
                                                >
                                                    <FontAwesomeIcon icon={faDollarSign} />Pay By Credit Card
                                                </Button>
                                            )}

                                            {/* Everything Else */}
                                            {![
                                                bookingTypes.aged,
                                                bookingTypes.otLesson,
                                                bookingTypes.otAssessment,
                                                bookingTypes.otAssessmentWithModifications,
                                            ].includes(this.state.booking.booking_type_id) && (
                                                <Button
                                                    onClick={this.otherPayments}
                                                >
                                                    <FontAwesomeIcon icon={faDollarSign} />Other Payment Options
                                                </Button>
                                            )}
                                        </div>
                                    </Form.Item>

                                    {typeof this.state.k2dcode !== 'undefined' && this.state.k2dcode !== null && (
                                        <>
                                            <h3>Keys2Drive Details</h3>
                                            <Form.Item label="Keys2Drive Code">
                                                {getFieldDecorator('keys2drive_code', {
                                                    initialValue: this.state.k2dcode
                                                })(
                                                    <Input
                                                        readOnly={true}
                                                    />
                                                )}
                                                <a target="_blank" rel="noopener noreferrer" href="https://www.keys2drive.com.au/login">
                                                    <FontAwesomeIcon icon={faKey} size="lg" fixedWidth/>
                                                </a>
                                            </Form.Item>
                                        </>
                                    )}

                                    <h3>Booking Details</h3>
                                    {this.state.booking.booking_type_id !== bookingTypes.cancellationFee && (
                                        <Form.Item label="Pick Up Address" >
                                            {getFieldDecorator('pick_up_address', {
                                                initialValue: (this.state.addressData.length === 1 ? this.state.addressData[0].key : (this.state.addressDataPickup !== null ? this.state.addressDataPickup.key : [])),
                                                rules: [{ required: true, message: 'Please select an Address.' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    ref={this.pickupRef}
                                                    loading ={this.state.loadingAddresses}
                                                    placeholder="Select a Location"
                                                    onChange={this.handleAddressChangePickup}
                                                >
                                                    {this.state.addressData}
                                                    <Option value="new">Add New Address</Option>
                                                </Select>
                                            )}
                                            {(this.state.booking.booking_start !== null &&
                                                <a style={{display:(this.state.newAddressModalLoading ? 'none' : 'inline')}} target="_blank" rel="noopener noreferrer" href={(((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPad") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) ? "maps://" : "https://") + "maps.google.com.au/?daddr=" + this.state.booking.booking_start.street_number + ' ' + this.state.booking.booking_start.street_name + ', ' + this.state.booking.booking_start.suburb.suburb_name + ' ' + this.state.booking.booking_start.suburb.post_code}>
                                                    <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" fixedWidth/>
                                                </a>
                                            )}
                                        </Form.Item>
                                    )}
                                    {this.state.booking.booking_type_id !== bookingTypes.cancellationFee && (
                                        <Form.Item label="Drop Off Address" >
                                            {getFieldDecorator('drop_off_address', {
                                                initialValue: (this.state.addressData.length === 1 ? this.state.addressData[0].key : (this.state.addressDataDropoff !== null ? this.state.addressDataDropoff.key : [])),
                                                rules: [{ required: true, message: 'Please select an Address.' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    ref={this.dropoffRef}
                                                    loading ={this.state.loadingAddresses}
                                                    placeholder="Select a Location"
                                                    onChange={this.handleAddressChangeDropoff}
                                                >
                                                    {this.state.addressData}
                                                    <Option value="new">Add New Address</Option>
                                                </Select>
                                            )}
                                            {(this.state.booking.booking_end !== null &&
                                                <a style={{display:(this.state.newAddressModalLoading ? 'none' : 'inline')}} target="_blank" rel="noopener noreferrer" href={(((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPad") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) ? "maps://" : "https://") + "maps.google.com.au/?daddr=" + this.state.booking.booking_end.street_number + ' ' + this.state.booking.booking_end.street_name + ', ' + this.state.booking.booking_end.suburb.suburb_name + ' ' + this.state.booking.booking_end.suburb.post_code}>
                                                    <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" fixedWidth/>
                                                </a>
                                            )}
                                        </Form.Item>
                                    )}
                                    <Form.Item label="Date" >
                                        {getFieldDecorator('start_date', {
                                            initialValue: (typeof this.state.booking.start_time !== 'undefined' && this.state.booking.start_time !== null ? moment(this.state.booking.start_time).format('D/MM/YYYY') : null)
                                        })(
                                            <Input readOnly={true} />
                                        )}
                                        <Link to={"/diary/" + moment(this.state.booking.start_time).format('YYYY-MM-DD')}>
                                            <FontAwesomeIcon icon={faCalendar} size="lg" fixedWidth/>
                                        </Link>
                                    </Form.Item>
                                    {this.state.booking.booking_type_id === bookingTypes.test && (
                                        <Form.Item label="Test Time" >
                                            <Input readOnly={true} className="time-input" value={(typeof this.state.booking.end_time !== 'undefined' && this.state.booking.end_time !== null ? moment(this.state.booking.end_time).subtract(75, 'minutes').format('h:mma') : null)} />
                                            <FontAwesomeIcon icon={faMinus} fixedWidth size="lg" style={{marginRight:'10px'}} />
                                            <Input readOnly={true} className="time-input" value={(typeof this.state.booking.end_time !== 'undefined' && this.state.booking.end_time !== null ? moment(this.state.booking.end_time).subtract(15, 'minutes').format('h:mma') : null)} />
                                        </Form.Item>
                                    )}
                                    {this.state.booking.booking_type_id !== bookingTypes.cancellationFee && (
                                        <Form.Item label="Time" >
                                            <Input readOnly={true} className="time-input" value={(typeof this.state.booking.start_time !== 'undefined' && this.state.booking.start_time !== null ? moment(this.state.booking.start_time).format('h:mma') : null)} />
                                            <FontAwesomeIcon icon={faMinus} fixedWidth size="lg" style={{marginRight:'10px'}} />
                                            <Input readOnly={true} className="time-input" value={(typeof this.state.booking.end_time !== 'undefined' && this.state.booking.end_time !== null ? moment(this.state.booking.end_time).format('h:mma') : null)} />
                                            <Button type="primary"
                                                onClick={this.changeTimeModalShowModal}
                                            >
                                                <FontAwesomeIcon icon={faClock} />Adjust Date/Time{this.state.booking.booking_type_id === bookingTypes.test && "/Warmup"}
                                            </Button>
                                        </Form.Item>
                                    )}

                                    {typeof this.state.booking.test_info !== 'undefined' && this.state.booking.test_info !== null && (
                                        <>
                                            <h3>Test Details</h3>
                                            <Form.Item label="Test Centre" >
                                                {getFieldDecorator('test_centre', {
                                                    initialValue: {key:this.state.booking.test_info.test_center}
                                                })(
                                                    <Select
                                                        labelInValue
                                                        showSearch
                                                        placeholder="Select a Test Centre"
                                                        optionFilterProp="children"
                                                        onChange={this.updateTestCentre}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={this.state.booking.test_info.test_center}>{this.state.booking.test_info.address.name}</Option>
                                                        {
                                                            this.state.test_centres.map((centre, index) => {
                                                                return (centre.id !== this.state.booking.test_info.test_center ? <Option key={index} value={centre.id}>{centre.name}</Option> : '');
                                                            })
                                                        }
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Test Result" >
                                                <Select
                                                    disabled={(this.props.office_user ? false : this.state.booking.test_info.student_passed !== null)}
                                                    placeholder="Select a Test Result"
                                                    defaultValue={(this.state.booking.test_info.student_passed === null ? '' : this.state.booking.test_info.student_passed.toString())}
                                                    onChange={this.updateTestResult}
                                                >
                                                    <Option value="">Select a Test Result</Option>
                                                    <Option value="true">Pass</Option>
                                                    <Option value="false">Fail</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Own Car?" >
                                                <Switch
                                                    checkedChildren="Yes" 
                                                    unCheckedChildren="No" 
                                                    defaultChecked={this.state.booking.own_car}
                                                    onChange={this.updateTestOwnCar}
                                                />
                                            </Form.Item>
                                        </>
                                    )}

                                    {typeof this.state.booking.aged_assessment_info !== 'undefined' && this.state.booking.aged_assessment_info !== null && (
                                        <>
                                            <h3>Aged Assessment Details</h3>
                                            <Form.Item label="Test Result" >
                                                <Select
                                                    disabled={(this.props.office_user ? false : this.state.booking.aged_assessment_info.student_passed !== null)}
                                                    placeholder="Select a Test Result"
                                                    defaultValue={(this.state.booking.aged_assessment_info.student_passed === null ? '' : this.state.booking.aged_assessment_info.student_passed.toString())}
                                                    onChange={this.updateAgedAssessmentResult}
                                                >
                                                    <Option value="">Select a Test Result</Option>
                                                    <Option value="true">Pass</Option>
                                                    <Option value="false">Fail</Option>
                                                </Select>
                                            </Form.Item>
                                        </>
                                    )}

                                    {this.state.booking.booking_type_id !== bookingTypes.cancellationFee && (window.location.pathname.split("/")[1] === 'lesson') &&
                                        <p>{lessonPlanButton}</p>
                                    }

                                    {this.state.booking.booking_type_id !== bookingTypes.cancellationFee && (
                                        <Button type="danger"
                                            onClick={(this.state.payment_info !== null && this.state.payment_info[this.state.booking.id] === 'Cash') ? this.cancelCashLesson : this.cancelLesson}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel Booking
                                        </Button>
                                    )}
                                    <Link
                                        style={{marginLeft: '20px'}}
                                        className="ant-btn ant-btn-primary"
                                        to={"/diary/" + moment(this.state.booking.start_time).format('YYYY-MM-DD')}
                                    >
                                        <FontAwesomeIcon icon={faCalendar} size="lg" fixedWidth/> Back to Diary
                                    </Link>

                                    {this.state.future_lessons.length > 0 && (
                                        <>
                                            <h3>Future Bookings for this Student</h3>
                                            <Table
                                                style={{maxWidth: '600px',margin:'auto'}}
                                                rowKey={record => record.id}
                                                dataSource={this.state.future_lessons}
                                            >
                                                <Column
                                                    title="Date & Time"
                                                    dataIndex="start_time"
                                                    key="start_time" 
                                                    render={(text) => (
                                                        moment(text).format('DD/MM/YYYY h:mma')
                                                    )}
                                                />
                                                <Column
                                                    title="Duration"
                                                    dataIndex="end_time"
                                                    key="duration" 
                                                    render={(text, record) => (
                                                        moment(record.end_time).diff(moment(record.start_time), 'minutes')/60
                                                    )}
                                                />
                                                <Column
                                                    title="Type"
                                                    dataIndex="booking_type.name"
                                                    key="type"
                                                    render={(text, record) => (
                                                        text + (record.reservation_info !== null ? " (Reservation)" : "")
                                                    )}
                                                />
                                                <Column
                                                    title=""
                                                    key="action"
                                                    render={(text, record) => (
                                                        record.booking_type_id !== bookingTypes.cancellationFee ?
                                                            <Link to={"/lesson/" + record.id}>View</Link>
                                                        : ''
                                                    )}
                                                />
                                            </Table>
                                        </>
                                    )}
                                </Form>

                                <Modal
                                    destroyOnClose={true}
                                    className="adjust-time-modal"
                                    visible={this.state.changeTimeModalVisible}
                                    title={"Adjust Date/Time" + (this.state.booking.booking_type_id === bookingTypes.test ? " and Warmup" : "")}
                                    onOk={this.changeTimeModalHandleOk}
                                    onCancel={this.changeTimeModalHandleCancel}
                                    footer={[
                                        <>
                                            {/* As of 2023-10-06, Don't show Notification Checkbox to DT Users */}
                                            {showSendNotificationToStudentCheckbox && this.props.office_user && (
                                                // Not a Tentative Test..
                                                this.state.booking.booking_type_id !== bookingTypes.test ||
                                                (
                                                    this.state.booking.booking_type_id === bookingTypes.test && 
                                                    this.state.booking.tentative !== true
                                                )
                                            ) && (
                                                <Checkbox
                                                    defaultChecked
                                                    key="send-confirmation-checkbox-datetime"
                                                    id="send-confirmation-checkbox-datetime"
                                                >
                                                    Send confirmation to Student?
                                                </Checkbox>
                                            )}
                                        </>,
                                        <Alert
                                            key="overlap-alert"
                                            style={{display:(this.state.overlap ? 'inline-block' : 'none')}}
                                            className="overlap-alert"
                                            type="error"
                                            banner
                                            message="Cannot save overlapping bookings"
                                        />,
                                        <Button
                                            key="back"
                                            onClick={this.changeTimeModalHandleCancel}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                        <Button
                                            key="submit"
                                            type="primary"
                                            loading={this.state.changeTimeModalLoading}
                                            onClick={this.changeTimeModalHandleOk}
                                            disabled={this.state.overlap || this.state.changeTimeModalLoading || this.state.calendarLoading}
                                        >
                                            <FontAwesomeIcon icon={faSave} />Save
                                        </Button>,
                                    ]}
                                    >
                                    <DatePicker
                                        allowClear={false}
                                        defaultValue={moment(this.state.start_time)}
                                        format={dateFormatList}
                                        size="large"
                                        onChange={this.changeTimeModalHandleDatePickerChange}
                                        disabled={this.state.calendarLoading}
                                        disabledDate={this.changeTimeModalDisabledDates}
                                        style={{width: '130px'}}
                                    />
                                    <Form layout="inline" style={{marginBottom: '10px'}}>
                                        <Form.Item label="Time" >
                                            {getFieldDecorator('modal_start_time', {
                                                initialValue: moment(this.state.start_time)
                                            })(
                                                <TimePicker
                                                    allowClear={false}
                                                    use12Hours
                                                    format='h:mm a'
                                                    minuteStep={5}
                                                    disabled={this.state.calendarLoading}
                                                    style={{width: '100px'}}
                                                    ref={this.timePickerRef}
                                                    onChange={this.startTimeChange}
                                                    inputReadOnly={true}
                                                    disabledHours={() => this.state.disabled_hours}
                                                    hideDisabledOptions={true}
                                                />
                                            )}
                                        </Form.Item>

                                        {![
                                            bookingTypes.k2d,
                                            bookingTypes.aged,
                                            bookingTypes.otLesson,
                                            bookingTypes.otAssessment,
                                            bookingTypes.otAssessmentWithModifications,
                                        ].includes(this.state.booking.booking_type_id) && (
                                            <Form.Item label="Hours" >
                                                {getFieldDecorator('modal_duration', {
                                                    initialValue: this.state.duration
                                                })(
                                                    <Input
                                                        style={{width: '50px', textAlign: 'center'}}
                                                        disabled={this.state.calendarLoading}
                                                        readOnly={true}
                                                    />
                                                )}
                                                {this.state.booking.booking_type_id !== bookingTypes.test && (
                                                    <ButtonGroup>
                                                        <Button
                                                            type="dashed"
                                                            disabled={this.state.calendarLoading}
                                                            onClick={this.durationIncrease}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} fixedWidth />
                                                        </Button>
                                                        <Button
                                                            type="dashed"
                                                            disabled={this.state.calendarLoading}
                                                            onClick={this.durationDecrease}
                                                        >
                                                            <FontAwesomeIcon icon={faMinus} fixedWidth />
                                                        </Button>
                                                    </ButtonGroup>
                                                )}
                                            </Form.Item>
                                        )}
                                        {this.state.booking.booking_type_id === bookingTypes.test && (
                                            <Form.Item label="Warmup (Minutes)" >
                                                {getFieldDecorator('modal_duration', {
                                                    initialValue: ((this.state.duration - 1.25) * 60)
                                                })(
                                                    <Input
                                                        style={{width: '50px', textAlign: 'center'}}
                                                        disabled={this.state.calendarLoading}
                                                        readOnly={true}
                                                    />
                                                )}
                                                <ButtonGroup>
                                                    <Button
                                                        type="dashed"
                                                        disabled={this.state.calendarLoading}
                                                        onClick={this.warmupIncrease}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                                                    </Button>
                                                    <Button
                                                        type="dashed"
                                                        disabled={this.state.calendarLoading}
                                                        onClick={this.warmupDecrease}
                                                    >
                                                        <FontAwesomeIcon icon={faMinus} fixedWidth />
                                                    </Button>
                                                </ButtonGroup>
                                            </Form.Item>
                                        )}
                                    </Form>
                                    <Spinner type="calendar" />
                                    <FullCalendar
                                        key={"diaryCal" + this.state.booking.id}
                                        ref={this.calendarRef}
                                        defaultView="timeGridDay"
                                        defaultDate={this.state.start_time}
                                        scrollTime={moment(this.state.start_time).subtract(1, 'hours').format('HH:mm:ss')}
                                        slotDuration='00:30:00'
                                        events={this.eventFetch}
                                        loading={this.calendarLoading}
                                        eventRender={this.handleEventRender}
                                        eventPositioned={this.checkOverlap}
                                        plugins={[ timeGridPlugin ]}
                                        timeZone='Australia/Sydney'
                                        nowIndicator={true}
                                        height={'parent'}
                                        header={null}
                                        minTime={dayStart + ':00:00'}
                                        maxTime={dayEnd + ':00:00'}
                                    />
                                </Modal>

                                <Modal
                                    destroyOnClose={true}
                                    className="new-address-modal"
                                    visible={this.state.newAddressModalVisible}
                                    title="New Address"
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={this.newAddressModalHandleCancel}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                        <Button
                                            form="newAddressForm"
                                            key="submit"
                                            htmlType="submit"
                                            type="primary"
                                            loading={this.state.newAddressModalLoading}
                                            disabled={this.state.newAddressModalLoading}
                                        >
                                            <FontAwesomeIcon icon={faSave} />Save
                                        </Button>
                                    ]}
                                >
                                    <Form
                                        id="newAddressForm"
                                        onSubmit={this.newAddressModalHandleOk}
                                    >
                                        <Form.Item label="Street Number" >
                                            {getFieldDecorator('street_number', {
                                                rules: [
                                                    { required: true, message: 'Please enter a Street Number.' },
                                                    { max: 10, message: 'Street Number cannot be more than 10 characters.' },
                                                ],
                                            })(
                                                <Input
                                                    required="required"
                                                    autoFocus={true}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Street Name" >
                                            {getFieldDecorator('street_name', {
                                                rules: [{ required: true, message: 'Please enter a Street Name.' }],
                                            })(
                                                <Input required="required" />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Suburb" >
                                            {getFieldDecorator('suburb_id', {
                                                rules: [{ required: true, message: 'Please enter a Suburb.' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    placeholder="Select Suburb"
                                                    defaultActiveFirstOption={false}
                                                    showArrow={false}
                                                    filterOption={false}
                                                    onSearch={this.handleSuburbSearch}
                                                    onFocus={this.disableAutoComplete}
                                                    notFoundContent={null}
                                                >
                                                    {suburbs}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Form>
                                </Modal>

                                <Modal
                                    destroyOnClose={true}
                                    className="pay-aged-assessment-modal"
                                    visible={this.state.agedAssessmentPaymentModalVisible}
                                    title="Purchase Aged Assessment"
                                    onCancel={this.changeAgedAssessmentPaymentModalVisibility}
                                    closable={false}
                                    maskClosable={false}
                                    keyboard={false}
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={this.changeAgedAssessmentPaymentModalVisibility}
                                            disabled={this.state.processPayment}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                    ]}
                                >
                                    {this.state.processPayment && (
                                        <Spinner type="mini" />
                                    )}
                                    {!this.state.processPayment && (
                                        <IPSI
                                            products={this.state.products}
                                            student_id={this.state.booking.student_attendance.student.id}
                                            studentData={this.state.booking.student_attendance.student}
                                            chosen_driver={this.props.chosen_driver} 
                                            product_id={products.agedAssessment}
                                            product_quantity={1}
                                            successfulPayment={() => {this.fetchLesson(); this.changeAgedAssessmentPaymentModalVisibility()}}
                                            changeSubmitting={() => {
                                                this.setState({processPayment: !this.state.processPayment});
                                            }}
                                            inModal={true}
                                        />
                                    )}
                                </Modal>

                                <Modal
                                    destroyOnClose={true}
                                    className="pay-ot-lesson-modal"
                                    visible={this.state.otLessonPaymentModalVisible}
                                    title="Purchase OT Lesson"
                                    onCancel={this.changeOTLessonPaymentModalVisibility}
                                    closable={false}
                                    maskClosable={false}
                                    keyboard={false}
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={this.changeOTLessonPaymentModalVisibility}
                                            disabled={this.state.processPayment}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                    ]}
                                >
                                    {this.state.processPayment && (
                                        <Spinner type="mini" />
                                    )}
                                    {!this.state.processPayment && (
                                        <IPSI
                                            products={this.state.products}
                                            student_id={this.state.booking.student_attendance.student.id}
                                            studentData={this.state.booking.student_attendance.student}
                                            chosen_driver={this.props.chosen_driver} 
                                            product_id={products.otLesson}
                                            product_quantity={1}
                                            successfulPayment={() => {this.fetchLesson(); this.changeOTLessonPaymentModalVisibility()}}
                                            changeSubmitting={() => {
                                                this.setState({processPayment: !this.state.processPayment});
                                            }}
                                            inModal={true}
                                        />
                                    )}
                                </Modal>

                                <Modal
                                    destroyOnClose={true}
                                    className="pay-ot-assessment-modal"
                                    visible={this.state.otAssessmentPaymentModalVisible}
                                    title="Purchase OT Assessment"
                                    onCancel={this.changeOTAssessmentPaymentModalVisibility}
                                    closable={false}
                                    maskClosable={false}
                                    keyboard={false}
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={this.changeOTAssessmentPaymentModalVisibility}
                                            disabled={this.state.processPayment}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                    ]}
                                >
                                    {this.state.processPayment && (
                                        <Spinner type="mini" />
                                    )}
                                    {!this.state.processPayment && (
                                        <IPSI
                                            products={this.state.products}
                                            student_id={this.state.booking.student_attendance.student.id}
                                            studentData={this.state.booking.student_attendance.student}
                                            chosen_driver={this.props.chosen_driver} 
                                            product_id={products.otAssessment}
                                            product_quantity={1}
                                            successfulPayment={() => {this.fetchLesson(); this.changeOTAssessmentPaymentModalVisibility()}}
                                            changeSubmitting={() => {
                                                this.setState({processPayment: !this.state.processPayment});
                                            }}
                                            inModal={true}
                                        />
                                    )}
                                </Modal>

                                <Modal
                                    destroyOnClose={true}
                                    className="pay-ot-assessment-with-modifications-modal"
                                    visible={this.state.otAssessmentWithModificationsPaymentModalVisible}
                                    title="Purchase OT Assessment With Modifications"
                                    onCancel={this.changeOTAssessmentWithModificationsPaymentModalVisibility}
                                    closable={false}
                                    maskClosable={false}
                                    keyboard={false}
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={this.changeOTAssessmentWithModificationsPaymentModalVisibility}
                                            disabled={this.state.processPayment}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                    ]}
                                >
                                    {this.state.processPayment && (
                                        <Spinner type="mini" />
                                    )}
                                    {!this.state.processPayment && (
                                        <IPSI
                                            products={this.state.products}
                                            student_id={this.state.booking.student_attendance.student.id}
                                            studentData={this.state.booking.student_attendance.student}
                                            chosen_driver={this.props.chosen_driver} 
                                            product_id={products.otAssessmentWithModifications}
                                            product_quantity={1}
                                            successfulPayment={() => {this.fetchLesson(); this.changeOTAssessmentWithModificationsPaymentModalVisibility()}}
                                            changeSubmitting={() => {
                                                this.setState({processPayment: !this.state.processPayment});
                                            }}
                                            inModal={true}
                                        />
                                    )}
                                </Modal>

                                <Modal
                                    destroyOnClose={true}
                                    className="add-lesson-plan-modal"
                                    visible={this.state.addLessonPlanModalVisible}
                                    title="Add Lesson Plan"
                                    onCancel={this.changeAddLessonPlanModalVisibility}
                                    footer={[
                                        <Link
                                            disabled={this.state.processLessonPlanAttach}
                                            key='createNewButton'
                                            className="ant-btn ant-btn-primary"
                                            to={"/lesson-plans/" + this.state.booking.student_attendance.student.id + "/" + this.state.booking.id}
                                            style={{marginRight: '10px'}}
                                        >
                                            <FontAwesomeIcon icon={faListAlt} />Create Lesson Plan
                                        </Link>,
                                        <Button
                                            disabled={this.state.processLessonPlanAttach}
                                            key="backButton"
                                            onClick={this.changeAddLessonPlanModalVisibility}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                    ]}
                                >
                                    The following Lesson Plans are not yet attached to Bookings.<br />
                                    You can select from these, or click Create below to create a new one.
                                    <Table
                                        rowClassName="lesson-plans-table-row"
                                        size="middle"
                                        showHeader={true}
                                        columns={this.addLessonPlanColumnFormat}
                                        rowKey={record => record.id}
                                        dataSource={this.state.unattached_lesson_plans}
                                        pagination={false}
                                        loading={{spinning: this.state.loading, indicator: <Spinner type="mini" />}}
                                        style={{maxHeight: 80+'vh', overflow: 'auto'}}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => this.selectLessonPlanRow(record)
                                            };
                                        }}
                                    />
                                    
                                </Modal>
                            </>
                        )
                    } 
                </Col>
            </Row>
        );
    }
};

const LessonForm = Form.create({name: 'lesson_form'})(Lesson);

export default withRouter(LessonForm);
