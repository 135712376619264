import api from './api';
import history from './history';

const eventFetch = (params = {}, successCallback) => {
    api.post("diary/events", {
        body: JSON.stringify({
            chosen_driver: params.chosen_driver,
            start: params.start,
            end: params.end,
            ...params
        })
    })
    .then(res => {
        //Check for an error response (status of "NOK")
        if (res.status === 'NOK' || typeof res.result.events === 'undefined') {
            history.push('/');
        } else {
            // Store Peak Availability
            localStorage.setItem('peakAvailability', res.result.peakAvailability);
            successCallback(res.result.events);
        }
    })
    .catch(error => {
        console.log(error);
        history.push('/');
    });
};

/**
 * Clicking on a booking, we do different actions:
 * * Background Events - do nothing
 * * Reservations - do nothing (as these are temporary - DT can't do anything with them)
 * * Reminders - Redirect to Reminder Edit Screen
 * * Everything Else - Treat as a booking and Redirect to it.
 * 
 * @param object info
 * @returns {Boolean/Redirect}
 */
const handleEventClick = (info) => {
    // Prevent Default to stop the url firing a page redirect
    info.jsEvent.preventDefault();

    // Ignore background-events
    if (info.event.rendering === 'inverse-background' || info.event.rendering === 'background') {
        return false;
    }

    // Ignore Reservations
    if (info.event.classNames.indexOf('reservation') >= 0) {
        return false;
    }

    // If it's a Reminder
    if (info.event.classNames.indexOf('reminder') >= 0) {
        return history.push('/reminders/edit/' + info.event.id);
    }

    // If it's an Event
    if (info.event.classNames.indexOf('event') >= 0) {
        return history.push('/event/' + info.event.id);
    }

    // If it's a Test
    if (info.event.classNames.indexOf('test') >= 0) {
        return history.push('/test/' + info.event.id);
    }

    // Otherwise, it's a Lesson
    return history.push('/lesson/' + info.event.id);
};

export default {
    eventFetch,
    handleEventClick
};
