import React from 'react';
import { Row, Col } from 'antd';
import RemindersTable from '../elements/reminders/RemindersTable';

class Reminders extends React.Component {

    componentDidMount() {
        this.props.hideReminderDrawer();
    }

    render(){
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <RemindersTable
                        counts={this.props.counts}
                        setAppStateValue={this.props.setAppStateValue}
                        chosen_driver={this.props.chosen_driver}
                        key={"remindersTable" + this.props.chosen_driver}
                    />
                </Col>
            </Row>
        );
    }
};

export default Reminders;