import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import RemindersForm from '../elements/reminders/RemindersForm';
import api from '../../helpers/api';
import Spinner from '../elements/Spinner';

class ReminderEdit extends React.Component {

    state = {
        reminder_id: null,
        reminder: null,
        loading: true
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (typeof (prevState.reminder) !== 'undefined' && prevState.reminder !== null && parseInt(nextProps.match.params.id) !== prevState.reminder_id){
            return {
                loading: true,
                reminder_id: parseInt(nextProps.match.params.id)
            };
        }
        return null;
    };

    componentDidMount() {
        this.props.hideReminderDrawer();
        if(this.state.reminder_id === null){
            this.setState({reminder_id: parseInt(this.props.match.params.id)}, this.fetchReminder);
        }
    };
    
    componentDidUpdate(){
        if(this.state.reminder === null || this.state.reminder_id !== this.state.reminder.id){
            this.fetchReminder();
        }
    }
    
    fetchReminder = async() => {
        await api.post("reminders/fetch/" + this.state.reminder_id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.reminder === 'undefined') {
                    this.props.history.push('/reminders');
                } else {
                    this.setState({reminder: res.result.reminder, loading: false}, this.props.hideReminderDrawer);
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/reminders');
        });
    };

    render() {
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h2>Edit Reminder</h2>
                    {
                    this.state.loading ? 
                        (
                            <Spinner type="mega" />
                        ) : (
                            <RemindersForm reminderToEdit={this.state.reminder} chosen_driver={this.props.chosen_driver} />
                        )
                    } 
                </Col>
            </Row>
        );
    }
};

export default withRouter(ReminderEdit);
