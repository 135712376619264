import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import SignaturePad from 'react-signature-canvas';
import { Row, Col, Form, Table, Button, Modal, Input, Select, DatePicker, InputNumber, Radio } from 'antd';
import api from '../../../helpers/api';
import moment from 'moment';
import update from 'immutability-helper';
import Spinner from '../../elements/Spinner';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faEraser, faExpandAlt, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';

const { TextArea } = Input;
const { Column } = Table;
const { Option } = Select;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

let suburbLookupTimeout;
let currentSuburb;

class AttendanceSheetForm extends React.Component {
    state = {
        suburbs: [],
        loading: false,
        autoCompleteDisabled: false,
        showCommentBox: [],
        studentSignInSelectedStudent: null,
        studentSignInStep1ModalVisible: false,
        studentSignInStep2ModalVisible: false,
        studentSignInStep2ModalLoading: false,
        studentSignInStep2ModalAddresses: [],
        studentSignInStep2ModalOtherGenderVisible: false,
        studentSignInStep2BigSigModalVisible: false,
        newAddressModalVisible: false,
        newAddressModalLoading: false,
        feedbackFormModalVisible: false,
        feedbackFormModalLoading: false,
        signature: null,
        attendees: this.props.attendees
    };

    addressRef = React.createRef();

    disableAutoComplete = () => {
        if (!this.state.autoCompleteDisabled) {
            const elementsToDisable = document.getElementsByClassName("ant-select-search__field");
            for (let i = 0; i < elementsToDisable.length; i++) {
                elementsToDisable[i].setAttribute("autocomplete","any-arbitrary-text");
            }
            this.setState({ autoCompleteDisabled: true });
        }
    };

    handleSuburbSearch = value => {
        this.suburbLookup(value, suburbs => this.setState({ suburbs }));
    };

    suburbLookup = (value, callback) => {
        if (suburbLookupTimeout) {
            clearTimeout(suburbLookupTimeout);
            suburbLookupTimeout = null;
        }
        currentSuburb = value;

        function debouncedLookup() {
            api.post("suburbs/search", {
                body: JSON.stringify({
                    search: value
                })
            })
            .then(d => {
                if (currentSuburb === value) {
                    const data = [];
                    d.result.suburbs.forEach(r => {
                        data.push({
                            value: r.id,
                            text: r.suburb_name
                        });
                    });
                    callback(data);
                }
            });
        }
        suburbLookupTimeout = setTimeout(debouncedLookup, 300);
    }

    showStudentSignInStep1Modal = (student_id) => {
        this.setState({
            studentSignInSelectedStudent: student_id,
            studentSignInStep1ModalVisible: true
        });
    };

    studentSignInStep1ModalHandleCancel = (e) => {
        this.setState({
            studentSignInSelectedStudent: null,
            studentSignInStep1ModalVisible: false
        });
    };

    studentSignInStep1ModalHandleOk = (e) => {
        // Populate this Student's addresses
        const addresses = [];
        this.state.attendees[this.state.studentSignInSelectedStudent].addresses.all.forEach((address) => {
            addresses.push(<Option key={address.id} value={address.id}>{address.address}</Option>);
        });
        this.setState({
            studentSignInStep2ModalAddresses: addresses,
            studentSignInStep1ModalVisible: false,
            studentSignInStep2ModalVisible: true,
            studentSignInStep2ModalOtherGenderVisible: (this.state.attendees[this.state.studentSignInSelectedStudent].user.gender === 'Other')
        }, () => {
            this.props.form.validateFields(['first_name', 'last_name', 'address', 'dob', 'gender', 'gender_other', 'licence_number', 'licence_expiry', 'driving_experience'], (err, values) => {return false;});
        });
    };

    studentSignInStep2ModalHandleCancel = (e) => {
        this.setState({
            studentSignInSelectedStudent: null,
            studentSignInStep2ModalVisible: false
        });
    };

    studentSignInStep2ModalHandleOk = (e) => {
        // Validate and Submit form.
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(['first_name', 'last_name', 'address', 'dob', 'gender', 'gender_other', 'licence_number', 'licence_expiry', 'driving_experience'], (err, values) => {
            if (!err) {
                if (this.state.signature === null) {
                    Modal.error({
                        title: "Signature Required",
                        content: (
                            <div>
                                <p>You must provide a signature to acknowledge that your details are correct.</p>
                            </div>
                        )
                    });
                    return false;
                }

                this.setState({
                    studentSignInStep2ModalLoading: true
                }, async () => {

                    await api.post("events/attendance/" + this.props.event_id + "/student", {
                        body: JSON.stringify({
                            chosen_driver: this.props.chosen_driver,
                            event_id: this.props.event_id,
                            student_id: this.state.studentSignInSelectedStudent,
                            enquiry_location: 'diary',
                            data: values,
                            signature: this.state.signature
                        })
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }

                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK' || typeof res.result.success === 'undefined' || res.result.success === false) {
                            Modal.error({
                                title: "Error",
                                content: (
                                    <div>
                                        <p>An error occurred when attempting to Save your Details.</p>
                                        {(typeof res.result.error !== 'undefined' ? <p>{res.result.error}</p> : null)}
                                        <p>Please inform your Instructor.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        studentSignInStep2ModalLoading: false
                                    });
                                }
                            });
                        } else {
                            Modal.success({
                                title: "Details Saved!",
                                content: (
                                    <div>
                                        <p>Thank you for signing your Attendance.</p>
                                        <p>Once you have completed your Module, please return here to complete a short feedback survey required by TfNSW.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        attendees: update(this.state.attendees, {
                                            [this.state.studentSignInSelectedStudent]: {
                                                "sdc_attendance": {
                                                    $set: true
                                                }
                                            }
                                        }),
                                        studentSignInStep2ModalLoading: false,
                                        studentSignInStep2ModalVisible: false
                                    }, () => {
                                        window.scrollTo(0, 0);
                                    });
                                }
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        Modal.error({
                            title: "Error",
                            content: (
                                <div>
                                    <p>An error occurred when attempting to Save your Details.</p>
                                    <p>Please inform your Instructor.</p>
                                </div>
                            ),
                            onOk: () => {
                                this.setState({
                                    studentSignInStep2ModalLoading: false
                                });
                            }
                        });
                    });
                });
            }
        });
    };

    handleGenderChange = (value) => {
        this.setState({
            studentSignInStep2ModalOtherGenderVisible: (value === 'Other')
        }, () => {
            if (this.state.studentSignInStep2ModalOtherGenderVisible) {
                this.genderOther.focus();
            }
        });
    };

    handleAddressChange = (value) => {
        if(value === 'new'){
            this.setState({
                newAddressModalVisible: true
            });
        }
    };

    newAddressModalHandleCancel = (e) => {
        this.setState({
            newAddressModalVisible: false
        }, () => {
            if(this.addressRef.current.props.value === 'new'){
                this.props.form.resetFields(['address']);
            }
        });
    };

    newAddressModalHandleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['street_number', 'street_name', 'suburb_id'], (err, values) => {
            if (!err) {
                this.setState({newAddressModalLoading: true});
                //save to DB and then add to options
                values.chosen_driver = this.props.chosen_driver;
                api.post("address/add", {
                    body: JSON.stringify(values)
                })
                .then(res => {
                    let addressData = this.state.studentSignInStep2ModalAddresses;
                    addressData.push(<Option key={res.result.id}>{res.result.address}</Option>);
                    this.setState({
                        studentSignInStep2ModalAddresses: addressData
                    },() => {
                        if(this.addressRef.current.props.value === 'new'){
                            this.props.form.setFieldsValue({
                                address: [res.result.id.toString()]
                            });
                        }
                        // Close this modal.
                        this.setState({
                            newAddressModalLoading: false,
                            newAddressModalVisible: false
                        });
                    });
                });
            }
        });
    };

    showFeedbackModal = (student_id) => {
        this.setState({
            studentSignInSelectedStudent: student_id,
            feedbackFormModalVisible: true
        }, () => {
            window.scrollTo(0, 0);
        });
    };

    feedbackFormModalHandleCancel = (e) => {
        this.setState({
            studentSignInSelectedStudent: null,
            feedbackFormModalVisible: false,
            showCommentBox: []
        });
    };

    feedbackFormModalHandleOk = (e) => {
        this.props.form.validateFieldsAndScroll(this.props.surveyQuestionsToValidate, (err, values) => {
            if (!err) {
                this.setState({
                    feedbackFormModalLoading: true
                }, async () => {
                    await api.post("surveys/respond/" + this.props.module, {
                        body: JSON.stringify({
                            chosen_driver: this.props.chosen_driver,
                            event_id: this.state.event_id,
                            student_id: this.state.studentSignInSelectedStudent,
                            data: values
                        })
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }
                        
                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK' || typeof res.result.success === 'undefined' || res.result.success === false) {
                            Modal.error({
                                title: "Error",
                                content: (
                                    <div>
                                        <p>An error occurred when attempting to Save your Responses.</p>
                                        {(typeof res.result.error !== 'undefined' ? <p>{res.result.error}</p> : null)}
                                        <p>Please inform your Instructor.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        feedbackFormModalLoading: false
                                    });
                                }
                            });
                        } else {
                            Modal.success({
                                title: "Survey Complete!",
                                content: (
                                    <div>
                                        <p>Thank you for completing the TfNSW Feedback Survey.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        attendees: update(this.state.attendees, {
                                            [this.state.studentSignInSelectedStudent]: {
                                                "survey_response": {
                                                    $set: true
                                                }
                                            }
                                        }),
                                        feedbackFormModalVisible: false,
                                        feedbackFormModalLoading: false,
                                        showCommentBox: []
                                    });
                                }
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        Modal.error({
                            title: "Error",
                            content: (
                                <div>
                                    <p>An error occurred when attempting to Save your Responses.</p>
                                    <p>Please inform your Instructor.</p>
                                </div>
                            ),
                            onOk: () => {
                                this.setState({
                                    feedbackFormModalLoading: false
                                });
                            }
                        });
                    });
                });
            }
        });
    };

    onRadioChange = (e) => {
        // Get the Question ID
        let qid = e.target.name.substring(9, e.target.name.length - 1);

        //Compare it against the corresponding threshold for displaying the Comment Box - Update state
        let showCommentBox = [...this.state.showCommentBox];
        showCommentBox[qid] = (e.target["data-value"] <= this.props.survey.survey_questions[e.target["data-qid"]].comment_threshold);
        this.setState({
            showCommentBox: showCommentBox 
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const suburbs = this.state.suburbs.map(d => <Option key={d.value}>{d.text}</Option>);

        return (
            <>
                <Row>
                    <Col 
                        xs={{ span: 24 }}
                        lg={{ span: 18, offset: 3 }}
                    >
                        <Table
                            rowClassName="students-table-row"
                            size="small"
                            loading={{spinning: this.state.loading, indicator: <Spinner type="mini" />}}
                            className='students-table'
                            rowKey={record => record.id}
                            dataSource={Object.values(this.state.attendees).sort(function (a,b) {
                                if (a.user.first_name < b.user.first_name) {
                                    return -1;
                                } else if (a.user.first_name > b.user.first_name) {
                                    return 1;
                                } else {
                                    if (a.user.last_name < b.user.last_name) {
                                        return -1;
                                    } else if (a.user.last_name > b.user.last_name) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                }
                            })}
                            showHeader={false}
                            pagination={false}
                        >
                            <Column
                                title="Name"
                                key="name"
                                render={(text, record) => (
                                    <>
                                        {record.user.first_name} {record.user.last_name}
                                    </>
                                )}
                            />
                            <Column
                                title="Actions"
                                key="actions"
                                align="center"
                                render={(text, record) => (
                                    <>
                                        {
                                        record.sdc_attendance === null
                                        ?
                                            <Button type="dashed" onClick={() => this.showStudentSignInStep1Modal(record.id)}>
                                                <FontAwesomeIcon icon={faUserCheck} fixedWidth />Sign In
                                            </Button>
                                        :
                                            record.survey_response
                                            ?
                                                <>
                                                    <FontAwesomeIcon icon={faCheck} fixedWidth />Complete
                                                </>
                                            :
                                                <Button type="dashed" onClick={() => this.showFeedbackModal(record.id)}>
                                                    <FontAwesomeIcon icon={faUserCheck} fixedWidth />TfNSW Feedback
                                                </Button>
                                        }
                                    </>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>

                <Modal
                    destroyOnClose={true}
                    className="step1-modal"
                    visible={this.state.studentSignInStep1ModalVisible}
                    title="Signing In..."
                    closable={false}
                    footer={[
                        <Button
                            key="back"
                            onClick={this.studentSignInStep1ModalHandleCancel}
                            disabled={this.state.studentSignInStep1ModalLoading}
                        >
                            <FontAwesomeIcon icon={faBan} />Cancel
                        </Button>,
                        <Button
                            key="submit"
                            onClick={this.studentSignInStep1ModalHandleOk}
                            type="primary"
                            loading={this.state.studentSignInStep1ModalLoading}
                            disabled={this.state.studentSignInStep1ModalLoading}
                        >
                            <FontAwesomeIcon icon={faUserCheck} />Confirm
                        </Button>
                    ]}
                >
                    {this.state.attendees !== null && this.state.studentSignInSelectedStudent !== null &&
                        <>
                            Confirming Attendance for {this.state.attendees[this.state.studentSignInSelectedStudent].user.first_name} {this.state.attendees[this.state.studentSignInSelectedStudent].user.last_name}.
                        </>
                    }
                </Modal>

                <Modal
                    style={{ top: 10 }}
                    width={(window.innerWidth < 768 ? "100%" : "90%")}
                    destroyOnClose={true}
                    className="step2-modal"
                    visible={this.state.studentSignInStep2ModalVisible}
                    title="Confirm Your Details"
                    closable={false}
                    footer={[
                        <Button
                            key="back"
                            onClick={this.studentSignInStep2ModalHandleCancel}
                            disabled={this.state.studentSignInStep2ModalLoading}
                        >
                            <FontAwesomeIcon icon={faBan} />Cancel
                        </Button>,
                        <Button
                            key="submit"
                            onClick={this.studentSignInStep2ModalHandleOk}
                            type="primary"
                            loading={this.state.studentSignInStep2ModalLoading}
                            disabled={this.state.studentSignInStep2ModalLoading}
                        >
                            <FontAwesomeIcon icon={faUserCheck} />Confirm
                        </Button>
                    ]}
                >
                    {this.state.attendees !== null && this.state.studentSignInSelectedStudent !== null &&
                        <>
                            <Form
                                id="studentSignInStep2Form"
                                onSubmit={this.studentSignInStep2ModalHandleOk}
                                labelCol={{span: 6}}
                                wrapperCol={{span: 18}}
                            >
                                <Form.Item label="First Name" hasFeedback>
                                    {getFieldDecorator('first_name', {
                                        initialValue: this.state.attendees[this.state.studentSignInSelectedStudent].user.first_name,
                                        rules: [
                                            { required: true, message: 'Please enter your First Name.' },
                                            { max: 30, message: 'First Name cannot be more than 30 characters.' },
                                        ]
                                    })(
                                        <Input
                                            maxLength={30}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Last Name" hasFeedback>
                                    {getFieldDecorator('last_name', {
                                        initialValue: this.state.attendees[this.state.studentSignInSelectedStudent].user.last_name,
                                        rules: [
                                            { required: true, message: 'Please enter your Last Name.' },
                                            { max: 30, message: 'Last Name cannot be more than 30 characters.' },
                                        ]
                                    })(
                                        <Input
                                            maxLength={30}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Address" hasFeedback>
                                    {getFieldDecorator('address', {
                                        initialValue: this.state.attendees[this.state.studentSignInSelectedStudent].user.address_id,
                                        rules: [{ required: true, message: 'Please select your Address.' }],
                                    })(
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            ref={this.addressRef}
                                            placeholder="Select an Address"
                                            onChange={this.handleAddressChange}
                                        >
                                            {this.state.studentSignInStep2ModalAddresses}
                                            <Option value="new">Add New Address</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Date of Birth" hasFeedback>
                                    {getFieldDecorator('dob', {
                                        rules: [{ required: true, message: 'Please enter your Date of Birth.' }],
                                        initialValue: (typeof this.state.attendees[this.state.studentSignInSelectedStudent].user.dob !== 'undefined' && this.state.attendees[this.state.studentSignInSelectedStudent].user.dob !== null ? moment(this.state.attendees[this.state.studentSignInSelectedStudent].user.dob) : null)
                                    })(
                                        <DatePicker
                                            dropdownClassName="no-keyboard"
                                            allowClear={false}
                                            placeholder='##/##/####'
                                            format={dateFormatList}
                                            disabledDate={(current)=>{return current < moment().subtract(25, 'years').startOf('day') || current > moment().endOf('day')}}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Gender" hasFeedback>
                                    {getFieldDecorator('gender', {
                                        initialValue: (this.state.attendees[this.state.studentSignInSelectedStudent].user.gender === 'Unknown' ? null : this.state.attendees[this.state.studentSignInSelectedStudent].user.gender),
                                        rules: [{ required: true, message: 'Please enter your Gender.' }]
                                    })(
                                        <Select
                                            placeholder="Select Gender"
                                            defaultActiveFirstOption={false}
                                            filterOption={false}
                                            notFoundContent={null}
                                            onChange={this.handleGenderChange}
                                        >
                                            <Option value="F">Female</Option>
                                            <Option value="M">Male</Option>
                                            <Option value="Other">Other</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                {this.state.studentSignInStep2ModalOtherGenderVisible && 
                                    <Form.Item label="Gender (Please Enter)" hasFeedback>
                                        {getFieldDecorator('gender_other', {
                                            initialValue: (this.state.attendees[this.state.studentSignInSelectedStudent].user.user_gender !== null ? this.state.attendees[this.state.studentSignInSelectedStudent].user.user_gender.gender : null),
                                            rules: [
                                                { required: true, message: 'Please enter your preferred Gender term.' },
                                                { max: 255, message: 'Term cannot be more than 255 characters.' },
                                            ]
                                        })(
                                            <Input
                                                ref={(input) => { this.genderOther = input; }}
                                                maxLength={255}
                                            />
                                        )}
                                    </Form.Item>
                                }
                                <Form.Item label="Driver Licence No." hasFeedback>
                                    {getFieldDecorator('licence_number', {
                                        initialValue: this.state.attendees[this.state.studentSignInSelectedStudent].licence_number,
                                        rules: [
                                            { required: true, message: 'Please enter your Licence No.' },
                                            { max: 20, message: 'Licence No. cannot be more than 20 characters.' },
                                        ]
                                    })(
                                        <Input
                                            maxLength={20}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Licence Expiry" hasFeedback>
                                    {getFieldDecorator('licence_expiry', {
                                        initialValue: (typeof this.state.attendees[this.state.studentSignInSelectedStudent].licence_expiry !== 'undefined' && this.state.attendees[this.state.studentSignInSelectedStudent].licence_expiry !== null ? moment(this.state.attendees[this.state.studentSignInSelectedStudent].licence_expiry) : null),
                                        rules: [{ required: true, message: 'Please enter your Licence Expiry Date.' }]
                                    })(
                                        <DatePicker
                                            dropdownClassName="no-keyboard"
                                            allowClear={false}
                                            placeholder='##/##/####'
                                            format={dateFormatList}
                                            disabledDate={(current)=>{return current < moment().startOf('day');}}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Logbook Hours" hasFeedback>
                                    {getFieldDecorator('driving_experience', {
                                        initialValue: this.state.attendees[this.state.studentSignInSelectedStudent].driving_experience,
                                        rules: [
                                            { required: true, message: 'Please enter your No. of Logbook Hours.' },
                                            { validator: (rule, value, cb) => (value < 50  ? cb(true) : cb()), message: 'Minimum 50 Hours Required.'}
                                        ]
                                    })(
                                        <InputNumber
                                            min={1}
                                            max={99999999999}
                                            maxLength={11}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={false}
                                    wrapperCol={{
                                        xs:{offset: 0, span: 24},
                                        sm:{offset: 6, span: 18}
                                    }}
                                >
                                    <p>
                                        <u><b>Privacy Policy:</b></u><br />
                                        You understand that Trent Driving School will disclose your personal information to Transport for NSW and it may be used by TfNSW for the purposes of:
                                    </p>
                                    <ul>
                                        <li>Verifying your successful completion of the course; and/or...</li>
                                        <li>Contacting you to discuss feedback on the course</li>
                                    </ul>
                                    <p>
                                        Otherwise TfNSW will not use or disclose your personal information without your consent unless authorised by law. Your personal information will be held by TfNSW at 18 Lee Street, Chippendale NSW 2008.<br />
                                        You may contact TfNSW to request to access or correct the information.
                                    </p>
                                </Form.Item>
                                <Form.Item label="Please Sign">
                                    <>
                                        <SignaturePad
                                            minWidth={1.5}
                                            maxWidth={4}
                                            clearOnResize={true}
                                            canvasProps={{className: 'sigCanvas', id: "smallSigningCanvas"}}
                                            ref={(ref) => { this.sigPad = ref }}
                                            onEnd={()=>{
                                                this.setState({
                                                    signature: this.sigPad.toDataURL('image/png')
                                                });
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            icon={faExpandAlt}
                                            size='lg'
                                            fixedWidth
                                            onClick={() => {
                                                //Draw Sig into Big Sig
                                                this.setState({
                                                    studentSignInStep2BigSigModalVisible: true
                                                }, () => {
                                                    var img = new Image();
                                                    img.onload = function() {
                                                        document.getElementById('bigSigningCanvas').getContext('2d').clearRect(0, 0, parseInt(getComputedStyle(document.getElementById('bigSigningCanvas')).width), parseInt(getComputedStyle(document.getElementById('bigSigningCanvas')).height));
                                                        document.getElementById('bigSigningCanvas').getContext('2d').drawImage(this, 0, 0, parseInt(getComputedStyle(document.getElementById('bigSigningCanvas')).width), parseInt(getComputedStyle(document.getElementById('bigSigningCanvas')).height));
                                                    }
                                                    if (!this.sigPad.isEmpty()) {
                                                        img.src = this.state.signature;
                                                    }
                                                });
                                            }}
                                            style={{position: 'absolute', marginTop: 0}}
                                        />
                                        <FontAwesomeIcon
                                            icon={faEraser}
                                            size='lg'
                                            fixedWidth
                                            onClick={() => {
                                                this.setState({
                                                    signature: null
                                                }, () => {
                                                    if (typeof(this.sigPad) !== 'undefined' && this.sigPad !== null) {
                                                        this.sigPad.clear();
                                                    }
                                                    if (typeof(this.sigPadBig) !== 'undefined' && this.sigPadBig !== null) {
                                                        this.sigPadBig.clear();
                                                    }
                                                });
                                            }}
                                            style={{position: 'absolute', bottom: 20}}
                                        />
                                    </>
                                </Form.Item>
                            </Form>
                        </>
                    }
                </Modal>

                <Modal
                    style={{ top: 10 }}
                    width={(window.innerWidth < 768 ? "100%" : "90%")}
                    destroyOnClose={true}
                    className="step2-modal-big-sig"
                    visible={this.state.studentSignInStep2BigSigModalVisible}
                    title="Please Sign"
                    closable={false}
                    footer={[
                        <Button
                            key="submit"
                            onClick={() => {
                                //Draw Big Sig into Sig
                                this.setState({
                                    studentSignInStep2BigSigModalVisible: false
                                }, () => {
                                    var img = new Image();
                                    img.onload = function() {
                                        document.getElementById('smallSigningCanvas').getContext('2d').clearRect(0, 0, parseInt(getComputedStyle(document.getElementById('smallSigningCanvas')).width), parseInt(getComputedStyle(document.getElementById('smallSigningCanvas')).height));
                                        document.getElementById('smallSigningCanvas').getContext('2d').drawImage(this, 0, 0, parseInt(getComputedStyle(document.getElementById('smallSigningCanvas')).width), parseInt(getComputedStyle(document.getElementById('smallSigningCanvas')).height));
                                    }
                                    if (!this.sigPadBig.isEmpty()) {
                                        img.src = this.state.signature;
                                    }
                                });
                            }}
                            type="primary"
                        >
                            <FontAwesomeIcon icon={faSave} />Save
                        </Button>
                    ]}
                >
                    {this.state.attendees !== null && this.state.studentSignInSelectedStudent !== null &&
                        <>
                            <SignaturePad
                                minWidth={3}
                                maxWidth={8}
                                clearOnResize={true}
                                canvasProps={{className: 'sigCanvasBig', id: "bigSigningCanvas"}}
                                ref={(ref) => { this.sigPadBig = ref }}
                                onEnd={()=>{
                                    this.setState({
                                        signature: this.sigPadBig.toDataURL('image/png')
                                    });
                                }}
                            />
                            <FontAwesomeIcon
                                icon={faEraser}
                                size='lg'
                                fixedWidth
                                onClick={() => {
                                    this.setState({
                                        signature: null
                                    }, () => {
                                        if (typeof(this.sigPad) !== 'undefined' && this.sigPad !== null) {
                                            this.sigPad.clear();
                                        }
                                        if (typeof(this.sigPadBig) !== 'undefined' && this.sigPadBig !== null) {
                                            this.sigPadBig.clear();
                                        }
                                    });
                                }}
                                style={{position: 'absolute'}}
                            />
                        </>
                    }
                </Modal>

                <Modal
                    destroyOnClose={true}
                    className="new-address-modal"
                    visible={this.state.newAddressModalVisible}
                    title="New Address"
                    footer={[
                        <Button
                            key="back"
                            onClick={this.newAddressModalHandleCancel}
                        >
                            <FontAwesomeIcon icon={faBan} />Cancel
                        </Button>,
                        <Button
                            form="newAddressForm"
                            key="submit"
                            htmlType="submit"
                            type="primary"
                            loading={this.state.newAddressModalLoading}
                        >
                            <FontAwesomeIcon icon={faSave} />Save
                        </Button>
                    ]}
                >
                    <Form
                        id="newAddressForm"
                        onSubmit={this.newAddressModalHandleOk}
                    >
                        <Form.Item label="Street Number" >
                            {getFieldDecorator('street_number', {
                                rules: [
                                    { required: true, message: 'Please enter a Street Number.' },
                                    { max: 10, message: 'Street Number cannot be more than 10 characters.' },
                                ],
                            })(
                                <Input
                                    required="required"
                                    autoFocus={true}
                                    maxLength={10}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Street Name" >
                            {getFieldDecorator('street_name', {
                                rules: [
                                    { required: true, message: 'Please enter a Street Name.' },
                                    { max: 60, message: 'Street Name cannot be more than 60 characters.' },
                                ],
                            })(
                                <Input
                                    required="required"
                                    maxLength={60}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Suburb" >
                            {getFieldDecorator('suburb_id', {
                                rules: [{ required: true, message: 'Please enter a Suburb.' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="Select Suburb"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={this.handleSuburbSearch}
                                    onFocus={this.disableAutoComplete}
                                    notFoundContent={null}
                                >
                                    {suburbs}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    style={{ top: 10 }}
                    width={(window.innerWidth < 768 ? "100%" : "90%")}
                    destroyOnClose={true}
                    className="feedback-modal"
                    visible={this.state.feedbackFormModalVisible}
                    title="Safer Drivers Course - Participant Evaluation"
                    closable={false}
                    footer={[
                        <Button
                            key="back"
                            onClick={this.feedbackFormModalHandleCancel}
                            disabled={this.state.feedbackFormModalLoading}
                        >
                            <FontAwesomeIcon icon={faBan} />Cancel
                        </Button>,
                        <Button
                            key="submit"
                            onClick={this.feedbackFormModalHandleOk}
                            type="primary"
                            loading={this.state.feedbackFormModalLoading}
                            disabled={this.state.feedbackFormModalLoading}
                        >
                            <FontAwesomeIcon icon={faSave} />Save
                        </Button>
                    ]}
                >
                    <Form
                        id="feedbackForm"
                        onSubmit={this.newAddressModalHandleOk}
                    >
                        <>
                            <p>Please take time to complete this evaluation form at the end of the training module you attended. TfNSW welcomes your feedback and will use the information provided to review the Safer Drivers Course and to make improvements where applicable.</p>

                            <p>On a scale of 0-10 (where 0 = strongly disagree and 10 = strongly agree), please rate your level of agreement for each of the following statements.</p>
                            <p>If your answer to any of the statements is less than 5, we would appreciate if you can explain why, or suggest how it might be improved.</p>

                            <div className='question-table'>
                                {this.props.survey !== null && (
                                    Object.entries(this.props.survey.survey_questions).map(([questionkey, question]) => (
                                        <Fragment key={"q" + questionkey}>
                                            {question._joinData.heading !== null && (
                                                <div className='header-row'>
                                                    <span className='question-column'>{question._joinData.heading}</span>
                                                    {question.format === 'radio' && (
                                                        <span className='answer-column-wrapper'>
                                                            <span className='answer-column'>0</span>
                                                            <span className='answer-column'>1</span>
                                                            <span className='answer-column'>2</span>
                                                            <span className='answer-column'>3</span>
                                                            <span className='answer-column'>4</span>
                                                            <span className='answer-column'>5</span>
                                                            <span className='answer-column'>6</span>
                                                            <span className='answer-column'>7</span>
                                                            <span className='answer-column'>8</span>
                                                            <span className='answer-column'>9</span>
                                                            <span className='answer-column'>10</span>
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                            {question._joinData.order !== null && question.format === 'radio' && (
                                                <>
                                                    <div className='question-row'>
                                                        <span className='question-column'>{question._joinData.order}. {question.text}</span>
                                                        <Form.Item label={false} >
                                                            {getFieldDecorator('question[' + question.id + ']', {
                                                                rules: [{ required: question._joinData.required, message: 'Please answer this question.' }]
                                                            })(
                                                                <Radio.Group
                                                                    id={"Question" + question.id}
                                                                    name={"question[" + question.id + "]"}
                                                                    onChange={this.onRadioChange.bind(this)}
                                                                >
                                                                    <span className='answer-column-wrapper'>
                                                                        {Object.entries(question.survey_question_answers).map(([answerkey, answer]) => (
                                                                            <span key={answerkey} className='answer-column'>
                                                                                <span className='mobile-only'>{answer.text}<br /></span>
                                                                                <Radio value={answer.id} data-value={answer.text} data-qid={questionkey}></Radio>
                                                                            </span>
                                                                        ))}
                                                                    </span>
                                                               </Radio.Group>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    {question.comment_threshold !== null && typeof(this.state.showCommentBox[question.id]) !== 'undefined' && this.state.showCommentBox[question.id] && (
                                                        <div className='question-row comment' >
                                                            <span className='question-column'></span>
                                                            <Form.Item label={false} >
                                                                {getFieldDecorator('comment[' + question.id + ']', {
                                                                    rules: [{ required: false, message: 'Please answer this question.' }]
                                                                })(
                                                                    <span className='answer-column-comment'>
                                                                        <TextArea
                                                                            autoFocus={true}
                                                                            placeholder="Comments (optional)"
                                                                            autoSize={{ minRows: 5, maxRows: 10 }}
                                                                            name={"comment[" + question.id + "]"}
                                                                        />
                                                                    </span>
                                                                )}
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {question._joinData.order !== null && question.format === 'text' && (
                                                <>
                                                    <div className='question-row' name={"question[" + question.id + "]"}>
                                                        <span className='question-column'>{question.text}</span>
                                                        <Form.Item label={false} >
                                                            {getFieldDecorator('question[' + question.id + ']', {
                                                                rules: [{ required: question._joinData.required, message: 'Please answer this question.' }]
                                                            })(
                                                                <span className='answer-column-comment'>
                                                                    <TextArea
                                                                        placeholder={"Comments" + (question._joinData.required ? ' (required)' : ' (optional)')}
                                                                        autoSize={{ minRows: 5, maxRows: 10 }}
                                                                        name={"question[" + question.id + "]"}
                                                                    />
                                                                </span>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </>
                                            )}
                                        </Fragment>
                                    ))
                                )}
                            </div>
                        </>
                    </Form>
                </Modal>
            </>
        );
    }
};

const AttendanceSheet = Form.create({name: 'login'})(AttendanceSheetForm);

export default withRouter(AttendanceSheet);
