import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Collapse, Button } from 'antd';
// Hidden 21/05/2024 as per TDS-822
//import MessageForm from '../elements/messages/MessageForm';
import api from '../../helpers/api';
import Spinner from '../elements/Spinner';
import renderHTML from 'react-render-html';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const { Panel } = Collapse;

class MessageEdit extends React.Component {

    state = {
        loading: true
    };

    async componentDidMount() {
        await api.post("messages/fetch/" + this.props.match.params.id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.messages === 'undefined') {
                    this.props.history.push('/messages');
                } else {
                    this.setState({
                        messages: res.result.messages,
                        current_message_id: res.result.current_message,
                        current_message: res.result.messages[res.result.current_message],
                        loading: false});

                }
            })
            .then(() => {
                api.post("users/me", {
                    body: JSON.stringify({
                        chosen_driver: this.props.chosen_driver
                    })
                })
                .then(res => {
                    this.props.setAppStateValue('counts', {
                        messages: res.result.user.counts.messages,
                        urgent: res.result.user.counts.urgent
                    });
                });
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/messages');
        });
    };

    panelHeader = (item, index) => {
        let sender = (item.sender === null ? "System" : item.sender.first_name + ' ' + item.sender.last_name);
        return (
            <div>
                <span>
                    {item.subject}
                </span>
                <span style={{color: 'rgba(0, 0, 0, 0.42)', paddingLeft: '0.35em'}}>
                    - From {sender}
                </span>
            </div>
        );
    }

    render() {
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                        {
                        this.state.loading ? 
                            (
                                <Spinner type="mega" />
                            ) : (
                                <>
                                    <Button onClick={() => this.props.history.goBack()} className="ant-btn goBack">
                                        <FontAwesomeIcon icon={ faArrowLeft } />Return
                                    </Button>
                                    <br />
                                    <Collapse 
                                        style={{textAlign: 'left', 'marginBottom':'50px'}}
                                        defaultActiveKey={this.state.current_message_id.toString()}
                                    >
                                        {this.state.messages.map((item, index) => (
                                            <Panel header={this.panelHeader(item, index)} style={{'fontWeight': index === this.state.current_message_id && this.state.messages.length > 1? "bold":"inherit"}} key={index}> 
                                                <span style={{"whiteSpace": "pre-wrap"}}>
                                                    {renderHTML(item.content)}
                                                </span>

                                            </Panel>
                                        ))}
                                    </Collapse>

                                    {/* Hidden 21/05/2024 as per TDS-822
                                    <Collapse
                                        style={{textAlign: 'left', 'marginBottom':'50px'}}
                                        defaultActiveKey={this.state.current_message_id.toString()}
                                    >
                                        <Panel
                                            header='Send Reply'
                                            key='reply'
                                        >
                                            <h2 style={{color: 'rgba(1, 1, 1)'}}>
                                                Send Reply
                                            </h2>
                                            <MessageForm
                                                messageToReply={this.state.current_message}
                                                setAppStateValue = {this.setAppStateValue}
                                                chosen_driver={this.props.chosen_driver}
                                            />
                                        </Panel>
                                    </Collapse>
                                    */}
                                </>
                            )
                        }
                </Col>
            </Row>
        );
    }
};

export default withRouter(MessageEdit);
