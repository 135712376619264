import React from 'react';
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';
import { Alert, Button, Form, Input } from 'antd';
import api from '../../../helpers/api';

import config from '../../../config';
const { logoFullImage } = config;
const images = require.context('../../../images/', true);
const logoFull = images('./' + logoFullImage);

class CreatePasswordForm extends React.Component {

    state = {
        errorMessage: false,
        errorMessageType: "error"
    };
    
    render() {
        let displayAlert = this.state.errorMessage !== false;
        const { getFieldDecorator } = this.props.form;

        var handleCreatePassword = async (e) => {
            e.preventDefault();

            return api.post("users/create-password", {
                body: JSON.stringify({
                    password: document.getElementById('login_password').value,
                    confirm: document.getElementById('login_confirm').value,
                    token: document.getElementById('login_token').value
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK'){
                    this.setState({
                        errorMessage: res.result.error,
                        errorMessageType: 'error'
                    });
                }else if (typeof res.result.message !== 'undefined') {
                    this.props.setAppStateValue('loginMessage', 'Password Updated! Please log in above.', true, () => {
                        //Redirect back to Home Screen / Login Form
                        this.props.history.push('/');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    errorMessage: "Error trying to Update your Password. Contact the office if this problem persists.",
                    errorMessageType: 'error'
                });
            });
        };

        return (
            <div className='login-screen'>
                <div className="login-container">
                    <div className="logo-container"><img src={logoFull} className="big-logo" alt="logo" /></div>
                    <Form id="loginForm" onSubmit={handleCreatePassword} layout='vertical'>
                        <Form.Item label="New Password" >
                          {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please enter your new password.' }],
                          })(
                            <Input type='password' required="required" autoFocus={true} />
                          )}
                        </Form.Item>
                        <Form.Item label="Confirm Password" >
                          {getFieldDecorator('confirm', {
                            rules: [{ required: true, message: 'Please re-enter your new password.' }],
                          })(
                            <Input type='password' required="required" />
                          )}
                        </Form.Item>
                        <Input type='hidden' name="login_token" id="login_token" value={window.location.pathname.split("/").pop()} />
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Create Password
                            </Button>
                            <Link to="/">
                                <Button type="link">
                                    Cancel
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>

                    <div className={displayAlert ? '' : 'hidden' }>
                        <Alert key="login-failed" message={this.state.errorMessage} type={this.state.errorMessageType} banner />
                    </div>
                </div>
            </div>
        );
    }
};

const CreatePassword = Form.create({name: 'login'})(CreatePasswordForm);

export default withRouter(CreatePassword);
