import React from 'react';
import { withRouter } from 'react-router';
import { Form, Input, Modal, Checkbox, Table, Row, Col, Button } from 'antd';
import config from '../../../config';
import api from '../../../helpers/api';
import CryptoJS from 'crypto-js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCreditCard, faPencilAlt, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Spinner';

class IPSI extends React.Component {
    
    state = {
        studentCards: {},
        existingCardProcessing: false,
        existingCardProcessingID: null,
        // Update Card Modal:
        updateCreditCardModalVisible: false,
        updateCreditCardModalCardID: null,
        updateCreditCardModalCardExpiry: null,
        updateCreditCardModalCardHolder: null,
        updatingCreditCard: false
    };

    /**
     * On load, add an event listener waiting for responses from the IPSI iframe.
     * Remove it on unload.
     */
    constructor(props) {
        super(props);
        window.addEventListener('message', this.ipsiResultFunction);
        // Check for any existing Remembered cards
        this.fetchStudentSavedCardData();
    };
    componentWillUnmount = () => {
        window.removeEventListener('message', this.ipsiResultFunction);
    };

    /**
     * On load or change of props, we pull the iframe from the DOM and replace it's source.
     * The reason for doing so is that if we change the src while in the DOM, it adds an entry to the browser history
     * Which means that our 'goBack()' functionality does not work as expected.
     */
    componentDidMount() {
        this.replaceIframeSrc();
    };
    componentDidUpdate(prevProps, prevState) {
        // Only re-render the form if we change the values
        if (prevProps.product_id !== this.props.product_id || prevProps.product_quantity !== this.props.product_quantity) {
            this.replaceIframeSrc();
        }
    };
    replaceIframeSrc = () => {
        var iframe = document.getElementById('ipsi-form');
        var container = iframe.parentNode;
        iframe.remove();
        iframe.src = this.ipsiPath();
        container.append(iframe);
    };

    fetchStudentSavedCardData = (params = {}) => {
        api.post("students/cards/" + this.props.student_id, {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(res => {
            //Check for an error response (status of "NOK")
            if(res.status === 'OK' || typeof res.result.cards !== 'undefined') {
                this.setState({
                    studentCards: (Object.entries(res.result.cards).length > 0 ? res.result.cards : {})
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    ipsiResultFunction = (message) => {
        try {
            let data = JSON.parse(message.data);
            if(data.info === "Payment outcome for the transaction.") {
                // If the responseCode indicates success - See one of these links for more details:
                // https://ipsi.atlassian.net/servicedesk/customer/portal/2/article/1597177857 (iframes the wiki link below)
                // https://ipsi.atlassian.net/wiki/spaces/PCKB/pages/1597177857/Payment+Response+Codes
                if (
                    ['00', '08', '16', '77'].includes(data.responseCode)
                ) {
                    this.processCart(data, document.getElementById('remember-card').checked);
                } else {
                    Modal.error({
                        className: 'ipsi-error',
                        title: 'Unable to process Credit Card.',
                        content: 'Please check card details and try again.',
                        icon: false,
                        closable: false,
                        keyboard: false,
                        okButtonProps: {
                            type: "primary"
                        },
                        onOk: () => {
                            this.forceUpdate();
                        }
                    });
                }
            }
        } catch(e) {
            //ignore
        }
    };

    processCart = (payment_data, store_token) => {
        this.props.changeSubmitting();

        let data = {
            chosen_driver: this.props.chosen_driver,
            student_id: this.props.student_id,
            product_id: this.props.product_id,
            product_quantity: this.props.product_quantity,
            payment_amount: (this.props.products[this.props.product_id].product_prices[0].base_cost * this.props.product_quantity).toFixed(2),
            payer_name: payment_data.name,
            external_reference_id: payment_data.txnReference,
            received_date: payment_data.transactionDate,
            source: 'Diary'
        };
        if (store_token) {
            data.cardToken = payment_data.cardToken;
            data.cardExpiry = payment_data.cardExpiryDate;
        }

        api.post("purchases/processIPSIPayment", {
            body: JSON.stringify(data)
        })
        .then(data => {
            this.props.changeSubmitting();
            if (typeof data.result === 'object' && data.result.success) {
                this.props.changeSubmitting();
                Modal.success({
                    title: "Payment Successful",
                    content: (
                        <div>
                            <p>The Payment was successfully processed.</p>
                        </div>
                    ),
                    closable: false,
                    keyboard: false,
                    onOk: () => {
                        if(this.props.inModal) {
                            this.props.successfulPayment();
                        } else {
                            this.props.backToReferrer();
                        }
                    }
                });
            } else {
                Modal.error({
                    title: 'Unable to process Credit Card.',
                    content: (
                        <div>
                            <p>Please check card details and try again.</p>
                        </div>
                    ),
                    closable: false,
                    keyboard: false
                });
            }
        });
    }

    // Unique Reference to identify this purchase
    reference = () => {
        // Student ID + UNIX Timestamp
        return this.props.student_id + "_" + Date.now();
    };

    // Encrypted method to generate and pass through the required security variable
    HmacSHA256 = (pathparams) => {
        return CryptoJS.HmacSHA256(pathparams, config.IPSI_secKey).toString();
    };

    ipsiPath = () => {
        let pathparams = "configId=" + config.IPSI_configId + 
                "&userName=" + config.IPSI_userName + 
                "&txnType=0" +
                "&amount=" + (this.props.products[this.props.product_id].product_prices[0].base_cost * this.props.product_quantity).toFixed(2) +
                "&merchReference=" + this.reference() + 
// cardHolderName prepopulation turned off due to issues with IPSI iframe v2 - expected to be fixed by end of Sept 2023
//                "&cardHolderName=" + encodeURI(this.props.studentData.user.first_name + ' ' + this.props.studentData.user.last_name).replace(/'/g, '%27') + 
                "&currency=AUD" +
                "&tokenControl.token=true" +
                "&tokenControl.tokenFormat=F2L4AN" +
                "&validCardTypes=V,MC" +
                "&staff=true" // SMS and Diary need to use staff=true to hide Email field and ClickToPay options.
                ;
        return config.IPSI_server + "?" + pathparams + "&verifyMessage=" + this.HmacSHA256(pathparams);
    };

    changeUpdateCreditCardModalVisibility = (card_id, card_expiry, card_holder) => {
        this.setState({
            updateCreditCardModalVisible: !this.state.updateCreditCardModalVisible,
            updateCreditCardModalCardID: !isNaN(card_id) ? card_id : null,
            updateCreditCardModalCardExpiry: (card_expiry !== null) ? card_expiry : null,
            updateCreditCardModalCardHolder: (card_holder !== null) ? card_holder : null
        });
    };

    updateCreditCardModalHandleOk = () => {
        if(document.getElementById('ipsi_form_cardExpiry').value === '' || document.getElementById('ipsi_form_cardHolder').value === ''){
            return false;
        }
        this.setState({
            updatingCreditCard: true
        }, () => {
            // API Request with details of purchase plus Card ID. Process the payment to IPSI via the API.
            api.post("students/cards/update", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    student_id: this.props.student_id,
                    card_id: this.state.updateCreditCardModalCardID,
                    card_expiry: document.getElementById('ipsi_form_cardExpiry').value,
                    card_holder: document.getElementById('ipsi_form_cardHolder').value
                })
            })
            .then(res => {
                if (res.status === 'OK' && res.result.success) {
                    this.fetchStudentSavedCardData();
                    Modal.success({
                        title: "Card Updated",
                        content: (
                            <div>
                                <p>Credit Card details successfully updated.</p>
                            </div>
                        ),
                        closable: false,
                        keyboard: false,
                        onOk: () => {
                            this.setState({
                                updatingCreditCard: false
                            }, () => {
                                this.changeUpdateCreditCardModalVisibility();
                            });
                        }
                    });
                } else {
                    Modal.error({
                        title: 'Error updating saved Credit Card.',
                        content: (
                                <div>
                                    {typeof(res.result.message) !== 'undefined' && (
                                        <p>{res.result.message}</p>
                                    )}
                                    {typeof(res.result.message) === 'undefined' && (
                                        <p>Please try again.</p>
                                    )}
                                    {typeof(res.result.errors) !== 'undefined' && res.result.errors.length > 0 && (
                                        <>
                                            <p>If the problem persists, please contact Support, and let them know about the errors reported below:</p>
                                            <ul>
                                                {res.result.errors.map((error, key) => (
                                                    <li key={"error_" + key}>{error}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                        ),
                        closable: false,
                        keyboard: false,
                        okButtonProps: {
                            type: "primary"
                        },
                        onOk: () => {
                            this.setState({
                                updatingCreditCard: false
                            }, () => {
                                document.getElementById('ipsi_form_cardExpiry').focus();
                                document.getElementById('ipsi_form_cardExpiry').select();
                            });
                        }
                    });
                }
            })
            .catch(error => {
                Modal.error({
                    className: 'ipsi-error',
                    title: 'Error updating saved Credit Card.',
                    content: (
                        <div>
                            <p>Please try again.</p>
                            <p>If the problem persists, please contact Support, and let them know about the errors reported below:</p>
                            <ul>
                                <li>{error.toString()}</li>
                            </ul>
                        </div>
                    ),
                    closable: false,
                    keyboard: false,
                    okButtonProps: {
                        type: "primary"
                    },
                    onOk: () => {
                        this.setState({
                            updatingCreditCard: false
                        }, () => {
                            document.getElementById('ipsi_form_cardExpiry').focus();
                            document.getElementById('ipsi_form_cardExpiry').select();
                        });
                    }
                });
            });
        });
    };

    deleteSavedCreditCard = () => {
        this.setState({
            updatingCreditCard: true
        }, () => {
            // API Request with details of purchase plus Card ID. Process the payment to IPSI via the API.
            api.post("students/cards/delete", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    student_id: this.props.student_id,
                    card_id: this.state.updateCreditCardModalCardID
                })
            })
            .then(res => {
                if (res.status === 'OK' && res.result.success) {
                    this.fetchStudentSavedCardData();
                    Modal.success({
                        title: "Card Deleted",
                        content: (
                            <div>
                                <p>Saved Credit Card successfully deleted.</p>
                            </div>
                        ),
                        closable: false,
                        keyboard: false,
                        onOk: () => {
                            this.setState({
                                updatingCreditCard: false
                            }, () => {
                                this.changeUpdateCreditCardModalVisibility();
                            });
                        }
                    });
                } else {
                    Modal.error({
                        title: 'Error deleting saved Credit Card.',
                        content: (
                                <div>
                                    {typeof(res.result.message) !== 'undefined' && (
                                        <p>{res.result.message}</p>
                                    )}
                                    {typeof(res.result.message) === 'undefined' && (
                                        <p>Please try again.</p>
                                    )}
                                    {typeof(res.result.errors) !== 'undefined' && res.result.errors.length > 0 && (
                                        <>
                                            <p>If the problem persists, please contact Support, and let them know about the errors reported below:</p>
                                            <ul>
                                                {res.result.errors.map((error, key) => (
                                                    <li key={"error_" + key}>{error}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                        ),
                        closable: false,
                        keyboard: false,
                        okButtonProps: {
                            type: "primary"
                        },
                        onOk: () => {
                            this.setState({
                                updatingCreditCard: false
                            }, () => {
                                document.getElementById('ipsi_form_cardExpiry').focus();
                                document.getElementById('ipsi_form_cardExpiry').select();
                            });
                        }
                    });
                }
            })
            .catch(error => {
                Modal.error({
                    className: 'ipsi-error',
                    title: 'Error deleting saved Credit Card.',
                    content: (
                        <div>
                            <p>Please try again.</p>
                            <p>If the problem persists, please contact Support, and let them know about the errors reported below:</p>
                            <ul>
                                <li>{error.toString()}</li>
                            </ul>
                        </div>
                    ),
                    closable: false,
                    keyboard: false,
                    okButtonProps: {
                        type: "primary"
                    },
                    onOk: () => {
                        this.setState({
                            updatingCreditCard: false
                        }, () => {
                            document.getElementById('ipsi_form_cardExpiry').focus();
                            document.getElementById('ipsi_form_cardExpiry').select();
                        });
                    }
                });
            });
        });
    };

    useCard = (card_id) => {
        this.setState({
            existingCardProcessing: true,
            existingCardProcessingID: card_id
        }, () => {
            // API Request with details of purchase plus Card ID. Process the payment to IPSI via the API.
            api.post("purchases/processIPSIPaymentToken", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    student_id: this.props.student_id,
                    card_id: card_id,
                    product_id: this.props.product_id,
                    product_quantity: this.props.product_quantity,
                    payment_amount: (this.props.products[this.props.product_id].product_prices[0].base_cost * this.props.product_quantity).toFixed(2),
                    source: 'Diary'
                })
            })
            .then(res => {
                if (res.status === 'OK' && res.result.success) {
                    Modal.success({
                        title: "Payment Successful",
                        content: (
                            <div>
                                <p>The Payment was successfully processed.</p>
                            </div>
                        ),
                        closable: false,
                        keyboard: false,
                        onOk: () => {
                            this.props.backToReferrer();
                        }
                    });
                } else {
                    Modal.error({
                        className: 'ipsi-error',
                        title: 'Error processing saved Credit Card.',
                        content: (
                                <div>
                                    {typeof(res.result.message) !== 'undefined' && (
                                        <p>{res.result.message}</p>
                                    )}
                                    {typeof(res.result.message) === 'undefined' && (
                                        <p>Please try again.</p>
                                    )}
                                    {typeof(res.result.errors) !== 'undefined' && res.result.errors.length > 0 && (
                                        <>
                                            <p>If the problem persists, please contact Support, and let them know about the errors reported below:</p>
                                            <ul>
                                                {res.result.errors.map((error, key) => (
                                                    <li key={"error_" + key}>{error}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                        ),
                        closable: false,
                        keyboard: false,
                        okButtonProps: {
                            type: "primary"
                        },
                        onOk: () => {
                            this.setState({
                                existingCardProcessing: false,
                                existingCardProcessingID: null
                            });
                        }
                    });
                }
            })
            .catch(error => {
                Modal.error({
                    className: 'ipsi-error',
                    title: 'Error processing saved Credit Card.',
                    content: (
                        <div>
                            <p>Please try again.</p>
                            <p>If the problem persists, please contact Support, and let them know about the errors reported below:</p>
                            <ul>
                                <li>{error.toString()}</li>
                            </ul>
                        </div>
                    ),
                    closable: false,
                    keyboard: false,
                    okButtonProps: {
                        type: "primary"
                    },
                    onOk: () => {
                        this.setState({
                            existingCardProcessing: false,
                            existingCardProcessingID: null
                        });
                    }
                });
            });
        });
    };

    columnFormat = [
        {
            title: 'Cardholder Name',
            dataIndex: 'cardHolder',
            render: (text, record) =>  {
                return (text);
            }
        },
        {
            title: 'Last Four Digits',
            dataIndex: 'last_four',
            render: (text, record) =>  {
                return (text);
            }
        },
        {
            title: 'Expiry Date',
            dataIndex: 'cardExpiry',
            render: (text, record) =>  {
                return (text);
            }
        },
        {
            title: '',
            className: 'actions',
            render: (text, record) => {
                return (
                    <div>
                        <Button
                            onClick={() => this.changeUpdateCreditCardModalVisibility(record.id, record.cardExpiry, record.cardHolder)}
                            disabled={this.state.existingCardProcessing}
                            className='edit-button'
                        >
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => this.useCard(record.id)}
                            disabled={this.state.existingCardProcessing}
                            loading={this.state.existingCardProcessing && this.state.existingCardProcessingID === record.id}
                        >
                            <FontAwesomeIcon icon={faCreditCard} />Pay
                        </Button>
                    </div>
                );
            }
        }
    ];

    render(){
        const { getFieldDecorator } = this.props.form;

        return (
            <div id="ipsi-trent">
                {this.state.existingCardProcessing && (
                    <Spinner type="mega" />
                )}
                {!this.state.existingCardProcessing && (
                    <>
                        {Object.entries(this.state.studentCards).length > 0 && (
                            <>
                                <h3>Previously Saved Credit Cards</h3>
                                <Row>
                                    <Col 
                                        xs={{ span: 18, offset: 3 }}
                                        lg={{ span: 16, offset: 4 }}
                                    >
                                        <Table
                                            size="middle"
                                            className="cards-table"
                                            rowClassName="cards-table-row"
                                            showHeader={true}
                                            columns={this.columnFormat}
                                            rowKey={record => record.id}
                                            dataSource={this.state.studentCards}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                                <h3
                                    style={{visibility: (this.state.existingCardProcessing ? 'hidden' : 'visible')}}
                                >
                                    Use A New Credit Card
                                </h3>
                            </>
                        )}
                        <Row>
                            <Col 
                                xs={{ span: 18, offset: 3 }}
                                lg={{ span: 12, offset: 6 }}
                            >
                                <div
                                    style={{visibility: (this.state.existingCardProcessing ? 'hidden' : 'visible')}}
                                >
                                    <div>
                                        <Checkbox
                                            id="remember-card"
                                            className="remember-card"
                                        >
                                            Remember card details for next time?
                                        </Checkbox>
                                    </div>
                                    <div>
                                        <iframe
                                            title="IPSI Form Iframe"
                                            id="ipsi-form"
                                            src="about:blank"
                                        ></iframe>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Modal
                            destroyOnClose={true}
                            className="update-credit-card"
                            visible={this.state.updateCreditCardModalVisible}
                            title="Update Saved Credit Card"
                            onCancel={this.changeUpdateCreditCardModalVisibility}
                            closable={false}
                            maskClosable={false}
                            keyboard={false}
                            footer={[
                                <Button
                                    key="delete"
                                    onClick={this.deleteSavedCreditCard}
                                    disabled={this.state.updatingCreditCard}
                                    className="ant-btn-danger pull-left"
                                >
                                    <FontAwesomeIcon icon={faTrash} />Delete Card
                                </Button>,
                                <Button
                                    key="back"
                                    onClick={this.changeUpdateCreditCardModalVisibility}
                                    disabled={this.state.updatingCreditCard}
                                >
                                    <FontAwesomeIcon icon={faBan} />Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    type="primary"
                                    loading={this.state.updatingCreditCard}
                                    disabled={
                                        this.state.updatingCreditCard || 
                                        (document.getElementById('ipsi_form_cardExpiry') !== null && document.getElementById('ipsi_form_cardExpiry').value === '') ||
                                        (document.getElementById('ipsi_form_cardHolder') !== null && document.getElementById('ipsi_form_cardHolder').value === '')
                                    }
                                    onClick={this.updateCreditCardModalHandleOk}
                                >
                                    <FontAwesomeIcon icon={faSave} />Save
                                </Button>
                            ]}
                        >
                            <Form
                                id="updateCreditCardForm"
                            >
                                <Form.Item label="Cardholder Name" >
                                    {getFieldDecorator('cardHolder', {
                                        initialValue: this.state.updateCreditCardModalCardHolder,
                                        rules: [
                                            { required: true, message: 'Please enter a Name.' },
                                            { max: 60, message: 'No more than 60 characters.' }
                                        ]
                                    })(
                                        <Input
                                            required="required"
                                            autoFocus={true}
                                            maxLength={60}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Expiry Date" >
                                    {getFieldDecorator('cardExpiry', {
                                        initialValue: this.state.updateCreditCardModalCardExpiry,
                                        rules: [
                                            { required: true, message: 'Please enter an Expiry Date.' },
                                            { max: 5, message: 'Please enter Expiry Date as MM/YY.' }
                                        ]
                                    })(
                                        <Input
                                            required="required"
                                            placeholder='MM/YY'
                                            maxLength={5}
                                        />
                                    )}
                                </Form.Item>
                            </Form>
                        </Modal>
                    </>
                )}
            </div>
        );
    }
};

const IPSIForm = Form.create({name: 'ipsi_form'})(IPSI);

export default withRouter(IPSIForm);
