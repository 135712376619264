import React from 'react';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Alert, Form, Button, Input } from 'antd';
import api from '../../../helpers/api';
import TopNavigation from '../../TopNavigation';
import AttendanceSheet from '../../elements/events/AttendanceSheet';
import Spinner from '../../elements/Spinner';
import moment from 'moment';

import config from '../../../config';
const { logoFullImage } = config;
const images = require.context('../../../images/', true);
const logoFull = images('./' + logoFullImage);

class AttendSDCForm extends React.Component {
    
    state = {
        errorMessage: false,
        errorMessageType: "error",
        studentSignInSelectedStudent: null,
        module: null,
        module_details: null,
        event_id: null,
        survey: null,
        surveyQuestionsToValidate: [],
        loginLoading: false,
        accessCodeLoading: false
    };

    async componentDidMount() {
        if (typeof(this.props.match.params.hash) !== 'undefined' && this.props.match.params.hash !== '') {
            this.setState({accessCodeLoading: true}, async () => {
                return await api.post("students/sdc/access/code", {
                    body: JSON.stringify({
                        access_code: this.props.match.params.hash
                    })
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if(typeof res === 'string'){
                        res = JSON.parse(res);
                    }
                    //Check for an error response (status of "NOK")
                    if(res.status === 'NOK' || typeof res.result.attendees === 'undefined' || res.result.attendees === null) {
                        this.setState({
                            errorMessage: res.result.error,
                            errorMessageType: 'error',
                            accessCodeLoading: false
                        });
                    } else {
                        localStorage.setItem('authToken', res.result.access_token);
                        localStorage.setItem('studentAccess', true);
                        this.setState({
                            attendees: res.result.attendees,
                            module: res.result.module,
                            module_details: res.result.module_details,
                            event_id: res.result.module_id,
                            studentSignInSelectedStudent: res.result.student_id,
                            accessCodeLoading: false
                        }, () => {
                            this.fetchSurvey();
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        errorMessage: "Error, please try again.",
                        errorMessageType: 'error',
                        accessCodeLoading: false
                    });
                });
            });
        }

        // Destroy the Student Token if they try leaving this page in any fashion.
        window.addEventListener("beforeunload", () => {
            localStorage.clear();
        });
    }

    fetchSurvey = async() => {
        await api.post("surveys/fetch/" + this.state.module, {
                body: JSON.stringify({
                    chosen_driver: this.state.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.survey === 'undefined' || res.result.survey === null) {
                    console.log('bad query');
//                        this.props.history.push('/event/' + this.state.event_id);
                } else {
                    //Build our list of questions for the survey so that we can validate the survey form
                    let surveyQuestionsToValidate = [].concat(res.result.survey.survey_questions.map(question => "question[" + question.id + "]"), res.result.survey.survey_questions.filter(question => question.format === 'radio').map(question => "comment[" + question.id + "]"));
                    this.setState({
                        survey: res.result.survey,
                        surveyQuestionsToValidate: surveyQuestionsToValidate
                    });
                }
            })
            .catch(error => {
                console.log(error);
        console.log('also bad');
//                this.props.history.push('/event/' + this.state.event_id);
        });
    };

    render() {
        let displayAlert = this.state.errorMessage !== false;
        const { getFieldDecorator } = this.props.form;

        var handleLogin = async (e) => {
            e.preventDefault();
            
            this.setState({loginLoading: true});

            return api.post("students/sdc/access", {
                body: JSON.stringify({
                    login_access_code: document.getElementById('login_access_code').value,
                    login_licence_number: document.getElementById('login_licence_number').value
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.attendees === 'undefined' || res.result.attendees === null) {
                    this.setState({
                        errorMessage: res.result.error,
                        errorMessageType: 'error',
                        loginLoading: false
                    });
                } else {
                    localStorage.setItem('authToken', res.result.access_token);
                    localStorage.setItem('studentAccess', true);
                    this.setState({
                        attendees: res.result.attendees,
                        module: res.result.module,
                        module_details: res.result.module_details,
                        event_id: res.result.module_id,
                        studentSignInSelectedStudent: res.result.student_id,
                        loginLoading: false
                    }, () => {
                        this.fetchSurvey();
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    errorMessage: "Error, please try again.",
                    errorMessageType: 'error',
                    loginLoading: false
                });
            });
        };

        return (
            <div>
                {
                this.state.accessCodeLoading ? 
                    (
                        <Spinner type="mega" />
                    ) : (
                        this.state.studentSignInSelectedStudent === null ? (
                            <div className='login-screen'>
                                <div className="login-container">
                                    <div className="logo-container"><img src={logoFull} className="big-logo" alt="logo" /></div>
                                    <h1 className="product-name">Safer Drivers Course</h1>
                                    <Form id="loginForm" onSubmit={handleLogin} layout='vertical'>
                                        <Form.Item label="Access Code" >
                                            {getFieldDecorator('access_code', {
                                                rules: [{ required: true, message: 'Please ask your instructor for an Access Code.' }],
                                            })(
                                            <Input
                                                required="required"
                                                autoFocus={true}
                                                maxLength={12}
                                                onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                            />
                                          )}
                                        </Form.Item>
                                        <Form.Item label="Licence Number" >
                                            {getFieldDecorator('licence_number', {
                                                rules: [{ required: true, message: 'Please enter your Driver Licence Number.' }],
                                            })(
                                            <Input
                                                required="required"
                                            />
                                          )}
                                        </Form.Item>
                                        <Form.Item>
                                            <Button loading={this.state.loginLoading} type="primary" htmlType="submit">
                                                Sign In
                                            </Button>
                                        </Form.Item>
                                    </Form>

                                    <div className={displayAlert ? '' : 'hidden' }>
                                        <Alert key="login-failed" message={this.state.errorMessage} type={this.state.errorMessageType} banner />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Layout style={{height: '100vh',overflow: 'hidden'}}>
                                <TopNavigation 
                                    collapsed={false}
                                    hide_menu_items ={true}
                                />
                                <Layout
                                    style={{position:'fixed', left: 0, right: 0, top: '64px', bottom: 0}}
                                >
                                    <section className="ant-layout">
                                        <main className="ant-layout-content">
                                            <Row type="flex" justify="space-around" id="mainBody">
                                                <Col span={24}>
                                                    <Row>
                                                        <Col>
                                                            <h2>Safer Drivers Course Module {this.state.module_details.module}</h2>
                                                            <div
                                                                className="ant-table ant-table-small ant-table-without-column-header ant-table-scroll-position-left"
                                                                style={{
                                                                    maxWidth: '500px',
                                                                    margin: '10px auto 20px'
                                                                }}
                                                            >
                                                                <div className="ant-table-content">
                                                                    <div className="ant-table-body">
                                                                        <table width="100%">
                                                                            <tbody className="ant-table-tbody">
                                                                                <tr className="ant-table-row students-table-row">
                                                                                    <th>Location</th>
                                                                                    <td>
                                                                                        {this.state.module_details.booking_start.name}<br />
                                                                                        {this.state.module_details.booking_start.street_number} {this.state.module_details.booking_start.street_name}, {this.state.module_details.booking_start.suburb.suburb_name} {this.state.module_details.booking_start.suburb.states.name} {this.state.module_details.booking_start.suburb.post_code}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="ant-table-row students-table-row">
                                                                                    <th>Date / Time</th>
                                                                                    <td>{moment(this.state.module_details.start_time).format('D/MM/YYYY h:mma')}</td>
                                                                                </tr>
                                                                                <tr className="ant-table-row students-table-row">
                                                                                    <th>{this.state.module_details.dt_title}</th>
                                                                                    <td>{this.state.module_details.dt_attendance.driver_trainer.user.first_name} {this.state.module_details.dt_attendance.driver_trainer.user.last_name}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h2>Attendance Sheet</h2>
                                                            <AttendanceSheet
                                                                attendees = {this.state.attendees}
                                                                event_id = {this.state.event_id}
                                                                survey = {this.state.survey}
                                                                module = {this.state.module}
                                                                surveyQuestionsToValidate = {this.state.surveyQuestionsToValidate}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </main>
                                    </section>
                                </Layout>
                            </Layout>
                        )
                    )
                }
            </div>
        );
    }
};

const AttendSDC = Form.create({name: 'login'})(AttendSDCForm);

export default withRouter(AttendSDC);
