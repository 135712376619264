import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import StudentsTable from '../elements/students/StudentsTable';

class Students extends React.Component {

    render(){
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h2>Select a Student</h2>
                    <StudentsTable
                        setAppStateValue={this.props.setAppStateValue}
                        chosen_driver={this.props.chosen_driver}
                        key={"studentsTable" + this.props.chosen_driver}
                    />
                    <Link to={"/students/add"}>
                        <Button
                            className='new-btn-student'
                            type="danger"
                            size="default"
                            id="newButton"
                        >
                            Add New Student
                        </Button>
                    </Link>
                </Col>
            </Row>
        );
    }
};

export default Students;