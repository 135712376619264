import React from 'react';
import { withRouter } from 'react-router';
import { Table, Input } from 'antd';
import api from '../../../helpers/api';
import Spinner from '../../elements/Spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

const { Search } = Input;

class DriversTable extends React.Component {
    state = {
        driverData: [],
        driverDataFiltered: [],
        searchText: '',
        loading: false
    };

    componentDidMount() {
        if(this.props.chosen_driver !== null){
            //Redirect to Home Screen for Selected DT
            this.props.history.push('/');
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if(this.state.driverData.length === 0 && this.props.driver_list.length > 0){
            this.setState({
                loading: false,
                driverData: this.props.driver_list,
                driverDataFiltered: this.props.driver_list
            });
        } else if (this.state.searchText !== prevState.searchText) {
            if(prevState.driverData.length === 0){
                this.fetch({
                    name: this.state.searchText
                });
            }else{
                var newFilteredData = [];
                var searchText = this.state.searchText.toUpperCase();
                prevState.driverData.forEach(function(driverItem) {
                    if(driverItem.name.toUpperCase().includes(searchText)){
                        newFilteredData.push(driverItem);
                    }
                });
                this.setState({driverDataFiltered: newFilteredData});
            }
        }
        if(document.getElementById('searchText') !== null){
            document.getElementById('searchText').focus();
        }
    }

    handleSearch = (searchText) => {
        this.setState({searchText: searchText});
    };

    columnFormat = [
        {
            title: 'Driver Name',
            dataIndex: 'name',
            render: (text, record) =>  {
                return (text);
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: 15,
            render: (text, record) => {
                return (
                    <FontAwesomeIcon icon={faArrowCircleRight} size="lg" />
                );
            }
        }
    ];

    fetch = (params = {}) => {
        this.setState({ loading: true });

        api.post("drivers/fetch",{
            body: JSON.stringify({
                results: 100,
                ...params
            })
        })
        .then(data => {
            this.setState({
                loading: false,
                driverData: data.result.drivers,
                driverDataFiltered: data.result.drivers
            });
        });
    };

    selectRow = (record) => {
        this.setState({
            loading: true
        });

        console.log(`Using Driver Trainer \`${record.name}\` (${record.id})`);

        api.post("drivers/select", {
            body: JSON.stringify({
                chosen_driver: record.id
            })
        })
        .then(() => {
            this.props.setAppStateValue('chosen_driver', record.id);
            this.props.setAppStateValue('chosen_driver_name', record.name);
            localStorage.setItem('chosen_driver', record.id);
            localStorage.setItem('chosen_driver_name', record.name);
        })
        .then(() => {
            //Redirect to Home Screen for Selected DT
            this.props.history.push('/');
        });
    };

    render() {
        return (
            <>
                <div className="ant-table-wrapper">
                    <Search
                        id="searchText"
                        placeholder="Filter Driver Trainers"
                        onSearch={value => this.handleSearch(value)}
                        size="large"
                        disabled={this.state.loading ? true : false}
                        onKeyUp={event => this.handleSearch(event.target.value)}
                        autoComplete="off"
                    />
                </div>
                <Table
                    className="drivers-table"
                    rowClassName="drivers-table-row"
                    size="middle"
                    showHeader={false}
                    columns={this.columnFormat}
                    rowKey={record => record.id}
                    dataSource={this.state.driverDataFiltered}
                    pagination={false}
                    loading={{spinning: this.state.loading, indicator: <Spinner type="mini" />}}
                    onRow={(record, rowIndex) => {
                        return {
                          onClick: event => this.selectRow(record)
                        };
                    }}
                />
            </>
        );
    }
};

export default withRouter(DriversTable);