import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Form } from 'antd';
import api from '../../helpers/api';
import Spinner from '../elements/Spinner';
import AttendanceSheet from '../elements/events/AttendanceSheet';

class EventAttendance extends React.Component {

    state = {
        event_id: null,
        loading: true,
        attendees: null,
        module: null,
        survey: null,
        surveyQuestionsToValidate: [],
        student_access_code: null
    };

    componentDidMount() {
        this.props.setAppStateValue('hide_menu_items', true);
        if(this.state.event_id === null){
            this.setState({event_id: parseInt(this.props.match.params.id)}, this.fetchEvent);
        }
    };
    
    componentDidUpdate(prevProps, prevState){
        if(parseInt(this.props.chosen_driver) !== parseInt(prevProps.chosen_driver)){
            this.fetchEvent();
        }
    }
    
    fetchEvent = async() => {
        await api.post("events/attendance/" + this.state.event_id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    survey: this.state.module
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.attendees === 'undefined' || res.result.attendees === null) {
                    this.props.history.push('/event/' + this.state.event_id);
                } else {
                    this.setState({
                        loading: false,
                        attendees: res.result.attendees,
                        module: res.result.module,
                        student_access_code: res.result.student_access_code
                    }, () => {
                        this.fetchSurvey();
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/event/' + this.state.event_id);
        });
    };

    fetchSurvey = async() => {
        await api.post("surveys/fetch/" + this.state.module, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.survey === 'undefined' || res.result.survey === null) {
                    this.props.history.push('/event/' + this.state.event_id);
                } else {
                    //Build our list of questions for the survey so that we can validate the survey form
                    let surveyQuestionsToValidate = [].concat(res.result.survey.survey_questions.map(question => "question[" + question.id + "]"), res.result.survey.survey_questions.filter(question => question.format === 'radio').map(question => "comment[" + question.id + "]"));
                    this.setState({
                        survey: res.result.survey,
                        surveyQuestionsToValidate: surveyQuestionsToValidate
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/event/' + this.state.event_id);
        });
    };

    render() {
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h1 className="attendance-header">SDC Module Attendance Sheet</h1>
                    {
                    this.state.loading ? 
                        (
                            <Spinner type="mega" />
                        ) : (
                            <>
                                <h2 className="attendance-header">
                                    Access Code: <span className="access-code">{this.state.student_access_code}</span>
                                </h2>
                                <AttendanceSheet 
                                    attendees = {this.state.attendees}
                                    event_id = {this.state.event_id}
                                    survey = {this.state.survey}
                                    module = {this.state.module}
                                    surveyQuestionsToValidate = {this.state.surveyQuestionsToValidate}
                                />
                            </>
                        )
                    } 
                </Col>
            </Row>
        );
    }
};

const EventAttendanceForm = Form.create({name: 'lesson_form'})(EventAttendance);

export default withRouter(EventAttendanceForm);
