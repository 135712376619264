import React from 'react';
import { withRouter } from 'react-router-dom';
import { Collapse, Form, Input } from 'antd';

import InactiveStudentCard from './InactiveStudentCard';

const { Panel } = Collapse;

class InactiveStudent extends React.Component {

    state = {
        loading: true,
        students: [],
    };

    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 8}
            },
            wrapperCol: {
                xs: {span: 16}
            }
            
        };

        return (
            <>
                <Form className="reportForm" {...formItemLayout} style={{"paddingTop": "2em"}}>

                    <Collapse>
                        <Panel header="Report Information" key="1">
                            <Form.Item label="Start Date" >
                                {getFieldDecorator('startDate', {
                                    rules: [],
                                    initialValue: (this.props.startDate.format("YYYY-MM-DD"))
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="End Date" >
                                {getFieldDecorator('endDate', {
                                    rules: [],
                                    initialValue: (this.props.endDate.format("YYYY-MM-DD"))
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Driver (Branch)" >
                                {getFieldDecorator('driver', {
                                    rules: [],
                                    initialValue: (this.props.report.driver_data.full_name +" ("+ this.props.report.driver_data.branch_code+")")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Students" key="2">
                            {   this.props.report.student_data &&
                                this.props.report.student_data.map((student => <InactiveStudentCard student={student}></InactiveStudentCard>))
                            }
                        </Panel>
                    </Collapse>
                </Form>
            </>
        );
    }
};

const InactiveStudentForm = Form.create({name: 'individual_performance_form'})(InactiveStudent);

export default withRouter(InactiveStudentForm);