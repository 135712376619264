import React from 'react';
import { withRouter } from 'react-router-dom';
import { Collapse, Form, Input } from 'antd';

const { Panel } = Collapse;

class IndividualPerformance extends React.Component {

    state = {
        data: {},
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 14}
            },
            wrapperCol: {
                xs: {span: 10}
            }
        };

        const centerSingleLayout = {
            labelCol: {
                xs: {span: 10}
            },
            wrapperCol: {
                xs: {span: 14}
            }
        };

        const {
            fullName: driver,
            branchCode,
            
            avgStudentsPerWeek: avgStudentPWeek,
            avgNewStudentsPerWeek: avgNewStudentPWeek,
            avgHoursPerWeek: avgHoursPWeek,
            avgInactiveStudentsPerWeek: avgInactiveStudentsPWeek,
            currentStudents,
            totalNewStudents,
            totalInactiveStudents: totalInactive,
            totalInactive1stLesson: totalInactiveAfterFirst,
            totalInactive2ndLesson: totalInactiveAfterSecond,
            totalNeverTaken: neverTaken,

            pass1stTry: pass1,
            pass2ndTry: pass2,
            passOtherTry: passOther,
            totalTestFail: failed,
            totalTests: total,

            totalLessons,
            totalPeakLessons: peak,
            totalOffpeakLessons: offpeak,

            cancelCP: cp,
            cancelCD: cd,
            cancelCS: cs,
            cancelCT: ct,

            cancelledViaDTD: cancelDTD, 
            sameDayCancellation: cancelDay,
            halfHourCancellation: cancelHalfHour,

            licenceLearner: learner,
            licenceProvisional: provisional,
            licenceFull: full,
            licenceOverseas: overseas,
            licenceAged: aged,
            licenceUnknown: other,

            referralsCount: referrals,
            referralsAskedCount: askedFor,

            bonusLessonInactiveStudent: bonusLessonsPerInactivePeriod,
            bonusLessonInactiveStudentCalendarYear: bonusLessonsPerInactiveYear,
            totalLessonPerInactiveStudent: totalLessonsPerInactivePeriod,
            totalLessonPerInactiveStudentCalendarYear: totalLessonsPerInactiveYear,

            '1stTestTotal': firstTestTotal,
            averageLessonStudentWeek: avgLessonPStudentPweek,
            ratioPassesInactiveStudent: passInactive,
            ratio1stAttemptPass: passFirstGo,
            ratio1st2ndAttemptPass: firstSecondAllPass,
            ratioOtherAttemptPass: otherPass,
            ratio1stInactiveAllInactive: inactive1st,
            ratio2ndInactiveAllInactive: inactive1st2nd,
            otherLicenceToAllStudents: otherLicenceNew,
            neverTakenToAllStudents: neverTakenNew,
            ratioPeakLessonToAllLesson: peakLessons,


        } = this.props.report || {};

        return (
            <>
                <Form className="reportForm" {...formItemLayout} style={{"paddingTop": "2em"}}>

                    <Collapse>
                        <Panel header="Report Information" key="1">
                            <Form.Item {...centerSingleLayout} label="Start Date" >
                                {getFieldDecorator('startDate', {
                                    rules: [],
                                    initialValue: (this.props.startDate.format("YYYY-MM-DD"))
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item {...centerSingleLayout} label="End Date" >
                                {getFieldDecorator('endDate', {
                                    rules: [],
                                    initialValue: (this.props.endDate.format("YYYY-MM-DD"))
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item {...centerSingleLayout} label="Driver (Branch)" >
                                {getFieldDecorator('driver', {
                                    rules: [],
                                    initialValue: (driver +" ("+ branchCode+")")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Students" key="2"  className="twoFieldsPerRow">
                            <Form.Item label="Avg Students Per Week" >
                                {getFieldDecorator('avgStudentPWeek', {
                                    rules: [],
                                    initialValue: (avgStudentPWeek)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Avg New Students Per Week" >
                                {getFieldDecorator('avgNewStudentPWeek', {
                                    rules: [],
                                    initialValue: (avgNewStudentPWeek)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Avg Hours Per Week" >
                                {getFieldDecorator('avgHoursPWeek', {
                                    rules: [],
                                    initialValue: (avgHoursPWeek)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Avg Inactive Students Per Week" >
                                {getFieldDecorator('avgInactiveStudentsPWeek', {
                                    rules: [],
                                    initialValue: (avgInactiveStudentsPWeek)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Current Students" >
                                {getFieldDecorator('currentStudents', {
                                    rules: [],
                                    initialValue: (currentStudents)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Total New Students" >
                                {getFieldDecorator('totalNewStudents', {
                                    rules: [],
                                    initialValue: (totalNewStudents)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Total Inactive" >
                                {getFieldDecorator('totalInactive', {
                                    rules: [],
                                    initialValue: (totalInactive)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Total Inactive After 1st Lesson" >
                                {getFieldDecorator('totalInactiveAfterFirst', {
                                    rules: [],
                                    initialValue: (totalInactiveAfterFirst)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Total Inactive After 2nd Lesson" >
                                {getFieldDecorator('totalInactiveAfterSecond', {
                                    rules: [],
                                    initialValue: (totalInactiveAfterSecond)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Never taken" >
                                {getFieldDecorator('neverTaken', {
                                    rules: [],
                                    initialValue: (neverTaken)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Tests" key="3" className="fourFieldsPerRow">
                            <Form.Item label="Pass 1" >
                                {getFieldDecorator('pass1', {
                                    rules: [],
                                    initialValue: (pass1)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Pass 2" >
                                {getFieldDecorator('pass2', {
                                    rules: [],
                                    initialValue: (pass2)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Pass Other" >
                                {getFieldDecorator('passOther', {
                                    rules: [],
                                    initialValue: (passOther)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Failed" >
                                {getFieldDecorator('failed', {
                                    rules: [],
                                    initialValue: (failed)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Total" >
                                {getFieldDecorator('total', {
                                    rules: [],
                                    initialValue: (total)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Lessons (Hours)" key="4" className="twoFieldsPerRow">
                            <Form.Item label="Peak" >
                                {getFieldDecorator('peak', {
                                    rules: [],
                                    initialValue: (peak)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Offpeak" >
                                {getFieldDecorator('Offpeak', {
                                    rules: [],
                                    initialValue: (offpeak)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Total" >
                                {getFieldDecorator('Total', {
                                    rules: [],
                                    initialValue: (totalLessons)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Cancelled" key="5" className="fourFieldsPerRow">
                            <Form.Item label="CP" >
                                {getFieldDecorator('cp', {
                                    rules: [],
                                    initialValue: (cp)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="CD" >
                                {getFieldDecorator('cd', {
                                    rules: [],
                                    initialValue: (cd)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="CS" >
                                {getFieldDecorator('cs', {
                                    rules: [],
                                    initialValue: (cs)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="CT" >
                                {getFieldDecorator('ct', {
                                    rules: [],
                                    initialValue: (ct)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Diary Cancellations" key="6" className="fourFieldsPerRow">
                            <Form.Item label="Total Cancellations" >
                                {getFieldDecorator('cancelDTD', {
                                    rules: [],
                                    initialValue: (cancelDTD)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Cancelled within the same day" >
                                {getFieldDecorator('cancelDay', {
                                    rules: [],
                                    initialValue: (cancelDay)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Cancelled within a half hour" >
                                {getFieldDecorator('cancelHalfHour', {
                                    rules: [],
                                    initialValue: (cancelHalfHour)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Licence Details" key="7" className="fourFieldsPerRow">
                            <Form.Item label="Learner" >
                                {getFieldDecorator('learner', {
                                    rules: [],
                                    initialValue: (learner)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Provisional" >
                                {getFieldDecorator('provisional', {
                                    rules: [],
                                    initialValue: (provisional)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Full" >
                                {getFieldDecorator('full', {
                                    rules: [],
                                    initialValue: (full)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Overseas" >
                                {getFieldDecorator('overseas', {
                                    rules: [],
                                    initialValue: (overseas)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Aged" >
                                {getFieldDecorator('aged', {
                                    rules: [],
                                    initialValue: (aged)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Other" >
                                {getFieldDecorator('other', {
                                    rules: [],
                                    initialValue: (other)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Referrals" key="8" className="twoFieldsPerRow">
                            <Form.Item label="Referrals" >
                                {getFieldDecorator('referrals', {
                                    rules: [],
                                    initialValue: (referrals)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Asked For" >
                                {getFieldDecorator('askedFor', {
                                    rules: [],
                                    initialValue: (askedFor)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Lessons Per Inactive Student" key="9">
                            <Form.Item label="(Bonus) Lessons Per Inactive Student (Selected period)" >
                                {getFieldDecorator('bonusLessonsPerInactivePeriod', {
                                    rules: [],
                                    initialValue: (bonusLessonsPerInactivePeriod)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="(Bonus) Lessons Per Inactive Student (Year)" >
                                {getFieldDecorator('bonusLessonsPerInactiveYear', {
                                    rules: [],
                                    initialValue: (bonusLessonsPerInactiveYear)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="(Total) Lessons Per Inactive Student (Selected period)" >
                                {getFieldDecorator('totalLessonsPerInactivePeriod', {
                                    rules: [],
                                    initialValue: (totalLessonsPerInactivePeriod)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="(Total) Lessons Per Inactive Student (Year)" >
                                {getFieldDecorator('totalLessonsPerInactiveYear', {
                                    rules: [],
                                    initialValue: (totalLessonsPerInactiveYear)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                        <Panel header="Other Stats" key="10" className="twoFieldsPerRow">
                            <Form.Item label="1st Test Total" >
                                {getFieldDecorator('firstTestTotal', {
                                    rules: [],
                                    initialValue: (firstTestTotal)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Average Lesson Per Student Per Week" >
                                {getFieldDecorator('avgLessonPStudentPweek', {
                                    rules: [],
                                    initialValue: (avgLessonPStudentPweek)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Pass/ Inactive" >
                                {getFieldDecorator('passInactive', {
                                    rules: [],
                                    initialValue: (passInactive)
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Pass First GO!" >
                                {getFieldDecorator('passFirstGo', {
                                    rules: [],
                                    initialValue: (passFirstGo + "%")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="1st & 2nd Pass/All Pass" >
                                {getFieldDecorator('firstSecondAllPass', {
                                    rules: [],
                                    initialValue: (firstSecondAllPass + "%")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Other Pass/All Pass" >
                                {getFieldDecorator('otherPass', {
                                    rules: [],
                                    initialValue: (otherPass + "%")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Inactive 1st/Inactive" >
                                {getFieldDecorator('inactive1st', {
                                    rules: [],
                                    initialValue: (inactive1st + "%")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Inactive 1st & 2nd/Inactive" >
                                {getFieldDecorator('inactive1st2nd', {
                                    rules: [],
                                    initialValue: (inactive1st2nd + "%")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Other Licence/New Students" >
                                {getFieldDecorator('otherLicenceNew', {
                                    rules: [],
                                    initialValue: (otherLicenceNew + "%")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Never Taken/New Student" >
                                {getFieldDecorator('neverTakenNew', {
                                    rules: [],
                                    initialValue: (neverTakenNew + "%")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Form.Item label="Peak Lessons/Lesson" >
                                {getFieldDecorator('peakLessons', {
                                    rules: [],
                                    initialValue: (peakLessons + "%")
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                        </Panel>
                    </Collapse>
                </Form>
            </>
        );
    }
};

const IndividualPerformanceForm = Form.create({name: 'individual_performance_form'})(IndividualPerformance);

export default withRouter(IndividualPerformanceForm);