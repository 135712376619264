import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { withRouter } from 'react-router-dom';
import { Row, Col, Modal, DatePicker } from 'antd';
import api from '../../helpers/api';
import moment from 'moment';
import Spinner from '../elements/Spinner';
import config from '../../config';

import FullCalendar from '@fullcalendar/react'
import '@fullcalendar/core/main.css';
import timeGridPlugin from '@fullcalendar/timegrid'
import '@fullcalendar/timegrid/main.css';
import interactionPlugin from '@fullcalendar/interaction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const { dayStart, dayEnd } = config;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

class Availability extends React.Component {
    state = {
        loading: true,
        peakAvailability: null,
        createModalVisible: false,
        createModalInfo: null,
        createModalConfirmLoading: false,
        updateModalVisible: false,
        updateModalInfo: null,
        updateModalConfirmLoading: false,
        updateModalText: '',
        deleteModalVisible: false,
        deleteModalId: null,
        deleteModalConfirmLoading: false,
        currentDatePeriod: null
    };

    componentDidMount() {
        if(typeof this.props.match.params.date !== 'undefined' && this.props.match.params.date !== null && (this.state.currentDatePeriod !== this.props.match.params.date)) {
            this.setState({
                currentDatePeriod: this.props.match.params.date
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.currentDatePeriod !== this.state.currentDatePeriod){
            let calendarApi = this.calendarRef.current.getApi();
            calendarApi.refetchEvents();
            if(this.state.currentDatePeriod !== null){
                calendarApi.gotoDate(this.state.currentDatePeriod);
            }else{
                calendarApi.gotoDate(new Date().toJSON().slice(0, 10));
            }
        }
    }

    loading = () => {
        if(this.state.loading){
            // Show Spinner
            document.getElementsByClassName('spinner')[0].style['display'] = 'block';
        }else{
            // Hide Spinner
            document.getElementsByClassName('spinner')[0].style['display'] = 'none';
        }
        this.setState({loading: !this.state.loading});
    };

    eventFetch = async(params = {}, successCallback) => {
        await api.post("drivers/shifts", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                specific_date: this.state.currentDatePeriod,
                ...params
            })
        })
        .then(res => {
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || typeof res.result.events === 'undefined') {
                this.props.history.push('/');
            } else {
                this.setState({peakAvailability: res.result.peakAvailability});
                successCallback(res.result.events);
            }
        })
        .catch(error => {
            console.log(error);
            this.props.history.push('/');
        });
    };

    //New Event
    handleEventCreate = async(info) => {
        //Prevent Default to stop the url firing a page redirect
        info.jsEvent.preventDefault();

        // Get the Calendar API to allow unselection
        let calendarApi = this.calendarRef.current.getApi();

        // No events spanning between days
        if(info.endStr.substring(0,10) !== info.startStr.substring(0,10)){
            // Reject ALL events that spill into a prev/next day.
            console.log('Sorry, Events must not occur over multiple days.');
            return calendarApi.unselect();
        }

        // Check all Events for Overlaps. No events should exist within the bounds of the select region
        let failOverlap = false;
        // Get all Events
        let checkEvents = calendarApi.getEvents();
        // Loop through them
        await checkEvents.forEach(function(event){
            // If it's not a background element, check whether the new element contains the existing, or vice versa.
            if(event.rendering !== "inverse-background" && event.rendering !== "background" && 
                    (
                        (event.start >= info.start && event.start < info.end) ||
                        (event.end > info.start && event.end <= info.end) ||
                        (info.start >= event.start && info.start < event.end) ||
                        (info.end > event.start && info.end <= event.end)
                    )
                ){
                    // It is an overlapping event, so fail here.
                    failOverlap = true;
                }
        });

        if(failOverlap){
            console.log('Sorry, Events must not overlap each other.');
            return calendarApi.unselect();
        }else{
            // Show a Confirmation Modal to Add the new Shift
            this.setState({
                createModalVisible: true,
                createModalInfo: info
            });
        }
    }
    handleCreateModalOk = () => {
        this.setState({
            createModalConfirmLoading: true
        }, () => {
            api.post("drivers/shifts/add", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    day_of_week: this.state.createModalInfo.start.getDay(),
                    start_time: this.state.createModalInfo.start.toLocaleTimeString('UTC'),
                    end_time: this.state.createModalInfo.end.toLocaleTimeString('UTC')
                })
            })
            .then(res => {
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.shift === 'undefined') {
                    this.setState({
                        createModalConfirmLoading: false
                    }, () => {
                        Modal.error({
                            title: "Error Adding Availability",
                            content: (
                                <div>
                                    <p>There was an error when trying to Add this Availability.</p>
                                    <p>If this problem persists, please contact the Office for assistance.</p>
                                </div>
                            )
                        });
                    });
                } else {
                    // Get the Calendar API to allow unselection
                    let calendarApi = this.calendarRef.current.getApi();
                    calendarApi.addEvent({
                        id: res.result.shift.id,
                        daysOfWeek: [(res.result.shift.day_of_week === 7 ? 0 : res.result.shift.day_of_week)],
                        startTime: res.result.shift.start_time.substring(11,19),
                        endTime: res.result.shift.end_time.substring(11,19),
                        classNames: 'booking',
                        title: 'Available'
                    });
                    this.setState({
                        peakAvailability: res.result.peakAvailability,
                        createModalVisible: false,
                        createModalInfo: null,
                        createModalConfirmLoading: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    createModalConfirmLoading: false
                }, () => {
                    Modal.error({
                        title: "Error Adding Availability",
                        content: (
                            <div>
                                <p>There was an error when trying to Add this Availability.</p>
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                });
            });
        });
    };
    handleCreateModalCancel = () => {
        this.setState({
            createModalVisible: false,
            createModalInfo: null
        });
    };

    //Drop/Move or Resize - Both trigger a Database Update.
    handleEventDrop = (info) => {
        //Prevent Default to stop the url firing a page redirect
        info.jsEvent.preventDefault();

        // Show a Confirmation Modal to Move the Shift
        this.setState({
            updateModalVisible: true,
            updateModalInfo: info,
            updateModalText: 'Moving'
        });
    }
    handleEventResize = (info) => {
        //Prevent Default to stop the url firing a page redirect
        info.jsEvent.preventDefault();

        // Reject any events being stretched over to a second day (will also reject multi-day Reminders)
        if(info.endDelta.days > 0){
            console.log('Sorry, Bookings cannot be for more than one Day.');
            return info.revert();
        }

        // Show a Confirmation Modal to Move the Shift
        this.setState({
            updateModalVisible: true,
            updateModalInfo: info,
            updateModalText: 'Changing'
        });
    }
    handleUpdateModalOk = () => {
        this.setState({
            updateModalConfirmLoading: true
        },
        () => {
            api.post("drivers/shifts/edit", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    id: this.state.updateModalInfo.event.id,
                    day_of_week: this.state.updateModalInfo.event.start.getDay(),
                    start_time: this.state.updateModalInfo.event.start.toLocaleTimeString('UTC'),
                    end_time: this.state.updateModalInfo.event.end.toLocaleTimeString('UTC')
                })
            })
            .then(res => {
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.shift === 'undefined') {
                    this.setState({
                        updateModalConfirmLoading: false
                    }, () => {
                        Modal.error({
                            title: "Error Saving Availability",
                            content: (
                                <div>
                                    <p>There was an error when trying to Save this Availability.</p>
                                    <p>If this problem persists, please contact the Office for assistance.</p>
                                </div>
                            )
                        });
                    });
                } else {
                    this.setState({
                        peakAvailability: res.result.peakAvailability,
                        updateModalVisible: false,
                        updateModalInfo: null,
                        updateModalConfirmLoading: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    updateModalConfirmLoading: false
                }, () => {
                    Modal.error({
                        title: "Error Saving Availability",
                        content: (
                            <div>
                                <p>There was an error when trying to Save this Availability.</p>
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                });
            });
        });
    };

    handleUpdateModalCancel = () => {
        this.state.updateModalInfo.revert();
        this.setState({
            updateModalVisible: false,
            updateModalInfo: null
        });
    };

    checkOverlap = (stillEvent, movingEvent) => {
        // All Day Events are okay for clashes
        if(stillEvent.allDay || (movingEvent.allDay && !stillEvent.allDay)) {
            return true;
        }

        // Background Events are okay for clashes
        if(stillEvent.rendering === 'inverse-background' || stillEvent.rendering === 'background'){
            return true;
        }

        // Otherwise, It's probably not allowed.
        return false;
    }

    handleEventDelete = (e) => {
        // Show a Confirmation Modal to Delete the Shift
        this.setState({
            deleteModalId: e.currentTarget.dataset.id
        }, () => {
            this.setState({
                //Show Modal AFTER setting the ID
                deleteModalVisible: true
            });
        });
    }
    handleDeleteModalOk = () => {
        this.setState({
            deleteModalConfirmLoading: true
        },
        () => {
            api.post("drivers/shifts/delete", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    id: this.state.deleteModalId
                })
            })
            .then(res => {
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    this.setState({
                        deleteModalConfirmLoading: false
                    }, () => {
                        Modal.error({
                            title: "Error Deleting Availability",
                            content: (
                                <div>
                                    <p>There was an error when trying to Delete this Availability.</p>
                                    <p>If this problem persists, please contact the Office for assistance.</p>
                                </div>
                            )
                        });
                    });
                } else {
                    //Delete the Shift from our View
                    let calendarApi = this.calendarRef.current.getApi();
                    var event = calendarApi.getEventById(this.state.deleteModalId);
                    event.remove();
                    this.setState({
                        peakAvailability: res.result.peakAvailability,
                        deleteModalVisible: false,
                        deleteModalConfirmLoading: false
                    });
                }
            })
            .then(res => {
                this.setState({
                    deleteModalId: null
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    deleteModalConfirmLoading: false
                }, () => {
                    Modal.error({
                        title: "Error Deleting Availability",
                        content: (
                            <div>
                                <p>There was an error when trying to Delete this Availability.</p>
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                });
            });
        });
    };

    handleDeleteModalCancel = () => {
        this.setState({
            deleteModalVisible: false
        },
        () => {
            this.setState({
                deleteModalId: null
            });
        });
    };

    handleEventRender = (info) => {
        //Insert Delete Icon for Non-Background Events
        if(info.event.rendering !== "inverse-background" && info.event.rendering !== "background" && this.props.office_user && this.state.currentDatePeriod === null) {
            const child = document.createElement('div');
            child.innerHTML = ReactDOMServer.renderToString(<FontAwesomeIcon className="availability-delete" data-id={info.event.id} icon={faTrashAlt} size="lg" />);
            info.el.appendChild(child);

            //Add listener
            const btns = info.el.getElementsByClassName('availability-delete');
            const self = this;
            btns[0].addEventListener('click', e => {
                self.handleEventDelete(e);
            });
        }
    };

    calendarRef = React.createRef();

    setAvailabilityPeriod = (e) => {
        this.setState({
            currentDatePeriod: (e === null ? null : e.format('YYYY-MM-DD'))
        }, () => {
            this.props.history.push('/availability' + (e === null ? '' : '/' + e.format('YYYY-MM-DD')));
        });
    };

    render() {
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <div className="availabilityCalendar">
                        <div className="fc-toolbar fc-header-toolbar">
                            <div className="fc-left"></div>
                            <div className="fc-center">
                                <h2>My Availability</h2>
                                {(this.props.office_user &&
                                    <div>
                                        <span>View Availability as at: </span>
                                        <DatePicker
                                            disabled={!this.props.office_user ? true : false} 
                                            size="small"
                                            disabledDate={d => !d || d.isAfter() }
                                            defaultValue={this.state.currentDatePeriod !== null ? moment(this.state.currentDatePeriod, 'YYYY-MM-DD') : null}
                                            allowClear={true}
                                            placeholder='Select Date'
                                            format={dateFormatList}
                                            onChange={(e) => this.setAvailabilityPeriod(e)}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="fc-right">
                                {(this.state.peakAvailability !== null &&
                                    <h2 className={(this.state.peakAvailability < 40 ? "low-availability" : "")}>Peak: <span id="peakAvailability">{this.state.peakAvailability}</span>%</h2>
                                )}
                            </div>
                        </div>
                        <Spinner type="mega" />
                        <Modal
                            title="Add Availability"
                            visible={this.state.createModalVisible}
                            onOk={this.handleCreateModalOk}
                            confirmLoading={this.state.createModalConfirmLoading}
                            onCancel={this.handleCreateModalCancel}
                            >
                            <p>Add an availability of {this.state.createModalVisible && this.state.createModalInfo.start.toLocaleString('en-au', {weekday: 'long'})} {this.state.createModalVisible && this.state.createModalInfo.start.toLocaleTimeString('UTC').substring(0,5)} - {this.state.createModalVisible && this.state.createModalInfo.end.toLocaleTimeString('UTC').substring(0,5)}?</p>
                        </Modal>
                        <Modal
                            title="Change Availability"
                            visible={this.state.updateModalVisible}
                            onOk={this.handleUpdateModalOk}
                            confirmLoading={this.state.updateModalConfirmLoading}
                            onCancel={this.handleUpdateModalCancel}
                            >
                            <p>{this.state.updateModalText} availability to {this.state.updateModalVisible && this.state.updateModalInfo.event.start.toLocaleString('en-au', {weekday: 'long'})} {this.state.updateModalVisible && this.state.updateModalInfo.event.start.toLocaleTimeString('UTC').substring(0,5)} - {this.state.updateModalVisible && this.state.updateModalInfo.event.end.toLocaleTimeString('UTC').substring(0,5)}?</p>
                        </Modal>
                        <Modal
                            title="Remove Availability"
                            visible={this.state.deleteModalVisible}
                            onOk={this.handleDeleteModalOk}
                            confirmLoading={this.state.deleteModalConfirmLoading}
                            onCancel={this.handleDeleteModalCancel}
                            >
                            <p>Are you sure you wish to remove this availability?</p>
                        </Modal>
                        <FullCalendar
                            key={"homeCal" + this.props.chosen_driver}
                            ref={this.calendarRef}
                            defaultView="timeGridWeek"
                            defaultDate={this.props.match.params.date}
                            editable={this.props.office_user && this.state.currentDatePeriod === null ? true : false}
                            selectable={this.props.office_user && this.state.currentDatePeriod === null ? true : false}
                            longPressDelay={50}
                            loading={this.loading}
                            allDaySlot={false}
                            events={this.eventFetch}
                            select={this.handleEventCreate}
                            eventDrop={this.handleEventDrop}
                            eventResize={this.handleEventResize}
                            eventOverlap={this.checkOverlap}
                            eventRender={this.handleEventRender}
                            plugins={[ timeGridPlugin, interactionPlugin ]} 
                            header={false}
                            views={{
                                timeGridWeek: {
                                    columnHeaderFormat: {weekday: 'long'}
                                }
                            }}
                            firstDay={1}
                            minTime={dayStart + ':00:00'}
                            maxTime={dayEnd + ':00:00'}
                            slotDuration='00:30:00'
                            snapDuration='00:30:00'
                        />
                    </div>
                </Col>
            </Row>
        );
    }
};

export default withRouter(Availability);
