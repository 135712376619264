import React from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom'
import { Drawer, Layout, Alert } from 'antd';
import api from '../helpers/api';
import Spinner from './elements/Spinner';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import TopNavigation from './TopNavigation';
import LeftNavigation from './LeftNavigation';

// Pages
import Home from './pages/Home';
import Diary from './pages/Diary';
import Event from './pages/Event';
import EventAttendance from './pages/EventAttendance';
import Lesson from './pages/Lesson';
import LessonAdd from './pages/LessonAdd';
import Students from './pages/Students';
import StudentAdd from './pages/StudentAdd';
import StudentProfile from './pages/StudentProfile';
import LessonPlans from './pages/LessonPlans';
import LessonPlan from './pages/LessonPlan';
import Payments from './pages/Payments';
import Messages from './pages/Messages';
// Hidden 21/05/2024 as per TDS-822
//import MessageAdd from './pages/MessageAdd';
import MessageEdit from './pages/MessageEdit';
import Reminders from './pages/Reminders';
import ReminderAdd from './pages/ReminderAdd';
import ReminderEdit from './pages/ReminderEdit';
import DTProfile from './pages/DTProfile';
import Library from './pages/Library';
import Availability from './pages/Availability';
import Reports from './pages/Reports';
import ChooseDriver from './pages/ChooseDriver';
import NotFound from './pages/NotFound';

const {Content, Sider} = Layout;

//To use a custom trigger icon, add `trigger={<CustomLeftMenuTrigger />}` to the <Sider>
//const CustomLeftMenuTrigger = () => (
//    <FontAwesomeIcon fixedWidth icon={faBars} size="lg" />
//);

const ReminderDrawerTitle = () => (
    <div className="reminder-drawer-title">
        <Link to={"/reminders"}>
            <FontAwesomeIcon fixedWidth icon={faBell} size="lg" style={{float:'left'}} />
        </Link>
        <span>Reminders</span>
        <Link to={"/reminders/add"}>
            <FontAwesomeIcon fixedWidth icon={faPlusCircle} size="lg" style={{float:'right'}} />
        </Link>
    </div>
);

class MainSite extends React.Component {

    state = {
        collapsed: true,
        page: "home",
        alerts: [],
        drawerVisible: false,
        reminder_list: []
    };

    constructor(props) {
        super(props);

        //Conditionally Load an Office.css for office_user specific styles.
        if(this.props.office_user) {
            require('../Office.css');
        }
    }

    componentDidMount() {
        if (this.props.user.group_id !== 5) {
            this.fetchDrivers();
        }
        window.addEventListener("resize", this.lockReminderDrawerOnHomeScreenForLargerScreens);
    };

    lockReminderDrawerOnHomeScreenForLargerScreens = () => {
        if(window.location.pathname === "/" && window.innerWidth >= 1024){
            this.showReminderDrawer();
        }
    };

    fetchDrivers = (params = {}) => {
        api.post("drivers/fetch",{
            body: JSON.stringify({
                results: 100,
                ...params
            })
        })
        .then(data => {
            this.props.setAppStateValue('driver_list', data.result.drivers, true);
        });
    };

    toggleReminderDrawer = () => {
        if(window.location.pathname !== "/" || (window.location.pathname === "/" && window.innerWidth < 1024)){
            this.setState({
                drawerVisible: !this.state.drawerVisible
            }, this.getReminders);
        }
    };

    hideReminderDrawer = () => {
        this.setState({
            drawerVisible: false,
            reminder_list: []
        });
    };

    showReminderDrawer = () => {
        this.setState({
            drawerVisible: true
        }, this.getReminders);
    };

    getReminders = () => {
        if(this.state.drawerVisible) {
            if(this.state.reminder_list.length === 0 && typeof document.getElementById('reminderDrawerSpinner') !== 'undefined' && document.getElementById('reminderDrawerSpinner') !== null){
                document.getElementById('reminderDrawerSpinner').style.display = 'block';
            }
            api.post("reminders/fetch",{
                body: JSON.stringify({
                    chosen_driver: parseInt(this.props.chosen_driver),
                    exclude_completed: true
                })
            })
            .then(data => {
                //Hide the loader
                if(typeof document.getElementById('reminderDrawerSpinner') !== 'undefined' && document.getElementById('reminderDrawerSpinner') !== null){
                    document.getElementById('reminderDrawerSpinner').style.display = 'none';
                }
                if(typeof document.getElementById('reminderDrawerNoReminders') !== 'undefined' && document.getElementById('reminderDrawerNoReminders') !== null){
                    document.getElementById('reminderDrawerNoReminders').style.display = 'none';
                }
                //Display the reminders
                if(data.result.reminders.length > 0) {
                    this.setState({
                        reminder_list: data.result.reminders
                    });
                } else if(typeof document.getElementById('reminderDrawerNoReminders') !== 'undefined' && document.getElementById('reminderDrawerNoReminders') !== null){
                    document.getElementById('reminderDrawerNoReminders').style.display = 'block';
                }
            });
        }
    };

    markReminderAsDone = (id, e) => {
        let parentToFade = e.target.closest(".reminder_box");
        e.preventDefault();
        parentToFade.style.opacity = 1;
        api.post("reminders/complete/" + id, {
            body: JSON.stringify({
                done: true,
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            if(typeof data.result === 'object' && data.result.saved){
                (function fade(){
                    parentToFade.style.opacity = parentToFade.style.opacity - 0.1;
                    if(parentToFade.style.opacity <= 0){
                        parentToFade.style.display = 'none';
                    } else {
                        setTimeout(fade, 50);
                    }
                })();
                this.props.setAppStateValue('counts', {
                    reminders: (this.props.counts.reminders - 1)
                });
            }
        });
    };

    /**
     * Add an alert to the top of the screen
     */
    addBannerAlert = (name, type, message) => {
        let currentAlerts = this.state.alerts;

        currentAlerts.push(
            {name, type, message}
        );

        this.setState({alerts: currentAlerts});
    }

    removeBannerAlert = (name) => {
        var array = [...this.state.alerts]; // make a separate copy of the array
        var index = array.indexOf(name)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({alerts: array});
        }
    }

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    }

    render() {
        let chosen_driver = parseInt(this.props.chosen_driver);

        return (
            <div>
                <Layout style={{height: '100vh',overflow: 'hidden'}}>
                    <TopNavigation
                        {...this.props}
                        collapsed={this.state.collapsed}
                        toggleReminderDrawer={this.toggleReminderDrawer}
                        chosen_driver={chosen_driver}
                        hideReminderDrawer={this.hideReminderDrawer}
                    />
                    <Layout
                        style={{position:'fixed', left: 0, right: 0, top: '64px', bottom: 0}}
                        className={(!this.props.office_user ? 'shortHeader' : '')}
                    >
                        {
                        !this.props.hide_menu_items &&
                        (!this.props.office_user || (this.props.office_user && !isNaN(chosen_driver) && chosen_driver)) &&
                            <Sider
                                collapsible
                                collapsed={this.state.collapsed}
                                onCollapse={this.onCollapse}
                                >
                                <LeftNavigation
                                    {...this.props}
                                    collapsed={this.state.collapsed}
                                    isLoaded={this.state.isLoaded}
                                />
                            </Sider>
                        }
                        <Drawer
                            className={(!this.props.office_user ? 'shortHeader' : '')}
                            mask={false}
                            title={<ReminderDrawerTitle />}
                            placement="right"
                            closable={false}
                            onClose={this.hideReminderDrawer}
                            visible={this.state.drawerVisible}
                            id="reminderDrawer"
                        >
                            <Spinner id='reminderDrawerSpinner' />
                            <div id="reminderDrawerNoReminders">
                                No Reminders
                            </div>
                            <div id='reminderDrawerReminders'>
                                {
                                    this.state.reminder_list.map((reminder, i) => 
                                        <Link to={"/reminders/edit/" + reminder.id} className="reminder_box" key={reminder.id}>
                                            {
                                                <>
                                                    <span
                                                        className='reminder-drawer-reminder-mark-done'
                                                        onClick={(e) => {this.markReminderAsDone(reminder.id, e)}}
                                                    >
                                                        <FontAwesomeIcon fixedWidth icon={faCheckCircle} />
                                                    </span>
                                                    <span className="reminder-drawer-reminder-title">{reminder.title}</span>
                                                    {reminder.due !== null ? <span className='reminder-drawer-reminder-due'>{reminder.due}</span> : ''}
                                                </>
                                            }
                                        </Link>
                                    )
                                }
                            </div>
                        </Drawer>
                        <Layout>
                            <Content>
                                {
                                    this.state.alerts.map((alert) => (
                                        <Alert key={alert.name} message={alert.message} type={alert.type} banner closable />
                                    ))
                                }
                                <Switch>
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/" render={(props) =>
                                            <Home
                                                {...this.props}
                                                showReminderDrawer={this.showReminderDrawer}
                                                hideReminderDrawer={this.hideReminderDrawer}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/diary" render={(props) => 
                                            <Diary
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/diary/:date" render={(props) => 
                                            <Diary
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/event/:id/attendance" render={(props) => 
                                            <EventAttendance
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/event/:id" render={(props) => 
                                            <Event
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/lesson/new/:id" render={(props) => 
                                            <LessonAdd
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/lesson/:id" render={(props) => 
                                            <Lesson
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/test/:id" render={(props) => 
                                            <Lesson
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/students" render={(props) => 
                                            <Students
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/students/add" render={(props) => 
                                            <StudentAdd
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/students/:id/:booking_id" render={(props) => 
                                            <StudentProfile
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/students/:id" render={(props) => 
                                            <StudentProfile
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/lesson-plans/:student_id" render={(props) => 
                                            <LessonPlans
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/lesson-plans/:student_id/new" render={(props) => 
                                            <LessonPlan
                                                {...this.props}
                                                newPlan={true}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/lesson-plans/:student_id/:booking_id" render={(props) => 
                                            <LessonPlan
                                                {...this.props}
                                                newPlan={false}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/payments/:student_id/:duration" render={(props) => 
                                            <Payments
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/payments/:student_id" render={(props) => 
                                            <Payments
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/messages" render={(props) => 
                                            <Messages
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {/* Hidden 21/05/2024 as per TDS-822
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/messages/new" render={(props) => 
                                            <MessageAdd
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    */}
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/messages/read/:id" render={(props) => 
                                            <MessageEdit
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/reminders" render={(props) => 
                                            <Reminders
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                                hideReminderDrawer={this.hideReminderDrawer}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/reminders/add" render={(props) => 
                                            <ReminderAdd
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                                hideReminderDrawer={this.hideReminderDrawer}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/reminders/add/:date" render={(props) => 
                                            <ReminderAdd
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                                hideReminderDrawer={this.hideReminderDrawer}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route path="/reminders/edit/:id" render={(props) => 
                                            <ReminderEdit
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                                hideReminderDrawer={this.hideReminderDrawer}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/profile" render={(props) => 
                                            <DTProfile
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/availability" render={(props) => 
                                            <Availability
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/availability/:date" render={(props) => 
                                            <Availability
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/reports" render={(props) => 
                                            <Reports
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {(!this.props.office_user || (this.props.office_user && chosen_driver)) &&
                                        <Route exact path="/library" render={(props) => 
                                            <Library
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    {this.props.office_user &&
                                        <Route exact path="/choose-driver" render={(props) => 
                                            <ChooseDriver
                                                {...this.props}
                                                addBannerAlert={this.addBannerAlert}
                                            />
                                        } />
                                    }
                                    <Route render={(props) => 
                                        <NotFound />
                                    } />
                                </Switch>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        );
    }
};

export default withRouter(MainSite);
