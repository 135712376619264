import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { Row, Col, Table, Button } from 'antd';
import api from '../../helpers/api';
import Spinner from '../elements/Spinner';
import moment from 'moment';

class LessonPlans extends React.Component {

    state = {
        lessonPlans: [],
        searchText: '',
        loading: true
    };

    componentDidMount() {
        this.fetch();
    }

    columnFormat = [
        {
            title: 'Booking Date',
            dataIndex: 'student_attendance.booking.start_time',
            width: '18%',
            render: (text, record) =>  {
                return (!!text ? (moment(text).format('D/MM/YYYY HH:mm')) : '');
            },
            sorter: (a, b) => {
                if(!!b.student_attendance && !!a.student_attendance){
                    return a.student_attendance.booking.start_time.localeCompare(b.student_attendance.booking.start_time);
                } else {
                    return true;
                }
            }
        },
        {
            title: 'Locations',
            dataIndex: 'locations',
            width: '22%',
            render: (text, record) =>  {
                return (text);
            }
        },
        {
            title: 'New Topics',
            dataIndex: 'new_topics',
            width: '30%',
            render: (text, record) =>  {
                return (text.map(t => (t.topic.display_number + ". " + t.topic.topic_name)).join(', '));
            }
        },
        {
            title: 'Practice Topics',
            dataIndex: 'practice_topics',
            width: '30%',
            render: (text, record) =>  {
                return (text.map(t => (t.topic.display_number + ". " + t.topic.topic_name)).join(', '));
            }
        }
    ];

    fetch = () => {
        this.setState({ loading: true });

        api.post("lessonPlans/list/" + this.props.match.params.student_id,{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            this.setState({
                loading: false,
                lessonPlans: data.result.lessonPlans
            });
        });
    };

    selectRow = (record) => {
        this.props.history.push('/lesson-plans/'+ this.props.match.params.student_id + "/" + (record.student_attendance === null ? 'lp' + record.id : record.student_attendance.booking.id));
    };

    render(){
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h2>Lesson Plans</h2>
                    <Table
                        className='lessonplans-table'
                        rowClassName="lesson-plans-table-row"
                        size="middle"
                        showHeader={true}
                        columns={this.columnFormat}
                        rowKey={record => (record.student_attendance === null ? 'lp' + record.id : record.student_attendance.booking.id)}
                        dataSource={this.state.lessonPlans}
                        pagination={false}
                        loading={{spinning: this.state.loading, indicator: <Spinner type="mini" />}}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => this.selectRow(record)
                            };
                        }}
                    />
                </Col>
                <Link to={"/lesson-plans/" + this.props.match.params.student_id + "/new"}>
                    <Button
                        type="danger"
                        size="default"
                        id="newButton"
                    >
                        Add Lesson Plan
                    </Button>
                </Link>
            </Row>
        );
    }
};

export default withRouter(LessonPlans);
