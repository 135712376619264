import React from 'react';
import { withRouter } from 'react-router';
import { Table, Input } from 'antd';
import api from '../../../helpers/api';
import Spinner from '../../elements/Spinner';

const { Search } = Input;

class StudentsTable extends React.Component {
    state = {
        studentData: [],
        studentDataFiltered: [],
        searchText: '',
        loading: true
    };

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.searchText !== prevState.searchText) {
            if(prevState.studentData.length === 0){
                this.fetch({
                    name: this.state.searchText
                });
            }else{
                var newFilteredData = [];
                var searchText = this.state.searchText.toUpperCase();
                prevState.studentData.forEach(function(studentItem) {
                    var name = studentItem.first_name.toUpperCase() + ' ' + studentItem.last_name.toUpperCase();
                    if(name.includes(searchText)){
                        newFilteredData.push(studentItem);
                    }
                });
                this.setState({studentDataFiltered: newFilteredData});
            }
        }
    }

    handleSearch = (searchText) => {
        this.setState({searchText: searchText});
    };

    columnFormat = [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            render: (text, record) =>  {
                return (text);
            },
            sorter: (a, b) => { return a.first_name.localeCompare(b.first_name);}
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            render: (text, record) =>  {
                return (text);
            },
            sorter: (a, b) => { return a.last_name.localeCompare(b.last_name);}
        },
        {
            title: 'Suburb',
            dataIndex: 'suburb_name',
            render: (text, record) =>  {
                return (text);
            },
            sorter: (a, b) => { return a.suburb_name.localeCompare(b.suburb_name);}
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (text, record) =>  {
                return (text);
            },
            sorter: (a, b) => { return a.status.localeCompare(b.status);},
            filters: [
                {
                    text: 'Active',
                    value: 'Active'
                },
                {
                    text: 'Inactive',
                    value: 'Inactive'
                }
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0
        },
        {
            title: 'Prev Lesson',
            dataIndex: 'prev_lesson',
            align: 'center',
            render: (text, record) =>  {
                return (text);
            },
            sorter: (a, b) => { return a.prev_lesson.localeCompare(b.prev_lesson);}
        },
        {
            title: 'Next Lesson',
            dataIndex: 'next_lesson',
            align: 'center',
            render: (text, record) =>  {
                return (text);
            },
            sorter: (a, b) => { return a.next_lesson.localeCompare(b.next_lesson);}
        },
        {
            title: 'Prepaid Lessons Remaining',
            dataIndex: 'prepaid.remaining',
            align: 'center',
            render: (text, record) =>  {
                return (parseFloat(text) > 0 ? text : '');
            },
            sorter: (a, b) => {
                return parseFloat(a.prepaid.remaining) < parseFloat(b.prepaid.remaining) ? 1 : -1;
            }
        }
    ];

    fetch = (params = {}) => {
        this.setState({ loading: true });

        api.post("students/my",{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(data => {
            this.setState({
                loading: false,
                studentData: data.result.students,
                studentDataFiltered: data.result.students
            });
            if(document.getElementById('searchText') !== null){
                document.getElementById('searchText').focus();
            }
        });
    };

    selectRow = (record) => {
        this.setState({
            loading: true
        });
        this.props.history.push('/students/' + record.id);
    };

    render() {
        return (
            <>
                <div className="ant-table-wrapper">
                    <Search
                        id="searchText"
                        placeholder="Filter Students"
                        onSearch={value => this.handleSearch(value)}
                        enterButton
                        size="large"
                        disabled={this.state.loading ? true : false}
                        onKeyUp={event => this.handleSearch(event.target.value)}
                        autoComplete="off"
                    />
                </div>
                <Table
                    rowClassName="students-table-row"
                    size="middle"
                    showHeader={true}
                    columns={this.columnFormat}
                    rowKey={record => record.id}
                    dataSource={this.state.studentDataFiltered}
                    pagination={false}
                    loading={{spinning: this.state.loading, indicator: <Spinner type="mini" />}}
                    className='students-table'
                    onRow={(record, rowIndex) => {
                        return {
                          onClick: event => this.selectRow(record)
                        };
                    }}
                
                />
            </>
        );
    }
};

export default withRouter(StudentsTable);