import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Alert, Modal, TimePicker, Button, Checkbox } from 'antd';
import api from '../../helpers/api';
import Spinner from '../elements/Spinner';
import moment from 'moment';
import config from '../../config';

import FullCalendar from '@fullcalendar/react'
import '@fullcalendar/core/main.css';
import dayGridPlugin from '@fullcalendar/daygrid'
import '@fullcalendar/daygrid/main.css';
import timeGridPlugin from '@fullcalendar/timegrid'
import '@fullcalendar/timegrid/main.css';
import listPlugin from '@fullcalendar/list'
import '@fullcalendar/list/main.css';
import interactionPlugin from '@fullcalendar/interaction'

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCalendarTimes, faClipboardCheck, faUserFriends, faUserPlus } from '@fortawesome/free-solid-svg-icons';

/*
 * Functions shared by different Components using FullCalendar
 * @todo: Migrate the functions below into the helper.
 */
import diaryHelper from '../../helpers/diary';

const { dayStart, dayEnd, historyEditDays, paymentBeforeBooking, allowChangeFirstLesson, showSendNotificationToStudentCheckbox } = config;

class Diary extends React.Component {
    state = {
        loading: true,
        errorMessage: false,
        errorMessageType: "error",
        currentView: 'timeGridWeek',
        disabled_hours: [],
        moving_booking: null,
        moving_booking_orig: null,
        resize_booking: null,
        resize_booking_original: null,
        
        createModalVisible: false,
        createModalStartTime: null,
        createModalEndTime: null,
        createModalDisabledMinutes: null,
        calendarApi: null,
        createModalShowOpenButton: false,
        createModalShowCloseButton: false,
        createModalShowCreateLessonButton: true
    };

    componentDidMount() {
        if(this.props.diaryCurrentDate === null && this.props.match.params.date === null){
            this.props.history.push('/diary/' + moment().format('YYYY-MM-DD'));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.diaryCurrentDate !== this.props.diaryCurrentDate){
            let calendarApi = this.calendarRef.current.getApi();
            calendarApi.gotoDate(this.props.diaryCurrentDate);
        }
    }

    loading = () => {
        if(this.state.loading){
            // Show Spinner
            document.getElementsByClassName('spinner')[0].style['display'] = 'block';
        }else{
            // Hide Spinner
            document.getElementsByClassName('spinner')[0].style['display'] = 'none';
        }
        this.setState({loading: !this.state.loading});
    };

    handleEventCreate = async(info) => {
        //Prevent Default to stop the url firing a page redirect
        info.jsEvent.preventDefault();

        // Get the Calendar API to allow unselection
        let calendarApi = this.calendarRef.current.getApi();

        // All Day Event - Allow Create of Reminder (Only for Single Day)
        if(info.allDay) {
            // Must be a Single Day - Check length of Event in Milliseconds (86400000 = 1 Day)
            if(info.end - info.start === 86400000){
                return this.props.history.push('/reminders/add/' + info.startStr);
            }
            // Reject 'All Day' event that doesn't span EXACTLY one day.
            console.log('Sorry, Reminders must not occur over multiple days.');
            return calendarApi.unselect();
        }

        // No events spanning between days
        if(info.endStr.substring(0,10) !== info.startStr.substring(0,10)){
            // Reject ALL events that spill into a prev/next day.
            console.log('Sorry, Events must not occur over multiple days.');
            return calendarApi.unselect();
        }

        // Cannot create Bookings in the past
        if(
            (this.props.office_user && !moment(new Date(info.startStr)).isAfter(moment().subtract(historyEditDays, 'days')))
            ||
            (!this.props.office_user && moment(new Date(info.startStr)).isBefore(moment().startOf('day')))
        ) {
            console.log('Sorry, Events must not be created in the Past.');
            return calendarApi.unselect();
        }

        // Check all Events for Overlaps. No events should exist within the bounds of the select region
        let failOverlap = false;
        // Get all Events
        let checkEvents = calendarApi.getEvents();
        // Check for Overlap on Shift Events - we want to offer to Open Up or Block Out time accordingly
        let noShiftEvents = true;
        let showOpenButton = false;
        let showCloseButton = false;
        let showCreateLessonButton = true;
        
        // Loop through them
        await checkEvents.forEach(function(event){
            // If it's not a background element, check whether the new element contains the existing, or vice versa.
            if(event.rendering !== "inverse-background" && event.rendering !== "background" && 
                (
                    (event.start >= info.start && event.start < info.end) ||
                    (event.end > info.start && event.end <= info.end) ||
                    (info.start >= event.start && info.start < event.end) ||
                    (info.end > event.start && info.end <= event.end)
                )
            ){
                // It is an overlapping event, so fail here.
                failOverlap = true;
            }
            
            if( 
                event.rendering === "inverse-background" && 
                (
                    (event.start >= info.start && event.start < info.end) ||
                    (event.end > info.start && event.end <= info.end) ||
                    (info.start >= event.start && info.start < event.end) ||
                    (info.end > event.start && info.end <= event.end)
                )
            ){
                //This is the regular shift / working time.
                noShiftEvents = false;
                showCloseButton = true;
            }
            if( 
                event.rendering === "background" && 
                (
                    (event.start >= info.start && event.start < info.end) ||
                    (event.end > info.start && event.end <= info.end) ||
                    (info.start >= event.start && info.start < event.end) ||
                    (info.end > event.start && info.end <= event.end)
                )
            ){
                //This is an absence
                noShiftEvents = false;
                showOpenButton = true;
            }
        });

        if(noShiftEvents){
            //This is likely outside of the regular shifts (so, outside standard working hours, with nothing to overlap)
            showOpenButton = true;
        }

        if (showOpenButton || showCloseButton) {
            //If we're going to show the Create button, but we shouldn't because we're only making time adjustments, fix that here.
            if(failOverlap) {
                showCreateLessonButton = false;
            }
            failOverlap = false;
        }

        if(failOverlap){
            console.log('Sorry, Events must not overlap each other.');
            return calendarApi.unselect();
        }else{
            //Work out a possible End time based on the Start time selected.
            let startMinute = info.start.getMinutes();
            let otherMinute = (startMinute >= 30 ? startMinute - 30 : startMinute + 30);
            let disabled_minutes = [...Array(60).keys()].filter(e => e !== startMinute).filter(e => e !== otherMinute);

            //Need to round our End time to the closest of startMinute or otherMinute.
            let eventEnd = info.end;
            
            //If less than an hour, set it to one hour (minimum lesson length
            let startTime = moment(new Date(info.start.getTime()));
            let endTime = moment(new Date(info.end.getTime()));
            if(endTime.diff(startTime, 'minutes') <= 60){
                endTime = startTime.add(1, 'hours');
                eventEnd = new Date(endTime);
            }else{
                // Here be dragons. Hard working dragons, but dragons nonetheless.
                // Works out the rounded End time for our timepicker, given our two potential start minute options (startMinute and otherMinute).
                // Does so by rounding to the nearest for each. Problem is of course that 5 is closer to 25 than it is to 55, so we need it to work backwards as well.
                let forcedEnd = ((Math.abs(Math.abs(startMinute - eventEnd.getMinutes()) - ((eventEnd.getMinutes() >= (startMinute + 15) ? 60 : 0))) > 45 ? Math.abs(Math.abs(Math.abs(startMinute - eventEnd.getMinutes()) - ((eventEnd.getMinutes() >= (startMinute + 15) ? 60 : 0))) - 60) : Math.abs(Math.abs(startMinute - eventEnd.getMinutes()) - ((eventEnd.getMinutes() >= (startMinute + 15) ? 60 : 0)))) < Math.abs(Math.abs(otherMinute - eventEnd.getMinutes()) - (Math.abs(otherMinute - eventEnd.getMinutes()) > 45 ? 30 : 0)) ? startMinute : otherMinute);
                // If we've rounded up or down past an hour, we need to update the Hours field.
                if(eventEnd.getMinutes() >= 45 && forcedEnd < 25){
                    eventEnd.setHours(eventEnd.getHours() + 1);
                }else if( forcedEnd >= 45 && eventEnd.getMinutes() < 25){
                    eventEnd.setHours(eventEnd.getHours() - 1);
                }
                // Update the Minutes field.
                eventEnd.setMinutes(forcedEnd);
            }

            //Get the correct offset - determine whether currently daylight savings
//            let selectedTime = new Date(info.start.getTime());
            let selectedTime = new Date(info.start.toISOString().substr(0,16))
            let selectedEndTime = new Date(eventEnd.toISOString().substr(0,16))
            let offset = (await this.isDstObserved(selectedEndTime)) ? selectedEndTime.getTimezoneOffset() : await this.stdTimezoneOffset(selectedEndTime);

            this.setState({
                createModalStartTime: moment(selectedTime),
                createModalEndTime: moment(new Date(eventEnd.getTime() + (offset * 60 * 1000))),
                createModalDisabledMinutes: disabled_minutes,
                createModalShowCreateLessonButton: showCreateLessonButton,
                createModalShowOpenButton: showOpenButton,
                createModalShowCloseButton: showCloseButton,
                calendarApi: calendarApi
            }, this.setState({
                createModalVisible: true
            }));

        }
    }
    
    stdTimezoneOffset = async(date) => {
        var jan = new Date(date.getFullYear(), 0, 1);
        var jul = new Date(date.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }
    
    isDstObserved = async(date) => {
        return date.getTimezoneOffset() < (await this.stdTimezoneOffset(date));
    }

    handleCreateModalAbsence = async() => {
        let start_time = this.timePickerRefCreateStart.current.timePickerRef.state.value.format('YYYY-MM-DD HH:mm:ss');
        let end_time = this.timePickerRefCreateStart.current.timePickerRef.state.value.format('YYYY-MM-DD') + ' ' + this.timePickerRefCreateEnd.current.timePickerRef.state.value.format('HH:mm:ss');
        await api.post("drivers/shifts/absence", {
            body: JSON.stringify({
                start: start_time,
                end: end_time,
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || typeof res.result.booking_id === 'undefined') {
                Modal.error({
                    title: "Error Blocking Out Time",
                    content: (
                        <div>
                            <p>There was an error when trying to Block Out this Time.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
            } else {
                let calendarApi = this.calendarRef.current.getApi();
                Modal.success({
                    title: "Time Blocked Out!",
                    content: (
                        <div>
                            <p>The Time has been successfully Blocked Out.</p>
                        </div>
                    ),
                    onOk: () => {
                        this.setState({
                            createModalVisible: false
                        }, () => {
                            if(res.result.resetCalendar){
                                // Re-Render entire Calendar - Remove ALL events, and then re-fetch Events
                                calendarApi.getEvents().forEach((event) => {
                                    event.remove();
                                });
                                calendarApi.getEventSources()[0].refetch();
                            } else {
                                // Add to Calendar
                                let newEvent = {
                                    id: res.result.booking_id,
                                    classNames: ['not-working'],
                                    rendering: 'background',
                                    start: start_time,
                                    end: end_time
                                };
                                // Add to Calendar
                                calendarApi.addEvent(newEvent);
                            }
                            Modal.destroyAll();
                        });
                    },
                    keyboard: false
                });
            }
        })
        .catch(error => {
            console.log(error);
            Modal.error({
                title: "Error Blocking Out Time",
                content: (
                    <div>
                        <p>There was an error when trying to Block Out this Time.</p>
                        <p>If this problem persists, please contact the Office for assistance.</p>
                    </div>
                )
            });
        });
    };

    handleCreateModalExtraShift = async() => {
        let start_time = this.timePickerRefCreateStart.current.timePickerRef.state.value.format('YYYY-MM-DD HH:mm:ss');
        let end_time = this.timePickerRefCreateStart.current.timePickerRef.state.value.format('YYYY-MM-DD') + ' ' + this.timePickerRefCreateEnd.current.timePickerRef.state.value.format('HH:mm:ss');
        await api.post("drivers/shifts/extra", {
            body: JSON.stringify({
                start_time: start_time,
                end_time: end_time,
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || typeof res.result.shift_id === 'undefined') {
                Modal.error({
                    title: "Error Opening Up Time",
                    content: (
                        <div>
                            <p>There was an error when trying to Open Up this Time.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
            } else {
                let calendarApi = this.calendarRef.current.getApi();
                Modal.success({
                    title: "Time Opened Up!",
                    content: (
                        <div>
                            <p>The Time has been successfully Opened Up.</p>
                        </div>
                    ),
                    onOk: () => {
                        this.setState({
                            createModalVisible: false
                        }, () => {
                            if(res.result.resetCalendar){
                                // Re-Render entire Calendar - Remove ALL events, and then re-fetch Events
                                calendarApi.getEvents().forEach((event) => {
                                    event.remove();
                                });
                                calendarApi.getEventSources()[0].refetch();
                            } else {
                                // Add to Calendar
                                let newEvent = {
                                    groupId: 1,
                                    classNames: ['not-working'],
                                    rendering: 'inverse-background',
                                    start: start_time,
                                    end: end_time
                                };
                                calendarApi.addEvent(newEvent);
                            }
                            Modal.destroyAll();
                        });
                    },
                    keyboard: false
                });
            }
        })
        .catch(error => {
            console.log(error);
            Modal.error({
                title: "Error Opening Up Time",
                content: (
                    <div>
                        <p>There was an error when trying to Open Up this Time.</p>
                        <p>If this problem persists, please contact the Office for assistance.</p>
                    </div>
                )
            });
        });
    };

    handleCreateModalOk = async() => {
        await api.post("bookings/reserve", {
            body: JSON.stringify({
                start: this.timePickerRefCreateStart.current.timePickerRef.state.value.format('YYYY-MM-DD HH:mm:ss'),
                end: this.timePickerRefCreateStart.current.timePickerRef.state.value.format('YYYY-MM-DD') + ' ' + this.timePickerRefCreateEnd.current.timePickerRef.state.value.format('HH:mm:ss'),
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || typeof res.result.booking_id === 'undefined') {
                Modal.error({
                    title: "Error Reserving Time",
                    content: (
                        <div>
                            <p>There was an error when trying to Reserve this Time.</p>
                            {(typeof(res.result.error) !== 'undefined' ? <p>{res.result.error}</p> : '')}
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
            } else {
                this.props.history.push('/lesson/new/' + res.result.booking_id);
            }
        })
        .catch(error => {
            console.log(error);
            Modal.error({
                title: "Error Reserving Time",
                content: (
                    <div>
                        <p>There was an error when trying to Reserve this Time.</p>
                        <p>If this problem persists, please contact the Office for assistance.</p>
                    </div>
                )
            });
        });
    };

    handleCreateStartTimeChange = async(time) => {
        // Close the TimePicker
        this.timePickerRefCreateStart.current.timePickerRef.setOpen(false);

        //Get Previous Start Time
        let prevStartTime = this.state.createModalStartTime;

        //Work out and Set the Possible Minutes for the End Time
        let startMinute = time.minutes();
        let otherMinute = (startMinute >= 30 ? startMinute - 30 : startMinute + 30);
        let disabled_minutes = [...Array(60).keys()].filter(e => e !== startMinute).filter(e => e !== otherMinute);

        //Whatever we just adjusted the start time by, do the same for the End time.
        let newEndTime = this.state.createModalEndTime.add(time.diff(prevStartTime, 'minutes'), 'minutes');

        // New End Time must be at least 60 minutes from New Start Time
        if(newEndTime.diff(time, 'minutes') < 60){
            newEndTime = time.clone().add(60, 'minutes');
        }

        //Update the State with new properties
        this.setState({
            createModalStartTime: time,
            createModalEndTime: newEndTime,
            createModalDisabledMinutes: disabled_minutes
        });
    };

    handleCreateEndTimeChange = async(time) => {
        // Close the TimePicker
        this.timePickerRefCreateEnd.current.timePickerRef.setOpen(false);

        // New End Time must be at least 60 minutes from New Start Time
        if(time.diff(this.state.createModalStartTime, 'minutes') < 60){
            time = this.state.createModalStartTime.clone().add(60, 'minutes');
        }

        //Update the State to stop the timepicker resetting back to the original time.
        this.setState({
            createModalEndTime: time
        });
    };

    handleResizeTimeChange = async(time) => {
        // Close the TimePicker
        this.timePickerRefResize.current.timePickerRef.setOpen(false);
    };

    handleMoveTimeChange = async(time) => {
        //Convert to proper format, inc. timezone
        let timeWhenModalOpened = moment(time._i);
        time = moment(time._d);
        
        // Close the TimePicker
        this.timePickerRefMove.current.timePickerRef.setOpen(false);

        // Calculate Start and End of Day for Comparison purposes.
        let startOfDay = moment(new Date(time.format('YYYY-MM-DD') + ' ' + dayStart + ':00:00'));
        let endOfDay = moment(new Date(time.format('YYYY-MM-DD') + ' ' + dayEnd + ':00:00'));

        // Check if new time is between our working hours.
        // Note the last parameter: '[' means startTime inclusive, ')' means endTime exclusive.
        if(!time.isBetween(startOfDay, endOfDay, null, '[)')) {
            //Get our original time in case we need to fall back to it.
            let originalBookedTime = moment(this.state.moving_booking_orig.start).add(time.toDate().getTimezoneOffset()/60, 'hours');
            //Reset the time picker..
            if(timeWhenModalOpened.isBetween(startOfDay, endOfDay, null, '[)')) {
                //Original Dropped Time
                this.timePickerRefMove.current.timePickerRef.setValue(timeWhenModalOpened);
            } else if(originalBookedTime.isBetween(startOfDay, endOfDay, null, '[)')) {
                //Original Booked Time
                this.timePickerRefMove.current.timePickerRef.setValue(originalBookedTime);
            } else {
                //Start of Day as absolute fallback
                this.timePickerRefMove.current.timePickerRef.setValue(startOfDay);
            }
            Modal.error({
                title: 'Error',
                content: (<div><p>Booking must be within working hours.</p></div>)
            });
        } else 

        // Make sure that the END of the lesson isn't outside our working hours.
        if((Math.floor((moment(time).startOf('day').add(dayEnd, 'hours').diff(moment(time), 'minutes')/60)*2)/2) < 1) {
            this.timePickerRefMove.current.timePickerRef.setValue(moment(this.state.moving_booking.start).add(new Date().getTimezoneOffset()/60, 'hours'), true);
            Modal.error({
                title: 'Error',
                content: (<div><p>You can not start this Booking any later in the day.</p></div>)
            });

        } else {
            // Format Start Time
            let timeStart = moment(new Date(time.format('YYYY-MM-DD H:mm:ss')).getTime() - (new Date().getTimezoneOffset() * 60 * 1000));
            // Calculate the length of our booking
            let duration = moment(this.state.moving_booking.end).diff(moment(this.state.moving_booking.start)) / 60 / 60 / 1000; //As Hours
            // Calculate the new 'End Time' based on our new Start Time and Duration.
            let timeEnd = timeStart.clone().add(duration, 'hours');

            // Get the Calendar API to allow unselection
            let calendarApi = this.calendarRef.current.getApi();

            // Check all Events for Overlaps. No events should exist within the bounds of the new booking
            let failOverlap = false;
            // Get all Events
            let checkEvents = calendarApi.getEvents();
            let moving_id = this.state.moving_booking.id;
            // Loop through them
            await checkEvents.forEach(function(event){
                // If it's not a background element, check whether the new element contains the existing, or vice versa.
                if(event.rendering !== "inverse-background" && event.rendering !== "background" && event.id !== moving_id &&
                        (
                            (event.start >= timeStart && event.start < timeEnd) ||
                            (event.end > timeStart && event.end <= timeEnd) ||
                            (timeStart >= event.start && timeStart < event.end) ||
                            (timeEnd > event.start && timeEnd <= event.end)
                        )
                    ){
                        // It is an overlapping event, so fail here.
                        failOverlap = true;
                    }
            });

            if(failOverlap){
                this.timePickerRefMove.current.timePickerRef.setValue(moment(this.state.moving_booking.start).add(new Date().getTimezoneOffset()/60, 'hours'), true);
                Modal.error({
                    title: 'Error',
                    content: (<div><p>Booking must not overlap other Bookings.</p></div>)
                });
            }
        }
    };

    handleEventDrop = (info) => {
        //Prevent Default to stop the url firing a page redirect
        info.jsEvent.preventDefault();

        //Reject All Day drops... unless it is a reminder
        if(info.event.allDay && info.event.classNames.indexOf('reminder') < 0){
            console.log('Sorry, only Reminders can be All Day Events.');
            return info.revert();
        }

        //Reject Event movements:
        if(info.event.classNames.indexOf('event') >= 0){
            console.log('Sorry, Events cannot be Moved.');
            return info.revert();
        }

        //For Reminders, we just update the due date.
        if(info.event.classNames.indexOf('reminder') >= 0){
            api.post("reminders/save", {
                body: JSON.stringify({
                    id: info.event.id,
                    due_date: moment(info.event.start).format('DD/MM/YYYY'),
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK'){
                    Modal.error({
                        title: "Error Updating Reminder",
                        content: (
                            <div>
                                <p>There was an error when trying to Update this Reminder.</p>
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                    return info.revert();
                } else {
                    //Success - Show a Success Modal
                    Modal.success({
                        title: "Reminder Updated!",
                        content: (
                            <div>
                                <p>The Reminder has been successfully updated.</p>
                            </div>
                        ),
                        onOk: () => {
                            Modal.destroyAll();
                        },
                        keyboard: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
                Modal.error({
                    title: "Error Updating Reminder",
                    content: (
                        <div>
                            <p>There was an error when trying to Update this Reminder.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
                return info.revert();
            });
        }else{
            if(info.event.classNames.indexOf('first_lesson') > 0 && !allowChangeFirstLesson){
                Modal.error({
                    title: "Booking can not be Adjusted",
                    content: (
                        <div>
                            <p>This Booking can not be Adjusted, because it is a new student.</p>
                            <p>Please contact the Office if you require it to be Adjusted.</p>
                        </div>
                    )
                });
                return info.revert();
            }
            let oldStart = moment(new Date(info.oldEvent.start.getTime() + (new Date(info.oldEvent.start).getTimezoneOffset() * 60 * 1000)));
            let nowTime = moment().subtract(30, 'minutes');
            if(
                (this.props.office_user && !oldStart.isAfter(moment().subtract(historyEditDays, 'days')))
                ||
                (!this.props.office_user && oldStart.diff(nowTime) < 0)
            ){
                Modal.error({
                    title: "Booking can not be Adjusted",
                    content: (
                        <div>
                            <p>This Booking can not be Adjusted, because it is in the past.</p>
                            <p>Please contact the Office if you require it to be Adjusted.</p>
                        </div>
                    )
                });
                return info.revert();
            }

            this.setState({
                moving_booking: info.event,
                moving_booking_orig: info.oldEvent
            }, async () => {

                let selectedTime = new Date(info.event.start.getTime());
                let offset = (await this.isDstObserved(selectedTime)) ? selectedTime.getTimezoneOffset() : await this.stdTimezoneOffset(selectedTime);
                let newStartTime = moment(new Date(info.event.start.getTime() + (offset * 60 * 1000)));
                
                Modal.confirm({
                    title: 'Move Booking?',
                    className: 'update-lesson-modal',
                    content: (
                        <div>
                            <Form layout="inline" id="moveLessonForm">
                                <Row>
                                    <Form.Item label="New Start Time:" >
                                        <TimePicker
                                            defaultValue={newStartTime}
                                            allowClear={false}
                                            use12Hours
                                            format='h:mm a'
                                            minuteStep={5}
                                            style={{width: '100px'}}
                                            ref={this.timePickerRefMove}
                                            onChange={this.handleMoveTimeChange}
                                            inputReadOnly={true}
                                            disabledHours={() => this.state.disabled_hours}
                                            hideDisabledOptions={true}
                                        />
                                    </Form.Item>
                                </Row>
                                {/* As of 2023-10-06, Don't show Notification Checkbox to DT Users */}
                                {showSendNotificationToStudentCheckbox && this.props.office_user && (
                                    // Not a Tentative Test..
                                    !info.event.classNames.includes('test') ||
                                    (
                                        info.event.classNames.includes('test') && 
                                        !info.event.classNames.includes('tentative')
                                    )
                                ) && (
                                    <Checkbox
                                        defaultChecked
                                        key="send-confirmation-checkbox-move"
                                        id="send-confirmation-checkbox-move"
                                    >
                                        Send confirmation to Student?
                                    </Checkbox>
                                )}
                            </Form>
                        </div>
                    ),
                    onOk: async() => {
                        //Update the database
                        let timePickerValue = this.timePickerRefMove.current.timePickerRef.state.value;
                        let selectedTime = timePickerValue.toDate();
                        let newStartTime = moment(selectedTime);
                        let duration = moment(new Date(this.state.moving_booking.end)).diff(moment(new Date(this.state.moving_booking.start)))/60/60/1000;
                        let newEndTime = newStartTime.clone().add(duration, 'hours');

                        let values = {
                            chosen_driver: this.props.chosen_driver,
                            booking: this.state.moving_booking.id,
                            start_time: newStartTime.format('YYYY-MM-DD HH:mm:ss'),
                            end_time: newEndTime.format('YYYY-MM-DD HH:mm:ss')
                        };
                        if (showSendNotificationToStudentCheckbox) {
                            // As of 2023-10-06, Don't show Notification Checkbox to DT Users
                            if (this.props.office_user) {
                                if (document.getElementById('send-confirmation-checkbox-move') !== null) {
                                    values.send_confirmation = document.getElementById('send-confirmation-checkbox-move').checked;
                                } else {
                                    values.send_confirmation = false;
                                }
                                // NEVER send for Tentative Tests
                                if (this.state.moving_booking.classNames.includes('test') && this.state.moving_booking.classNames.includes('tentative')) {
                                    values.send_confirmation = false;
                                }
                            } else {
                                // For DT's, ALWAYS send Notification
                                let sendToStudent = true;
                                // UNLESS it's a Tentative Test Booking
                                if (this.state.moving_booking.classNames.includes('test') && this.state.moving_booking.classNames.includes('tentative')) {
                                    sendToStudent = false;
                                }
                                values.send_confirmation = sendToStudent;
                            }
                        }
                        await api.post("bookings/update", {
                            body: JSON.stringify(values)
                        })
                        .then(res => {
                            //Convert to JSON in case we've received a string response
                            if(typeof res === 'string'){
                                res = JSON.parse(res);
                            }

                            //Check for an error response (status of "NOK")
                            if(res.status === 'NOK') {
                                Modal.error({
                                    title: "Error Updating Booking Date/Time",
                                    content: (
                                        <div>
                                            <p>There was an error when trying to Update this Booking.</p>
                                            <p>If this problem persists, please contact the Office for assistance.</p>
                                        </div>
                                    )
                                });
                                return info.revert();
                            } else if(!res.result.success) {
                                Modal.error({
                                    title: "Error Updating Booking Date/Time",
                                    content: (
                                        <div>
                                            <p>There was an error when trying to Update this Booking.</p>
                                             {(typeof(res.result.error) !== 'undefined' ? <p>{res.result.error}</p> : '')}
                                            <p>If this problem persists, please contact the Office for assistance.</p>
                                        </div>
                                    )
                                });
                                return info.revert();
                            } else {
                                //Success - Show a Success Modal
                                Modal.success({
                                    title: "Booking Updated!",
                                    content: (
                                        <div>
                                            <p>The Booking has been successfully updated.</p>
                                        </div>
                                    ),
                                    onOk: () => {
                                        Modal.destroyAll();
                                        //Adjust the display to the new start time, moving the end time to keep duration.
                                        let calendarApi = this.calendarRef.current.getApi();
                                        let eventToUpdate = calendarApi.getEventById(this.state.moving_booking.id);
                                        eventToUpdate.setStart(timePickerValue.format(), {maintainDuration: true});
                                    },
                                    keyboard: false
                                });
                            }
                        })
                        .catch(() => {
                            Modal.error({
                                title: "Error Updating Booking Date/Time",
                                content: (
                                    <div>
                                        <p>There was an error when trying to Update this Booking.</p>
                                        <p>If this problem persists, please contact the Office for assistance.</p>
                                    </div>
                                )
                            });
                            return info.revert();
                        });
                    },
                    onCancel: () => {return info.revert();},
                    okText: "Yes",
                    cancelText: "No"
                });
            });
        }
    }

    handleEventResize = (info) => {
        //Prevent Default to stop the url firing a page redirect
        info.jsEvent.preventDefault();

        // Reject any events being stretched over to a second day (will also reject multi-day Reminders)
        if(info.endDelta.days > 0){
            console.log('Sorry, Bookings cannot be for more than one Day.');
            return info.revert();
        }

        //Reject Event movements:
        if(info.event.classNames.indexOf('event') >= 0){
            console.log('Sorry, Events cannot be Resized.');
            return info.revert();
        }

        if(info.event.classNames.indexOf('reminder') >= 0){
            console.log('Sorry, Reminders cannot be Resized.');
            return info.revert();
        }

        if(info.event.classNames.indexOf('first_lesson') > 0 && !allowChangeFirstLesson){
            Modal.error({
                title: "Booking can not be Adjusted",
                content: (
                    <div>
                        <p>This Booking can not be Adjusted, because it is a new student.</p>
                        <p>Please contact the Office if you require it to be Adjusted.</p>
                    </div>
                )
            });
            return info.revert();
        }
        let oldStart = moment(new Date(info.event.start.getTime() + (new Date(info.event.start).getTimezoneOffset() * 60 * 1000)));
        let nowTime = moment().subtract(30, 'minutes');
        if(
            (this.props.office_user && !oldStart.isAfter(moment().subtract(historyEditDays, 'days')))
            ||
            (!this.props.office_user && oldStart.diff(nowTime) < 0)
        ) {
            Modal.error({
                title: "Booking can not be Adjusted",
                content: (
                    <div>
                        <p>This Booking can not be Adjusted, because it is in the past.</p>
                        <p>Please contact the Office if you require it to be Adjusted.</p>
                    </div>
                )
            });
            return info.revert();
        }

        if(moment(new Date(info.event.end)).diff(moment(new Date(info.event.start)), 'minutes') < 60) {
            Modal.error({
                title: "Booking can not be Adjusted",
                content: (
                    <div>
                        <p>Bookings must be at least 1 hour long.</p>
                        <p>Please contact the Office if you require it to be Adjusted.</p>
                    </div>
                )
            });
            return info.revert();
        }

        this.setState({
            resize_booking: info.event,
            resize_booking_original: info.prevEvent
        },() => {
            //Need to disable minutes that are not the the start time or 30 minute increments.
            let startMinute = info.event.start.getMinutes();
            let otherMinute = (startMinute >= 30 ? startMinute - 30 : startMinute + 30);
            let disabled_minutes = [...Array(60).keys()].filter(e => e !== startMinute).filter(e => e !== otherMinute);

            //Need to round our End time to the closest of startMinute or otherMinute.
            let eventEnd = info.event.end;
            // Here be dragons. Hard working dragons, but dragons nonetheless.
            // Works out the rounded End time for our timepicker, given our two potential start minute options (startMinute and otherMinute).
            // Does so by rounding to the nearest for each. Problem is of course that 5 is closer to 25 than it is to 55, so we need it to work backwards as well.
            let forcedEnd = ((Math.abs(Math.abs(startMinute - eventEnd.getMinutes()) - ((eventEnd.getMinutes() >= (startMinute + 15) ? 60 : 0))) > 45 ? Math.abs(Math.abs(Math.abs(startMinute - eventEnd.getMinutes()) - ((eventEnd.getMinutes() >= (startMinute + 15) ? 60 : 0))) - 60) : Math.abs(Math.abs(startMinute - eventEnd.getMinutes()) - ((eventEnd.getMinutes() >= (startMinute + 15) ? 60 : 0)))) < Math.abs(Math.abs(otherMinute - eventEnd.getMinutes()) - (Math.abs(otherMinute - eventEnd.getMinutes()) > 45 ? 30 : 0)) ? startMinute : otherMinute);
            // If we've rounded up or down past an hour, we need to update the Hours field.
            if(eventEnd.getMinutes() >= 45 && forcedEnd < 25){
                eventEnd.setHours(eventEnd.getHours() + 1);
            }else if( forcedEnd >= 45 && eventEnd.getMinutes() < 25){
                eventEnd.setHours(eventEnd.getHours() - 1);
            }
            // Update the Minutes field.
            eventEnd.setMinutes(forcedEnd);

            // Show the Modal
            Modal.confirm({
                title: 'Resize Booking?',
                className: 'update-lesson-modal',
                content: (
                    <div>
                        <Form layout="inline" id="resizeLessonForm">
                            <Row>
                                <Form.Item label="New End Time:" >
                                    <TimePicker
                                        defaultValue={moment(new Date(eventEnd.getTime() + (eventEnd.getTimezoneOffset() * 60 * 1000)))}
                                        allowClear={false}
                                        use12Hours
                                        format='h:mm a'
                                        minuteStep={5}
                                        style={{width: '100px'}}
                                        ref={this.timePickerRefResize}
                                        onChange={this.handleResizeTimeChange}
                                        inputReadOnly={true}
                                        disabledHours={() => this.state.disabled_hours}
                                        disabledMinutes={() => disabled_minutes}
                                        hideDisabledOptions={true}
                                    />
                                </Form.Item>
                            </Row>
                            {/* As of 2023-10-06, Don't show Notification Checkbox to DT Users */}
                            {showSendNotificationToStudentCheckbox && this.props.office_user && (
                                // Not a Tentative Test..
                                !info.event.classNames.includes('test') ||
                                (
                                    info.event.classNames.includes('test') && 
                                    !info.event.classNames.includes('tentative')
                                )
                            ) && (
                                <Checkbox
                                    defaultChecked
                                    key="send-confirmation-checkbox-resize"
                                    id="send-confirmation-checkbox-resize"
                                >
                                    Send confirmation to Student?
                                </Checkbox>
                            )}
                        </Form>
                    </div>
                ),
                onOk: async() => {
                    //Check for Prepaids if required
                    var existingDuration = moment(this.state.resize_booking_original.end).diff(moment(this.state.resize_booking_original.start), 'minutes')/60;
                    var newDuration = moment(this.timePickerRefResize.current.timePickerRef.state.value.clone().subtract(eventEnd.getTimezoneOffset()/60, 'hours').toDate()).diff(moment(this.state.resize_booking.start), 'minutes')/60;
                    //should only be an issue if increasing the duration.
                    var timePickerValue = this.timePickerRefResize.current.timePickerRef.state.value;
                    if (paymentBeforeBooking && newDuration > existingDuration) {
                        document.getElementsByClassName('spinner')[0].style['display'] = 'block';
                        await this.checkForEnoughPrepaidLessonsToCoverDuration(this.state.resize_booking.extendedProps.student_id, (newDuration - existingDuration),
                            async () => {
                                await this.updateBookingDuration(timePickerValue, info);
                                document.getElementsByClassName('spinner')[0].style['display'] = 'none';
                            },
                            () => {
                                info.revert();
                                document.getElementsByClassName('spinner')[0].style['display'] = 'none';
                            },
                        );
                    } else {
                        await this.updateBookingDuration(timePickerValue, info);
                    }
                },
                onCancel: () => {return info.revert();},
                okText: "Yes",
                cancelText: "No"
            });
        });
    }

    updateBookingDuration = async (timePickerValue, info) => {
        let values = {
            chosen_driver: this.props.chosen_driver,
            booking: this.state.resize_booking.id,
            start_time: this.state.resize_booking.start,
            end_time: timePickerValue.clone().subtract(timePickerValue.toDate().getTimezoneOffset()/60, 'hours').toDate()
        };
        if (showSendNotificationToStudentCheckbox) {
            // As of 2023-10-06, Don't show Notification Checkbox to DT Users
            if (this.props.office_user) {
                if (document.getElementById('send-confirmation-checkbox-resize') !== null) {
                    values.send_confirmation = document.getElementById('send-confirmation-checkbox-resize').checked;
                } else {
                    values.send_confirmation = false;
                }
                // NEVER send for Tentative Tests
                if (this.state.resize_booking.classNames.includes('test') && this.state.resize_booking.classNames.includes('tentative')) {
                    values.send_confirmation = false;
                }
            } else {
                // For DT's, ALWAYS send Notification
                let sendToStudent = true;
                // UNLESS it's a Tentative Test Booking
                if (this.state.resize_booking.classNames.includes('test') && this.state.resize_booking.classNames.includes('tentative')) {
                    sendToStudent = false;
                }
                values.send_confirmation = sendToStudent;
            }
        }
        await api.post("bookings/update", {
            body: JSON.stringify(values)
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }

            //Check for an error response (status of "NOK")
            if(res.status === 'NOK') {
                Modal.error({
                    title: "Error Updating Booking Date/Time",
                    content: (
                        <div>
                            <p>There was an error when trying to Update this Booking.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
                return info.revert();
            } else if(!res.result.success) {
                Modal.error({
                    title: "Error Updating Booking Date/Time",
                    content: (
                        <div>
                            <p>There was an error when trying to Update this Booking.</p>
                             {(typeof(res.result.error) !== 'undefined' ? <p>{res.result.error}</p> : '')}
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
                return info.revert();
            } else {
                //Success - Show a Success Modal
                Modal.success({
                    title: "Booking Updated!",
                    content: (
                        <div>
                            <p>The Booking has been successfully updated.</p>
                        </div>
                    ),
                    onOk: () => {
                        Modal.destroyAll();
                        //Adjust the display to the new start time, moving the end time to keep duration.
                        let calendarApi = this.calendarRef.current.getApi();
                        let eventToUpdate = calendarApi.getEventById(this.state.resize_booking.id);
                        eventToUpdate.setEnd(timePickerValue.format(), {maintainDuration: false});
                    },
                    keyboard: false
                });
            }
        })
        .catch(() => {
            Modal.error({
                title: "Error Updating Booking Date/Time",
                content: (
                    <div>
                        <p>There was an error when trying to Update this Booking.</p>
                        <p>If this problem persists, please contact the Office for assistance.</p>
                    </div>
                )
            });
            return info.revert();
        });
    };


    // Note that this differs from the function in LessonAdd.js - here we only look for the increase in duration.
    // This version of the function also accepts the required duration as a parameter
    checkForEnoughPrepaidLessonsToCoverDuration = (value, durationRequired, callback, badCallback) => {
        api.post("students/fetch/" + value,{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            // We also only run the callback when duration is valid.
            if(data.result.student.prepaid.remaining < durationRequired) {
                //Disable the Save Button, show a message
                Modal.error({
                    title: "Not Enough Lesson Credits",
                    content: (
                        <div>
                            <p>Student does not have enough lesson credits, change duration or visit profile to take payment.</p>
                        </div>
                    )
                });
                badCallback();
            } else {
                if (typeof callback !== 'undefined') {
                    callback();
                }
            }
        });
    };

    checkOverlap = (stillEvent, movingEvent) => {
        // All Day Events are okay for clashes
        if(stillEvent.allDay || (movingEvent.allDay && !stillEvent.allDay)) {
            return true;
        }

        // Background Events are okay for clashes
        if(stillEvent.rendering === 'inverse-background' || stillEvent.rendering === 'background'){
            return true;
        }

        // Otherwise, It's probably not allowed.
        return false;
    }

    calendarRef = React.createRef();
    timePickerRefMove = React.createRef();
    timePickerRefResize = React.createRef();
    timePickerRefCreateStart = React.createRef();
    timePickerRefCreateEnd = React.createRef();

    agendaDateFormat = (date) => {
        return moment(date.date.marker).format('dddd DD MMMM YYYY');
    };

    handleDatesRender = (info) => {
        if(this.calendarRef.current !== null){
            let calendarApi = this.calendarRef.current.getApi();
            let currentDate = moment(calendarApi.getDate()).format('YYYY-MM-DD');
            if(currentDate !== this.props.match.params.date){
                this.props.history.push('/diary/' + currentDate);
            }
        }
    };

    handleEventRender = (info) => {
        if(info.view.type ==='timeGridWeek') {
            var weeklyTotals = document.getElementsByClassName('fc-weeklytotal');
            while (weeklyTotals.length > 0) weeklyTotals[0].remove();
            if(document.getElementsByClassName('fc-axis fc-widget-header').length > 0){
                //Place a Total in the Header of the first Column
                var lbd = document.createElement('div');
                lbd.className = 'fc-weeklytotal';
                lbd.id = 'weeklytotalLB';
                lbd.innerHTML = 'LB: <span>' + 0 + '</span><br />';
                document.getElementsByClassName('fc-axis fc-widget-header')[0].appendChild(lbd);
                var thd = document.createElement('div');
                thd.className = 'fc-weeklytotal';
                thd.id = 'weeklytotalTH';
                thd.innerHTML = 'TH: <span>' + 0 + '</span>';
                document.getElementsByClassName('fc-axis fc-widget-header')[0].appendChild(thd);
                if(this.props.office_user && (document.getElementById('weeklytotalPeak') === null || document.getElementById('weeklytotalPeak').length === 0)){
                    var ppd = document.createElement('div');
                    ppd.className = 'fc-weeklytotal';
                    ppd.id = 'weeklytotalPeak';
                    ppd.innerHTML = 'P: <span>' + localStorage.getItem('peakAvailability') + '</span>%';
                    document.getElementsByClassName('fc-axis fc-widget-header')[0].appendChild(ppd);
                }
            }
        }
        var dailyTotals = document.getElementsByClassName('fc-dailytotal');
        while (dailyTotals.length > 0) dailyTotals[0].remove();
        if(document.getElementsByClassName('fc-day-header').length > 0) {
            Array.from(document.getElementsByClassName('fc-day-header')).forEach((el) => {
                if(el.getAttribute('data-date') !== null){
                    var d = document.createElement('div');
                    d.className = 'fc-dailytotal';
                    d.id = 'dailytotal-' + el.getAttribute('data-date');
                    d.innerHTML = 0;
                    el.appendChild(d);
                }
            });
        }
        
        switch(info.view.type){
            case 'timeGridDay':
                let dayTitle = info.el.querySelector('.fc-title');
                if(dayTitle !== null){
                    dayTitle.innerHTML = 
                            (typeof(info.event.extendedProps.payment_icon) !== 'undefined' && info.event.extendedProps.payment_icon !== '' && info.event.extendedProps.payment_icon !== null ? "<span class='payment_icon day'>" + info.event.extendedProps.payment_icon  + "</span>" : '') + 
                            dayTitle.innerHTML + 
                            (typeof(info.event.extendedProps.test_location) !== 'undefined' && info.event.extendedProps.test_location !== '' ? "<br />Test Start Time: " + info.event.extendedProps.test_start_time + "<br />Test Center: " + info.event.extendedProps.test_location : "") + 
                            (typeof(info.event.extendedProps.start_location) !== 'undefined' && info.event.extendedProps.start_location !== '' ? "<br />" + (info.event.extendedProps.booking_type === 'Event' ? "Event Location: " : "Pick Up: ") + info.event.extendedProps.start_location : '') + 
                            (typeof(info.event.extendedProps.end_location) !== 'undefined' && info.event.extendedProps.end_location !== '' ? " - Drop Off: " + info.event.extendedProps.end_location : '') + 
                            '';
                }
                break;
            case 'timeGridWeek':
            default:
                let weekTitle = info.el.querySelector('.fc-title');
                if(weekTitle !== null){
                    weekTitle.innerHTML = 
                            weekTitle.innerHTML + 
                            '<span class="nobreak">' +
                            (typeof(info.event.extendedProps.start_suburb) !== 'undefined' && info.event.extendedProps.start_suburb !== '' && info.event.extendedProps.start_suburb !== null ? "<br />" + info.event.extendedProps.start_suburb : '') + 
                            (typeof(info.event.extendedProps.end_suburb) !== 'undefined' && info.event.extendedProps.end_suburb !== '' && info.event.extendedProps.end_suburb !== null ? " &#8250; " + info.event.extendedProps.end_suburb : '') + 
                            (typeof(info.event.extendedProps.test_location) !== 'undefined' && info.event.extendedProps.test_location !== '' && info.event.extendedProps.test_location !== null ? "<br />Center: " + info.event.extendedProps.test_location : "") + 
                            (typeof(info.event.extendedProps.test_start_time) !== 'undefined' && info.event.extendedProps.test_start_time !== '' && info.event.extendedProps.test_start_time !== null ? "<br />Test Start Time: " + info.event.extendedProps.test_start_time : "") + 
                            '</span>';
                }
                break;
            case 'dayGridMonth':
                //No extra info for Month View - not enough space!
                break;
            case 'listAgenda':
                let listWeekTitle = info.el.querySelector('.fc-list-item-title');
                if(listWeekTitle !== null){
                    listWeekTitle.innerHTML = 
                            (typeof(info.event.extendedProps.payment_icon) !== 'undefined' && info.event.extendedProps.payment_icon !== '' && info.event.extendedProps.payment_icon !== null ? "<span class='payment_icon'>" + info.event.extendedProps.payment_icon  + "</span>" : '') + 
                            listWeekTitle.innerHTML + 
                            (typeof(info.event.extendedProps.test_location) !== 'undefined' && info.event.extendedProps.test_location !== '' && info.event.extendedProps.test_location !== null ? "<br />Test Start Time: " + info.event.extendedProps.test_start_time + "<br />Test Center: " + info.event.extendedProps.test_location : "") + 
                            (typeof(info.event.extendedProps.start_location) !== 'undefined' && info.event.extendedProps.start_location !== '' && info.event.extendedProps.start_location !== null ? "<br />" + (info.event.extendedProps.booking_type === 'Event' ? "Event Location: " : "Pick Up: ") + info.event.extendedProps.start_location : '') + 
                            (typeof(info.event.extendedProps.end_location) !== 'undefined' && info.event.extendedProps.end_location !== '' && info.event.extendedProps.end_location !== null ? "<br />Drop Off: " + info.event.extendedProps.end_location : '') + 
                            (typeof(info.event.extendedProps.test_location) !== 'undefined' && info.event.extendedProps.test_location !== '' && info.event.extendedProps.test_location !== null ? "<br />Test Start Time: " + info.event.extendedProps.test_start_time : "") + 
                            (typeof(info.event.extendedProps.reminder_description) !== 'undefined' && info.event.extendedProps.reminder_description !== '' && info.event.extendedProps.reminder_description !== null ? "<br />" + info.event.extendedProps.reminder_description : '') + 
                            '';
                }
                //Label Reminders as such:
                let listWeekTime = info.el.querySelector('.fc-list-item-time');
                if(listWeekTime.innerHTML === 'all-day'){
                    listWeekTime.innerHTML = 'Reminder';
                }
                //Determine Icon to use
                let iconToUse = '';
                if (info.event.classNames.indexOf('first_lesson') >= 0) {
                    iconToUse = faUserPlus;
                } else if (info.event.classNames.indexOf('test') >= 0) {
                    iconToUse = faClipboardCheck;
                } else if (info.event.classNames.indexOf('event') >= 0) {
                    iconToUse = faCalendarTimes;
                } else if (info.event.classNames.indexOf('reminder') >= 0) {
                    iconToUse = faBell;
                } else {
                    iconToUse = faUserFriends;
                }

                //Get Time Field - We'll insert before it
                let timeTD = info.el.querySelector('.fc-list-item-time');

                //Create New TD:
                var newTD = document.createElement('td');
                newTD.innerHTML = ReactDOMServer.renderToString(<FontAwesomeIcon fixedWidth icon={iconToUse} size="lg" />);

                //Insert TD:
                timeTD.parentNode.insertBefore(newTD, timeTD);
                break;
        }
        
    }

    handleEventPositioned = (event, element, view) => {
        //Exclude Shifts/Availability and Reminders
        if(event.event.rendering !== 'inverse-background' && event.event.rendering !== 'background' && event.event.start && !event.event.allDay){
            //Get Date as YYYY-MM-DD for totals group
            var eventDurationHours = moment.duration(moment(event.event.end).diff(event.event.start)).asHours();
            if (eventDurationHours > 0){
                var currentday = event.event.start.toISOString().slice(0,10);
                if(typeof (document.getElementById("dailytotal-" + currentday)) !== 'undefined' && document.getElementById("dailytotal-" + currentday) !== null){
                    var prev = parseFloat(document.getElementById("dailytotal-" + currentday).innerHTML) || 0;
                    document.getElementById("dailytotal-" + currentday).innerHTML = (prev + eventDurationHours).toFixed(2);
                }
                if(typeof (document.getElementById("weeklytotalTH")) !== 'undefined' && document.getElementById("weeklytotalTH") !== null){
                    //if it's NOT an Event, add to the LB (Lesson Bookings) total
                    if(event.event.classNames.indexOf('event') !== 0){
                        var weeklyPrevLB = parseFloat(document.getElementById("weeklytotalLB").getElementsByTagName('span')[0].innerHTML) || 0;
                        document.getElementById("weeklytotalLB").getElementsByTagName('span')[0].innerHTML = (weeklyPrevLB + eventDurationHours).toFixed(2);
                    }
                    //Add everything to the TH (Total Hours) total
                    var weeklyPrev = parseFloat(document.getElementById("weeklytotalTH").getElementsByTagName('span')[0].innerHTML) || 0;
                    document.getElementById("weeklytotalTH").getElementsByTagName('span')[0].innerHTML = (weeklyPrev + eventDurationHours).toFixed(2);
                }
            }
        }
    }

    eventFetch = (params = {}, successCallback) => {
        params.chosen_driver = this.props.chosen_driver;
        diaryHelper.eventFetch(params, successCallback);
    };

    downloadICS = (params = {}, successCallback) => {
        this.setState({
            errorMessage: false
        });
        api.post("diary/ics", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(res => {
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || typeof res.result.file === 'undefined') {
                this.setState({
                    errorMessage: 'Unable to download Calendar file. Please try again.',
                    errorMessageType: 'error'
                });
            } else {
                window.open( "data:text/calendar;charset=utf8," + escape(res.result.file));
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({
                errorMessage: 'Unable to download Calendar file. Please try again.',
                errorMessageType: 'error'
            });
        });
    };

    render(){
        let displayAlert = this.state.errorMessage !== false;

        if(this.state.disabled_hours.length === 0){
            for (let i = 0; i < 24; i++){
                if(i < dayStart || i >= dayEnd){
                    this.state.disabled_hours.push(i);
                }
            }
        }

        return (
            <Row type="flex" justify="space-around" id="mainBody" style={{marginBottom: 0}}>
                <Col span={24}>
                    <div style={{height:'100%'}} id='calendarWrapper'>
                        <Spinner type="mega" />
                        <FullCalendar
                            key={"diaryCal" + this.props.chosen_driver}
                            ref={this.calendarRef}
                            defaultView={document.documentElement.clientWidth < 1024 ? "listAgenda" : "timeGridWeek"}
                            defaultDate={this.props.match.params.date}
                            navLinks={true}
                            editable={true}
                            longPressDelay={200}
                            selectable={true}
                            select={this.handleEventCreate}
                            slotDuration='00:30:00'
                            snapDuration='00:30:00'
                            events={this.eventFetch}
                            eventPositioned={this.handleEventPositioned}
                            loading={this.loading}
                            datesRender={this.handleDatesRender}
                            eventRender={this.handleEventRender}
                            eventClick={diaryHelper.handleEventClick}
                            eventDrop={this.handleEventDrop}
                            eventResize={this.handleEventResize}
                            eventOverlap={this.checkOverlap}
                            plugins={[ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ]} 
                            timeZone='Australia/Sydney'
                            nowIndicator={true}
                            height={'parent'}
                            customButtons={{
                                ics: {
                                    click: this.downloadICS
                                }
                            }}
                            header={{
                                left:   'prev,next today ics',
                                center: 'title',
                                right:  'timeGridDay,timeGridWeek,dayGridMonth,listAgenda'
                            }}
                            buttonIcons={{
                                ics: 'download'
                            }}
                            buttonText={{
                                month:    'Month',
                                week:     'Week',
                                day:      'Day',
                                list:     'Agenda',
                                today:    'Today'
                            }}
                            firstDay={1}
                            minTime={dayStart + ':00:00'}
                            maxTime={dayEnd + ':00:00'}
                            views={{
                                dayGridMonth: {
                                    titleFormat: { year: 'numeric', month: 'long' },
                                    columnHeaderFormat: {weekday: 'long'},
                                    eventLimit: 4,
                                    weekNumbers: true
                                },
                                timeGridWeek: {
                                    titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
                                    columnHeaderFormat: function(date) {
                                        return date.date.marker.toLocaleString('en-au', {  weekday: 'short', day: 'numeric', month: 'numeric' }).replace(',', '')
                                    }
                                },
                                timeGridDay: {
                                    titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }
                                },
                                listAgenda: {
                                    type: 'list',
                                    dateAlignment: 'week',
                                    duration: { days: 7 },
                                    listDayFormat: this.agendaDateFormat
                                }
                            }}
                        />
                        <div className={displayAlert ? '' : 'hidden' } style={{position:'fixed', width: 'calc(100% - 80px)',left: '80px',bottom: '0px',zIndex: '999'}}>
                            <Alert key="login-failed" message={this.state.errorMessage} type={this.state.errorMessageType} banner />
                        </div>


                        <Modal
                            visible={this.state.createModalVisible}
                            title= 'Create Booking?'
                            className= 'create-choice-modal'
                            onCancel={()=>{this.state.calendarApi.unselect();this.setState({createModalVisible: false});}}
                            footer={[
                                <Button
                                    style={{display: (this.state.createModalShowCreateLessonButton ? 'inline-block' : 'none')}}
                                    key="ok"
                                    type="primary"
                                    onClick={this.handleCreateModalOk}
                                >
                                    Create Lesson
                                </Button>,
                                <Button
                                    style={{display: (this.state.createModalShowCloseButton ? 'inline-block' : 'none')}}
                                    key="absence"
                                    type="primary"
                                    onClick={this.handleCreateModalAbsence}
                                >
                                    Block Out Time
                                </Button>,
                                <Button
                                    style={{display: (this.state.createModalShowOpenButton ? 'inline-block' : 'none')}}
                                    key="extrashift"
                                    type="primary"
                                    onClick={this.handleCreateModalExtraShift}
                                >
                                    Open Up Time
                                </Button>,
                                <Button
                                    key="cancel"
                                    onClick={() => {
                                        this.state.calendarApi.unselect();
                                        this.setState({
                                            createModalVisible: false
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                            ]}
                        >
                            <Form layout="inline" id="createLessonForm">
                                <Form.Item label="Start Time:" >
                                    <TimePicker
                                        id="createLessonModalStartTimePicker"
                                        value={this.state.createModalStartTime}
                                        allowClear={false}
                                        use12Hours
                                        format='h:mm a'
                                        minuteStep={5}
                                        style={{width: '100px'}}
                                        ref={this.timePickerRefCreateStart}
                                        onChange={this.handleCreateStartTimeChange}
                                        inputReadOnly={true}
                                        disabledHours={() => this.state.disabled_hours}
                                        hideDisabledOptions={true}
                                    />
                                </Form.Item>
                                <Form.Item label="End Time:" >
                                    <TimePicker
                                        id="createLessonModalEndTimePicker"
                                        value={this.state.createModalEndTime}
                                        allowClear={false}
                                        use12Hours
                                        format='h:mm a'
                                        minuteStep={5}
                                        style={{width: '100px'}}
                                        ref={this.timePickerRefCreateEnd}
                                        onChange={this.handleCreateEndTimeChange}
                                        inputReadOnly={true}
                                        disabledHours={() => this.state.disabled_hours}
                                        disabledMinutes={() => this.state.createModalDisabledMinutes}
                                        hideDisabledOptions={true}
                                    />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                </Col>
            </Row>
        );
    }
};

export default withRouter(Diary);
