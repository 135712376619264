import React from 'react';
import { withRouter } from 'react-router';
import { Alert, Button, Form, Input, DatePicker, Popconfirm, Switch } from 'antd';
import api from '../../../helpers/api';
import moment from 'moment';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faBan } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';

const { TextArea } = Input;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

class Reminders extends React.Component {

    state = {
        errorMessage: false,
        errorMessageType: "error"
    };

    render() {
        let displayAlert = this.state.errorMessage !== false;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 7},
                xl: {span: 9}
            },
            wrapperCol: {
                xs: {span: 17},
                xl: {span: 15}
            }
        };

        var handleArchive = async (id) => {
            api.post("reminders/archive/" + id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(data => {
                if(typeof data.result === 'object' && data.result.saved){
                    this.props.history.push('/reminders');
                }else{
                    this.setState({
                        errorMessage: data.result.error,
                        errorMessageType: 'error'
                    });
                }
            });
        };

        var handleSave = async (e) => {
            e.preventDefault();

            this.props.form.validateFields((err, values) => {
                if (!err) {
                    return api.post("reminders/save", {
                        body: JSON.stringify({
                            id: (typeof this.props.reminderToEdit !== 'undefined' ? this.props.reminderToEdit.id : null),
                            title: values.title,
                            due_date: values.due_date,
                            description: values.description,
                            completed: values.completed,
                            chosen_driver: this.props.chosen_driver
                        })
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }
                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK'){
                            this.setState({
                                errorMessage: res.result.error,
                                errorMessageType: 'error'
                            });
                        } else {
                            if(typeof this.props.reminderToEdit === 'undefined'){
                                this.props.setAppStateValue('counts', {
                                    reminders: (this.props.counts.reminders + 1)
                                }, false, this.props.history.goBack());
                            }else{
                                this.props.history.goBack();
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            errorMessage: "Error saving Reminder, please try again.",
                            errorMessageType: 'error'
                        });
                    });
                }
            });
        }

        return (
            <>
                <Form {...formItemLayout} id="reminderForm" onSubmit={handleSave}>
                    <Form.Item label="Title" hasFeedback >
                        {getFieldDecorator('title', {
                            rules: [{ required: true, message: 'Please enter a Reminder Title.' }],
                            initialValue: (typeof this.props.reminderToEdit !== 'undefined' ? this.props.reminderToEdit.title : null)
                        })(
                            <Input autoFocus={true} />
                        )}
                    </Form.Item>
                    <Form.Item label="Due Date" hasFeedback >
                        {getFieldDecorator('due_date', {
                            rules: [],
                            initialValue: (typeof this.props.reminderToEdit !== 'undefined' && typeof this.props.reminderToEdit.due_date !== 'undefined' && this.props.reminderToEdit.due_date !== null ? moment(this.props.reminderToEdit.due_date) : (typeof this.props.dueDate !== 'undefined' ? moment(this.props.dueDate) : null))
                        })(
                            <DatePicker
                                size="large"
                                allowClear={false}
                                placeholder='Select Due Date'
                                format={dateFormatList}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Description" hasFeedback >
                        {getFieldDecorator('description', {
                            rules: [],
                            initialValue: (typeof this.props.reminderToEdit !== 'undefined' ? this.props.reminderToEdit.description : null)
                        })(
                            <TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
                        )}
                    </Form.Item>
                    {
                        (typeof this.props.reminderToEdit !== 'undefined' &&
                            <Form.Item label="Status">
                                {getFieldDecorator('completed', {
                                    valuePropName: 'checked',
                                    initialValue: this.props.reminderToEdit.completed
                                })(
                                    <Switch 
                                        checkedChildren="Done" 
                                        unCheckedChildren="Not Done" 
                                    />
                                )}
                            </Form.Item>
                        )
                    }

                    <div className='form-buttons space-evenly'>
                        <Button type="primary" htmlType="submit">
                            <FontAwesomeIcon icon={faSave} />Save Reminder
                        </Button>
                        <Button onClick={() => this.props.history.goBack()} type="danger" htmlType="button">
                            <FontAwesomeIcon icon={faBan} />Cancel
                        </Button>
                        {
                            (typeof this.props.reminderToEdit !== 'undefined' &&
                                <Popconfirm title="Archive this reminder?" onConfirm={() => handleArchive(this.props.reminderToEdit.id)}>
                                    <Button>
                                        <FontAwesomeIcon icon={faArchive} />Archive Reminder
                                    </Button>
                                </Popconfirm>
                            )
                        }
                    </div>
                </Form>
        
                <div className={displayAlert ? '' : 'hidden' }>
                    <Alert key="login-failed" message={this.state.errorMessage} type={this.state.errorMessageType} banner />
                </div>
            </>
        );
    }
};

const RemindersForm = Form.create({name: 'reminder_form'})(Reminders);

export default withRouter(RemindersForm);
