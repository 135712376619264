import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card } from 'antd';

class InactiveStudentCard extends React.Component {

    state = {};

    
    render(){
        const {
            student_first_name: first_name,
            student_last_name: last_name,
            student_mobile_phone: mobile_phone = "",
            student_home_phone: home_phone = "",
            final_booking: last_active,

            total_booking,
            total_test_booking,
            keys2drive,
            total_sdc_booking,

            test_passed_date = "",
            driving_experience = 0,
            remaining_lessons = 0,
            referral_count = 0,

        } = this.props.student || {};

        return (
            <Card title={first_name + " " + last_name}>
                <table width="100%" className="inactiveStudentTable">
                    <thead>
                        <tr>
                            <th>Student Mobile</th>
                            <th>Student Home Phone</th>
                            <th>Last Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{mobile_phone}</td>
                            <td>{home_phone}</td>
                            <td>{last_active}</td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" className="inactiveStudentTable">
                    <thead>
                        <tr>
                            <th colSpan="4">Student Bookings</th>
                        </tr>
                        <tr>
                            <th># of Bookings</th>
                            <th># of Tests</th>
                            <th>Had K2D</th>
                            <th>Had SDC</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{total_booking}</td>
                            <td>{total_test_booking}</td>
                            <td>{keys2drive > 0 ? "Yes": "No"}</td>
                            <td>{total_sdc_booking > 0 ? "Yes": "No"}</td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" className="inactiveStudentTable">
                    <thead>
                        <tr>
                            <th colSpan="4">Other Information</th>
                        </tr>
                        <tr>
                            <th>Date passed</th>
                            <th>Hours Driving Experience</th>
                            <th>Remaining Prepaid Lessons</th>
                            <th># Referrals made</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{test_passed_date}</td>
                            <td>{driving_experience? driving_experience: 0}</td>
                            <td>{Math.round(remaining_lessons)}</td>
                            <td>{referral_count}</td>
                        </tr>
                    </tbody>
                </table>
                
            </Card>
        );
    }
};


export default withRouter(InactiveStudentCard);