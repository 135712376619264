import React from 'react';
import { Spin } from 'antd';
import TyreIcon from './icons/TyreIcon';

class Spinner extends React.Component {

    render() {
        return (
            <div className={"spinner " + (this.props.type !== 'undefined' ? this.props.type + "-spinner" : '') + (this.props.hidden ? " hidden" : "")} id={(this.props.id !== 'undefined' ? this.props.id : '')} >
                <Spin indicator={TyreIcon} />
            </div>
        );
    }
};

export default Spinner;