import React from 'react';
import { Row, Col, Button } from 'antd';
import api from '../../helpers/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';

class Library extends React.Component {

    componentDidMount() {
        this.recordAccess();
    }

    recordAccess = async(params = {}) => {
        await api.post("library/access", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .catch(error => {
            console.log(error);
        });
    };
    
    goToGoogleDrive = () => {
        api.post("library/access/drive", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .catch(error => {
            console.log(error);
        });
        //Open new window
        window.open("https://drive.google.com/embeddedfolderview?id=1p_qX2_NvErs6PfyyY2i3ZiVvyHeYARBN#list", "_blank");
    };

    render(){
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h2>Resource Library</h2>
                    <Row type="flex" style={{paddingTop: '5vh', justifyContent: 'space-evenly'}} >
                        <Button
                            type="primary"
                            htmlType="button"
                            onClick={this.goToGoogleDrive}
                        >
                            <FontAwesomeIcon icon={faGoogleDrive} />Resources
                        </Button>
                    </Row>
                </Col>
            </Row>
        );
    }
};

export default Library;
