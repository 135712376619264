import React from 'react';

class NotFound extends React.Component {

    render(){
        return (
            <div id='NotFound'>
                <p>
                    Unable to find <code>{window.location.pathname}</code>.<br />
                    The page either does not exist,<br />or you do not have permission to view it.
                </p>
            </div>
        );
    }
};

export default NotFound;