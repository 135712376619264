import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Select, Layout, Menu, DatePicker } from 'antd';
import config from '../config';
import api from '../helpers/api';
import moment from 'moment';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faBell as faBellSolid, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

//Images
const { logoFullImage, logoLImage, logoFullTestImage, logoLTestImage, isPFG } = config;
const images = require.context('../images/', true);
const logoFull = images('./' + logoFullImage);
const logoL = images('./' + logoLImage);
const logoFullTest = images('./' + logoFullTestImage);
const logoLTest = images('./' + logoLTestImage);

const {Header} = Layout;
const { Option } = Select;

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const TopNavigation = withRouter(props => {
    const { office_user, logout, counts, toggleReminderDrawer, driver_list, chosen_driver, chosen_driver_name, topNavDatePickerOpen, collapsed } = props;

    function onDriverSelect(item) {
        console.log(`Using Driver Trainer \`${item.label}\` (${item.key})`);

        if(window.location.pathname !== "/" || (window.location.pathname === "/" && window.innerWidth < 1024)) {
            props.hideReminderDrawer();
        }

        api.post("drivers/select", {
            body: JSON.stringify({
                chosen_driver: item.key
            })
        })
        .then(() => {
            props.setAppStateValue('chosen_driver', item.key);
            props.setAppStateValue('chosen_driver_name', item.label);
            localStorage.setItem('chosen_driver', item.key);
            localStorage.setItem('chosen_driver_name', item.label);
        });
    }

    function toggleDatePicker() {
        props.setAppStateValue('topNavDatePickerOpen', !topNavDatePickerOpen);
    }

    function handleDatePickerChange(chosen_date){
        if(chosen_date !== null){
            toggleDatePicker();
            props.setAppStateValue('diaryCurrentDate', moment(chosen_date).format('YYYY-MM-DD'));
            return props.history.push('/diary/' + moment(chosen_date).format('YYYY-MM-DD'));
        }
    }

    return (
        <>
            <div id="calendarDatePickerWrapper">
                <DatePicker
                    animation="slide-up"
                    style={{display:'none'}}
                    open={topNavDatePickerOpen}
                    getCalendarContainer={() => document.getElementById('calendarDatePickerWrapper')}
                    dropdownClassName='date-picker-popup'
                    format={dateFormatList}
                    onChange={handleDatePickerChange}
                />
            </div>
            <Header
                style={{ position: 'fixed', top:0, left:0, right:0, zIndex: 1, width: '100%' }}
                className={(window.location.pathname === "/choose-driver" || !office_user ? 'shortHeader' : '')}
            >
                {
                    props.hide_menu_items &&
                    <img src={(!window.location.hostname.startsWith('diary') ? logoFullTest : logoFull)} className={"nav-logo"} alt="logo" style={{ top: 20 }} />
                }
                {
                    !props.hide_menu_items &&
                    isPFG && 
                    <Link className={"nav-logo-link collapsed"} to={(office_user && !chosen_driver) ? ('/choose-driver') : ('/')}>
                        <img src={(!window.location.hostname.startsWith('diary') ? logoFullTest : logoFull)} className={"nav-logo collapsed"} alt="logo" style={{ top: 5 }} />
                    </Link>
                }
                {
                    !props.hide_menu_items &&
                    !isPFG &&
                    <Link className={"nav-logo-link " + (isPFG ? ' pfg ' : '') + (collapsed && window.location.pathname !== "/choose-driver" ? " collapsed " : "")} to={(office_user && !chosen_driver) ? ('/choose-driver') : ('/')}>
                        <img src={(!window.location.hostname.startsWith('diary') ? logoFullTest : logoFull)} className={"nav-logo " + (isPFG ? ' collapsed ' : '') + (collapsed && window.location.pathname !== "/choose-driver" ? " hide " : "")} alt="logo" />
                        <img src={(!window.location.hostname.startsWith('diary') ? logoLTest : logoL)} className={"nav-logo collapsed " + (collapsed && window.location.pathname !== "/choose-driver" ? "" : " hide ")} alt="logo" />
                    </Link>
                }
                <Menu
                    theme="light"
                    mode="horizontal"
                    >
                    {
                        !props.hide_menu_items &&
                        (!office_user || (office_user && chosen_driver)) && 
                        <Menu.Item key="datepicker" onClick={toggleDatePicker}>
                            <FontAwesomeIcon fixedWidth icon={faCalendar} size="lg" />
                        </Menu.Item>
                    }
                    {
                        !props.hide_menu_items &&
                        (!office_user || (office_user && chosen_driver)) &&
                        <Menu.Item key="reminders">
                            <div
                                onClick={toggleReminderDrawer}
                            >
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={(counts.reminders > 0 ? faBellSolid : faBell)}
                                    size="lg"
                                    className={(counts.reminders > 0 ? 'reminder-pulse' : '')}
                                />
                            </div>
                        </Menu.Item>
                    }
                    {
                        !props.hide_menu_items &&
                        <Menu.Item key="logout" onClick={logout}>
                            <FontAwesomeIcon fixedWidth icon={faSignOutAlt} size="lg" />
                        </Menu.Item>
                    }
                    {
                        props.hide_menu_items && localStorage.getItem('studentAccess') &&
                        <Menu.Item key="logout" onClick={()=>{
                            localStorage.clear();
                            window.location.href = '/attend';
                        }}>
                            <FontAwesomeIcon fixedWidth icon={faSignOutAlt} size="lg" />
                        </Menu.Item>
                    }
                </Menu>
                {
                    !props.hide_menu_items &&
                    (office_user && !isNaN(chosen_driver) && chosen_driver) && 
                    <Select
                        labelInValue
                        showSearch
                        style={{ width: 250, position: 'absolute', right: '250px', top: '15px' }}
                        placeholder="Select a Driver Trainer"
                        optionFilterProp="children"
                        onChange={onDriverSelect}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue={{key:chosen_driver}}
                        value={{key:chosen_driver}}
                    >
                        <Option value={chosen_driver}>{chosen_driver_name}</Option>
                        {
                            driver_list.map((driver, index) => {
                                return (driver.id !== chosen_driver ? <Option key={index} value={driver.id}>{driver.name}</Option> : '');
                            })
                        }
                    </Select>
                }
            </Header>
        </>
    );
});

export default TopNavigation;
