import React from 'react';
import api from '../../../helpers/api';
import { Alert, Button, Form, Input, Modal } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';

class GiftVoucher extends React.Component {

    state = {
        errorMessage: false,
        errorMessageType: "error",
        urgent: false,
        submitting: false
    };

    render(){
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 6, offset: 3}
            },
            wrapperCol: {
                xs: {span: 12}
            }
        };

        var redeemGiftVoucher = async (e) => {
            e.preventDefault();

            this.setState({
                submitting: true
            }, () => {
                return api.post("giftVouchers/redeemGiftVoucher", {
                    body: JSON.stringify({
                        voucher_code: document.getElementById('gift_voucher_form_voucher_code').value,
                        student_id: this.props.student_id,
                        chosen_driver: this.props.chosen_driver
                    })
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if(typeof res === 'string'){
                        res = JSON.parse(res);
                    }
                    //Check for an error response (status of "NOK")
                    if(res.status === 'NOK' || (typeof res.result.error !== 'undefined' && res.result.error !== null)){
                        this.setState({
                            errorMessage: res.result.error,
                            errorMessageType: 'error',
                            submitting: false
                        });
                    } else {
                        this.setState({
                            submitting: false
                        }, () => {
                            //Success - Show a Success Modal
                            Modal.success({
                                title: "Gift Voucher Redeemed!",
                                content: (
                                    <div>
                                        <p>The Gift Voucher has been successfully redeemed.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.props.goBack();
                                }
                            });
                        });
                    }
                })
                .catch(error => {
                    console.log('caught here!!');
                    console.log(error);
                    this.setState({
                        errorMessage: "Error redeeming gift voucher, please try again.",
                        errorMessageType: 'error',
                        submitting: false
                    });
                });
            });
        };

        return (
            <div className="paymentOptionContainer">
                <div className={this.state.errorMessage !== false ? '' : 'hidden' }>
                    <Alert key="voucher-failed" message={this.state.errorMessage} type={this.state.errorMessageType} banner />
                </div>
                <Form {...formItemLayout} id="giftVoucher" onSubmit={redeemGiftVoucher}>
                    <Form.Item label="Voucher Code" >
                        {getFieldDecorator('voucher_code', {
                            rules: [{ required: true, message: 'Please enter a Voucher Code.' }],
                            initialValue: null
                        })(
                            <Input
                                required="required"
                                autoFocus={true}
                                disabled={this.state.submitting}
                            />
                        )}
                    </Form.Item> 
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.submitting}
                        disabled={this.state.submitting}
                    >
                        <FontAwesomeIcon icon={faGift} />Redeem Gift Voucher
                    </Button>
                </Form>
            </div>
        );
    }
};

const GiftVoucherRedemption = Form.create({name: 'gift_voucher_form'})(GiftVoucher);

export default GiftVoucherRedemption;