import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Button, Modal, Form, Input, Switch } from 'antd';
import api from '../../../helpers/api';
import moment from 'moment';
import Spinner from '../../elements/Spinner';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEnvelope, faEnvelopeOpen, faExclamationTriangle, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
    faCheckSquare,
    // Hidden 21/05/2024 as per TDS-822
    // faPlusSquare
} from '@fortawesome/free-regular-svg-icons';

class MessagesTable extends React.Component {  
    state = {
        data: [],
        pagination: {},
        loading: true,
        viewFilter: "all",
        selectedRows: [],
        searchModalVisible: false,
        searchTerm: null,
        searchExact: false
    };

    componentDidMount() {
        this.fetch();
    }

    getKeyByValue = (object, field, value, returnData) => {
        for (var key in object) {
            // skip loop if the property is from prototype
            if (!object.hasOwnProperty(key)) continue;

            var obj = object[key];
            for (var prop in obj) {
                // skip loop if the property is from prototype
                if(obj.hasOwnProperty(prop) && prop === field && obj[prop] === value){
                    if(returnData){
                        return obj;
                    }
                    return key;
                }
            }
        }
        return false;
    }

    handleRowClick = (record,event ) => {
        this.setState({
            loading: true
        });

        this.props.history.push('/messages/read/' + record.id);
    }

    markRead = async (id, readStatus) => {
        api.post("messages/read/" + id, {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                status: readStatus
            })
        })
        .then(data => {
            if(typeof data.result === 'object' && data.result.saved){
                //Reload Messages and Update Pagination/Left Menu
                this.handleTableChange(this.state.pagination);
            }else{
                //@todo: Uncheck box and show error
            }
        });
    };

    markSelectedAsRead = () => {
        api.post("messages/read", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                messages: this.state.selectedRows
            })
        })
        .then(data => {
            if(typeof data.result === 'object' && data.result.saved){
                //Reload Messages and Update Pagination/Left Menu
                this.handleTableChange(this.state.pagination);
                document.querySelectorAll('input:checked').forEach((item)=>{
                    item.click();
                });
            }else{
                //@todo: Uncheck box and show error
            }
        });
    }

    urgentRowFormat = (record, index) => {
        return {
            className: record.urgent_message && !record.read? "urgent-message": "",
            onClick: event => this.handleRowClick(record, event)
        }
    } 

    columnFormat = [
        {
            title: 'Name',
            dataIndex: 'sender.first_name',
            render: (text, record) =>  {
                return (
                    <div className={"small-message-content " + (record.read  ? "" : "unread")}>
                        {(record.sender !== null ? text + " " +  record.sender.last_name : "")}
                    </div>
                );
            }
        },
        {
            title: 'Message',
            dataIndex: 'subject',
            render: (text, record) =>  {
                return (
                        <div className={"small-message-content " + (record.read  ? "" : "unread")}>
                            <span className={record.completed ? "done" : ""}>
                                {record.urgent_message &&
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="red-icon" />
                                }
                                {text} 
                            </span>
                            <span style={{color: 'rgba(0, 0, 0, 0.42)', marginLeft: "0.5em"}} >
                            {record.content}
                            </span>
                        </div>
                );
            }
        },
        {
            title: 'Date',
            dataIndex: 'created',
            width: 125,
            render: (text) =>  {
                return (
                    <span>
                        <span className="datebubble">
                            {moment(text).format("DD/MM/YYYY")}
                        </span>
                    </span>
                );
            }
        },
        {
            title: 'Read',
            key: 'read',
            width: 75,
            render: (text, record) => {
                if (text.read === true){
                    return (
                        <span onClick = {(e) => {e.stopPropagation()}}>
                            <Popconfirm title="Mark this as Unread?" className = "no-forward" onConfirm={() => this.markRead(record.id, "unread")}>
                                <FontAwesomeIcon icon={faEnvelopeOpen} className="no-forward" />
                            </Popconfirm>
                        </span>
                    );
                } else {
                    return (
                        <span onClick = {(e) => {e.stopPropagation()}}>
                            <Popconfirm title="Mark this as Read?" className = "no-forward" onConfirm={() => this.markRead(record.id, "read")}>
                                <FontAwesomeIcon icon={faEnvelope} className="no-forward" />
                            </Popconfirm>
                        </span>
                    );
                }
                
            }
        }
    ];

    handleTableChange = (pagination, filters) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            ...filters
        });
    };

    fetchUrgent = () => {
        this.setState({
            loading: true,
            viewFilter: "urgent"
        }, () => {
            this.fetch();
        });
    }
    fetchUnread = () => {
        this.setState({
            loading: true,
            viewFilter: "unread"
        }, () => {
            this.fetch();
        });
    }
    fetchAll = () => {
        this.setState({
            loading: true,
            viewFilter: "all"
        }, () => {
            this.fetch();
        });
    }
    fetch = (params = {}) => {
        this.setState({ loading: true });

        let data = {
            results: 10,
            chosen_driver: this.props.chosen_driver,
            filter: this.state.viewFilter,
            ...params
        };
        if (this.state.searchTerm !== null) {
            data.term = this.state.searchTerm;
            if (this.state.searchExact) {
                data.exact = true;
            }
        }

        api.post("messages/fetch", {
            body: JSON.stringify(data)
        })
        .then(data => {
            const pagination = { ...this.state.pagination };
            pagination.total = data.result.totalCount;
            this.setState({
                loading: false,
                data: data.result.messages,
                pagination
            });
            //Set state for count so that left menu is updated
            this.props.setAppStateValue('counts', {
                messages: data.result.unreadCount,
                urgent: data.result.urgentCount
            });
        });
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRows: selectedRowKeys });
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['search_term'], (err, values) => {
            if (!err) {
                this.setState({
                    loading: true,
                    searchModalVisible: false,
                    searchTerm: values.search_term,
                    searchExact: this.state.searchExact
                }, () => {
                    this.fetch();
                });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <>
                <div className="space-evenly">
                    {/* Hidden 21/05/2024 as per TDS-822
                    <Link to={"/messages/new"}>
                        <Button
                            type="danger"
                        >
                            <FontAwesomeIcon icon={faPlusSquare} />New Message
                        </Button>
                    </Link>
                    */}
                    <Button
                        type="primary"
                        onClick={() => {
                            this.setState({
                                searchModalVisible: true
                            });
                        }}
                    >
                        <FontAwesomeIcon icon={faSearch} />Search
                    </Button>
                    <Popconfirm title="Mark Selected Messages as Read?" onConfirm={() => this.markSelectedAsRead()}>
                        <Button
                            type="primary"
                        >
                            <FontAwesomeIcon icon={faCheckSquare} />Mark Selected as Read
                        </Button>
                    </Popconfirm>
                    {!(this.state.viewFilter === "unread") && (
                        <Button
                            type="primary"
                            onClick= {this.fetchUnread}
                        >
                            <FontAwesomeIcon icon={faEnvelope} />View Unread
                        </Button>
                    )}
                    {!(this.state.viewFilter === "all") && (
                        <Button
                            type="primary"
                            onClick={this.fetchAll}
                        >
                            <FontAwesomeIcon icon={faEnvelopeOpen} />View All
                        </Button>
                    )}
                    {!(this.state.viewFilter === 'urgent') && (
                        <Button
                            type="primary"
                            onClick={this.fetchUrgent}
                        >
                            <FontAwesomeIcon icon={faExclamationTriangle} />View Urgent
                        </Button>
                    )}
                </div>
                {this.state.searchTerm !== null && (
                    <p>
                        Current Search: {(this.state.searchExact ? '"' : '') + this.state.searchTerm + (this.state.searchExact ? '"' : '')} [
                            <Link
                                to={() => {
                                    return false;
                                }}
                                onClick={() => {
                                    this.setState({
                                        loading: true,
                                        searchTerm: null,
                                        searchExact: false
                                    }, () => {
                                        this.fetch();
                                    });
                                }}
                            >
                                x
                            </Link>
                        ]
                    </p>
                )}
                <Table
                    rowClassName="messages-table-row"
                    size="middle"
                    showHeader={false}
                    columns={this.columnFormat}
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={{spinning: this.state.loading, indicator: <Spinner type="mini" />}}
                    onChange={this.handleTableChange}
                    onRow={this.urgentRowFormat}
                    rowSelection={{onChange: this.onSelectChange, onSelect: (record,selected,rows,e) => {e.stopPropagation()} }}
                    style={{ tableLayout: "fixed" }}
                />

                <Modal
                    destroyOnClose={true}
                    className="new-address-modal"
                    visible={this.state.searchModalVisible}
                    title="Search"
                    footer={[
                        <Button
                            key="back"
                            onClick={() => {
                                this.setState({
                                    searchModalVisible: false
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faBan} />Cancel
                        </Button>,
                        <Button
                            form="searchForm"
                            key="submit"
                            htmlType="submit"
                            type="primary"
                            loading={this.state.loading}
                            disabled={this.state.loading}
                        >
                            <FontAwesomeIcon icon={faSearch} />Search
                        </Button>
                    ]}
                >
                    <Form
                        id="searchForm"
                        onSubmit={this.handleSearch}
                        layout='horizontal'
                    >
                        <Form.Item label={false} >
                            {getFieldDecorator('search_term', {
                                rules: [
                                    { required: true, message: 'Please enter a Search Term.' }
                                ],
                                initialValue: this.state.searchTerm
                            })(
                                <Input
                                    required="required"
                                    autoFocus={true}
                                />
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Exact Search?"
                            colon={false}
                        >
                            <Switch
                                defaultChecked={this.state.searchExact}
                                onChange={() => {
                                    this.setState({
                                        searchExact: !this.state.searchExact
                                    });
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
};

const SearchForm = Form.create({name: 'search_form'})(MessagesTable);

export default withRouter(SearchForm);
