import React from 'react';
import { Row, Col, Card, DatePicker, Button, Tooltip } from 'antd';
import api from '../../helpers/api';
import Spinner from '../elements/Spinner';

import IndividualPerformance from '../elements/reports/IndividualPerformance';
import InactiveStudent from '../elements/reports/InactiveStudent';

const { RangePicker} = DatePicker;

class Reports extends React.Component {

    state = {
        showPerformance: false,
        showInactive: false,
        daterange: [],
        startDate: null,
        endDate: null
    };

    individualPerformanceClicked = () => {
        this.fetchIndividual();
    }
    
    inactiveStudentClicked = () => {
        this.fetchInactive();
    }

    dateRangeUpdate = (dates, datestrings) => {
        let startDate = dates[0];
        let endDate = dates[1];
        this.setState({startDate: startDate, endDate:endDate, update: true})
    }

    fetchInactive = () => {
        this.setState({ 
            loading: true,
            showPerformance: false, 
            showInactive: true,
        });

        api.post("reports/inactive",{
            body: JSON.stringify({
                start_date: this.state.startDate,
                end_date: this.state.endDate,
                chosen_driver: parseInt(localStorage.getItem('chosen_driver')),
            })
        })
        .then(data => {
            console.log(data);
            let results = data.result.inactive_stats;
            this.setState({
                loading: false,
                results,
            });
        });
    };

    fetchIndividual = () => {
        this.setState({ 
            loading: true,
            showPerformance: true,
            showInactive: false
        });
        api.post("reports/individual",{
            body: JSON.stringify({
                start_date: this.state.startDate.format("YYYY-MM-DD"),
                end_date: this.state.endDate.format("YYYY-MM-DD"),
                chosen_driver: parseInt(localStorage.getItem('chosen_driver')),
            })
        })
        .then(data => {
            let results = data.result.stats;
            //Set state for count so that left menu is updated
            this.setState({
                loading: false,
                results,
            });
        });
    };

    render(){
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                <Card size="small" title="Reports" >
                        <div>
                            <div>
                                <RangePicker onChange={this.dateRangeUpdate}/>
                                <div className="space-evenly">
                                {!(this.state.startDate && this.state.endDate) &&
                                    <>
                                        <Tooltip placement="top" title="Please set start and end dates">
                                            <Button
                                                type="primary"
                                                shape="round"
                                                icon="check-square"
                                                onClick={this.individualPerformanceClicked}
                                                disabled={!(this.state.startDate && this.state.endDate)}
                                            >Individual Performance Report</Button>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Please set start and end dates">
                                            <Button
                                                type="primary"
                                                shape="round"
                                                icon="inbox"
                                                onClick={this.inactiveStudentClicked}
                                                disabled={!(this.state.startDate && this.state.endDate)}
                                            >Inactive Student Report</Button>
                                        </Tooltip>
                                    </>
                                }
                                {(this.state.startDate && this.state.endDate) &&
                                    <>
                                        <Button
                                            type="primary"
                                            shape="round"
                                            icon="check-square"
                                            onClick={this.individualPerformanceClicked}
                                            disabled={!(this.state.startDate && this.state.endDate)}
                                        >Individual Performance report</Button>
                                        
                                        <Button
                                            type="primary"
                                            shape="round"
                                            icon="inbox"
                                            onClick={this.inactiveStudentClicked}
                                            disabled={!(this.state.startDate && this.state.endDate)}

                                        >Inactive Student report</Button>
                                    </>
                                }
                                </div>
                            </div>
                            <div>
                                {this.state.loading &&
                                    <Spinner type="mega" />
                                }
                                {!this.state.loading && this.state.showPerformance && 
                                    <IndividualPerformance
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        report={this.state.results}
                                    />
                                }
                                {!this.state.loading && this.state.showInactive && 
                                    <InactiveStudent
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        report={this.state.results}
                                    />
                                }
                                </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        );
    }
};

export default Reports;