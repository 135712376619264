import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import config from './config';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

const { isProduction, gaCode } = config;

if(isProduction){
    // Google Analytics
    ReactGA.initialize(gaCode);

    // Sentry
    Sentry.init({
        dsn: 'https://8f3fe6c2c1624fa485811b806ee0f4e2@sentry.twopicode.com/75'
    });
}

ReactDOM.render(<App />, document.getElementById('root'));