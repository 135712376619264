import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import SignaturePad from 'react-signature-canvas';
import { Link } from 'react-router-dom';
import { Alert, Row, Col, Form, Input, Table, Button, Modal, Checkbox, Select, DatePicker, InputNumber } from 'antd';
import api from '../../helpers/api';
import moment from 'moment';
import Spinner from '../elements/Spinner';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEnvelope, faMapMarkedAlt, faMinus, faPhoneAlt, faUserFriends, faUserPlus, faUserMinus, faEraser, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faSave } from '@fortawesome/free-regular-svg-icons';

const { TextArea } = Input;
const { Column } = Table;
const { Option } = Select;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

let suburbLookupTimeout;
let currentSuburb;

class Event extends React.Component {

    state = {
        event_id: null,
        event: null,
        loading: true,
        attendees: null,
        mod1students: null,
        mod2students: null,
        mod2studentBreakdown: null,
        mod2studentBreakdownData: null,
        mod1studentsWithoutMod2: null,
        potentialEnrollees: null,
        mod2: [],
        sitins: null,
        edit: false,
        Restricted_SDCDigitalPaperwork: false,
        // Module 1 Remove Modal
        mod1RemoveModalVisible: false,
        mod1RemoveModalLoading: false,
        mod1RemoveModalStudentId: null,
        // Module 2 Enrol Modal
        mod2EnrolModalVisible: false,
        mod2EnrolModalLoading: false,
        mod2EnrolModalMod2Id: null,
        mod2EnrolModalMod2Capacity: null,
        // Module 2 Remove Modal
        mod2RemoveModalVisible: false,
        mod2RemoveModalLoading: false,
        mod2RemoveModalMod2Id: null,
        // DT Signoff Modal
        dtSignoffModalVisible: false,
        dtSignoffModalLoading: true,
        dtSignoffModalError: null,
        dtSignoffModalData: null,
        dtSignoffModalSignedIn: [],
        dtSignoffStudentAddresses: [],
        //Signature
        signature: null,
        bigSigModalVisible: false,
        signatureExists: false,
        //Address
        newAddressRecordId: null,
        newAddressStudentId: null,
        newAddressModalVisible: false,
        newAddressModalLoading: false,
        suburbs: [],
        autoCompleteDisabled: false
    };

//    addressRef = React.createRef();
    addressRef = [];

    static getDerivedStateFromProps(nextProps, prevState) {
        if (typeof (prevState.event) !== 'undefined' && prevState.event !== null && parseInt(nextProps.match.params.id) !== prevState.event_id){
            return {
                loading: true,
                event_id: parseInt(nextProps.match.params.id)
            };
        }
        return null;
    };

    componentDidMount() {
        if(this.state.event_id === null){
            this.setState({event_id: parseInt(this.props.match.params.id)}, this.fetchEvent);
        }
    };
    
    componentDidUpdate(prevProps, prevState){
        if(parseInt(this.props.chosen_driver) !== parseInt(prevProps.chosen_driver)){
            this.fetchEvent();
        }
    }

    fetchEvent = async() => {
        await api.post("events/fetch/" + this.state.event_id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.event === 'undefined' || res.result.event === null) {
                    this.props.history.push('/diary');
                } else {
                    // If this is NOT an EVENT - Redirect here.
                    if (res.result.event.booking_type_id !== 7 && res.result.event.booking_type_id !== 8 && res.result.event.booking_type_id !== 9) {
                        return this.props.history.push('/lesson/' + this.state.event_id);
                    }

                    this.setState({
                        event: res.result.event,
                        loading: false,
                        attendees: (typeof res.result.attendees !== 'undefined' ? res.result.attendees : null),
                        mod1students: (typeof res.result.mod1students !== 'undefined' ? res.result.mod1students : null),
                        mod2students: (typeof res.result.mod2students !== 'undefined' ? res.result.mod2students : null),
                        mod2studentBreakdown: (typeof res.result.mod2studentBreakdown !== 'undefined' ? res.result.mod2studentBreakdown : null),
                        mod2studentBreakdownData: (typeof res.result.mod2studentBreakdownData !== 'undefined' ? res.result.mod2studentBreakdownData : null),
                        mod1studentsWithoutMod2: (typeof res.result.mod2students !== 'undefined' ? res.result.mod1students.filter(x => !res.result.mod2students.includes(x)) : null),
                        mod2: (typeof res.result.mod2 !== 'undefined' ? Object.values(res.result.mod2) : []),
                        sitins: (typeof res.result.sitins !== 'undefined' ? res.result.sitins : []),
                        edit: (res.result.edit ? true : false),
                        Restricted_SDCDigitalPaperwork: (res.result.Restricted_SDCDigitalPaperwork ? true : false)
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/diary');
        });
    };

    disableAutoComplete = () => {
        if (!this.state.autoCompleteDisabled) {
            const elementsToDisable = document.getElementsByClassName("ant-select-search__field");
            for (let i = 0; i < elementsToDisable.length; i++) {
                elementsToDisable[i].setAttribute("autocomplete","any-arbitrary-text");
            }
            this.setState({ autoCompleteDisabled: true });
        }
    };

    handleSuburbSearch = value => {
        this.suburbLookup(value, suburbs => this.setState({ suburbs }));
    };

    suburbLookup = (value, callback) => {
        if (suburbLookupTimeout) {
            clearTimeout(suburbLookupTimeout);
            suburbLookupTimeout = null;
        }
        currentSuburb = value;

        function debouncedLookup() {
            api.post("suburbs/search", {
                body: JSON.stringify({
                    search: value
                })
            })
            .then(d => {
                if (currentSuburb === value) {
                    const data = [];
                    d.result.suburbs.forEach(r => {
                        data.push({
                            value: r.id,
                            text: r.suburb_name
                        });
                    });
                    callback(data);
                }
            });
        }
        suburbLookupTimeout = setTimeout(debouncedLookup, 300);
    }

    showMod2EnrolModal = (id, capacity) => {
        // Build our Table of Potential Enrollees
        let potentialEnrollees = [];
        for (var key in this.state.mod1studentsWithoutMod2) {
            potentialEnrollees.push(this.state.attendees[this.state.mod1studentsWithoutMod2[key]]);
        }

        // Check to see if we have any Mod2 students from outside this Mod1 who have been removed from their Mod2 here.
        let orphans = JSON.parse(localStorage.getItem('orphans')) || {};
        if(typeof(orphans[this.state.event_id]) !== 'undefined') {
            Object.values(orphans[this.state.event_id]).forEach((item) => {
                potentialEnrollees.push(item.user);
            });
        }

        this.setState({
            potentialEnrollees: potentialEnrollees,
            mod2EnrolModalMod2Id: id,
            mod2EnrolModalMod2Capacity: capacity,
            mod2EnrolModalVisible: true
        });
    };

    mod2EnrolModalHandleCancel = () => {
        this.setState({
            potentialEnrollees: null,
            mod2EnrolModalMod2Id: null,
            mod2EnrolModalMod2Capacity: null,
            mod2EnrolModalVisible: false,
            mod2EnrolModalLoading: false
        });
    };

    mod2EnrolModalHandleOk = async() => {
        //Count checked boxes and compare to capacity.
        let checked = document.querySelectorAll('input[name="mod2EnrolCheckbox"]:checked');
        if(checked.length === 0) {
            Modal.error({
                title: "No Students Selected",
                content: (
                    <div>
                        <p>You must select a Student to Enrol.</p>
                    </div>
                )
            });
        } else if(checked.length > this.state.mod2EnrolModalMod2Capacity) {
            Modal.error({
                title: "Too Many Students",
                content: (
                    <div>
                        <p>Please select less Students and try again.</p>
                    </div>
                )
            });
        } else {
            this.setState({
                mod2EnrolModalLoading: true
            }, async () => {
                let enrollingStudents = [];
                checked.forEach((x)=>{
                    enrollingStudents.push(x.getAttribute('data-studentid'));
                });

                await api.post("events/sdc/enrol", {
                        body: JSON.stringify({
                            chosen_driver: this.props.chosen_driver,
                            module_1: this.state.event_id,
                            module_2: this.state.mod2EnrolModalMod2Id,
                            students: enrollingStudents,
                            enquiry_location: 'diary'
                        })
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }

                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK' || typeof res.result.success === 'undefined' || res.result.success === false) {
                            //Reload data, in case it's changed elsewhere
                            Modal.error({
                                title: "Error",
                                content: (
                                    <div>
                                        <p>An error occurred when attempting to Enrol students.</p>
                                        <p>Clicking OK will reload this Event, and you can try again.</p>
                                        <p>If the problem persists, please contact the Office.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        loading: true,
                                        mod2EnrolModalVisible: false,
                                        mod2EnrolModalLoading: false
                                    }, () => {
                                        this.fetchEvent();
                                    });
                                }
                            });
                        } else {
                            Modal.success({
                                title: "Enrolment Successful!",
                                content: (
                                    <div>
                                        <p>{enrollingStudents.length > 1 ? "Students have" : "Student has"} been successfully enrolled.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    // Check if any additions were 'orphan' Mod2 students, remove them from localStorage
                                    let orphans = JSON.parse(localStorage.getItem('orphans')) || {};
                                    if(typeof(orphans[this.state.event_id]) !== 'undefined') {
                                        enrollingStudents.forEach((item) => {
                                            if(typeof(orphans[this.state.event_id][item]) !== 'undefined') {
                                                delete orphans[this.state.event_id][item];
                                            }
                                        });
                                        localStorage.setItem('orphans', JSON.stringify(orphans));
                                    }

                                    this.setState({
                                        loading: true,
                                        mod2EnrolModalVisible: false,
                                        mod2EnrolModalLoading: false
                                    }, () => {
                                        this.fetchEvent();
                                    });
                                }
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        //Reload data, in case it's changed elsewhere
                        Modal.error({
                            title: "Error",
                            content: (
                                <div>
                                    <p>An error occurred when attempting to Enrol students.</p>
                                    <p>Clicking OK will reload this Event, and you can try again.</p>
                                    <p>If the problem persists, please contact the Office.</p>
                                </div>
                            ),
                            onOk: () => {
                                this.setState({
                                    loading: true,
                                    mod2EnrolModalVisible: false,
                                    mod2EnrolModalLoading: false
                                }, () => {
                                    this.fetchEvent();
                                });
                            }
                        });
                });
            });
        }
    };

    mod2EnrolModalAddSitIn = async() => {
        this.setState({
            mod2EnrolModalLoading: true
        }, async () => {
            await api.post("events/sdc/sitin", {
                    body: JSON.stringify({
                        chosen_driver: this.props.chosen_driver,
                        module_1: this.state.event_id,
                        module_2: this.state.mod2EnrolModalMod2Id,
                        enquiry_location: 'diary'
                    })
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if(typeof res === 'string'){
                        res = JSON.parse(res);
                    }

                    //Check for an error response (status of "NOK")
                    if(res.status === 'NOK' || typeof res.result.success === 'undefined' || res.result.success === false) {
                        //Reload data, in case it's changed elsewhere
                        Modal.error({
                            title: "Error",
                            content: (
                                <div>
                                    <p>An error occurred when attempting to add a Sit-In Student.</p>
                                    <p>Clicking OK will reload this Event, and you can try again.</p>
                                    <p>If the problem persists, please contact the Office.</p>
                                </div>
                            ),
                            onOk: () => {
                                this.setState({
                                    loading: true,
                                    mod2EnrolModalVisible: false,
                                    mod2EnrolModalLoading: false
                                }, () => {
                                    this.fetchEvent();
                                });
                            }
                        });
                    } else {
                        Modal.success({
                            title: "Enrolment Successful!",
                            content: (
                                <div>
                                    <p>Sit-In Student has been successfully added.</p>
                                </div>
                            ),
                            onOk: () => {
                                this.setState({
                                    loading: true,
                                    mod2EnrolModalVisible: false,
                                    mod2EnrolModalLoading: false
                                }, () => {
                                    this.fetchEvent();
                                });
                            }
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    //Reload data, in case it's changed elsewhere
                    Modal.error({
                        title: "Error",
                        content: (
                            <div>
                                <p>An error occurred when attempting to add a Sit-In Student.</p>
                                <p>Clicking OK will reload this Event, and you can try again.</p>
                                <p>If the problem persists, please contact the Office.</p>
                            </div>
                        ),
                        onOk: () => {
                            this.setState({
                                loading: true,
                                mod2EnrolModalVisible: false,
                                mod2EnrolModalLoading: false
                            }, () => {
                                this.fetchEvent();
                            });
                        }
                    });
                });
        });
    };

    showMod2RemoveModal = (id) => {
        //Check that there is more than Zero students in this Module 2, else show error
        if (typeof(this.state.mod2studentBreakdown[id]) !== 'undefined' && this.state.mod2studentBreakdown[id].length === 0) {
            Modal.error({
                title: "No Students Available",
                content: (
                    <div>
                        <p>There are no students in this Module 2.</p>
                    </div>
                )
            });
        } else {
            // Build our Table of Potential Enrollees
            let potentialEnrollees = [];
            for (var key in this.state.mod2studentBreakdown[id]) {
                if (typeof this.state.attendees[this.state.mod2studentBreakdown[id][key]] === 'undefined') {
                    potentialEnrollees.push(this.state.mod2studentBreakdownData[id][this.state.mod2studentBreakdown[id][key]].user);
                } else {
                    potentialEnrollees.push(this.state.attendees[this.state.mod2studentBreakdown[id][key]]);
                }
            }
            if (this.state.sitins[id] > 0) {
                let sitin = {};
                sitin.first_name = 'Student Sit-In';
                sitin.last_name = '';
                sitin.address = {};
                sitin.address.suburb = {};
                sitin.address.suburb.suburb_name = '';
                sitin.id = 'sitin';
                potentialEnrollees.push(sitin);
            }

            this.setState({
                potentialEnrollees: potentialEnrollees,
                mod2RemoveModalMod2Id: id,
                mod2RemoveModalVisible: true
            });
        }
    };

    mod2RemoveModalHandleCancel = () => {
        this.setState({
            potentialEnrollees: null,
            mod2RemoveModalMod2Id: null,
            mod2RemoveModalVisible: false,
            mod2RemoveModalLoading: false
        });
    };

    mod2RemoveModalHandleOk = async() => {
        //Count checked boxes and compare to capacity.
        let checked = document.querySelectorAll('input[name="mod2RemoveCheckbox"]:checked');
        if(checked.length === 0) {
            Modal.error({
                title: "No Students Selected",
                content: (
                    <div>
                        <p>You must select a Student to Remove.</p>
                    </div>
                )
            });
        } else {
            this.setState({
                mod2RemoveModalLoading: true
            }, async () => {
                let removingStudents = [];
                checked.forEach((x)=>{
                    removingStudents.push(x.getAttribute('data-studentid'));
                });
                
                await api.post("events/sdc/remove", {
                        body: JSON.stringify({
                            chosen_driver: this.props.chosen_driver,
                            module_1: this.state.event_id,
                            module_2: this.state.mod2RemoveModalMod2Id,
                            students: removingStudents,
                            enquiry_location: 'diary'
                        })
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }

                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK' || typeof res.result.success === 'undefined' || res.result.success === false) {
                            //Reload data, in case it's changed elsewhere
                            Modal.error({
                                title: "Error",
                                content: (
                                    <div>
                                        <p>An error occurred when attempting to Remove students.</p>
                                        <p>Clicking OK will reload this Event, and you can try again.</p>
                                        <p>If the problem persists, please contact the Office.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        loading: true,
                                        mod2RemoveModalVisible: false,
                                        mod2RemoveModalLoading: false
                                    }, () => {
                                        this.fetchEvent();
                                    });
                                }
                            });
                        } else {
                            Modal.success({
                                title: "Removal Successful!",
                                content: (
                                    <div>
                                        <p>{removingStudents.length > 1 ? "Students have" : "Student has"} been successfully removed.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    // Check to see if any of these students were from a different Module 1 - they won't be in our attendee list
                                    removingStudents.forEach((item) => {
                                        if (!this.state.attendees.hasOwnProperty(item)){
                                            // Get the Student Data and Store in localStorage (we need it after a page reload)
                                            let orphans = JSON.parse(localStorage.getItem('orphans')) || {};
                                            if(typeof(orphans[this.state.event_id]) === 'undefined') {
                                                orphans[this.state.event_id] = {};
                                            }
                                            orphans[this.state.event_id][item] = this.state.mod2studentBreakdownData[this.state.mod2RemoveModalMod2Id][item];
                                            localStorage.setItem('orphans', JSON.stringify(orphans));
                                        }
                                    });

                                    this.setState({
                                        loading: true,
                                        mod2RemoveModalVisible: false,
                                        mod2RemoveModalLoading: false
                                    }, () => {
                                        this.fetchEvent();
                                    });
                                }
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        //Reload data, in case it's changed elsewhere
                        Modal.error({
                            title: "Error",
                            content: (
                                <div>
                                    <p>An error occurred when attempting to Remove students.</p>
                                    <p>Clicking OK will reload this Event, and you can try again.</p>
                                    <p>If the problem persists, please contact the Office.</p>
                                </div>
                            ),
                            onOk: () => {
                                this.setState({
                                    loading: true,
                                    mod2RemoveModalVisible: false,
                                    mod2RemoveModalLoading: false
                                }, () => {
                                    this.fetchEvent();
                                });
                            }
                        });
                });
            });
        }
    };

    showMod1RemoveModal = (student_id) => {
        this.setState({
            mod1RemoveModalStudentId: student_id,
            mod1RemoveModalVisible: true
        });
    };

    mod1RemoveModalHandleCancel = () => {
        this.setState({
            mod1RemoveModalStudentId: null,
            mod1RemoveModalVisible: false,
            mod1RemoveModalLoading: false
        });
    };

    mod1RemoveModalHandleOk = async() => {
        this.props.form.validateFields(['reason'], (err, values) => {
            if (!err) {
                
                this.setState({
                    mod1RemoveModalLoading: true
                }, async () => {

                    await api.post("events/sdc/unenrol", {
                            body: JSON.stringify({
                                chosen_driver: this.props.chosen_driver,
                                module_1: this.state.event_id,
                                student_id: this.state.mod1RemoveModalStudentId,
                                reason: values.reason,
                                enquiry_location: 'diary'
                            })
                        })
                        .then(res => {
                            //Convert to JSON in case we've received a string response
                            if(typeof res === 'string'){
                                res = JSON.parse(res);
                            }

                            //Check for an error response (status of "NOK")
                            if(res.status === 'NOK' || typeof res.result.success === 'undefined' || res.result.success === false) {
                                //Reload data, in case it's changed elsewhere
                                Modal.error({
                                    title: "Error",
                                    content: (
                                        <div>
                                            <p>An error occurred when attempting to Unenrol the student.</p>
                                            <p>Clicking OK will reload this Event, and you can try again.</p>
                                            <p>If the problem persists, please contact the Office.</p>
                                        </div>
                                    ),
                                    onOk: () => {
                                        this.setState({
                                            loading: true,
                                            mod1RemoveModalVisible: false,
                                            mod1RemoveModalLoading: false
                                        }, () => {
                                            this.fetchEvent();
                                        });
                                    }
                                });
                            } else {
                                Modal.success({
                                    title: "Removal Successful!",
                                    content: (
                                        <div>
                                            <p>Student has been successfully unenrolled.</p>
                                        </div>
                                    ),
                                    onOk: () => {
                                        this.setState({
                                            loading: true,
                                            mod1RemoveModalVisible: false,
                                            mod1RemoveModalLoading: false
                                        }, () => {
                                            this.fetchEvent();
                                        });
                                    }
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            //Reload data, in case it's changed elsewhere
                            Modal.error({
                                title: "Error",
                                content: (
                                    <div>
                                        <p>An error occurred when attempting to Unenrol the student.</p>
                                        <p>Clicking OK will reload this Event, and you can try again.</p>
                                        <p>If the problem persists, please contact the Office.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        loading: true,
                                        mod1RemoveModalVisible: false,
                                        mod1RemoveModalLoading: false
                                    }, () => {
                                        this.fetchEvent();
                                    });
                                }
                            });
                    });
                });
            }
        });
    };

    showDtSignoffModal = async() => {
        this.setState({
            dtSignoffModalData: null,
            dtSignoffModalSignedIn: [],
            dtSignoffModalError: null,
            dtSignoffModalVisible: true,
            dtSignoffModalLoading: true
        }, async () => {
            await api.post("events/sdc/signoff/" + this.state.event_id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.sdc_attendance === 'undefined' || res.result.sdc_attendance === null) {
                    this.setState({
                        dtSignoffModalError: 'Unable to fetch Attendee Data. Please try again.',
                        dtSignoffModalLoading: false
                    });
                } else {
                    // Format the Addresses
                    const addresses = [];
                    Object.values(res.result.addresses).forEach((studentAddresses) => {
                        const thisStudentAddresses = [];
                        studentAddresses.all.forEach((address) => {
                            thisStudentAddresses.push(<Option key={address.id} value={address.id}>{address.address}</Option>);
                        });
                        addresses["student_" + studentAddresses.student_id] = [];
                        addresses["student_" + studentAddresses.student_id].push(thisStudentAddresses);
                    });
                    this.setState({
                        dtSignoffStudentAddresses: addresses,
                        dtSignoffModalData: res.result.sdc_attendance,
                        dtSignoffModalSignedIn: res.result.signed_in,
                        dtSignoffModalError: null,
                        dtSignoffModalLoading: false,
                        signatureExists: res.result.signature
                    });
                }
            })
            .catch(error => {
                this.setState({
                    dtSignoffModalError: 'Unable to fetch Attendee Data. Please try again..',
                    dtSignoffModalLoading: false
                });
            });
        });
    };

    dtSignoffModalHandleCancel = () => {
        this.setState({
            dtSignoffModalVisible: false
        });
    };

    dtSignoffModalHandleOk = (e) => {
        let fieldList = [];
        document.querySelectorAll("#dtSignoffForm input[id^='lesson_form_id_']").forEach((input) => {
            fieldList.push("first_name_" + input.value);
            fieldList.push("last_name_" + input.value);
            fieldList.push("address_" + input.value);
            fieldList.push("licence_number_" + input.value);
            fieldList.push("licence_expiry_" + input.value);
            fieldList.push("driving_experience_" + input.value);
        });
        
        // Signature required - we don't worry about how many checkboxes are ticked, in case they aren't doing all?
        e.preventDefault();
        this.props.form.validateFields(fieldList, (err, values) => {
            if (!err) {
                if (this.state.signature === null && !this.state.signatureExists) {
                    Modal.error({
                        title: "Signature Required",
                        content: (
                            <div>
                                <p>You must provide a signature to confirm that student details are correct.</p>
                            </div>
                        )
                    });
                    return false;
                }

                this.setState({
                    dtSignoffModalLoading: true
                }, async () => {
                    let checked_boxes = {};
                    document.querySelectorAll('input[name="dtConfirmCheckbox[]"]').forEach((input) => {
                        checked_boxes[input.dataset.studentid] = input.checked;
                    });
                    values.confirmation = checked_boxes;
                    await api.post("events/attendance/" + this.state.event_id + "/signoff", {
                        body: JSON.stringify({
                            chosen_driver: this.props.chosen_driver,
                            event_id: this.state.event_id,
                            data: values,
                            signature: this.state.signature
                        })
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }
                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK' || typeof res.result.success === 'undefined' || res.result.success === false) {
                            Modal.error({
                                title: "Error",
                                content: (
                                    <div>
                                        <p>An error occurred when attempting to Save your Confirmation.</p>
                                        {(typeof res.result.error !== 'undefined' ? <p>{res.result.error}</p> : null)}
                                        <p>Please try again, or contact the office if the problem persists.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        dtSignoffModalLoading: false
                                    });
                                }
                            });
                        } else {
                            Modal.success({
                                title: "Saved!",
                                content: (
                                    <div>
                                        <p>Thank you for confirming Student Attendance/Details.</p>
                                    </div>
                                ),
                                onOk: () => {
                                    this.setState({
                                        dtSignoffModalLoading: false,
                                        dtSignoffModalVisible: false
                                    });
                                }
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        Modal.error({
                            title: "Error",
                            content: (
                                <div>
                                    <p>An error occurred when attempting to Save your Confirmation.</p>
                                    <p>Please try again, or contact the office if the problem persists.</p>
                                </div>
                            ),
                            onOk: () => {
                                this.setState({
                                    dtSignoffModalLoading: false
                                });
                            }
                        });
                    });

                });
            }
        });
    };

    newAddressModalHandleCancel = (e) => {
        this.setState({
            newAddressModalVisible: false
        }, () => {
            if(this.addressRef["student_" + this.state.newAddressStudentId].props.value === 'new'){
                this.props.form.resetFields(['address_' + this.state.newAddressRecordId]);
            }
        });
    };

    newAddressModalHandleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['street_number', 'street_name', 'suburb_id'], (err, values) => {
            if (!err) {
                this.setState({newAddressModalLoading: true});
                //save to DB and then add to options
                values.chosen_driver = this.props.chosen_driver;
                api.post("address/add", {
                    body: JSON.stringify(values)
                })
                .then(res => {
                    let addressData = this.state.dtSignoffStudentAddresses;
                    addressData["student_" + this.state.newAddressStudentId].push(<Option key={res.result.id}>{res.result.address}</Option>);
                    this.setState({
                        dtSignoffStudentAddresses: addressData
                    },() => {
                        if(this.addressRef["student_" + this.state.newAddressStudentId].props.value === 'new'){
                            this.props.form.setFieldsValue({
                                ["address_" + this.state.newAddressRecordId]: [res.result.id.toString()]
                            });
                        }
                        // Close this modal.
                        this.setState({
                            newAddressModalLoading: false,
                            newAddressModalVisible: false,
                            newAddressStudentId: null,
                            newAddressRecordId: null
                        });
                    });
                });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const suburbs = this.state.suburbs.map(d => <Option key={d.value}>{d.text}</Option>);
        const formItemLayout = {
            labelCol: {
                xs: {span: 7},
                xl: {span: 9}
            },
            wrapperCol: {
                xs: {span: 17},
                xl: {span: 15}
            }
        };
        return (
            <Row type="flex" justify="space-around" id="mainBody" className='event-page'>
                <Col span={24}>
                    <h2>Event Details</h2>
                    {
                    this.state.loading ? 
                        (
                            <Spinner type="mega" />
                        ) : (
                            <>
                                <Form {...formItemLayout} id="lessonForm">
                                    <Form.Item label="Event Name" >
                                        {getFieldDecorator('title', {
                                            initialValue: (typeof this.state.event.title !== 'undefined' && this.state.event.title !== null ? this.state.event.title : null)
                                        })(
                                            <Input readOnly={true} />
                                        )}
                                    </Form.Item>
                                    {this.state.attendees === null && (
                                        <Form.Item label="Event Description" >
                                             {getFieldDecorator('event_description', {
                                                 initialValue: (typeof this.state.event.event_description !== 'undefined' && this.state.event.event_description !== null ? this.state.event.event_description : null)
                                             })(
                                                 <TextArea readOnly={true} autoSize={{ minRows: 4, maxRows: 10 }} />
                                             )}
                                        </Form.Item>
                                    )}
                                    {this.state.attendees !== null && (
                                        <>
                                            {this.state.Restricted_SDCDigitalPaperwork &&
                                                <>
                                                    <Button href={"/event/" + this.state.event_id + "/attendance"} target="_blank" id="markAttendance" type="primary" size="large">
                                                        <FontAwesomeIcon icon={faUserFriends} />Student Sign In Sheet
                                                    </Button>
                                                    <Button onClick={() => this.showDtSignoffModal()} type="primary" size="large" style={{marginLeft: '10px'}}>
                                                        <FontAwesomeIcon icon={faUserFriends} />{this.state.event.title === "SDC Module 1" ? "Facilitator" : "Coach"} Confirmation
                                                    </Button>
                                                </>
                                            }
                                            <Form.Item label="Attendees" >
                                                 {getFieldDecorator('attendees', {
                                                 })(
                                                 <>
                                                    <Table
                                                        id="attendeesTable"
                                                        rowKey={record => record.id}
                                                        dataSource={Object.values(this.state.attendees).sort(function (a,b) {
                                                            if (a.first_name < b.first_name) {
                                                                return -1;
                                                            } else if (a.first_name > b.first_name) {
                                                                return 1;
                                                            } else {
                                                                if (a.last_name < b.last_name) {
                                                                    return -1;
                                                                } else if (a.last_name > b.last_name) {
                                                                    return 1;
                                                                } else {
                                                                    return 0;
                                                                }
                                                            }
                                                        })}
                                                        showHeader={false}
                                                        pagination={false}
                                                    >
                                                        <Column
                                                            title="Name"
                                                            key="name"
                                                            render={(text, record) => (
                                                                <span>{record.first_name} {record.last_name}</span>
                                                            )}
                                                        />
                                                        <Column
                                                            title="Mobile"
                                                            key="mobile"
                                                            render={(text, record) => (
                                                                <>
                                                                    {record.mobile_phone !== null && record.mobile_phone !== '' &&
                                                                        <a className="ant-btn ant-btn-dashed" href={"tel:" + this.props.stripPhoneNumber(record.mobile_phone)}>
                                                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                                                        </a>
                                                                    }
                                                                </>
                                                            )}
                                                        />
                                                        <Column
                                                            title="Email"
                                                            key="email"
                                                            render={(text, record) => (
                                                                <>
                                                                    {record.email !== null && record.email !== '' &&
                                                                        <a className="ant-btn ant-btn-dashed" href={"mailto:" + record.email}>
                                                                            <FontAwesomeIcon icon={faEnvelope} />
                                                                        </a>
                                                                    }
                                                                </>
                                                            )}
                                                        />
                                                        {this.state.edit &&
                                                            <Column
                                                                title="Actions"
                                                                key="actions"
                                                                render={(text, record) => (
                                                                    <>
                                                                        <Button type="dashed" onClick={() => this.showMod1RemoveModal(record.id)}>
                                                                            <FontAwesomeIcon icon={faUserMinus} fixedWidth />
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            />
                                                        }
                                                    </Table>
                                                    {this.state.event.title === "SDC Module 2" && this.state.sitins[this.state.event_id] > 0 && (
                                                        <div className="ant-table" id="sitinTable">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span>Sit-In Student</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                    <iframe title="hidden-phone-iframe" name="hidden-phone-iframe" style={{visibility:'hidden',position:'absolute'}}></iframe>
                                                </>
                                            )}
                                            </Form.Item>
                                        </>
                                    )}

                                    <Form.Item label="Location" >
                                        {getFieldDecorator('location', {
                                            initialValue: (typeof this.state.event.location !== 'undefined' && this.state.event.location !== null ? this.state.event.location : null)
                                        })(
                                            <Input readOnly={true} />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Address" >
                                        {getFieldDecorator('address', {
                                            initialValue: (typeof this.state.event.address !== 'undefined' && this.state.event.address !== null ? this.state.event.address : null)
                                        })(
                                            <Input readOnly={true} />
                                        )}
                                        <a target="_blank" rel="noopener noreferrer" href={(((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPad") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) ? "maps://" : "https://") + "maps.google.com.au/?daddr=" + this.state.event.address}>
                                            <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" fixedWidth/>
                                        </a>
                                    </Form.Item>
                                    <Form.Item label="Event Date" >
                                        {getFieldDecorator('start_date', {
                                            initialValue: (typeof this.state.event.start !== 'undefined' && this.state.event.start !== null ? moment(this.state.event.start).format('D/MM/YYYY') : null)
                                        })(
                                            <Input readOnly={true} />
                                        )}
                                        <Link to={"/diary/" + moment(this.state.event.start).format('YYYY-MM-DD')}>
                                            <FontAwesomeIcon icon={faCalendar} size="lg" fixedWidth/>
                                        </Link>
                                    </Form.Item>
                                    <Form.Item label="Time" >
                                        <Input readOnly={true} className="time-input" value={(typeof this.state.event.start !== 'undefined' && this.state.event.start !== null ? moment(this.state.event.start).format('h:mma') : null)} />
                                        <FontAwesomeIcon icon={faMinus} fixedWidth size="lg" style={{marginRight:'10px'}} />
                                        <Input readOnly={true} className="time-input" value={(typeof this.state.event.end !== 'undefined' && this.state.event.end !== null ? moment(this.state.event.end).format('h:mma') : null)} />
                                    </Form.Item>
                                </Form>

                                <Modal
                                    style={{ top: 10 }}
                                    width={'95vw'}
                                    destroyOnClose={true}
                                    className="dt-signoff-modal"
                                    visible={this.state.dtSignoffModalVisible}
                                    title={(this.state.event.title === "SDC Module 1" ? "Facilitator" : "Coach") + " Confirmation"}
                                    closable={false}
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={this.dtSignoffModalHandleCancel}
                                            disabled={this.state.dtSignoffModalLoading}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                        <Button
                                            key="submit"
                                            onClick={this.dtSignoffModalHandleOk}
                                            type="primary"
                                            loading={this.state.dtSignoffModalLoading}
                                            disabled={this.state.dtSignoffModalLoading || this.state.dtSignoffModalError !== null || Object.values(this.state.dtSignoffModalData).length === 0 }
                                        >
                                            <FontAwesomeIcon icon={faSave} />Update &amp; Save
                                        </Button>
                                    ]}
                                >
                                    <Form
                                        id="dtSignoffForm"
                                        onSubmit={this.dtSignoffModalHandleOk}
                                    >
                                        {this.state.dtSignoffModalData === null && this.state.dtSignoffModalLoading && (
                                            <Spinner type="inline" />
                                        )}
                                        {this.state.dtSignoffModalError !== null && (
                                            <Alert key="error" message={this.state.dtSignoffModalError} type='error' banner />
                                        )}
                                        {this.state.dtSignoffModalData !== null && Object.values(this.state.dtSignoffModalData).length === 0 && (
                                            <p>
                                                There is currently no unconfirmed data submitted by Students.<br />
                                                Please check the Sign In sheet to confirm that all students have completed their attendance record.
                                            </p>
                                        )}
                                        {this.state.dtSignoffModalData !== null && Object.values(this.state.dtSignoffModalData).length > 0 && (
                                            <>
                                                <h3>Students Signed In - Awaiting Confirmation</h3>
                                                <p>
                                                    Please confirm that the information supplied by students below is correct.
                                                    You may make any required updates/changes here, but once confirmed, the Student data is finalised.
                                                </p>
                                                <Table
                                                    id="dtSignoffTable"
                                                    rowKey={record => record.id}
                                                    dataSource={Object.values(this.state.dtSignoffModalData).sort(function (a,b) {
                                                        if (a.first_name < b.first_name) {
                                                            return -1;
                                                        } else if (a.first_name > b.first_name) {
                                                            return 1;
                                                        } else {
                                                            if (a.last_name < b.last_name) {
                                                                return -1;
                                                            } else if (a.last_name > b.last_name) {
                                                                return 1;
                                                            } else {
                                                                return 0;
                                                            }
                                                        }
                                                    })}
                                                    pagination={false}
                                                >
                                                    <Column
                                                        title="Name & Address"
                                                        key="name_address"
                                                        render={(text, record) => (
                                                            <>
                                                                <Form.Item label={false}>
                                                                    {getFieldDecorator("id_" + record.id, {
                                                                        initialValue: record.id
                                                                    })(
                                                                        <Input type="hidden" />
                                                                    )}
                                                                </Form.Item>
                                                                <Form.Item label={false}>
                                                                    {getFieldDecorator("student_id_" + record.id, {
                                                                        initialValue: record.student_id
                                                                    })(
                                                                        <Input type="hidden" />
                                                                    )}
                                                                </Form.Item>
                                                                <Form.Item label={false} className='inline'>
                                                                    {getFieldDecorator("first_name_" + record.id, {
                                                                        initialValue: record.first_name,
                                                                        rules: [{ required: true, message: 'Please enter First Name.' }]
                                                                    })(
                                                                        <Input />
                                                                    )}
                                                                </Form.Item>
                                                                <Form.Item label={false} className='inline'>
                                                                    {getFieldDecorator("last_name_" + record.id, {
                                                                        initialValue: record.last_name,
                                                                        rules: [{ required: true, message: 'Please enter Last Name.' }]
                                                                    })(
                                                                        <Input />
                                                                    )}
                                                                </Form.Item>
                                                                <Form.Item label={false}>
                                                                    {getFieldDecorator("address_" + record.id, {
                                                                        initialValue: record.address.id,
                                                                        rules: [{ required: true, message: 'Please select an Address.' }]
                                                                    })(
                                                                        <Select
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            ref={(ref) => {this.addressRef["student_" + record.student_id] = ref; return true;}}
                                                                            placeholder="Select an Address"
                                                                            onChange={(value) => {
                                                                                if(value === 'new'){
                                                                                    this.setState({
                                                                                        newAddressRecordId: record.id,
                                                                                        newAddressStudentId: record.student_id,
                                                                                        newAddressModalVisible: true
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {this.state.dtSignoffStudentAddresses["student_" + record.student_id]}
                                                                            <Option value="new">Add New Address</Option>
                                                                        </Select>
                                                                    )}
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Licence Number & Expiry"
                                                        key="licence_details"
                                                        render={(text, record) => (
                                                            <>
                                                                <Form.Item label={false}>
                                                                    {getFieldDecorator("licence_number_" + record.id, {
                                                                        initialValue: record.licence_number,
                                                                        rules: [{ required: true, message: 'Please enter Licence No.' }]
                                                                    })(
                                                                        <Input />
                                                                    )}
                                                                </Form.Item>
                                                                <Form.Item label={false}>
                                                                    {getFieldDecorator("licence_expiry_" + record.id, {
                                                                        initialValue: moment(record.licence_expiry),
                                                                        rules: [{ required: true, message: 'Please enter Licence Expiry Date.' }]
                                                                    })(
                                                                        <DatePicker
                                                                            id={"lesson_form_licence_expiry_" + record.id}
                                                                            allowClear={false}
                                                                            placeholder='##/##/####'
                                                                            format={dateFormatList}
                                                                            disabledDate={(current)=>{return current < moment().startOf('day');}}
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Log Book Hours"
                                                        key="driving_experience"
                                                        render={(text, record) => (
                                                            <Form.Item label={false} className='inline'>
                                                                {getFieldDecorator("driving_experience_" + record.id, {
                                                                    initialValue: record.driving_experience,
                                                                    rules: [
                                                                        { required: true, message: 'Please enter Log Book Hours.' },
                                                                        { validator: (rule, value, cb) => (value < 50  ? cb(true) : cb()), message: 'Minimum 50 Hours Required.'}
                                                                    ]
                                                                })(
                                                                    <InputNumber min={1} />
                                                                )}
                                                            </Form.Item>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Details Confirmed"
                                                        key="confirm"
                                                        align='center'
                                                        render={(text, record) => (
                                                            <Form.Item label={false} >
                                                                <Checkbox
                                                                    name="dtConfirmCheckbox[]"
                                                                    id={"lesson_form_checkbox_" + record.id}
                                                                    data-studentid={record.id}
                                                                ></Checkbox>
                                                            </Form.Item>
                                                        )}
                                                    />
                                                </Table>

                                                {!this.state.signatureExists && (
                                                    <Form.Item label="Please Sign" className='signature-pad'>
                                                        <>
                                                            <SignaturePad
                                                                minWidth={1.5}
                                                                maxWidth={4}
                                                                clearOnResize={true}
                                                                canvasProps={{className: 'sigCanvas', id: "smallSigningCanvas"}}
                                                                ref={(ref) => { this.sigPad = ref }}
                                                                onEnd={()=>{
                                                                    this.setState({
                                                                        signature: this.sigPad.toDataURL('image/png')
                                                                    });
                                                                }}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faExpandAlt}
                                                                size='lg'
                                                                fixedWidth
                                                                onClick={() => {
                                                                    //Draw Sig into Big Sig
                                                                    this.setState({
                                                                        bigSigModalVisible: true
                                                                    }, () => {
                                                                        var img = new Image();
                                                                        img.onload = function() {
                                                                            document.getElementById('bigSigningCanvas').getContext('2d').clearRect(0, 0, parseInt(getComputedStyle(document.getElementById('bigSigningCanvas')).width), parseInt(getComputedStyle(document.getElementById('bigSigningCanvas')).height));
                                                                            document.getElementById('bigSigningCanvas').getContext('2d').drawImage(this, 0, 0, parseInt(getComputedStyle(document.getElementById('bigSigningCanvas')).width), parseInt(getComputedStyle(document.getElementById('bigSigningCanvas')).height));
                                                                        }
                                                                        if (!this.sigPad.isEmpty()) {
                                                                            img.src = this.state.signature;
                                                                        }
                                                                    });
                                                                }}
                                                                style={{position: 'absolute', marginTop: 0}}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faEraser}
                                                                size='lg'
                                                                fixedWidth
                                                                onClick={() => {
                                                                    this.setState({
                                                                        signature: null
                                                                    }, () => {
                                                                        if (typeof(this.sigPad) !== 'undefined' && this.sigPad !== null) {
                                                                            this.sigPad.clear();
                                                                        }
                                                                        if (typeof(this.sigPadBig) !== 'undefined' && this.sigPadBig !== null) {
                                                                            this.sigPadBig.clear();
                                                                        }
                                                                    });
                                                                }}
                                                                style={{position: 'absolute', bottom: 20}}
                                                            />
                                                        </>
                                                    </Form.Item>
                                                )}
                                            </>
                                        )}

                                        {!this.state.dtSignoffModalLoading && (
                                            <>
                                                <h3>Students Not Signed In</h3>
                                                <Table
                                                    rowClassName="students-table-row"
                                                    size="small"
                                                    loading={{spinning: this.state.loading, indicator: <Spinner type="mini" />}}
                                                    className='students-table'
                                                    rowKey={record => record.id}
                                                    dataSource={Object.values(this.state.attendees).sort(function (a,b) {
                                                        if (a.first_name < b.first_name) {
                                                            return -1;
                                                        } else if (a.first_name > b.first_name) {
                                                            return 1;
                                                        } else {
                                                            if (a.last_name < b.last_name) {
                                                                return -1;
                                                            } else if (a.last_name > b.last_name) {
                                                                return 1;
                                                            } else {
                                                                return 0;
                                                            }
                                                        }
                                                    }).filter(a => !this.state.dtSignoffModalSignedIn.includes(a.id))}
                                                    showHeader={false}
                                                    pagination={false}
                                                    locale={{emptyText: "All students have Signed In to this Module."}}
                                                >
                                                    <Column
                                                        title="Name"
                                                        key="name"
                                                        render={(text) => (
                                                            <>
                                                                {text.first_name} {text.last_name}
                                                            </>
                                                        )}
                                                    />
                                                </Table>
                                            </>
                                        )}
                                    </Form>
                                </Modal>

                                {!this.state.signatureExists && (
                                    <Modal
                                        style={{ top: 10 }}
                                        width={(window.innerWidth < 768 ? "100%" : "90%")}
                                        destroyOnClose={true}
                                        className="modal-big-sig"
                                        visible={this.state.bigSigModalVisible}
                                        title="Please Sign"
                                        closable={false}
                                        footer={[
                                            <Button
                                                key="submit"
                                                onClick={() => {
                                                    //Draw Big Sig into Sig
                                                    this.setState({
                                                        bigSigModalVisible: false
                                                    }, () => {
                                                        var img = new Image();
                                                        img.onload = function() {
                                                            document.getElementById('smallSigningCanvas').getContext('2d').clearRect(0, 0, parseInt(getComputedStyle(document.getElementById('smallSigningCanvas')).width), parseInt(getComputedStyle(document.getElementById('smallSigningCanvas')).height));
                                                            document.getElementById('smallSigningCanvas').getContext('2d').drawImage(this, 0, 0, parseInt(getComputedStyle(document.getElementById('smallSigningCanvas')).width), parseInt(getComputedStyle(document.getElementById('smallSigningCanvas')).height));
                                                        }
                                                        if (!this.sigPadBig.isEmpty()) {
                                                            img.src = this.state.signature;
                                                        }
                                                    });
                                                }}
                                                type="primary"
                                            >
                                                <FontAwesomeIcon icon={faSave} />Save
                                            </Button>
                                        ]}
                                    >
                                        <>
                                            <SignaturePad
                                                minWidth={3}
                                                maxWidth={8}
                                                clearOnResize={true}
                                                canvasProps={{className: 'sigCanvasBig', id: "bigSigningCanvas"}}
                                                ref={(ref) => { this.sigPadBig = ref }}
                                                onEnd={()=>{
                                                    this.setState({
                                                        signature: this.sigPadBig.toDataURL('image/png')
                                                    });
                                                }}
                                            />
                                            <FontAwesomeIcon
                                                icon={faEraser}
                                                size='lg'
                                                fixedWidth
                                                onClick={() => {
                                                    this.setState({
                                                        signature: null
                                                    }, () => {
                                                        if (typeof(this.sigPad) !== 'undefined' && this.sigPad !== null) {
                                                            this.sigPad.clear();
                                                        }
                                                        if (typeof(this.sigPadBig) !== 'undefined' && this.sigPadBig !== null) {
                                                            this.sigPadBig.clear();
                                                        }
                                                    });
                                                }}
                                                style={{position: 'absolute'}}
                                            />
                                        </>
                                    </Modal>
                                )}

                                <Modal
                                    destroyOnClose={true}
                                    className="new-address-modal"
                                    visible={this.state.newAddressModalVisible}
                                    title="New Address"
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={this.newAddressModalHandleCancel}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                        <Button
                                            form="newAddressForm"
                                            key="submit"
                                            htmlType="submit"
                                            type="primary"
                                            loading={this.state.newAddressModalLoading}
                                        >
                                            <FontAwesomeIcon icon={faSave} />Save
                                        </Button>
                                    ]}
                                >
                                    <Form
                                        id="newAddressForm"
                                        onSubmit={this.newAddressModalHandleOk}
                                    >
                                        <Form.Item label="Street Number" >
                                            {getFieldDecorator('street_number', {
                                                rules: [
                                                    { required: true, message: 'Please enter a Street Number.' },
                                                    { max: 10, message: 'Street Number cannot be more than 10 characters.' },
                                                ],
                                            })(
                                                <Input
                                                    required="required"
                                                    autoFocus={true}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Street Name" >
                                            {getFieldDecorator('street_name', {
                                                rules: [{ required: true, message: 'Please enter a Street Name.' }],
                                            })(
                                                <Input required="required" />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Suburb" >
                                            {getFieldDecorator('suburb_id', {
                                                rules: [{ required: true, message: 'Please enter a Suburb.' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    placeholder="Select Suburb"
                                                    defaultActiveFirstOption={false}
                                                    showArrow={false}
                                                    filterOption={false}
                                                    onSearch={this.handleSuburbSearch}
                                                    onFocus={this.disableAutoComplete}
                                                    notFoundContent={null}
                                                >
                                                    {suburbs}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Form>
                                </Modal>

                                {this.state.mod2.length > 0 &&
                                    <>
                                        <h3>Module 2 Information</h3>
                                        <Row justify="center">
                                            <Col span={1}></Col>
                                            <Col span={22}>
                                                <div className="ant-table ant-table-middle ant-table-without-column-header ant-table-scroll-position-left">
                                                    <div className="ant-table-content">
                                                        <div className="ant-table-body">
                                                            <table id="module2Details" className="display stripe table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Route & Time</th>
                                                                        <th colSpan="2">Coach</th>
                                                                        <th colSpan="3">Students</th>
                                                                        <th>Module 1</th>
                                                                        <th>Enrolment</th>
                                                                        {this.state.edit &&
                                                                            <th>Actions</th>
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    {this.state.mod2.map((item, id) => 
                                                                        <Fragment key={"tablerow" + id}>
                                                                            <tr className={(item[0].student_attendance === null ? "empty" : (item.length === item[0].sdc_module_info.max_enrolled ? "full" : "vacancies"))}>
                                                                                <td>
                                                                                    {item[0].booking_start.name}<br />
                                                                                    {moment(item[0].start_time).format('D/MM/YYYY h:mma')}
                                                                                </td>
                                                                                <td>
                                                                                    {item[0].dt_attendance.driver_trainer.user.first_name} {item[0].dt_attendance.driver_trainer.user.last_name}
                                                                                </td>
                                                                                <td width="52">
                                                                                    {item[0].dt_attendance.driver_trainer.user.mobile_phone !== null && item[0].dt_attendance.driver_trainer.user.mobile_phone !== '' &&
                                                                                        <a className="ant-btn ant-btn-dashed" href={"tel:" + this.props.stripPhoneNumber(item[0].dt_attendance.driver_trainer.user.mobile_phone)}>
                                                                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                                                                        </a>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {item.map((item2, id2) => 
                                                                                        <Fragment key={"name" + id2}>
                                                                                        {item2.student_attendance !== null &&
                                                                                            <>
                                                                                                {item2.student_attendance.student.user.first_name} {item2.student_attendance.student.user.last_name}
                                                                                                <br />
                                                                                            </>
                                                                                        }
                                                                                        </Fragment>
                                                                                    )}
                                                                                    {this.state.sitins[item[0].id] > 0 && (
                                                                                        <>
                                                                                            Sit-In Student
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td width="52">
                                                                                    {item.map((item2, id2) => 
                                                                                        <Fragment key={"phone" + id2}>
                                                                                            {item2.student_attendance !== null &&
                                                                                                <>
                                                                                                    {item2.student_attendance.student.user.mobile_phone !== null && item2.student_attendance.student.user.mobile_phone !== '' &&
                                                                                                        <a className="ant-btn ant-btn-dashed" href={"tel:" + this.props.stripPhoneNumber(item2.student_attendance.student.user.mobile_phone)}>
                                                                                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                                                                                        </a>
                                                                                                    }
                                                                                                    <br />
                                                                                                </>
                                                                                            }
                                                                                        </Fragment>
                                                                                    )}
                                                                                    {this.state.sitins[item[0].id] > 0 && (
                                                                                        <>
                                                                                            &nbsp;
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td width="52">
                                                                                    {item.map((item2, id2) => 
                                                                                        <Fragment key={"email" + id2}>
                                                                                            {item2.student_attendance !== null &&
                                                                                                <>
                                                                                                    {item2.student_attendance.student.user.email !== null && item2.student_attendance.student.user.email !== '' &&
                                                                                                        <a className="ant-btn ant-btn-dashed" href={"mailto:" + item2.student_attendance.student.user.email}>
                                                                                                            <FontAwesomeIcon icon={faEnvelope} />
                                                                                                        </a>
                                                                                                    }
                                                                                                    <br />
                                                                                                </>
                                                                                            }
                                                                                        </Fragment>
                                                                                    )}
                                                                                    {this.state.sitins[item[0].id] > 0 && (
                                                                                        <>
                                                                                            &nbsp;
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{textAlign: 'center'}}>
                                                                                    {item.map((item2, id2) => 
                                                                                        <Fragment key={"email" + id2}>
                                                                                            {item2.student_attendance !== null &&
                                                                                                <>
                                                                                                    {item2.student_attendance.student.mod_1_date !== null && item2.student_attendance.student.mod_1_date !== '' &&
                                                                                                        moment(item2.student_attendance.student.mod_1_date).format('D/MM/YY')
                                                                                                    }
                                                                                                    <br />
                                                                                                </>
                                                                                            }
                                                                                        </Fragment>
                                                                                    )}
                                                                                    {this.state.sitins[item[0].id] > 0 && (
                                                                                        <>
                                                                                            &nbsp;
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{textAlign: 'center'}}>
                                                                                    {(item[0].student_attendance !== null ? item.length : 0)} / {item[0].sdc_module_info.max_enrolled}
                                                                                </td>
                                                                                {this.state.edit &&
                                                                                    <td>
                                                                                        {
                                                                                        (
                                                                                            (item[0].student_attendance !== null ? item.length : 0) < item[0].sdc_module_info.max_enrolled
                                                                                            && 
                                                                                            (
                                                                                            typeof(this.state.sitins[item[0].id]) === 'undefined'
                                                                                            || 
                                                                                            this.state.sitins[item[0].id] === 0
                                                                                            )
                                                                                        )
                                                                                        ||
                                                                                        (
                                                                                            (item[0].student_attendance !== null ? item.length : 0) === 0
                                                                                            &&
                                                                                            (
                                                                                            typeof(this.state.sitins[item[0].id]) !== 'undefined'
                                                                                            &&
                                                                                            this.state.sitins[item[0].id] > 0
                                                                                            )
                                                                                        )
                                                                                        ?
                                                                                        <Button type="dashed" onClick={() => this.showMod2EnrolModal(item[0].id, (item[0].sdc_module_info.max_enrolled - (item[0].student_attendance !== null ? item.length : 0)))}><FontAwesomeIcon icon={faUserPlus} fixedWidth size="lg" /></Button>
                                                                                        :
                                                                                        ''
                                                                                        }

                                                                                        {
                                                                                            (item[0].student_attendance !== null ? item.length : 0) > 0
                                                                                            ||
                                                                                            (
                                                                                            typeof(this.state.sitins[item[0].id]) !== 'undefined'
                                                                                            &&
                                                                                            this.state.sitins[item[0].id] > 0
                                                                                            )
                                                                                        ?
                                                                                        <Button type="dashed" onClick={() => this.showMod2RemoveModal(item[0].id)}><FontAwesomeIcon icon={faUserMinus} fixedWidth size="lg" /></Button>
                                                                                        :
                                                                                        ''
                                                                                        }
                                                                                    </td>
                                                                                }
                                                                            </tr>
                                                                        </Fragment>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Modal
                                            destroyOnClose={true}
                                            className="mod2-enrol-modal"
                                            visible={this.state.mod2EnrolModalVisible}
                                            title="Enrol Students"
                                            closable={false}
                                            footer={[
                                                <Button
                                                    style={{float:'left'}}
                                                    key="sitin"
                                                    onClick={this.mod2EnrolModalAddSitIn}
                                                    type="dashed"
                                                    loading={this.state.mod2EnrolModalLoading}
                                                    disabled={this.state.mod2EnrolModalLoading}
                                                >
                                                    <FontAwesomeIcon icon={faUserPlus} />Add Sit-In Student
                                                </Button>,
                                                <Button
                                                    key="back"
                                                    onClick={this.mod2EnrolModalHandleCancel}
                                                    disabled={this.state.mod2EnrolModalLoading}
                                                >
                                                    <FontAwesomeIcon icon={faBan} />Cancel
                                                </Button>,
                                                <Button
                                                    key="submit"
                                                    onClick={this.mod2EnrolModalHandleOk}
                                                    type="primary"
                                                    loading={this.state.mod2EnrolModalLoading}
                                                    disabled={this.state.mod2EnrolModalLoading || (this.state.potentialEnrollees !== null && this.state.potentialEnrollees.length === 0)}
                                                >
                                                    <FontAwesomeIcon icon={faUserPlus} />Enrol
                                                </Button>
                                            ]}
                                        >
                                            <Form
                                                id="mod2EnrolForm"
                                                onSubmit={this.mod2EnrolModalHandleOk}
                                            >
                                                <Table
                                                    locale={{emptyText: "There are no students in this Module 1 who are not enrolled in a Module 2."}}
                                                    id="mod2EnrolTable"
                                                    rowKey={record => record.id}
                                                    dataSource={this.state.potentialEnrollees}
                                                    showHeader={false}
                                                    pagination={false}
                                                >
                                                    <Column
                                                        title="Name"
                                                        key="name"
                                                        render={(text, record) => (
                                                            <span>{record.first_name} {record.last_name}</span>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Suburb"
                                                        key="suburb"
                                                        render={(text, record) => (
                                                            <span>{record.address.suburb.suburb_name}</span>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Checkbox"
                                                        key="checkbox"
                                                        render={(text, record) => (
                                                            <Form.Item label={false} >
                                                                <Checkbox
                                                                    name="mod2EnrolCheckbox"
                                                                    id={"checkbox_" + record.id}
                                                                    data-studentid={record.id}
                                                                ></Checkbox>
                                                            </Form.Item>
                                                        )}
                                                    />
                                                </Table>
                                            </Form>
                                        </Modal>

                                        <Modal
                                            destroyOnClose={true}
                                            className="mod2-remove-modal"
                                            visible={this.state.mod2RemoveModalVisible}
                                            title="Remove Students"
                                            closable={false}
                                            footer={[
                                                <Button
                                                    key="back"
                                                    onClick={this.mod2RemoveModalHandleCancel}
                                                    disabled={this.state.mod2RemoveModalLoading}
                                                >
                                                    <FontAwesomeIcon icon={faBan} />Cancel
                                                </Button>,
                                                <Button
                                                    key="submit"
                                                    onClick={this.mod2RemoveModalHandleOk}
                                                    type="primary"
                                                    loading={this.state.mod2RemoveModalLoading}
                                                    disabled={this.state.mod2RemoveModalLoading}
                                                >
                                                    <FontAwesomeIcon icon={faUserMinus} />Remove
                                                </Button>
                                            ]}
                                        >
                                            <Form
                                                id="mod2RemoveForm"
                                                onSubmit={this.mod2RemoveModalHandleOk}
                                            >
                                                <Table
                                                    id="mod2RemoveTable"
                                                    rowKey={record => record.id}
                                                    dataSource={this.state.potentialEnrollees}
                                                    showHeader={false}
                                                    pagination={false}
                                                >
                                                    <Column
                                                        title="Name"
                                                        key="name"
                                                        render={(text, record) => (
                                                            <span>{record.first_name} {record.last_name}</span>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Suburb"
                                                        key="suburb"
                                                        render={(text, record) => (
                                                            <span>{record.address.suburb.suburb_name}</span>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Checkbox"
                                                        key="checkbox"
                                                        render={(text, record) => (
                                                            <Form.Item label={false} >
                                                                <Checkbox
                                                                    name="mod2RemoveCheckbox"
                                                                    id={"checkbox_" + record.id}
                                                                    data-studentid={record.id}
                                                                ></Checkbox>
                                                            </Form.Item>
                                                        )}
                                                    />
                                                </Table>
                                            </Form>
                                        </Modal>

                                        <Modal
                                            destroyOnClose={true}
                                            className="mod1-remove-modal"
                                            visible={this.state.mod1RemoveModalVisible}
                                            title="Unenrol Student"
                                            closable={false}
                                            footer={[
                                                <Button
                                                    key="back"
                                                    onClick={this.mod1RemoveModalHandleCancel}
                                                    disabled={this.state.mod1RemoveModalLoading}
                                                >
                                                    <FontAwesomeIcon icon={faBan} />Cancel
                                                </Button>,
                                                <Button
                                                    key="submit"
                                                    onClick={this.mod1RemoveModalHandleOk}
                                                    type="primary"
                                                    loading={this.state.mod1RemoveModalLoading}
                                                    disabled={this.state.mod1RemoveModalLoading}
                                                >
                                                    <FontAwesomeIcon icon={faUserMinus} />Confirm Unenrolment
                                                </Button>
                                            ]}
                                        >
                                            <Form
                                                id="mod1RemoveForm"
                                                onSubmit={this.mod1RemoveModalHandleOk}
                                            >
                                                Please note that this will remove the student from Module 1 <b>and</b> Module 2.
                                                <Form.Item label="Reason" >
                                                    {getFieldDecorator('reason', {
                                                        initialValue: null,
                                                        rules: [{ required: true, message: 'You must enter a Reason.' }]
                                                    })(
                                                        <TextArea autoFocus={true} autoSize={{ minRows: 4, maxRows: 10 }} />
                                                    )}
                                               </Form.Item>
                                            </Form>
                                        </Modal>

                                    </>
                                }
                            </>
                        )
                    } 
                </Col>
            </Row>
        );
    }
};

const EventForm = Form.create({name: 'lesson_form'})(Event);

export default withRouter(EventForm);
