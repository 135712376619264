import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Form, Input, InputNumber, Select, Collapse, Switch, Button, Checkbox, DatePicker, Table, Modal } from 'antd';
import api from '../../helpers/api';
import moment from 'moment';
import Spinner from '../elements/Spinner';
import config from '../../config';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faDollarSign, faMapMarkedAlt, faPhone, faEnvelope, faMobileAlt, faBullhorn, faUser } from '@fortawesome/free-solid-svg-icons';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';

const { bookingTypes, drivingSchoolShortName } = config;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
const { Column } = Table;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

let suburbLookupTimeout;
let currentSuburb;

class StudentProfile extends React.Component {
    state = {
        studentData: [],
        loading: true,
        suburbs: [],
        next_lesson: null
    };

    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate() {
        if(typeof(this.state.studentData.driver_trainer_id) !== 'undefined' && parseInt(this.props.chosen_driver) !== parseInt(this.state.studentData.driver_trainer_id)){
            this.props.history.push('/students');
        }
    }

    sendReferralLinkSMS = () => {
        api.post("students/sendDTReferralLink", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                student: this.props.match.params.id
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }

            //Check for an error response (status of "NOK")
            if(res.status === 'NOK') {
                console.log(res);
                Modal.error({
                    title: "Error",
                    content: (
                        <div>
                            <p>An issue occurred trying to send an SMS to this Student.</p>
                            <p>Please try again. If the problem persists, please report this to the Office.</p>
                        </div>
                    )
                });
            } else {
                // Success Modal
                Modal.success({
                    title: "SMS Queued!",
                    content: (
                        <div>
                            <p>An SMS has been queued to be sent to the Student.</p>
                            <p>It should be sent within the next 5 minutes.</p>
                        </div>
                    )
                });
            }
        })
        .catch(error => {
            console.log(error);
            //@todo: We should show an error/alert to the user.
        });
    };

    disableAutoComplete = () => {
        if (!this.state.autoCompleteDisabled) {
            const elementsToDisable = document.getElementsByClassName("ant-select-search__field");
            for (let i = 0; i < elementsToDisable.length; i++) {
                elementsToDisable[i].setAttribute("autocomplete","any-arbitrary-text");
            }
            this.setState({ autoCompleteDisabled: true });
        }
    };

    updateDateValue = (fieldName, e) => {
        //Convert moment to a MySQL friendly date and call the updateValue function (note reversed order of variables)
        this.updateValue(e.format('YYYY-MM-DD HH:mm:ss'), fieldName);
    };

    updateAddressValue = (value, field) => {
        //Only update if the new Value is different from the existing.
        if(value !== this.state.studentData.user.address[field]){
            api.post("students/update/address/" + this.props.match.params.id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    field: field,
                    value: value
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    console.log(res);
                } else {
                    //Update the state studentData to reflect the change
                    let studentObject = Object.assign({}, this.state.studentData);
                    studentObject.user.address[field] = value;
                    this.setState({studentData: studentObject});
                }
            })
            .catch(error => {
                console.log(error);
                //@todo: We should show an error/alert to the user.
            });
        }
    }

    updateValue = (e, fieldName) => {
        let field = (typeof fieldName !== 'undefined' ? fieldName : e.id.substring(21));
        let value = (typeof fieldName !== 'undefined' ? e : e.value);

        //Only update if the new Value is different from the existing.
        let doUpdate = true;

        // If we have a single . then we are updating one level deep.
        // Note that at this point, we do not have any references in the form below to further nested objects.
        // If/when that occurs, this will need to be replaced with a recursive function (using `if((field.match(/\./g)||[]).length > 0){` as a base
        if (field.includes('.')) {
            let before = field.substr(0, field.indexOf('.'));
            let after = field.substr(field.indexOf('.')+1);
            if(value === this.state.studentData[before][after]){
                doUpdate = false;
            }
        } else {
            //Otherwise, we simply compare the current state to the submitted value.
            if(value === this.state.studentData[field]){
                doUpdate = false;
            }
        }

        // Don't submit an empty user.email or parent_email
        if (['user.email', 'parent_email'].includes(field) && value.length === 0) {
            doUpdate = false;
        }

        if(doUpdate){
             api.post("students/update/" + this.props.match.params.id, {
                 body: JSON.stringify({
                     chosen_driver: this.props.chosen_driver,
                     field: field,
                     value: value
                 })
             })
             .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    console.log(res);
                } else {
                    //Update the state studentData to reflect the change
                    let studentObject = Object.assign({}, this.state.studentData);
                    //As above, this currently relies on an object depth of one
                    if(field.includes('.')){
                        let before = field.substr(0, field.indexOf('.'));
                        let after = field.substr(field.indexOf('.')+1);
                        studentObject[before][after] = value;
                    }else{
                        studentObject[field] = value;
                    }
                    this.setState({
                        studentData: studentObject
                    }, () => {
                        if(fieldName === 'licence_expiry'){
                            //Update the eligible field as well.
                            let studentObject = Object.assign({}, this.state.studentData);
                            studentObject['needs_eligible_test_on'] = moment(e).subtract(4, 'years').format();
                            this.setState({studentData: studentObject});
                        }
                    });
                }
             })
             .catch(error => {
                 console.log(error);
                 //@todo: We should show an error/alert to the user.
             });
        }
    };

    toggleDrivingWith = (e) => {
        document.getElementById('student_profile_form_vde_driving_with').closest('.ant-row').style.display = (e ? 'block' : 'none');
        this.updateValue(e, 'vde_done_driving');
    };

    togglePrivateFrequency = (e) => {
        document.getElementById('student_profile_form_te_trent_and_private_freq').closest('.ant-row').style.display = (e.target.checked ? 'block' : 'none');
        this.updateValue(e.target.checked, 'te_trent_and_private');
    };

    toggleSingleItem = (e) => {
        document.getElementById('student_profile_form_te_single_item').closest('.ant-row').style.display = (e.target.checked ? 'block' : 'none');
        this.updateValue(e.target.checked, 'te_single');
    };

    toggleAgedItems = (e) => {
        document.getElementById('student_profile_form_te_aged_annual_test').closest('.ant-row').style.display = (e.target.checked ? 'block' : 'none');
        document.getElementById('student_profile_form_te_aged_first_test').closest('.ant-row').style.display = (e.target.checked ? 'block' : 'none');
        document.getElementById('student_profile_form_te_aged_learners').closest('.ant-row').style.display = (e.target.checked ? 'block' : 'none');
        document.getElementById('student_profile_form_te_aged_last_attempt').closest('.ant-row').style.display = (e.target.checked ? 'block' : 'none');
        this.updateValue(e.target.checked, 'te_aged');
    };

    handleSearch = value => {
        this.suburbLookup(value, suburbs => this.setState({ suburbs }));
    };

    suburbLookup = (value, callback) => {
        if (suburbLookupTimeout) {
            clearTimeout(suburbLookupTimeout);
            suburbLookupTimeout = null;
        }
        currentSuburb = value;

        function debouncedLookup() {
            api.post("suburbs/search", {
                body: JSON.stringify({
                    search: value
                })
            })
            .then(d => {
                if (currentSuburb === value) {
                    const data = [];
                    d.result.suburbs.forEach(r => {
                        data.push({
                            value: r.id,
                            text: r.suburb_name
                        });
                    });
                    callback(data);
                }
            });
        }
        suburbLookupTimeout = setTimeout(debouncedLookup, 300);
    }

    fetch = (params = {}) => {
        api.post("students/fetch/" + this.props.match.params.id, {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(res => {
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || typeof res.result === 'undefined' || typeof res.result.student === 'undefined') {
                this.props.history.push('/students');
            } else {
                this.setState({
                    loading: false,
                    studentData: res.result.student
                }, () => {
                    // Get Next Lesson from Lessons List
                    const entries = Object.entries(this.state.studentData.student_attendance);
                    let now = new moment();
                    for (const [, data] of entries) {
                        //Compare all lessons, grab first one in the future.
                        if(moment(data.booking.start_time) > now && data.booking.booking_type_id !== bookingTypes.cancellationFee){
                            //setState for next lesson
                            this.setState({
                                next_lesson: data.booking
                            });
                            break;
                        }
                    }
                });
            }
        })
        .catch(error => {
            console.log(error);
            this.props.history.push('/students');
        });
    };

    downloadPDF = () => {
        alert('this will ping the API to generate and return a PDF');
    };
    
    unfocusInputField = (e) => {
        setTimeout(() => {
            //Make Field Readonly
            let calInputField = document.querySelector('input.ant-calendar-input');
            calInputField.readOnly = true;
            //Scroll the input to the top of the page
            let coords = calInputField.getBoundingClientRect();
			let height = window.scrollY + coords.top - 80;
            window.scrollTo(0, height);
        }, 100); //Wait a moment since the fields used won't be rendered if done without a timeout
    }

    render(){
        const { getFieldDecorator } = this.props.form;
        const noLabelFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24
                },
            }
        };
        const insetNoLabelFormItemLayout = {
            wrapperCol: {
                xs: {
                    offset: 4,
                    span: 20
                }
            }
        };
        const suburbs = this.state.suburbs.map(d => <Option key={d.value}>{d.text}</Option>);

        let infobox = (
            <div style={{textAlign: 'left'}}>
                <b>Hours Completed:</b> {(typeof this.state.studentData !== 'undefined' ? this.state.studentData.lessons_completed : 0)}<br />
                <b>Prepaid Lessons Remaining:</b> {(typeof this.state.studentData.prepaid !== 'undefined' ? this.state.studentData.prepaid.remaining : 0)}<br />
                {(this.state.next_lesson !== null) &&
                    <>
                        <b>Next Lesson:</b> {moment(this.state.next_lesson.start_time).format('DD/MM/YYYY h:mma')} (<Link to={"/lesson/" + this.state.next_lesson.id}>View</Link>)
                    </>
                }
            </div>
        );

        return (
            <Row id="mainBody" className="borderlessInputsContainer">
                { this.state.loading ? 
                    (
                        <Spinner type="mega" />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    {(typeof this.props.match.params.booking_id !== 'undefined' && this.props.match.params.booking_id !== null) &&
                                        <Link to={"/lesson/" + parseInt(this.props.match.params.booking_id)} className="back-to-lesson">
                                            <Button type="dashed" htmlType="button" size="small">
                                                <FontAwesomeIcon icon={faChevronLeft} fixedWidth /> Back to Booking
                                            </Button>
                                        </Link>
                                    }
                                    <Form.Item className="headerInput align-right">
                                        {getFieldDecorator('user.first_name', {
                                            rules: [{ required: true, message: 'Please enter a First Name.' }],
                                            initialValue: (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.first_name : null)
                                        })(
                                            <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                        )}
                                    </Form.Item>
                                    <Form.Item className="headerInput">
                                        {getFieldDecorator('user.last_name', {
                                            rules: [{ required: true, message: 'Please enter a Last Name.' }],
                                            initialValue: (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.last_name : null)
                                        })(
                                            <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="alignItemsLeft reducedFormSpacing">
                                <Col xs={24} sm={24} md={12} lg={5} xl={5}>
                                    <Row>
                                        <Row className="iconAsLabelFormItem">
                                            <Button href={"tel:" + (typeof this.state.studentData.user !== 'undefined' ? this.props.stripPhoneNumber(this.state.studentData.user.home_phone) : null)} className="hiddenButtonWrapper" target="hidden-phone-iframe">
                                                <FontAwesomeIcon icon={faPhone} fixedWidth />
                                            </Button>
                                            <Form.Item>
                                                {getFieldDecorator('user.home_phone', {
                                                    rules: [{ required: true, message: 'Please enter a Home Phone.' }],
                                                    initialValue: (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.home_phone : null)
                                                })(
                                                    <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                                )}
                                            </Form.Item>
                                        </Row>
                                        <Row className="iconAsLabelFormItem">
                                            <Button href={"tel:" + (typeof this.state.studentData.user !== 'undefined' ? this.props.stripPhoneNumber(this.state.studentData.user.mobile_phone) : null)} className="hiddenButtonWrapper" target="hidden-phone-iframe">
                                                <FontAwesomeIcon icon={faMobileAlt} fixedWidth />
                                            </Button>
                                            <Form.Item>
                                                {getFieldDecorator('user.mobile_phone', {
                                                    rules: [{ required: true, message: 'Please enter a Mobile Phone.' }],
                                                    initialValue: (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.mobile_phone : null)
                                                })(
                                                    <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                                )}
                                            </Form.Item>
                                        </Row>
                                        <Row className="iconAsLabelFormItem">
                                            <Button href={"mailto:" + (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.email : null)} className="hiddenButtonWrapper" target="hidden-phone-iframe">
                                                <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                                            </Button>
                                            <Form.Item>
                                                {getFieldDecorator('user.email', {
                                                    rules: [{ required: true, message: 'Please enter an Email Address.' }],
                                                    initialValue: (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.email : null)
                                                })(
                                                    <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                                )}
                                            </Form.Item>
                                        </Row>
                                        <Collapse style={{textAlign: 'left', width: 'calc(100% - 10px)'}}>
                                            <Panel header="Parent Contact Info" key="0" className="reducedFormSpacing parentContactInfo">
                                                <Row className="iconAsLabelFormItem">
                                                    <FontAwesomeIcon icon={faUser} fixedWidth />
                                                    <Form.Item>
                                                        {getFieldDecorator('parent_name', {
                                                            rules: [{ required: true, message: 'Please enter a Name.' }],
                                                            initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.parent_name : null)
                                                        })(
                                                            <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                                        )}
                                                    </Form.Item>
                                                </Row>
                                                <Row className="iconAsLabelFormItem">
                                                    <Button href={"tel:" + (typeof this.state.studentData !== 'undefined' ? this.props.stripPhoneNumber(this.state.studentData.parent_phone) : null)} className="hiddenButtonWrapper" target="hidden-phone-iframe">
                                                        <FontAwesomeIcon icon={faMobileAlt} fixedWidth />
                                                    </Button>
                                                    <Form.Item>
                                                        {getFieldDecorator('parent_phone', {
                                                            rules: [{ required: true, message: 'Please enter a Mobile Phone.' }],
                                                            initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.parent_phone : null)
                                                        })(
                                                            <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                                        )}
                                                    </Form.Item>
                                                </Row>
                                                <Row className="iconAsLabelFormItem">
                                                    <Button href={"mailto:" + (typeof this.state.studentData !== 'undefined' ? this.state.studentData.parent_email : null)} className="hiddenButtonWrapper" target="hidden-phone-iframe">
                                                        <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                                                    </Button>
                                                    <Form.Item>
                                                        {getFieldDecorator('parent_email', {
                                                            rules: [{ required: true, message: 'Please enter an Email Address.' }],
                                                            initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.parent_email : null)
                                                        })(
                                                            <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                                        )}
                                                    </Form.Item>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                        <Row className="iconAsLabelFormItem">
                                            <FontAwesomeIcon icon={faBullhorn} fixedWidth title="Student Referral Code"/>
                                            <div className="ant-row ant-form-item">
                                                <div className="ant-col ant-form-item-control-wrapper">
                                                    <div className="ant-form-item-control has-success">
                                                        <span className="ant-form-item-children">
                                                            <Input className="ant-input" readOnly="readonly" value={this.state.studentData.user.referral_code} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </Row>
                                    <Row type="flex">
                                        <Link to={"/diary"} className="btn-profile booking">
                                            <Button type="primary" htmlType="button">
                                                <FontAwesomeIcon icon={faDollarSign} fixedWidth /> Make a Booking
                                            </Button>
                                        </Link>
                                        <Link
                                            to={{
                                                pathname: "/payments/" + this.props.match.params.id,
                                                returnPath: window.location.pathname
                                            }}
                                            className="btn-profile payment"
                                        >
                                            <Button type="primary" htmlType="button">
                                                <FontAwesomeIcon icon={faDollarSign} fixedWidth /> Take a Payment
                                            </Button>
                                        </Link>
                                        <Link to={"/lesson-plans/" + this.props.match.params.id} className="btn-profile plans">
                                            <Button type="primary" htmlType="button">
                                                <FontAwesomeIcon icon={faListAlt} fixedWidth /> Lesson Plans
                                            </Button>
                                        </Link>
                                        <span
                                            className="ant-btn btn-profile event"
                                            onClick={() => {
                                                this.sendReferralLinkSMS();
                                            }}
                                        >
                                            <Button type="primary" htmlType="button">
                                                <FontAwesomeIcon icon={faMobileAlt} fixedWidth /> Send Referral Link
                                            </Button>
                                        </span>
                                    </Row>
                                    <Row className="alignItemsLeft">
                                        <span className="profileSectionHeader">Licence Details</span>
                                        <Form.Item label="D.O.B" className="inlineCalendar shortLabel">
                                            {getFieldDecorator('user.dob', {
                                                rules: [{ required: true, message: 'Please enter a Date of Birth.' }],
                                                initialValue: (typeof this.state.studentData.user !== 'undefined' && this.state.studentData.user.dob !== null ? moment(this.state.studentData.user.dob) : null)
                                            })(
                                                <DatePicker
                                                    readonly={true}
                                                    required="required"
                                                    size="large"
                                                    allowClear={false}
                                                    placeholder='##/##/####'
                                                    format={dateFormatList}
                                                    onOpenChange={(e) => this.unfocusInputField(e)}
                                                    onChange={(e) => this.updateDateValue('user.dob', e)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="No." className="inlineInput shortLabel">
                                            {getFieldDecorator('licence_number', {
                                                rules: [{ required: true, message: 'Please enter a Licence Number.' }],
                                                initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.licence_number : null)
                                            })(
                                                <Input placeholder="#########" required="required" onBlur={(e) => this.updateValue(e.target)} />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Exp" className="inlineCalendar shortLabel">
                                            {getFieldDecorator('licence_expiry', {
                                                rules: [{ required: true, message: 'Please enter a Licence Expiry Date.' }],
                                                initialValue: (typeof this.state.studentData !== 'undefined' && this.state.studentData.licence_expiry !== null ? moment(this.state.studentData.licence_expiry) : null)
                                            })(
                                                <DatePicker
                                                    required="required"
                                                    size="large"
                                                    allowClear={false}
                                                    placeholder='##/##/####'
                                                    format={dateFormatList}
                                                    onOpenChange={(e) => this.unfocusInputField(e)}
                                                    onChange={(e) => this.updateDateValue('licence_expiry', e)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('licence_type', {
                                                rules: [{ required: true, message: 'Please select a Licence Type.' }],
                                                initialValue: (typeof this.state.studentData !== 'undefined' && this.state.studentData.licence_type !== null ? this.state.studentData.licence_type : 'disabled')
                                            })(
                                                <Select
                                                    disabled={(this.state.studentData.licence_type !== null)}
                                                    className="profileSelect"
                                                    size="large"
                                                    required="required"
                                                    onSelect={(e) => this.updateValue(e,'licence_type')}
                                                >
                                                    <Option value="disabled" disabled>Select a Licence Type</Option>
                                                    <Option value="Learner">Learner</Option>
                                                    <Option value="Provisional">Provisional</Option>
                                                    <Option value="Aged">Aged</Option>
                                                    <Option value="Overseas">Overseas</Option>
                                                    <Option value="Full">Full</Option>
                                                    <Option value="Unknown">Unknown</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Collapse style={{textAlign: 'left', width: 'calc(100% - 10px)'}}>
                                            <Panel header="Expectations" key="0" className="reducedFormSpacing">
                                                <Form.Item {...noLabelFormItemLayout}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_finalise_train_and_test ? true : false}
                                                        onChange={(e) => this.updateValue(e.target.checked, 'te_finalise_train_and_test')}
                                                    >
                                                        Finalise training and presentation for a licence test
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item {...noLabelFormItemLayout}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_all_trent ? true : false}
                                                        onChange={(e) => this.updateValue(e.target.checked, 'te_all_trent')}
                                                    >
                                                        All instruction with {drivingSchoolShortName}
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item {...noLabelFormItemLayout}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_trent_and_private ? true : false}
                                                        onChange={this.togglePrivateFrequency}
                                                    >
                                                        Tuition with {drivingSchoolShortName} and private tutor
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item label="Frequency" style={{display: (typeof this.state.studentData !== 'undefined' && this.state.studentData.te_trent_and_private ? 'block' : 'none')}}>
                                                    {getFieldDecorator('te_trent_and_private_freq', {
                                                        rules: [{ required: true, message: 'Please select a Frequency.' }],
                                                        initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.te_trent_and_private_freq : 'disabled')
                                                    })(
                                                        <Select className="profileSelect" size="large" required="required" onSelect={(e) => this.updateValue(e,'te_trent_and_private_freq')} >
                                                            <Option value="disabled" disabled>Select a Frequency</Option>
                                                            <Option value="Weekly">Weekly</Option>
                                                            <Option value="Fortnightly">Fortnightly</Option>
                                                            <Option value="Monthly">Monthly</Option>
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                                <Form.Item {...noLabelFormItemLayout}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_intro_and_home ? true : false}
                                                        onChange={(e) => this.updateValue(e.target.checked, 'te_intro_and_home')}
                                                    >
                                                        Introductory lesson and home practice
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item {...noLabelFormItemLayout}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_single ? true : false}
                                                        onChange={this.toggleSingleItem}
                                                    >
                                                        Single item tuition
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item label="Item" style={{display: (typeof this.state.studentData !== 'undefined' && this.state.studentData.te_single ? 'block' : 'none')}}>
                                                    {getFieldDecorator('te_single_item', {
                                                        rules: [{ required: true, message: 'Please enter an Item.' }],
                                                        initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.te_single_item : null)
                                                    })(
                                                        <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                                    )}
                                                </Form.Item>
                                                <Form.Item {...noLabelFormItemLayout}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_overseas_conversion ? true : false}
                                                        onChange={(e) => this.updateValue(e.target.checked, 'te_overseas_conversion')}
                                                    >
                                                        Overseas licence conversion
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item {...noLabelFormItemLayout}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_aged ? true : false}
                                                        onChange={this.toggleAgedItems}
                                                    >
                                                        Aged licence holder
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item {...insetNoLabelFormItemLayout} style={{display: (typeof this.state.studentData !== 'undefined' && this.state.studentData.te_aged ? 'block' : 'none')}}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_aged_annual_test ? true : false}
                                                        onChange={(e) => this.updateValue(e.target.checked, 'te_aged_annual_test')}
                                                    >
                                                        Annual test
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item {...insetNoLabelFormItemLayout} style={{display: (typeof this.state.studentData !== 'undefined' && this.state.studentData.te_aged ? 'block' : 'none')}}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_aged_first_test ? true : false}
                                                        onChange={(e) => this.updateValue(e.target.checked, 'te_aged_first_test')}
                                                    >
                                                        First test
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item {...insetNoLabelFormItemLayout} style={{display: (typeof this.state.studentData !== 'undefined' && this.state.studentData.te_aged ? 'block' : 'none')}}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_aged_learners ? true : false}
                                                        onChange={(e) => this.updateValue(e.target.checked, 'te_aged_learners')}
                                                    >
                                                        Learners licence
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item {...insetNoLabelFormItemLayout} style={{display: (typeof this.state.studentData !== 'undefined' && this.state.studentData.te_aged ? 'block' : 'none')}}>
                                                    <Checkbox
                                                        defaultChecked={this.state.studentData.te_aged_last_attempt ? true : false}
                                                        onChange={(e) => this.updateValue(e.target.checked, 'te_aged_last_attempt')}
                                                    >
                                                        Last attempt
                                                    </Checkbox>
                                                </Form.Item>
                                            </Panel>
                                        </Collapse>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{paddingRight: '15px'}}>
                                    <Row>
                                        {infobox}

                                        <div className="studentAddress">
                                            <a target="_blank" rel="noopener noreferrer" href={(((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPad") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) ? "maps://" : "https://") + "maps.google.com.au/?daddr=" + this.state.studentData.user.address.street_number + ' ' + this.state.studentData.user.address.street_name + ', ' + this.state.studentData.user.address.suburb.suburb_name + ' ' + this.state.studentData.user.address.suburb.post_code}>
                                                <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" fixedWidth />
                                            </a>
                                            <Form.Item className="number">
                                                {getFieldDecorator('user.address.street_number', {
                                                    rules: [{ required: true, message: 'Please enter a Street Number.' }],
                                                    initialValue: (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.address.street_number : null)
                                                })(
                                                    <Input required="required" onBlur={(e) => this.updateAddressValue(e.target.value, 'street_number')} />
                                                )}
                                            </Form.Item>
                                            <Form.Item className="street">
                                                {getFieldDecorator('user.address.street_name', {
                                                    rules: [{ required: true, message: 'Please enter a Street Name.' }],
                                                    initialValue: (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.address.street_name : null)
                                                })(
                                                    <Input required="required" onBlur={(e) => this.updateAddressValue(e.target.value, 'street_name')} />
                                                )}
                                            </Form.Item>
                                            <Form.Item className="suburb">
                                                {getFieldDecorator('user.address.suburb.suburb_name', {
                                                    rules: [{ required: true, message: 'Please enter a Suburb.' }],
                                                    initialValue: (typeof this.state.studentData.user !== 'undefined' ? this.state.studentData.user.address.suburb.suburb_name + ", " + this.state.studentData.user.address.suburb.post_code : null)
                                                })(
                                                    <Select
                                                        showSearch
                                                        placeholder="Select Suburb"
                                                        defaultActiveFirstOption={false}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        onSearch={this.handleSearch}
                                                        onSelect={(e) => this.updateAddressValue(e, 'suburb_id')}
                                                        onFocus={this.disableAutoComplete}
                                                        notFoundContent={null}
                                                        >
                                                        {suburbs}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </Row>
                                    <Row>
                                        <span className="profileSectionHeader">Student Insights</span>
                                        <Form.Item>
                                             {getFieldDecorator('notes', {
                                                 rules: [],
                                                 initialValue: (typeof this.state.studentData.notes !== 'undefined' ? this.state.studentData.notes : null)
                                             })(
                                                 <TextArea autoSize={{ minRows: 8, maxRows: 8 }} onBlur={(e) => this.updateValue(e.target)} />
                                             )}
                                        </Form.Item>
                                        <span className="profileSectionHeader">Additional Notes</span>
                                        <Form.Item>
                                             {getFieldDecorator('dt_notes', {
                                                 rules: [],
                                                 initialValue: (typeof this.state.studentData.dt_notes !== 'undefined' ? this.state.studentData.dt_notes : null)
                                             })(
                                                 <TextArea placeholder="Parents' names, etc" autoSize={{ minRows: 8, maxRows: 8 }} onBlur={(e) => this.updateValue(e.target)} />
                                             )}
                                        </Form.Item>
                                    </Row>
                                    <Row>
                                        <Collapse style={{textAlign: 'left'}}>
                                            <Panel header="Lesson History" key="7">
                                                <Table
                                                    rowKey={record => record.booking.id}
                                                    dataSource={this.state.studentData.student_attendance}
                                                >
                                                    <Column
                                                        title="Date & Time"
                                                        dataIndex="booking.start_time"
                                                        key="start_time" 
                                                        render={(text) => (
                                                            moment(text).format('DD/MM/YYYY h:mma')
                                                        )}
                                                    />
                                                    <Column
                                                        title="Duration"
                                                        dataIndex="booking.end_time"
                                                        key="duration" 
                                                        render={(text, record) => (
                                                            moment(record.booking.end_time).diff(moment(record.booking.start_time), 'minutes')/60
                                                        )}
                                                    />
                                                    <Column
                                                        title="Type"
                                                        dataIndex="booking.booking_type.name"
                                                        key="type"
                                                        render={(text, record) => (
                                                            text + 
                                                            (record.booking.reservation_info !== null ? " (Reservation)" : "") + 
                                                            (record.booking.test_info !== null ? " " + (record.booking.test_info.student_passed ? "(PASS)" : "(FAIL)") : "")
                                                        )}
                                                    />
                                                    <Column
                                                        title=""
                                                        key="action"
                                                        render={(text, record) => (
                                                            record.booking.booking_type_id !== bookingTypes.cancellationFee ?
                                                                <Link to={"/lesson/" + record.booking.id}>View</Link>
                                                            : ''
                                                        )}
                                                    />
                                                </Table>
                                            </Panel>
                                        </Collapse>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                    <Row className="reducedFormSpacing">
                                        <span className="profileSectionHeader">Establishing Needs</span>
                                        <span className="licenceDetailField">
                                            <strong>{(typeof this.state.studentData.hours_remaining !== 'undefined' ? 120 - this.state.studentData.hours_remaining : "") + ' '}</strong>
                                             Logbook Hours
                                        </span>
                                        <span className="licenceDetailField">
                                            <strong>{(typeof this.state.studentData.hours_remaining !== 'undefined' ? this.state.studentData.hours_remaining : "") + ' '}</strong>
                                             Hrs Remaining
                                        </span>
                                        <span className="licenceDetailField">
                                            <strong>{(typeof this.state.studentData.hours_per_week_required !== 'undefined' ? this.state.studentData.hours_per_week_required : "") + ' '}</strong>
                                             Hours/Weeks to achieve goal
                                        </span>

                                        <Form.Item label="SDC" className="inlineSwitchInput">
                                            <Switch
                                                checkedChildren="Yes" 
                                                unCheckedChildren="No" 
                                                defaultChecked={this.state.studentData.hadSDC ? true : false}
                                                className="disabledSwitch"
                                                />
                                        </Form.Item>
                                        <Form.Item label="K2D" className="inlineSwitchInput">
                                            <Switch
                                                checkedChildren="Yes" 
                                                unCheckedChildren="No" 
                                                defaultChecked={this.state.studentData.hadK2D ? true : false}
                                                className="disabledSwitch"
                                                />
                                        </Form.Item>
                                        <Form.Item label="Open Road" className="inlineSwitchInput">
                                            <Switch
                                                checkedChildren="Yes" 
                                                unCheckedChildren="No" 
                                                defaultChecked={this.state.studentData.done_open_road ? true : false}
                                                onChange={(e) => this.updateValue(e,'done_open_road')}
                                                />
                                        </Form.Item>
                                        <Form.Item label="HPT" className="inlineSwitchInput" >
                                            <Switch 
                                                checkedChildren="Yes" 
                                                unCheckedChildren="No" 
                                                defaultChecked={this.state.studentData.done_hpt ? true : false}
                                                onChange={(e) => this.updateValue(e,'done_hpt')}
                                                />
                                        </Form.Item>

                                        <Form.Item label="Eligible for test" className="inlineCalendar">
                                            {getFieldDecorator('needs_eligible_test_on', {
                                                rules: [{ required: true, message: 'Please enter an Eligibility Date.' }],
                                                initialValue: (typeof this.state.studentData.needs_eligible_test_on !== 'undefined' && this.state.studentData.needs_eligible_test_on !== null ? moment(this.state.studentData.needs_eligible_test_on) : null)
                                            })(
                                                <DatePicker
                                                    required="required"
                                                    size="large"
                                                    allowClear={false}
                                                    placeholder='##/##/####'
                                                    format={dateFormatList}
                                                    onOpenChange={(e) => this.unfocusInputField(e)}
                                                    onChange={(e) => this.updateDateValue('needs_eligible_test_on', e)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Complete tuition"  className="inlineCalendar">
                                            {getFieldDecorator('needs_complete_tuition_by', {
                                                rules: [{ required: true, message: 'Please enter a Completion Date.' }],
                                                initialValue: (typeof this.state.studentData.needs_complete_tuition_by !== 'undefined' && this.state.studentData.needs_complete_tuition_by !== null ? moment(this.state.studentData.needs_complete_tuition_by) : null)
                                            })(
                                                <DatePicker
                                                    required="required"
                                                    size="large"
                                                    allowClear={false}
                                                    placeholder='##/##/####'
                                                    format={dateFormatList}
                                                    onOpenChange={(e) => this.unfocusInputField(e)}
                                                    onChange={(e) => this.updateDateValue('needs_complete_tuition_by', e)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Test date" className="inlineCalendar">
                                            {getFieldDecorator('needs_test_arranged_for', {
                                                rules: [{ required: true, message: 'Please enter a Test Date.' }],
                                                initialValue: (typeof this.state.studentData.needs_test_arranged_for !== 'undefined' && this.state.studentData.needs_test_arranged_for !== null ? moment(this.state.studentData.needs_test_arranged_for) : null)
                                            })(
                                                <DatePicker
                                                    required="required"
                                                    size="large"
                                                    allowClear={false}
                                                    placeholder='##/##/####'
                                                    format={dateFormatList}
                                                    onOpenChange={(e) => this.unfocusInputField(e)}
                                                    onChange={(e) => this.updateDateValue('needs_test_arranged_for', e)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Test Booked" className="inlineSwitchInput">
                                            <Switch
                                                checkedChildren="Yes" 
                                                unCheckedChildren="No" 
                                                defaultChecked={this.state.studentData.needs_test_booked ? true : false}
                                                onChange={(e) => this.updateValue(e,'needs_test_booked')}
                                                />
                                        </Form.Item>
                                    </Row>
                                    <Collapse style={{textAlign: 'left', width: 'calc(100% - 10px)'}}>
                                        <Panel header="Evaluation" key="3" className="reducedFormSpacing">
                                            <Form.Item label="Prior experience" className="inlineSwitchInput">
                                                <Switch
                                                    checkedChildren="Yes" 
                                                    unCheckedChildren="No" 
                                                    defaultChecked={this.state.studentData.vde_done_driving ? true : false}
                                                    onChange={this.toggleDrivingWith}
                                                    />
                                            </Form.Item>
                                            <Form.Item label="Who with?" style={{display: (typeof this.state.studentData !== 'undefined' && this.state.studentData.vde_done_driving ? 'block' : 'none')}}>
                                                {getFieldDecorator('vde_driving_with', {
                                                    rules: [{ required: true, message: 'Please select an option.' }],
                                                    initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.vde_driving_with : 'disabled')
                                                })(
                                                    <Select className="profileSelect" size="large" required="required" onSelect={(e) => this.updateValue(e,'vde_driving_with')} >
                                                        <Option value="disabled" disabled>Select an option</Option>
                                                        <Option value="Parent">Parent</Option>
                                                        <Option value="Friend">Friend</Option>
                                                        <Option value="Driving School">Driving School</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Hours Driving" >
                                                {getFieldDecorator('driving_experience', {
                                                    rules: [{ required: true, message: 'Please enter Hours Driving.' }],
                                                    initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.driving_experience : null)
                                                })(
                                                    <InputNumber
                                                        min={1}
                                                        required={true}
                                                        onBlur={(e) => this.updateValue(e.target)}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Last tuition?" >
                                                {getFieldDecorator('vde_last_tuition', {
                                                    rules: [{ required: true, message: 'Please select a Time Frame.' }],
                                                    initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.vde_last_tuition : 'disabled')
                                                })(
                                                    <Select className="profileSelect" size="large" required="required" onSelect={(e) => this.updateValue(e,'vde_last_tuition')} >
                                                        <Option value="disabled" disabled>Select a Time Frame</Option>
                                                        <Option value="Recent">Recent</Option>
                                                        <Option value="1 Month">1 Month</Option>
                                                        <Option value="Over 6 Months">Over 6 Months</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Where did you have the tuition?" >
                                                {getFieldDecorator('vde_tuition_where', {
                                                    rules: [{ required: true, message: 'Please select a Location.' }],
                                                    initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.vde_tuition_where : 'disabled')
                                                })(
                                                    <Select className="profileSelect" size="large" required="required" onSelect={(e) => this.updateValue(e,'vde_tuition_where')} >
                                                        <Option value="disabled" disabled>Select a Location</Option>
                                                        <Option value="Off Road">Off Road</Option>
                                                        <Option value="Quiet Area">Quiet Area</Option>
                                                        <Option value="Busy">Busy</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Log book correct?" className="inlineSwitchInput">
                                                <Switch
                                                    checkedChildren="Yes" 
                                                    unCheckedChildren="No" 
                                                    defaultChecked={this.state.studentData.vde_log_book_up_to_date ? true : false}
                                                    onChange={(e) => this.updateValue(e,'vde_log_book_up_to_date')}
                                                    />
                                            </Form.Item>
                                            <Form.Item label="Car Type" >
                                                {getFieldDecorator('vde_car_type', {
                                                    rules: [{ required: true, message: 'Please select a Car Type.' }],
                                                    initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.vde_car_type : 'disabled')
                                                })(
                                                    <Select className="profileSelect" size="large" required="required" onSelect={(e) => this.updateValue(e,'vde_car_type')} >
                                                        <Option value="disabled" disabled>Select a Car Type</Option>
                                                        <Option value="Auto">Auto</Option>
                                                        <Option value="Manual">Manual</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Car Description" >
                                                {getFieldDecorator('vde_car_description', {
                                                    rules: [{ required: true, message: 'Please enter a Car Description.' }],
                                                    initialValue: (typeof this.state.studentData !== 'undefined' ? this.state.studentData.vde_car_description : null)
                                                })(
                                                    <Input required="required" onBlur={(e) => this.updateValue(e.target)} />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Driven in traffic?" className="inlineSwitchInput" >
                                                <Switch 
                                                    checkedChildren="Yes" 
                                                    unCheckedChildren="No" 
                                                    defaultChecked={this.state.studentData.vde_been_in_traffic ? true : false}
                                                    onChange={(e) => this.updateValue(e,'vde_been_in_traffic')}
                                                    />
                                            </Form.Item>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </>
                    )
                }
            </Row>
        );
    }
};

const StudentProfileForm = Form.create({name: 'student_profile_form'})(StudentProfile);

export default withRouter(StudentProfileForm);
