import React from 'react';
import { Row, Col } from 'antd';
import DriversTable from '../elements/drivers/DriversTable';

class ChooseDriver extends React.Component {

    render(){
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h2>Select a Driver Trainer</h2>
                    <DriversTable
                        setAppStateValue={this.props.setAppStateValue}
                        driver_list={this.props.driver_list}
                        chosen_driver={this.props.chosen_driver} 
                    />
                </Col>
            </Row>
        );
    }
};

export default ChooseDriver;