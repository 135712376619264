import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Popconfirm, Checkbox, Button } from 'antd';
import api from '../../../helpers/api';
import update from 'immutability-helper';
import Spinner from '../../elements/Spinner';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faPlusSquare, faEdit } from '@fortawesome/free-regular-svg-icons';

class RemindersTable extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: true,
        allDoneLoading: false,
        archiveCompletedLoading: false
    };

    componentDidMount() {
        this.fetch();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.data !== nextState.data;
    }

    getKeyByValue = (object, field, value, returnData) => {
        for (var key in object) {
            // skip loop if the property is from prototype
            if (!object.hasOwnProperty(key)) continue;

            var obj = object[key];
            for (var prop in obj) {
                // skip loop if the property is from prototype
                if(obj.hasOwnProperty(prop) && prop === field && obj[prop] === value){
                    if(returnData){
                        return obj;
                    }
                    return key;
                }
            }
        }
        return false;
    }

    handleRowSelect = (e) => {
        let targetRow = parseInt(this.getKeyByValue(this.state.data, 'id', e.target.row, false));
        let rowData = this.getKeyByValue(this.state.data, 'id', e.target.row, true);
        this.markReminderAsDone(targetRow, rowData, e.target.checked);
    }
    
    markReminderAsDone = async(targetRow, rowData, checked) => {
        await api.post("reminders/complete/" + rowData.id, {
            body: JSON.stringify({
                done: checked,
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            if(typeof data.result === 'object' && data.result.saved){
                this.setState({
                    data: update(this.state.data, {
                        [targetRow]: {
                            "completed": {
                                $set: checked
                            }
                        }
                    })
                });
                //Set state for count so that left menu is updated
                this.props.setAppStateValue('counts', {
                    reminders: (checked ? this.props.counts.reminders - 1 : this.props.counts.reminders + 1)
                });
            }else{
                //@todo: Uncheck box and show error
            }
        });
    };

    markAllAsDone = async () => {
        this.setState({allDoneLoading: true});
        await api.post("reminders/complete/all", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            if(typeof data.result === 'object' && data.result.saved){
                this.handleTableChange(this.state.pagination);
            }else{
                //@todo: Uncheck box and show error
            }
            this.setState({allDoneLoading: false});
        })
        .catch(error => {
            console.log(error);
            this.setState({allDoneLoading: false});
        });
    };

    handleArchive = async (id, completed) => {
        api.post("reminders/archive/" + id, {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            if(typeof data.result === 'object' && data.result.saved){
                //Reload Reminders and Update Pagination/Left Menu
                this.handleTableChange(this.state.pagination);
                if(!completed){
                    this.props.setAppStateValue('counts', {
                        reminders: this.props.counts.reminders - 1
                    });
                }
            }else{
                //@todo: Uncheck box and show error
            }
        });
    };

    archiveCompleted = async () => {
        this.setState({archiveCompletedLoading: true});
        await api.post("reminders/archive/all", {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            if(typeof data.result === 'object' && data.result.saved){
                this.handleTableChange(this.state.pagination);
            }else{
                //@todo: Uncheck box and show error
            }
            this.setState({archiveCompletedLoading: false});
        })
        .catch(error => {
            console.log(error);
            this.setState({archiveCompletedLoading: false});
        });
    };

    columnFormat = [
        {
            width: 35,
            key: 'id',
            render: (text, record) => {
                return (
                    <Checkbox onChange={this.handleRowSelect} defaultChecked={record.completed ? true : false} checked={record.completed ? true : false} row={record.id}></Checkbox>
                );
            }
        },
        {
            title: 'Reminder',
            dataIndex: 'title',
            render: (text, record) =>  {
                return (
                    <span className={record.completed ? "done" : ""}>
                        {text}
                    </span>
                );
            }
        },
        {
            title: 'Date',
            dataIndex: 'due',
            width: 125,
            render: (text, record) =>  {
                return (
                    <span className={record.completed ? "done" : ""}>
                        <span className={record.due !== null ? "datebubble" : ""}>
                            {text}
                        </span>
                    </span>
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: 75,
            render: (text, record) => {
                return (
                    <span>
                        <Link to={"/reminders/edit/" + record.id}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Link>
                        <Divider type="vertical" />
                        <Popconfirm title="Archive this reminder?" onConfirm={() => this.handleArchive(record.id, record.completed)}>
                            <FontAwesomeIcon icon={faArchive} />
                        </Popconfirm>
                    </span>
                );
            }
        }
    ];

    columnFormatBasic = [
        {
            width: 35,
            key: 'id',
            render: (text, record) => {
                return (
                    <Checkbox onChange={this.handleRowSelect} defaultChecked={record.completed ? true : false} checked={record.completed ? true : false} row={record.id}></Checkbox>
                );
            }
        },
        {
            title: 'Reminder',
            dataIndex: 'title',
            render: (text, record) =>  {
                return (
                    <span className={record.completed ? "reminderList done" : "reminderList"}>
                        {text}
                    </span>
                );
            }
        }
    ];

    handleTableChange = (pagination, filters) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            ...filters
        });
    };

    fetch = (params = {}) => {
        this.setState({ loading: true });

        api.post("reminders/fetch",{
            body: JSON.stringify({
                results: 10,
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(data => {
            const pagination = { ...this.state.pagination };
            pagination.total = data.result.totalCount;
            this.setState({
                loading: false,
                data: data.result.reminders,
                pagination
            });
        });
    };

    render() {
        return (
            <>
                {
                    ((typeof this.props.basic === 'undefined' || this.props.basic === false) &&
                        <div className="space-evenly">
                            <Link to={"/reminders/add"}>
                                <Button
                                    className='new-btn-reminder' 
                                    type="danger"
                                >
                                    <FontAwesomeIcon icon={faPlusSquare} />New Reminder
                                </Button>
                            </Link>
                            <Popconfirm title="Mark ALL Reminders as Done?" onConfirm={() => this.markAllAsDone()}>
                                <Button
                                  type="primary"
                                  loading={this.state.allDoneLoading}
                                >
                                    <FontAwesomeIcon icon={faCheckSquare} />Mark All as Done
                                </Button>
                            </Popconfirm>
                            <Popconfirm title="Archive ALL Completed Reminders?" onConfirm={() => this.archiveCompleted()}>
                                <Button
                                  type="primary"
                                  loading={this.state.archiveCompletedLoading}
                                >
                                    <FontAwesomeIcon icon={faArchive} />Archive Completed
                                </Button>
                            </Popconfirm>
                        </div>
                    )
                }
                <Table
                    rowClassName="reminders-table-row"
                    size="middle"
                    showHeader={false}
                    columns={(typeof this.props.basic === 'undefined' || this.props.basic === false) ? this.columnFormat : this.columnFormatBasic}
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={{spinning: this.state.loading, indicator: <Spinner type="mini" />}}
                    onChange={this.handleTableChange}
                />
            </>
        );
    }
};

export default RemindersTable;