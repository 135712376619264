import React from 'react';
import api from '../../../helpers/api';
import IPSI from './IPSI';
import config from '../../../config';
import { Row, Select } from 'antd';
import Spinner from '../../elements/Spinner';

const { products } = config;
const { Option } = Select;

class LessonPackages extends React.Component {

    state = {
        selectedProductId: null,
        selectedProductQuantity: 1,
        singleLessonQuantity: parseFloat(this.props.duration),
        products: {}
    };

    componentDidMount(){
        this.retrieveLessonProducts();
    }

    setProduct(productId){
        this.setState({
            selectedProductId: productId
        });
    }

    setSingleLessonQuantity(value){
        this.setState({
            singleLessonQuantity: parseFloat(value)
        });
    }

    retrieveLessonProducts = async () => {        
        return api.post("products/getLessonPackagesForStudent", {
            body: JSON.stringify({
                student_id: this.props.student_id,
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if(typeof res === 'string'){
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK'){
                this.setState({
                    errorMessage: res.result.error,
                    errorMessageType: 'error'
                });
            } else {
                this.setState({
                    products: res.result.products
                });
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({
                errorMessage: "Error redeeming gift voucher, please try again.",
                errorMessageType: 'error'
            });
        });
    };

    render(){
        let singleLessonPrice = (!!this.state.products[products.singleLesson] ? parseFloat(parseFloat(this.state.singleLessonQuantity) * this.state.products[products.singleLesson].product_prices[0].base_cost).toFixed(2) : "##.##");
        let sixLessonPrice = (!!this.state.products[products.sixLesson] ? this.state.products[products.sixLesson].product_prices[0].base_cost : "##.##");
        let tenLessonPrice = (!!this.state.products[products.tenLesson] ? this.state.products[products.tenLesson].product_prices[0].base_cost : "##.##");
        let twentyFiveLessonPrice = (!!this.state.products[products.twentyFiveLesson] ? this.state.products[products.twentyFiveLesson].product_prices[0].base_cost : "##.##");

        return (
            <div className="paymentOptionContainer">
                {Object.entries(this.state.products).length === 0 && (
                    <Spinner type="inline" />
                )}
                {Object.entries(this.state.products).length > 0 && (
                    <>
                        <Row>
                            <div
                                className={(this.state.selectedProductId === products.singleLesson ? "active " : "") + "lessonPackageDisplay"} 
                                onClick={() => this.setProduct(products.singleLesson)}
                            >
                                <h2>Single lesson</h2>
                                <div>
                                    <Select
                                        defaultValue={this.state.singleLessonQuantity}
                                        className="lessonNumberSelect"
                                        onChange={(v) => this.setSingleLessonQuantity(v)}
                                    >
                                        <Option value="0.5">0.5</Option>
                                        <Option value="1" >1</Option>
                                        <Option value="1.5">1.5</Option>
                                        <Option value="2">2</Option>
                                        <Option value="2.5">2.5</Option>
                                        <Option value="3">3</Option>
                                        <Option value="3.5">3.5</Option>
                                        <Option value="4">4</Option>
                                        <Option value="4.5">4.5</Option>
                                        <Option value="5">5</Option>
                                        <Option value="5.5">5.5</Option>
                                    </Select> lesson credits
                                </div>
                                <div>$<span>{singleLessonPrice}</span></div>
                            </div>
                            <div 
                                className={(this.state.selectedProductId === products.sixLesson ? "active " : "") + "lessonPackageDisplay"} 
                                onClick={() => this.setProduct(products.sixLesson)}
                            >
                                <h2>6 Lesson Package</h2>
                                <div><span>6</span> lesson credits</div>
                                <div>$<span>{sixLessonPrice}</span></div>
                            </div>
                            <div 
                                className={(this.state.selectedProductId === products.tenLesson ? "active " : "") + "lessonPackageDisplay"} 
                                onClick={() => this.setProduct(products.tenLesson)}
                            >
                                <h2>10 Lesson Package</h2>
                                <div><span>10</span> lesson credits</div>
                                <div>$<span>{tenLessonPrice}</span></div>
                            </div>
                            <div 
                                className={(this.state.selectedProductId === products.twentyFiveLesson ? "active " : "") + "lessonPackageDisplay"} 
                                onClick={() => this.setProduct(products.twentyFiveLesson)}
                            >
                                <h2>25 Lesson Package</h2>
                                <div><span>25</span> lesson credits</div>
                                <div>$<span>{twentyFiveLessonPrice}</span></div>
                            </div>
                        </Row>
                        {this.state.selectedProductId && this.props.studentData && (
                            <Row>
                                <IPSI
                                    products={this.state.products}
                                    student_id={this.props.student_id}
                                    studentData={this.props.studentData}
                                    chosen_driver={this.props.chosen_driver} 
                                    product_id={this.state.selectedProductId}
                                    product_quantity={(this.state.selectedProductId === products.singleLesson ? this.state.singleLessonQuantity : this.state.selectedProductQuantity)}
                                    backToReferrer={this.props.backToReferrer}
                                    changeSubmitting={() => this.props.changeSubmitting()}
                                    inModal={false}
                                />
                            </Row>
                        )}
                    </>
                )}
            </div>
        );
    }
};

export default LessonPackages;