import React from 'react';
import ReactDOMServer from 'react-dom/server';
import diaryHelper from '../../helpers/diary';

import FullCalendar from '@fullcalendar/react'
import '@fullcalendar/core/main.css';
import listPlugin from '@fullcalendar/list'
import '@fullcalendar/list/main.css';

import moment from 'moment';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCalendarTimes, faClipboardCheck, faUserFriends, faUserPlus } from '@fortawesome/free-solid-svg-icons';

class Home extends React.Component {
    calendarRef = React.createRef();

    componentDidMount() {
        if(window.innerWidth >= 1024){
            this.props.showReminderDrawer();
        }
    }

    componentWillUnmount() {
        this.props.hideReminderDrawer();
    }

    agendaDateFormat = (date) => {
        return moment(date.date.marker).format('dddd DD MMMM YYYY');
    };

    handleEventRender = (info) => {
        let listWeekTitle = info.el.querySelector('.fc-list-item-title');
        if(listWeekTitle !== null){
            listWeekTitle.innerHTML = 
                    (typeof(info.event.extendedProps.payment_icon) !== 'undefined' && info.event.extendedProps.payment_icon !== '' && info.event.extendedProps.payment_icon !== null ? "<span class='payment_icon'>" + info.event.extendedProps.payment_icon  + "</span>" : '') + 
                    listWeekTitle.innerHTML + 
                    (typeof(info.event.extendedProps.test_location) !== 'undefined' && info.event.extendedProps.test_location !== '' && info.event.extendedProps.test_location !== null ? "<br />Test Start Time: " + info.event.extendedProps.test_start_time + "<br />Test Center: " + info.event.extendedProps.test_location : "") + 
                    (typeof(info.event.extendedProps.start_location) !== 'undefined' && info.event.extendedProps.start_location !== '' && info.event.extendedProps.start_location !== null ? "<br />" + (info.event.extendedProps.booking_type === 'Event' ? "Event Location: " : "Pick Up: ") + info.event.extendedProps.start_location : '') + 
                    (typeof(info.event.extendedProps.end_location) !== 'undefined' && info.event.extendedProps.end_location !== '' && info.event.extendedProps.end_location !== null ? "<br />Drop Off: " + info.event.extendedProps.end_location : '') + 
                    (typeof(info.event.extendedProps.reminder_description) !== 'undefined' && info.event.extendedProps.reminder_description !== '' && info.event.extendedProps.reminder_description !== null ? "<br />" + info.event.extendedProps.reminder_description : '') + 
                    '';
        }
        //Label Reminders as such:
        let listWeekTime = info.el.querySelector('.fc-list-item-time');
        if(listWeekTime.innerHTML === 'all-day'){
            listWeekTime.innerHTML = 'Reminder';
        }

        //Determine Icon to use
        let iconToUse = '';
        if (info.event.classNames.indexOf('first_lesson') >= 0) {
            iconToUse = faUserPlus;
        } else if (info.event.classNames.indexOf('test') >= 0) {
            iconToUse = faClipboardCheck;
        } else if (info.event.classNames.indexOf('event') >= 0) {
            iconToUse = faCalendarTimes;
        } else if (info.event.classNames.indexOf('reminder') >= 0) {
            iconToUse = faBell;
        } else {
            iconToUse = faUserFriends;
        }
        
        //Get Time Field - We'll insert before it
        let timeTD = info.el.querySelector('.fc-list-item-time');

        //Create New TD:
        var newTD = document.createElement('td');
        newTD.innerHTML = ReactDOMServer.renderToString(<FontAwesomeIcon fixedWidth icon={iconToUse} size="lg" />);

        //Insert TD:
        timeTD.parentNode.insertBefore(newTD, timeTD);
    };

    eventFetch = (params = {}, successCallback) => {
        params.chosen_driver = this.props.chosen_driver;
        diaryHelper.eventFetch(params, successCallback);
    };

    render() {
        return (
            <div className="homeAgenda">
                <FullCalendar
                    key={"homeCal" + this.props.chosen_driver}
                    ref={this.calendarRef}
                    defaultView="listHomeAgenda"
                    views={{
                        listHomeAgenda: {
                            type: 'list',
                            duration: { days: 140 },
                            listDayFormat: this.agendaDateFormat
                        }
                    }}
                    events={this.eventFetch}
                    eventRender={this.handleEventRender}
                    eventClick={diaryHelper.handleEventClick}
                    plugins={[ listPlugin ]} 
                    timeZone='Australia/Sydney'
                    header={null}
                    firstDay={1}
                />
            </div>
        );
    }
};

export default Home;