import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Alert, Select, Switch, Modal, DatePicker, TimePicker, Table, Checkbox } from 'antd';
import api from '../../helpers/api';
import moment from 'moment';
import Spinner from '../elements/Spinner';

import FullCalendar from '@fullcalendar/react'
import '@fullcalendar/core/main.css';
import timeGridPlugin from '@fullcalendar/timegrid'
import '@fullcalendar/timegrid/main.css';
import diaryHelper from '../../helpers/diary';
import config from '../../config';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faClock, faSave, faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';

const { Option } = Select;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const ButtonGroup = Button.Group;
const { Column } = Table;

const { dayStart, dayEnd, productName, bookingTypes, historyEditDays, paymentBeforeBooking, showSendNotificationToStudentCheckbox } = config;

let suburbLookupTimeout;
let currentSuburb;

class LessonAdd extends React.Component {

    state = {
        booking_id: null,
        booking: null,
        start_time: null,
        end_time: null,
        duration: 0,
        test_centres: null,
        loading: true,
        changeTimeModalVisible: false,
        changeTimeModalLoading: false,
        changeTimeModalLoaded: false,
        calendarLoading: false,
        disabled_hours: [],
        overlap: false,
        loadingStudents: true,
        studentData: [],
        selectedStudent: null,
        loadingAddresses: false,
        addressData: [],
        addressDataPickup: null,
        addressDataDropoff: null,
        loadingLessonTypes: true,
        lessonTypeData: [],
        visibleBookingElements: bookingTypes.lesson,
        suburbs: [],
        newAddressModalVisible: false,
        newAddressModalLoading: false,
        buttonsDisabled: false,
        unmountOkay: false,
        noPrepaids: false,

        occurrencesVisible: false,
        occurrencesFrequencyString: "Week",
        occurrencesNumber: 1,
        occurrencesLoading: false,
        occurrencesData: null,
        occurrencesError: null

    };

    componentWillUnmount() {
        if(!this.state.unmountOkay) {
            return api.post("bookings/cancelReservation", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    booking: this.state.booking_id
                })
            });
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (typeof (prevState.booking) !== 'undefined' && prevState.booking !== null && parseInt(nextProps.match.params.id) !== prevState.booking_id){
            return {
                loading: true,
                booking_id: parseInt(nextProps.match.params.id)
            };
        }
        return null;
    };

    componentDidMount() {
        if(this.state.booking_id === null){
            this.setState({
                booking_id: parseInt(this.props.match.params.id)
            });
        }else{
            this.fetchLesson();
        }
        this.fetchStudents();
        this.fetchLessonTypes();
    };
    
    componentDidUpdate(prevProps, prevState){
        if((parseInt(this.props.chosen_driver) !== parseInt(prevProps.chosen_driver)) || (parseInt(this.state.booking_id) !== parseInt(prevState.booking_id))){
            this.fetchLesson();
        }
    }
    
    fetchLessonTypes = (params = {}) => {
        this.setState({ loadingLessonTypes: true });

        api.post("lessontypes/fetch",{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(data => {
            this.setState({
                loadingLessonTypes: false,
                lessonTypeData: data.result.lesson_types
            });
        });
    };

    handleLessonTypeChange = async (value) => {
        this.setState({
            visibleBookingElements: value
        });

        if (value === bookingTypes.test && this.state.duration < 2) {
            this.changeTimeModalShowModal();

            //Adjust the booking length to be test minimum (2 hours)
            await this.updateBookingToTestWhenCalendarLoaded();
        }

        // K2D, Aged Assessments and OT Lesson, OT Assessment and OT Assessment With Modifications are limited to one hour.
        if ([
                bookingTypes.k2d,
                bookingTypes.aged,
                bookingTypes.otLesson,
                bookingTypes.otAssessment,
                bookingTypes.otAssessmentWithModifications,
            ].includes(value) && this.state.duration > 1
        ) {
            //Update the state booking data to reflect the change
            let bookingObject = Object.assign({}, this.state.booking);
            bookingObject.end_time = moment(this.state.booking.start_time).add(1, 'hours').format();
            bookingObject.duration = 1;
            this.setState({
                booking: bookingObject,
                end_time: moment(this.state.booking.start_time).add(1, 'hours').format(),
                duration: 1
            }, () => {
                api.post("bookings/update", {
                    body: JSON.stringify({
                        chosen_driver: this.props.chosen_driver,
                        booking: this.state.booking_id,
                        start_time: this.state.start_time,
                        end_time: this.state.end_time
                    })
                });
            });
        }
    };

    updateBookingToTestWhenCalendarLoaded = async () => {
        if(this.state.changeTimeModalLoaded === true){
            if (this.state.duration === 1) {
                this.startTimeChange(moment(this.state.start_time).subtract(45, 'minutes'));
                this.durationIncrease();
                this.durationIncrease();
            }else if (this.state.duration === 1.5){
                this.startTimeChange(moment(this.state.start_time).subtract(15, 'minutes'));
                this.durationIncrease();
            }

            return true;
        }else{
            setTimeout(async () => { 
                return await this.updateBookingToTestWhenCalendarLoaded();
            }, 250);
        }
    }

    fetchStudents = (params = {}) => {
        this.setState({ loadingStudents: true });

        api.post("students/my",{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(data => {
            this.setState({
                loadingStudents: false
            }, () => {
                if(data.result.students.length > 0){
                    const students = [];
                    data.result.students.forEach((student) => {
                        students.push(<Option key={student.id}>{student.first_name + ' ' + student.last_name}</Option>);
                    });
                    this.setState({
                        studentData: students
                    });
                }
            });
        });
    };

    handleStudentChange = (value) => {
        // If we require lessons to be prepaid, check for a prepaid count and disallow a New Booking if Student has no prepaids remaining
        if (paymentBeforeBooking) {
            this.checkForEnoughPrepaidLessonsToCoverDuration(value, () => {
                this.fetchStudentAddresses(value);
            });
        } else {
            this.fetchStudentAddresses(value);
        }
    };

    fetchStudentAddresses = (value) => {
        //Fetch the Students' Addresses
        this.setState({
            loadingAddresses: true,
            addressData: [],
            addressDataPickup: null,
            addressDataDropoff: null,
            selectedStudent: null
        });

        api.post("students/addresses/" + value,{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            this.setState({
                loadingAddresses: false
            }, () => {
                if(data.result.addresses.all.length > 0){
                    const addresses = [];
                    data.result.addresses.all.forEach((address) => {
                        addresses.push(<Option key={address.id}>{address.address}</Option>);
                    });
                    this.setState({
                        selectedStudent: value,
                        addressData: addresses,
                        addressDataPickup: <Option key={data.result.addresses.common_pickup.id}>{data.result.addresses.common_pickup.address}</Option>,
                        addressDataDropoff: <Option key={data.result.addresses.common_dropoff.id}>{data.result.addresses.common_dropoff.address}</Option>
                    }, () => {
                        if (this.state.occurrencesVisible) {
                            this.checkRecurringAvailability();
                        }
                    });
                }
            });
        });
    };

    checkForEnoughPrepaidLessonsToCoverDuration = (value, callback) => {
        api.post("students/fetch/" + value,{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(data => {
            if(data.result.student.prepaid.remaining < this.state.duration){
                //Disable the Save Button, show a message
                this.setState({
                    noPrepaids: true
                }, () => {
                    Modal.error({
                        title: "Not Enough Lesson Credits",
                        content: (
                            <div>
                                <p>Student does not have enough lesson credits, change duration or visit profile to take payment.</p>
                            </div>
                        )
                    });
                });
            } else {
                this.setState({
                    noPrepaids: false
                });
            }
            if (typeof callback !== 'undefined') {
                callback();
            }
        });
    };

    handleAddressChange = (value) => {
        if(value === 'new'){
            this.setState({
                newAddressModalVisible: true
            });
        }
    };

    newAddressModalHandleCancel = (e) => {
        this.setState({
            newAddressModalVisible: false
        }, () => {
            if(this.pickupRef.current.props.value === 'new'){
                this.props.form.resetFields(['pick_up_address']);
            }
            if(this.dropoffRef.current.props.value === 'new'){
                this.props.form.resetFields(['drop_off_address']);
            }
        });
    };

    newAddressModalHandleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['street_number', 'street_name', 'suburb_id'], (err, values) => {
            if (!err) {
                //save to DB and then add to drop downs, select in the one we were using.
                values.chosen_driver = this.props.chosen_driver;
                api.post("address/add", {
                    body: JSON.stringify(values)
                })
                .then(res => {
                    let addressData = this.state.addressData;
                    addressData.push(<Option key={res.result.id}>{res.result.address}</Option>);
                    this.setState({
                        addressData: addressData
                    },() => {
                        if(this.pickupRef.current.props.value === 'new'){
                            this.props.form.setFieldsValue({
                                pick_up_address: [res.result.id.toString()]
                            });
                        }
                        if(this.dropoffRef.current.props.value === 'new'){
                            this.props.form.setFieldsValue({
                                drop_off_address: [res.result.id.toString()]
                            });
                        }
                        // Close this modal.
                        this.setState({
                            newAddressModalVisible: false
                        });
                    });
                });
            }
        });
    };

    disableAutoComplete = () => {
        if (!this.state.autoCompleteDisabled) {
            const elementsToDisable = document.getElementsByClassName("ant-select-search__field");
            for (let i = 0; i < elementsToDisable.length; i++) {
                elementsToDisable[i].setAttribute("autocomplete","any-arbitrary-text");
            }
            this.setState({ autoCompleteDisabled: true });
        }
    };

    handleSuburbSearch = value => {
        this.suburbLookup(value, suburbs => this.setState({ suburbs }));
    };

    suburbLookup = (value, callback) => {
        if (suburbLookupTimeout) {
            clearTimeout(suburbLookupTimeout);
            suburbLookupTimeout = null;
        }
        currentSuburb = value;

        function debouncedLookup() {
            api.post("suburbs/search", {
                body: JSON.stringify({
                    search: value
                })
            })
            .then(d => {
                if (currentSuburb === value) {
                    const data = [];
                    d.result.suburbs.forEach(r => {
                        data.push({
                            value: r.id,
                            text: r.suburb_name
                        });
                    });
                    callback(data);
                }
            });
        }
        suburbLookupTimeout = setTimeout(debouncedLookup, 300);
    }
    
    handleSave = async (e) => {
        e.preventDefault();
        let fieldsToValidate = ['student_id', 'booking_type_id', 'start_date', 'pick_up_address', 'drop_off_address'];

        //Test
        if(this.state.visibleBookingElements === bookingTypes.test){
            fieldsToValidate.push('test_centre');
            fieldsToValidate.push('own_car');
            fieldsToValidate.push('tentative');
        }

        //Keys2Drive
        if(this.state.visibleBookingElements === bookingTypes.k2d){
            fieldsToValidate.push('k2d_code');
        }
        
        return this.props.form.validateFields(fieldsToValidate, async (err, values) => {
            if (!err) {
                this.setState({
                    buttonsDisabled: true
                }, () => {
                    values.chosen_driver = this.props.chosen_driver;
                    values.booking_id = this.state.booking_id;
                    values.enquiry_location = productName;

                    if (showSendNotificationToStudentCheckbox) {
                        // As of 2023-10-06, Don't show Notification Checkbox to DT Users
                        if (this.props.office_user) {
                            if (document.getElementById('send-confirmation-checkbox') !== null) {
                                values.send_confirmation = document.getElementById('send-confirmation-checkbox').checked;
                            } else {
                                values.send_confirmation = false;
                            }
                            // NEVER send for Tentative Tests
                            if (values.booking_type_id === bookingTypes.test && values.tentative) {
                                values.send_confirmation = false;
                            }
                        } else {
                            // For DT's, ALWAYS send Notification
                            let sendToStudent = true;
                            // UNLESS it's a Tentative Test Booking
                            if (values.booking_type_id === bookingTypes.test && values.tentative) {
                                sendToStudent = false;
                            }
                            values.send_confirmation = sendToStudent;
                        }
                    }

                    return api.post("bookings/add", {
                        body: JSON.stringify(values)
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }
                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK' || (res.status === 'OK' && res.result.success === false)){
                            this.setState({
                                buttonsDisabled: false
                            }, () => {
                                Modal.error({
                                    title: "Error Confirming Booking",
                                    content: (
                                        <div>
                                            <p>There was an error when trying to Save this Booking.</p>
                                             {(typeof(res.result.error) !== 'undefined' ? <p>{res.result.error}</p> : '')}
                                            <p>If this problem persists, please contact the Office for assistance.</p>
                                        </div>
                                    )
                                });
                            });
                        } else {
                            this.setState({
                                unmountOkay: true
                            }, () => {
                                this.props.history.push('/lesson/' + this.state.booking_id);
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            buttonsDisabled: false
                        }, () => {
                            Modal.error({
                                title: "Error Confirming Booking",
                                content: (
                                    <div>
                                        <p>There was an error when trying to Save this Booking.</p>
                                        <p>If this problem persists, please contact the Office for assistance.</p>
                                    </div>
                                )
                            });
                        });
                    });
                });
            }
        });
    };

    fetchLesson = async() => {
        await api.post("lessons/fetch/" + this.state.booking_id, {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.booking === 'undefined' || res.result.booking === null) {
                    this.props.history.push('/diary');
                } else if(res.result.booking.reservation_info === null) {
                    console.log('This is not a reservation');
                    this.props.history.push('/diary');
                } else {
                    res.result.booking.duration = moment(res.result.booking.end_time).diff(moment(res.result.booking.start_time), 'minutes')/60;

                    // Update State
                    this.setState({
                        loading: false,
                        booking: res.result.booking,
                        start_time: res.result.booking.start_time,
                        end_time: res.result.booking.end_time,
                        duration: res.result.booking.duration,
                        test_centres: res.result.test_centres, 
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/diary');
        });
    };

    handleRepeatsFrequencyChange = (value) => {
        this.setState({
            occurrencesVisible: value !== 'None',
            occurrencesFrequencyString: value,
            occurrencesLoading: true
        }, () => {
            this.checkRecurringAvailability();
        });
    };
    
    handleRepeatsVolumeChange = (value) => {
        this.setState({
            occurrencesNumber: value,
            occurrencesLoading: true
        }, () => {
            this.checkRecurringAvailability();
        });
    };
    
    checkRecurringAvailability = async() => {
        await this.setState({
            occurrencesLoading: true
        }, async () => {
            await api.post("bookings/reserve-repeats", {
                    body: JSON.stringify({
                        chosen_driver: this.props.chosen_driver,
                        student: this.state.selectedStudent,
                        frequency: this.state.occurrencesFrequencyString,
                        occurrences: this.state.occurrencesNumber,
                        start_time: this.state.booking.start_time,
                        end_time: this.state.booking.end_time,
                        duration: this.state.booking.duration,
                        booking_id: this.state.booking_id
                    })
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if(typeof res === 'string'){
                        res = JSON.parse(res);
                    }

                    //Check for an error response (status of "NOK")
                    if(res.status === 'NOK' || typeof res.result.results === 'undefined' || res.result.results === null) {
                        this.props.history.push('/diary');
                    } else {
                        // Update State
                        this.setState({
                            occurrencesLoading: false,
                            occurrencesData: res.result.results,
                            occurrencesError: null
                        }, () => {
                            this.state.occurrencesData.every((x) => {
                                if(x.error !== null){
                                    this.setState({
                                        occurrencesError: false
                                    }, () => {
                                        return false;
                                    });
                                }
                                return true;
                            });
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.props.history.push('/diary');
            });
        });
    };

    calendarRef = React.createRef();
    timePickerRef = React.createRef();
    pickupRef = React.createRef();
    dropoffRef = React.createRef();

    eventFetch = (params = {}, successCallback) => {
        params.chosen_driver = this.props.chosen_driver;
        diaryHelper.eventFetch(params, successCallback);
    };

    calendarLoading = () => {
        this.setState({calendarLoading: !this.state.calendarLoading}, () => {
            if(this.state.calendarLoading){
                // Show Spinner
                if (document.getElementsByClassName('calendar-spinner').length > 0) {
                    document.getElementsByClassName('calendar-spinner')[0].style['display'] = 'block';
                }
                if (document.getElementsByClassName('fc').length > 0) {
                    document.getElementsByClassName('fc')[0].style['visibility'] = 'hidden';
                }
            }else{
                // Hide Spinner
                if (document.getElementsByClassName('calendar-spinner').length > 0) {
                    document.getElementsByClassName('calendar-spinner')[0].style['display'] = 'none';
                }
                if (document.getElementsByClassName('fc').length > 0) {
                    document.getElementsByClassName('fc')[0].style['visibility'] = 'visible';
                }
            }
        });
    };

    changeTimeModalShowModal = () => {
        this.setState({
            changeTimeModalVisible: true
        });
    };

    changeTimeModalHandleOk = () => {
        this.setState({
            changeTimeModalLoading: true
        }, () => {
            //Do Save Request
            document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = true;
            api.post("bookings/update", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    booking: this.state.booking_id,
                    start_time: this.state.start_time,
                    end_time: this.state.end_time
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    Modal.error({
                        title: "Error Updating Booking Date/Time",
                        content: (
                            <div>
                                <p>There was an error when trying to Update this Booking.</p>
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                    document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = false;
                    this.setState({
                        changeTimeModalLoading: false
                    });
                } else if(!res.result.success) {
                    Modal.error({
                        title: "Error Updating Booking Date/Time",
                        content: (
                            <div>
                                <p>There was an error when trying to Update this Booking.</p>
                                 {(typeof(res.result.error) !== 'undefined' ? <p>{res.result.error}</p> : '')}
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                    document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = false;
                    this.setState({
                        changeTimeModalLoading: false
                    });
                } else {
                    //Success - Re-Fetch the lesson to get updated Payment info and Times.
                    this.fetchLesson();
                    //Check for Prepaids if required
                    if (paymentBeforeBooking) {
                        this.checkForEnoughPrepaidLessonsToCoverDuration(this.state.selectedStudent);
                    }
                    //Show a Success Modal
                    Modal.success({
                        title: "Booking Updated!",
                        content: (
                            <div>
                                <p>The Booking has been successfully updated.</p>
                            </div>
                        ),
                        onOk: () => {
                            Modal.destroyAll();
                            this.setState({
                                changeTimeModalLoading: false,
                                changeTimeModalLoaded: false,
                                changeTimeModalVisible: false
                            });
                        }
                    });
                }
            })
            .catch(() => {
                Modal.error({
                    title: "Error Updating Booking Date/Time",
                    content: (
                        <div>
                            <p>There was an error when trying to Update this Booking.</p>
                            <p>If this problem persists, please contact the Office for assistance.</p>
                        </div>
                    )
                });
                document.querySelector('.adjust-time-modal .ant-modal-footer button').disabled = false;
                this.setState({
                    changeTimeModalLoading: false
                });
            });
        });
    };

    changeTimeModalHandleCancel = () => {
        if(this.state.visibleBookingElements === bookingTypes.test && this.state.booking.duration < 2 && (this.state.duration < 2 || this.state.overlap)){
            Modal.error({
                title: "Please update booking length and time",
                content: (
                    <div>
                        <p>A test booking must be at least two hours long (45 minutes warm up time).</p>
                    </div>
                )
            });

            return false;
        }else{
            //Reset the start time, end time and duration to their original states.
            this.setState({
                changeTimeModalVisible: false,
                changeTimeModalLoaded: false,
            }, () => {
                this.setState({
                    start_time: this.state.booking.start_time,
                    end_time: this.state.booking.end_time,
                    duration: moment(this.state.booking.end_time).diff(moment(this.state.booking.start_time), 'minutes')/60
                });
            });
        }
    };

    handleCancelLesson = (e) => {
        this.setState({
            buttonsDisabled: true
        }, () => {
            return api.post("bookings/cancelReservation", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    booking: this.state.booking_id
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }

                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || !res.result.success) {
                    this.setState({
                        buttonsDisabled: false
                    }, () => {
                        Modal.error({
                            title: "Error Cancelling Booking",
                            content: (
                                <div>
                                    <p>There was an error when trying to Cancel this Booking.</p>
                                    <p>If this problem persists, please contact the Office for assistance.</p>
                                </div>
                            )
                        });
                    });
                } else {
                    this.props.history.goBack();
                }
            })
            .catch(() => {
                this.setState({
                    buttonsDisabled: false
                }, () => {
                    Modal.error({
                        title: "Error Cancelling Booking",
                        content: (
                            <div>
                                <p>There was an error when trying to Cancel this Booking.</p>
                                <p>If this problem persists, please contact the Office for assistance.</p>
                            </div>
                        )
                    });
                });
            });
        });
    };

    changeTimeModalDisabledDates = (current) => {
        if(this.props.office_user) {
            return current < moment().startOf('day').subtract(historyEditDays, 'days');
        } else {
            return current < moment().startOf('day');
        }
    };

    changeTimeModalHandleDatePickerChange = (chosen_date) => {
        this.setState({
            start_time: chosen_date.set({
                hours: moment(this.state.start_time).hours(), 
                minutes: moment(this.state.start_time).minutes()
            }).format(),
            end_time: chosen_date.add(this.state.duration, 'hours').format()
        }, () => {
            let calendarApi = this.calendarRef.current.getApi();
            // Get the Original Event details
            let originalEvent = calendarApi.getEventById(this.state.booking.id);
            // Change the date of the Calendar
            calendarApi.gotoDate(chosen_date.format('Y-MM-DD'));
            // Render a new Event in place on the new date.
            let newEvent = {
                id: this.state.booking.id,
                title: originalEvent.title,
                classNames: originalEvent.classNames,
                extendedProps: originalEvent.extendedProps,
                start: this.state.start_time,
                end: this.state.end_time //moment(this.state.start_time).add(this.state.duration, 'hours').format()
            };
            // Remove the original Event, to avoid ID clashes.
            originalEvent.remove();
            calendarApi.addEvent(newEvent);
        });
    };

    startTimeChange = (time) => {
        // Close the Time Picker
        this.timePickerRef.current.timePickerRef.setOpen(false);

        if((Math.floor((moment(time).startOf('day').add(dayEnd, 'hours').diff(moment(time), 'minutes')/60)*2)/2) < 1) {
            Modal.error({
                title: 'Error',
                content: (<div><p>You can not start this Booking any later in the day.</p></div>)
            });
        } else {
            if (time.hours() < dayStart) {
                time.set({hours: dayStart});
            }else if (time.hours() >= dayEnd) {
                time.set({hours: (dayEnd - 1)});
            }
            this.setState({
                start_time: time.format(),
                end_time: time.clone().add(this.state.duration, 'hours').format()
            }, () => {
                // get the specific booking calendar event
                let calendarApi = this.calendarRef.current.getApi();
                let eventToUpdate = calendarApi.getEventById(this.state.booking.id);
                //Adjust the display to the new start time, moving the end time to keep duration.
                eventToUpdate.setStart(time.format(), {maintainDuration: true});

                //Check if End Time is now outside bounds of Calendar
                if(
                    (new Date(this.state.end_time).getDate() > new Date(this.state.start_time).getDate())
                ||
                    (new Date(this.state.end_time).getHours() >= dayEnd)
                ) {
                    // End Time is out of bounds - Update End and Duration
                    this.setState({
                        end_time: moment(this.state.start_time).add(Math.floor((moment(this.state.start_time).startOf('day').add(dayEnd, 'hours').diff(moment(this.state.start_time), 'minutes')/60)*2)/2, 'hours').format(),
                        duration: Math.floor((moment(this.state.start_time).startOf('day').add(dayEnd, 'hours').diff(moment(this.state.start_time), 'minutes')/60)*2)/2
                    }, () => {
                        //Update the displayed Event
                        eventToUpdate.setEnd(moment(this.state.end_time).format(), {maintainDuration: false});
                    });
                }
            });
        }
    };

    durationIncrease = async () => {
        // Cap the max-length of a lesson to dayEnd - start_time in hours
        if((this.state.duration + 0.5) > (moment(this.state.start_time).startOf('day').add(dayEnd, 'hours').diff(moment(this.state.start_time), 'minutes')/60)) {
            Modal.error({
                title: 'Error',
                content: (<div><p>You can not extend this Booking any further as it will extend beyond the end of the day.</p></div>)
            });

            return false;
        } else {
            this.setState({
                duration: (this.state.duration + 0.5),
                end_time: moment(this.state.start_time).add((this.state.duration + 0.5), 'hours').format()
            }, () => {
                // get the specific booking calendar event
                let calendarApi = this.calendarRef.current.getApi();
                let eventToUpdate = calendarApi.getEventById(this.state.booking.id);

                //Adjust the display by 1/2 an hour
                eventToUpdate.setEnd(eventToUpdate.end.getTime() + (0.5 * 60 * 60 * 1000));
            });

            return true;
        }
    };

    durationDecrease = async () => {
        if(this.state.duration <= 1 || (this.state.visibleBookingElements === bookingTypes.test && this.state.duration <= 2)){
            Modal.error({
                title: 'Error',
                content: (
                    <div>
                        <p>You can not reduce this Booking any further.</p>
                        <p>To cancel the Booking, please click the Cancel Booking button.</p>
                    </div>
                )
            });

            return false;
        }else{
            this.setState({
                duration: (this.state.duration - 0.5),
                end_time: moment(this.state.start_time).add((this.state.duration - 0.5), 'hours').format()
            }, () => {
                // get the specific booking calendar event
                let calendarApi = this.calendarRef.current.getApi();
                let eventToUpdate = calendarApi.getEventById(this.state.booking.id);
                
                //Adjust the display by 1/2 an hour
                eventToUpdate.setEnd(eventToUpdate.end.getTime() - (0.5 * 60 * 60 * 1000));
            });

            return true;
        }
    };

    warmupIncrease = async () => {
        //Only change the start time if the length increase is successful
        await this.durationIncrease()
            .then(result => {
                if (result) {
                    this.startTimeChange(moment(this.state.start_time).subtract(30, 'minutes'));
                }
            });
    }

    warmupDecrease = async () => {
        //Only change the start time if the length decrease is successful
        await this.durationDecrease()
            .then(result => {
                if (result) {
                    this.startTimeChange(moment(this.state.start_time).add(30, 'minutes'));
                }
            });
    }

    handleEventRender = (info) => {
        let title = info.el.querySelector('.fc-title');
        if(title !== null){
            title.innerHTML = title.innerHTML + 
                (typeof(info.event.extendedProps.test_location) !== 'undefined' && info.event.extendedProps.test_location !== '' && info.event.extendedProps.test_location !== null ? "<br />Test Start Time: " + info.event.extendedProps.test_start_time + "<br />Test Center: " + info.event.extendedProps.test_location : "") + 
                (typeof(info.event.extendedProps.start_location) !== 'undefined' && info.event.extendedProps.start_location !== '' && info.event.extendedProps.start_location !== null ? "<br />" + (info.event.extendedProps.booking_type === 'Event' ? "Event Location: " : "Pick Up: ") + info.event.extendedProps.start_location : '') + 
                (typeof(info.event.extendedProps.end_location) !== 'undefined' && info.event.extendedProps.end_location !== '' && info.event.extendedProps.end_location !== null ? " - Drop Off: " + info.event.extendedProps.end_location : '');
        }

        this.setState({
            changeTimeModalLoaded: true
        });
    };

    checkOverlap = (info) => {
        if(parseInt(info.event.id) === parseInt(this.state.booking.id)) {
            this.setState({overlap: false}, () => {
                //Get All available Events
                let calendarApi = this.calendarRef.current.getApi();
                let events = calendarApi.getEvents();

                // Loop all events, ignore our one - if any others are inside/overlapping our bounds, show error and disable Save button.
                events.forEach((event) => {
                    // Ignore this event.
                    if(parseInt(this.state.booking.id) === parseInt(event.id)){
                        return;
                    }
                    // All Day Events are okay for clashes
                    if(event.allDay){
                        return;
                    }
                    // Background Events are okay for clashes
                    if(event.rendering === 'inverse-background' || event.rendering === 'background'){
                        return;
                    }

                    // Now we need to check the overlap itself...
                    let eventStart = new Date(event.start.getTime() + (new Date(event.start).getTimezoneOffset() * 60 * 1000));
                    let eventEnd = new Date(event.end.getTime() + (new Date(event.end).getTimezoneOffset() * 60 * 1000));
                    if(
                        (moment(eventStart) >= moment(this.state.start_time) && moment(eventStart) < moment(this.state.end_time)) ||
                        (moment(eventEnd) > moment(this.state.start_time) && moment(eventEnd) <= moment(this.state.end_time)) ||
                        (moment(this.state.start_time) >= moment(eventStart) && moment(this.state.start_time) < moment(eventEnd)) ||
                        (moment(this.state.end_time) > moment(eventStart) && moment(this.state.end_time) <= moment(eventEnd))
                    ){
                        this.setState({overlap: true});
                    }
                });
            });
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 7},
                xl: {span: 9}
            },
            wrapperCol: {
                xs: {span: 17},
                xl: {span: 15}
            }
        };
        const suburbs = this.state.suburbs.map(d => <Option key={d.value}>{d.text}</Option>);

        if(this.state.disabled_hours.length === 0){
            for (let i = 0; i < 24; i++){
                if(i < dayStart || i >= dayEnd){
                    this.state.disabled_hours.push(i);
                }
            }
        }
        
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h2>Create Booking</h2>
                    {
                    this.state.loading || this.state.loadingStudents ? 
                        (
                            <Spinner type="mega" />
                        ) : (
                            <>
                                <Form {...formItemLayout} id="lessonForm">
                                    <Form.Item label="Student" >
                                        {getFieldDecorator('student_id', {
                                            rules: [{ required: true, message: 'Please select a Student.' }],
                                        })(
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                loading={this.state.loadingStudents}
                                                onChange={this.handleStudentChange}
                                                placeholder="Select a Student"
                                                autoFocus={true}
                                                disabled={this.state.buttonsDisabled}
                                            >
                                                {this.state.studentData}
                                            </Select>
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Lesson Type" >
                                        {getFieldDecorator('booking_type_id', {
                                            initialValue: this.state.booking.booking_type_id,
                                            rules: [{ required: true, message: 'Please select a Lesson Type.' }],
                                        })(
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                loading={this.state.loadingLessonTypes}
                                                onChange={this.handleLessonTypeChange}
                                                disabled={this.state.buttonsDisabled}
                                            >
                                                {
                                                    this.state.lessonTypeData.map((centre, index) => {
                                                        return <Option key={index} value={centre.id}>{centre.name}</Option>;
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>

                                    {this.state.visibleBookingElements === bookingTypes.k2d && (
                                        <>
                                            <Form.Item label="Keys2Drive Code" >
                                            {getFieldDecorator('k2d_code', {
                                                rules: [{ required: true, message: 'Please select a Keys2Drive Code.' }],
                                            })(
                                                <Input />
                                            )}
                                            </Form.Item>
                                        </>
                                    )}

                                    <Form.Item label="Date" >
                                        {getFieldDecorator('start_date', {
                                            initialValue: (typeof this.state.booking.start_time !== 'undefined' && this.state.booking.start_time !== null ? moment(this.state.booking.start_time).format('dddd D/MM/YYYY') : null),
                                            rules: [{ required: true, message: 'Please enter a Date.' }],
                                        })(
                                            <Input readOnly={true} />
                                        )}
                                        <Link to={"/diary/" + moment(this.state.booking.start_time).format('YYYY-MM-DD')}>
                                            <FontAwesomeIcon icon={faCalendar} size="lg" fixedWidth/>
                                        </Link>
                                    </Form.Item>
                                    <Form.Item label="Time" >
                                        {getFieldDecorator('start_time', {
                                            rules: [{ required: true, message: 'Please enter a Time.' }],
                                            initialValue: (typeof this.state.booking.start_time !== 'undefined' && this.state.booking.start_time !== null ? moment(this.state.booking.start_time).format('h:mma') : null)
                                        })(
                                            <Input readOnly={true} className="time-input" />
                                        )}
                                        <FontAwesomeIcon icon={faMinus} fixedWidth size="lg" style={{marginRight:'10px'}} />
                                        <Input readOnly={true} className="time-input" value={(typeof this.state.booking.end_time !== 'undefined' && this.state.booking.end_time !== null ? moment(this.state.booking.end_time).format('h:mma') : null)} />
                                        <Button type="primary"
                                            onClick={this.changeTimeModalShowModal}
                                            disabled={this.state.buttonsDisabled}
                                        >
                                            <FontAwesomeIcon icon={faClock} />Adjust Date/Time{this.state.visibleBookingElements === bookingTypes.test && "/Warmup"}
                                        </Button>
                                    </Form.Item>

                                    {this.state.visibleBookingElements === bookingTypes.lesson && (
                                        <>
                                            <Form.Item label="Repeats" style={{marginBottom: 0}}>
                                                {getFieldDecorator('repeats', {
                                                    initialValue: 'None',
                                                    rules: [{ required: true, message: 'Please select an option.' }],
                                                })(
                                                    <Select
                                                        disabled={this.state.selectedStudent === null || this.state.buttonsDisabled}
                                                        onChange={this.handleRepeatsFrequencyChange}
                                                    >
                                                        <Option key='None' value='None'>Does Not Repeat</Option>
                                                        <Option key='Day' value='Day'>Daily</Option>
                                                        <Option key='Week' value='Week'>Weekly{(typeof this.state.booking.start_time !== 'undefined' && this.state.booking.start_time !== null ? " on " + moment(this.state.booking.start_time).format('dddd') : null)}</Option>
                                                        <Option key='Fortnight' value='Fortnight'>Fortnightly{(typeof this.state.booking.start_time !== 'undefined' && this.state.booking.start_time !== null ? " on " + moment(this.state.booking.start_time).format('dddd') : null)}</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            {this.state.visibleBookingElements === bookingTypes.lesson && this.state.occurrencesVisible && (
                                                <Form.Item label='Repeats for' style={{marginBottom: 0}}>
                                                    {getFieldDecorator('occurrences', {
                                                        rules: [{ required: true, message: 'Please select an option.' }],
                                                    })(
                                                        <>
                                                            <Select
                                                                className='inline-select'
                                                                onChange={this.handleRepeatsVolumeChange}
                                                                defaultValue={this.state.occurrencesNumber}
                                                                disabled={this.state.buttonsDisabled}
                                                            >
                                                                <Option key={1} value={1}>1</Option>
                                                                <Option key={2} value={2}>2</Option>
                                                                <Option key={3} value={3}>3</Option>
                                                                <Option key={4} value={4}>4</Option>
                                                                <Option key={5} value={5}>5</Option>
                                                                <Option key={6} value={6}>6</Option>
                                                                <Option key={7} value={7}>7</Option>
                                                                <Option key={8} value={8}>8</Option>
                                                                <Option key={9} value={9}>9</Option>
                                                                <Option key={10} value={10}>10</Option>
                                                            </Select>
                                                            {this.state.occurrencesFrequencyString}{this.state.occurrencesNumber > 1 && ('s')}.
                                                        </>
                                                    )}
                                                </Form.Item>
                                            )}
                                        </>
                                    )}

                                    {this.state.visibleBookingElements === bookingTypes.lesson && this.state.occurrencesVisible && (
                                        <>
                                            <div className="ant-row ant-form-item">
                                                <div className="ant-col ant-form-item-label ant-col-xs-7 ant-col-xl-9">
                                                </div>
                                                <div className="ant-col ant-form-item-control-wrapper ant-col-xs-17 ant-col-xl-15">
                                                    <div id='recurringDates'>
                                                        <h4 style={{margin: 0}}>Repeating Lesson Dates</h4>
                                                        {this.state.occurrencesLoading ? (
                                                            <>
                                                                <Spinner type="inline" /> <span>Checking Dates...</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Table
                                                                    id="recurringTable"
                                                                    rowKey={record => record.date}
                                                                    dataSource={Object.values(this.state.occurrencesData)}
                                                                    showHeader={false}
                                                                    pagination={false}
                                                                >
                                                                    <Column
                                                                        title="Date"
                                                                        key="date"
                                                                        render={(text, record) => (
                                                                            <span>{moment(record.date).format('dddd D/MM/YY h:mma')}</span>
                                                                        )}
                                                                    />
                                                                    <Column
                                                                        title="Status"
                                                                        key="status"
                                                                        render={(text, record) => (
                                                                            <>
                                                                                {record.error === null &&
                                                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                                                }
                                                                                {record.error !== null &&
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={faTimesCircle} /> {record.error}
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Table>
                                                                {this.state.occurrencesError !== null && (
                                                                    <Alert
                                                                        type="error"
                                                                        message="Only lessons with a Green Tick will be booked."
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <h3>Address Details</h3>
                                    <Form.Item label="Pick Up Address" >
                                        {getFieldDecorator('pick_up_address', {
                                            initialValue: (this.state.addressData.length === 1 ? this.state.addressData[0].key : (this.state.addressDataPickup !== null ? this.state.addressDataPickup.key : [])),
                                            rules: [{ required: true, message: 'Please select an Address.' }],
                                        })(
                                            <Select
                                                disabled={this.state.selectedStudent === null || this.state.buttonsDisabled}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={this.pickupRef}
                                                loading ={this.state.loadingAddresses}
                                                placeholder="Select a Location"
                                                onChange={this.handleAddressChange}
                                            >
                                                {this.state.addressData}
                                                <Option value="new">Add New Address</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Drop Off Address" >
                                        {getFieldDecorator('drop_off_address', {
                                            initialValue: (this.state.addressData.length === 1 ? this.state.addressData[0].key : (this.state.addressDataDropoff !== null ? this.state.addressDataDropoff.key : [])),
                                            rules: [{ required: true, message: 'Please select an Address.' }],
                                        })(
                                            <Select
                                                disabled={this.state.selectedStudent === null || this.state.buttonsDisabled}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={this.dropoffRef}
                                                loading ={this.state.loadingAddresses}
                                                placeholder="Select a Location"
                                                onChange={this.handleAddressChange}
                                            >
                                                {this.state.addressData}
                                                <Option value="new">Add New Address</Option>
                                            </Select>
                                        )}
                                    </Form.Item>

                                    {this.state.visibleBookingElements === bookingTypes.test && (
                                        <>
                                            <h3>Test Details</h3>
                                            <Form.Item label="Test Centre" >
                                                {getFieldDecorator('test_centre', {
                                                    rules: [{ required: true, message: 'Please select a Test Centre.' }],
                                                })(
                                                    <Select
                                                        labelInValue
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        placeholder="Select a Test Centre"
                                                        disabled={this.state.buttonsDisabled}
                                                    >
                                                        {
                                                            this.state.test_centres.map((centre, index) => {
                                                                return <Option key={index} value={centre.id}>{centre.name}</Option>;
                                                            })
                                                        }
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Own Car?" >
                                                {getFieldDecorator('own_car', {
                                                    valuePropName: 'checked',
                                                    initialValue: this.state.booking.own_car
                                                })(
                                                    <Switch
                                                        checkedChildren="Yes" 
                                                        unCheckedChildren="No" 
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Tentative?" >
                                                {getFieldDecorator('tentative', {
                                                    valuePropName: 'checked',
                                                    initialValue: this.state.booking.tentative
                                                })(
                                                    <Switch
                                                        checkedChildren="Yes" 
                                                        unCheckedChildren="No" 
                                                    />
                                                )}
                                            </Form.Item>
                                        </>
                                    )}

                                    {/* As of 2023-10-06, Don't show Notification Checkbox to DT Users */}
                                    {showSendNotificationToStudentCheckbox && this.props.office_user && (
                                        // Not a Tentative Test..
                                        this.props.form.getFieldValue('booking_type_id') !== bookingTypes.test ||
                                        (
                                            this.props.form.getFieldValue('booking_type_id') === bookingTypes.test && 
                                            this.props.form.getFieldValue('tentative') !== true
                                        )
                                    ) && (
                                        <div className='form-buttons' style={{marginBottom: '20px'}}>
                                            <Checkbox
                                                defaultChecked
                                                key="send-confirmation-checkbox"
                                                id="send-confirmation-checkbox"
                                            >
                                                Send confirmation to Student?
                                            </Checkbox>
                                        </div>
                                    )}

                                    <div className='form-buttons'>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            onClick={this.handleSave}
                                            loading={this.state.buttonsDisabled}
                                            disabled={this.state.buttonsDisabled || this.state.loadingAddresses || this.state.noPrepaids}
                                        >
                                            <FontAwesomeIcon icon={faSave} />Save Booking
                                        </Button>
                                        <Button
                                            type="danger"
                                            htmlType="button"
                                            onClick={this.handleCancelLesson}
                                            disabled={this.state.buttonsDisabled}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>
                                    </div>

                                </Form>

                                <Modal
                                    destroyOnClose={true}
                                    className="adjust-time-modal"
                                    visible={this.state.changeTimeModalVisible}
                                    title={"Adjust Date/Time" + (this.state.visibleBookingElements === bookingTypes.test ? " and Warmup" : '')}
                                    onOk={this.changeTimeModalHandleOk}
                                    onCancel={this.changeTimeModalHandleCancel}
                                    footer={[
                                        <Alert
                                            key="overlap-alert"
                                            style={{display:(this.state.overlap ? 'inline-block' : 'none')}}
                                            className="overlap-alert"
                                            type="error"
                                            banner
                                            message="Cannot save overlapping bookings"
                                        />,
                                        <Button
                                            key="back"
                                            onClick={this.changeTimeModalHandleCancel}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                        <Button
                                            key="submit"
                                            type="primary"
                                            loading={this.state.changeTimeModalLoading}
                                            onClick={this.changeTimeModalHandleOk}
                                            disabled={this.state.overlap || this.state.changeTimeModalLoading || this.state.calendarLoading}
                                        >
                                            <FontAwesomeIcon icon={faSave} />Save
                                        </Button>,
                                    ]}
                                    >
                                    <DatePicker
                                        allowClear={false}
                                        defaultValue={moment(this.state.start_time)}
                                        format={dateFormatList}
                                        size="large"
                                        onChange={this.changeTimeModalHandleDatePickerChange}
                                        disabled={this.state.calendarLoading}
                                        disabledDate={this.changeTimeModalDisabledDates}
                                        style={{width: '130px'}}
                                    />
                                    <Form layout="inline" style={{marginBottom: '10px'}}>
                                        <Form.Item label="Time" >
                                            {getFieldDecorator('modal_start_time', {
                                                initialValue: moment(this.state.start_time)
                                            })(
                                                <TimePicker
                                                    allowClear={false}
                                                    use12Hours
                                                    format='h:mm a'
                                                    minuteStep={5}
                                                    disabled={this.state.calendarLoading}
                                                    style={{width: '100px'}}
                                                    ref={this.timePickerRef}
                                                    onChange={this.startTimeChange}
                                                    inputReadOnly={true}
                                                    disabledHours={() => this.state.disabled_hours}
                                                    hideDisabledOptions={true}
                                                />
                                            )}
                                        </Form.Item>

                                        {![
                                            bookingTypes.k2d,
                                            bookingTypes.aged,
                                            bookingTypes.otLesson,
                                            bookingTypes.otAssessment,
                                            bookingTypes.otAssessmentWithModifications,
                                        ].includes(this.state.visibleBookingElements) && (
                                            <Form.Item label="Hours" >
                                                {getFieldDecorator('modal_duration', {
                                                    initialValue: this.state.duration
                                                })(
                                                    <Input
                                                        style={{width: '50px', textAlign: 'center'}}
                                                        disabled={this.state.calendarLoading}
                                                        readOnly={true}
                                                    />
                                                )}
                                                {this.state.visibleBookingElements !== bookingTypes.test && (
                                                    <ButtonGroup>
                                                        <Button
                                                            type="dashed"
                                                            disabled={this.state.calendarLoading}
                                                            onClick={this.durationIncrease}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} fixedWidth />
                                                        </Button>
                                                        <Button
                                                            type="dashed"
                                                            disabled={this.state.calendarLoading}
                                                            onClick={this.durationDecrease}
                                                        >
                                                            <FontAwesomeIcon icon={faMinus} fixedWidth />
                                                        </Button>
                                                    </ButtonGroup>
                                                )}
                                            </Form.Item>
                                        )}
                                        {this.state.visibleBookingElements === bookingTypes.test && (
                                            <Form.Item label="Warmup (Minutes)" >
                                                {getFieldDecorator('modal_duration', {
                                                    initialValue: ((this.state.duration - 1.25) * 60)
                                                })(
                                                    <Input
                                                        style={{width: '50px', textAlign: 'center'}}
                                                        disabled={this.state.calendarLoading}
                                                        readOnly={true}
                                                    />
                                                )}
                                                <ButtonGroup>
                                                    <Button
                                                        type="dashed"
                                                        disabled={this.state.calendarLoading}
                                                        onClick={this.warmupIncrease}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                                                    </Button>
                                                    <Button
                                                        type="dashed"
                                                        disabled={this.state.calendarLoading}
                                                        onClick={this.warmupDecrease}
                                                    >
                                                        <FontAwesomeIcon icon={faMinus} fixedWidth />
                                                    </Button>
                                                </ButtonGroup>
                                            </Form.Item>
                                        )}
                                    </Form>
                                    <Spinner type="calendar" />
                                    <FullCalendar
                                        key={"diaryCal" + this.state.booking.id}
                                        ref={this.calendarRef}
                                        defaultView="timeGridDay"
                                        defaultDate={this.state.start_time}
                                        scrollTime={moment(this.state.start_time).subtract(1, 'hours').format('HH:mm:ss')}
                                        slotDuration='00:30:00'
                                        events={this.eventFetch}
                                        loading={this.calendarLoading}
                                        eventRender={this.handleEventRender}
                                        eventPositioned={this.checkOverlap}
                                        plugins={[ timeGridPlugin ]}
                                        timeZone='Australia/Sydney'
                                        nowIndicator={true}
                                        height={'parent'}
                                        header={null}
                                        minTime={dayStart + ':00:00'}
                                        maxTime={dayEnd + ':00:00'}
                                    />
                                </Modal>
                                
                                <Modal
                                    destroyOnClose={true}
                                    className="new-address-modal"
                                    visible={this.state.newAddressModalVisible}
                                    title="New Address"
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={this.newAddressModalHandleCancel}
                                        >
                                            <FontAwesomeIcon icon={faBan} />Cancel
                                        </Button>,
                                        <Button
                                            form="newAddressForm"
                                            key="submit"
                                            htmlType="submit"
                                            type="primary"
                                            loading={this.state.newAddressModalLoading}
                                            disabled={this.state.newAddressModalLoading}
                                        >
                                            <FontAwesomeIcon icon={faSave} />Save
                                        </Button>
                                    ]}
                                >
                                    <Form
                                        id="newAddressForm"
                                        onSubmit={this.newAddressModalHandleOk}
                                    >
                                        <Form.Item label="Street Number" >
                                            {getFieldDecorator('street_number', {
                                                rules: [
                                                    { required: true, message: 'Please enter a Street Number.' },
                                                    { max: 10, message: 'Street Number cannot be more than 10 characters.' },
                                                ],
                                            })(
                                                <Input
                                                    required="required"
                                                    autoFocus={true}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Street Name" >
                                            {getFieldDecorator('street_name', {
                                                rules: [{ required: true, message: 'Please enter a Street Name.' }],
                                            })(
                                                <Input required="required" />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Suburb" >
                                            {getFieldDecorator('suburb_id', {
                                                rules: [{ required: true, message: 'Please enter a Suburb.' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    placeholder="Select Suburb"
                                                    defaultActiveFirstOption={false}
                                                    showArrow={false}
                                                    filterOption={false}
                                                    onSearch={this.handleSuburbSearch}
                                                    onFocus={this.disableAutoComplete}
                                                    notFoundContent={null}
                                                    >
                                                    {suburbs}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Form>
                                </Modal>

                            </>
                        )
                    } 
                </Col>
            </Row>
        );
    }
};

const LessonForm = Form.create({name: 'lesson_form'})(LessonAdd);

export default withRouter(LessonForm);
