import 'whatwg-fetch'; 
import config from '../config';
const getAuthToken = () => localStorage.getItem('authToken');

const request = method => async (resource, options = {}) => {
    const { apiRoot } = config;
  
    if (!resource || !apiRoot) return null;
    return await fetch(`${apiRoot}/${resource}`, {
        method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getAuthToken()
        },
        ...options
    })
    .then((result) => {
        if (result.ok) {
            return result.json();
        } else {
            return result.text().then(bodyText => {
                if (bodyText === '' && result.statusText !== '') {
                    return '{"status":"NOK","result":{"error":"' + result.statusText + '"}}';
                }
                return '{"status":"NOK","result":{"error":"' + bodyText + '"}}';
            });
        }
    })
    .catch((error) => {
        return '{"status":"NOK","result":{"error":"Unknown Error"}}';
    });
};

export default {
    get: request('GET'),
    post: request('POST'),
    patch: request('PATCH'),
    put: request('PUT'),
    delete: request('DELETE'),
    getAuthToken: getAuthToken
};
