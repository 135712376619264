import React from 'react';
import { Row, Col } from 'antd';
import RemindersForm from '../elements/reminders/RemindersForm';

class ReminderAdd extends React.Component {

    componentDidMount() {
        this.props.hideReminderDrawer();
    }

    render() {
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    <h2>New Reminder</h2>
                    <RemindersForm setAppStateValue = {this.props.setAppStateValue} dueDate={this.props.match.params.date} chosen_driver={this.props.chosen_driver} counts={this.props.counts} />
                </Col>
            </Row>
        );
    }
};

export default ReminderAdd;