import React from 'react';
import { Modal, Button } from 'antd';

class BadTestModal extends React.Component {

    state = {
        displayConfirmModal: false
    };

    render() {
        return (
            <>
                <Modal
                    zIndex={5000}
                    destroyOnClose={true}
                    className="bad-test-modal"
                    visible={true}
                    title="Test Result Required"
                    closable={false}
                    footer={[
                        <Button
                            key="pass"
                            onClick={() => {
                                this.setState({displayConfirmModal: true});
                            }}
                            type="primary"
                        >
                            Pass
                        </Button>,
                        <Button
                            key="fail"
                            onClick={() => {
                                if (this.props.test.booking_type === 'Driving Test') {
                                    this.props.testResultSave(this.props.test.id, false);
                                } else if (this.props.test.booking_type === 'Aged Assessment') {
                                    this.props.agedAssessmentResultSave(this.props.test.id, false);
                                }
                            }}
                            type="danger"
                        >
                            Fail
                        </Button>
                    ]}
                >
                    Did {this.props.test.title} pass their {this.props.test.booking_type} on {new Date(Date.parse(this.props.test.start)).toLocaleDateString()}?
                </Modal>
                <Modal
                    zIndex={5000}
                    destroyOnClose={true}
                    className="bad-test-modal"
                    visible={this.state.displayConfirmModal}
                    title="Please Confirm Pass"
                    closable={false}
                    footer={[
                        <Button
                            key="pass"
                            onClick={() => {
                                if (this.props.test.booking_type === 'Driving Test') {
                                    this.props.testResultSave(this.props.test.id, true);
                                } else if (this.props.test.booking_type === 'Aged Assessment') {
                                    this.props.agedAssessmentResultSave(this.props.test.id, true);
                                }
                            }}
                            type="primary"
                        >
                            Confirm
                        </Button>,
                        <Button
                            key="fail"
                            onClick={() => {
                                this.setState({displayConfirmModal: false});
                            }}
                            type="danger"
                        >
                            Go Back
                        </Button>
                    ]}
                >
                    Please confirm that {this.props.test.title} passed their {this.props.test.booking_type} on {new Date(Date.parse(this.props.test.start)).toLocaleDateString()}.<br />
                    The office will be sending them a congratulations email.
                </Modal>
            </>
        );
    }
};

export default BadTestModal;
